﻿//
// Pages
//

body.page {
  &:not([class="parent_category"]){
    h1{
      @include media-breakpoint-down(sm) {
        font-size: 1.8rem;
      }
    }
  }
  main{
    margin-bottom: 5rem;
    @include media-breakpoint-down(md) {
      margin-bottom: 3rem;
    }
  }
  h1,
  h2,
  h3,
  h4,
  h5{
    @include mintyo();
    @include media-breakpoint-down(md) {
      font-family: $font-family-android;
    }
  }
  h1{
    padding: 1.5rem 0 2rem;

  }
  h2{
    margin: 1.5rem 0 1.5rem;
    padding:.5rem .5rem .5rem 1rem;
  }
  h3{
    margin: 1.5rem 0 1.5rem;
    &:before{
      content:"";
      display: inline-block;
      width:20px;
      height:21px;
      background: url(#{$dir}/pages/bg_head_pattern01.svg)no-repeat;
      background-size: 20px 21px;
      margin-right: 1rem;
    }
  }
  h4{
    margin: 1.5rem 0 1.5rem;
    span{
      &:after{
        content:"";
        display: inline-block;
        width:39px;
        height:9px;
        background: url(#{$dir}/pages/bg_head_pattern02.svg)no-repeat;
        background-size: 39px 9px;
        margin-left: 1rem;
        margin-bottom: .2rem;
      }
    }
  }
  h5{
    margin: 1.5rem 0 1rem;
  }
  section{
    margin-bottom: 2rem;
  }
  .breadcrumb{
    background-color: transparent;
    padding:0.75rem 1rem 1.5rem 0;
  }
  .single__img,
  .single__img__add-caption,
  .two__img,
  .two__img__add-caption,
  .three__img,
  .three__img__add-caption,
  .four__img,
  .four__img__add-caption{
    // margin-bottom: 1rem;
    .img__item{
      // @include media-breakpoint-down(md) {
        margin-bottom: 1rem;
      // }
    }
  }
  .single__img{
    margin-bottom: 1rem;
  }
  .two__img__add-caption2{
    figcaption{
      p.bold{
        font-weight: bold;
        font-size: rem-calc(24);
        margin: .5rem 0;
      }
    }
  }
  ol{
    &.bold{
      font-weight: bold;
    }
  }
  .gmap{
    a{
      &:before{
        content:"";
        display: inline-block;
        background: url(#{$dir}/common/icon_googlemap.svg)no-repeat;
        background-size: 18px 18px;
        width:18px;
        height:18px;
        vertical-align: middle;
        margin-bottom: 2px;
        margin-right: 5px;
      }
    }
  }
  .page-header{
    padding-top: 30%;
    position: relative;
    width:100%;
    height:100%;
    @include media-breakpoint-down(sm) {
      padding-top: 60%;
    }
    .page-header__inner{
      position: absolute;
      left:0;
      top:0;
      width:100%;
      height:100%;
      // background: url(#{$dir}/pages/bg_pages_main_sample.jpg)no-repeat;
      // background-size: cover;
      // background-position: center center;
      &:after{
        content:"";
        display: block;
        background: url(#{$dir}/pages/bg_white_wave.svg)repeat-x;
        background-size: 20px 10px;
        width:100%;
        height:10px;
        position: absolute;
        bottom:0;
      }
      @include media-breakpoint-down(xs) {
        // background: url(#{$dir}/pages/bg_pages_main_sample_sp.jpg)no-repeat;
        // background-size: cover;
        // background-position: center center;
      }
      .catch-copy{
        @include mintyo();
        font-size: 1.5rem;
        position: absolute;
        top:50%;
        left:50%;
        transform: translate(-50%,-50%);
        color:$white;
        background: rgba(#000,.7);
        margin-bottom: 0;
        padding:1rem 3rem;
        text-align: center;
        @include media-breakpoint-down(sm) {
          padding:1rem;
          width:90%;
          font-size: 1.1rem;
        }
      }
    }
  }
  &.parent_know{
    .page-header{
      .page-header__inner{
        // background: url(#{$dir}/pages/bg_main_know.jpg)no-repeat;
        // background-size: cover;
        // background-position: center center;
      }
    }
  }
  &.dmo-about{
    .page-header{
      .page-header__inner{
        // background: url(#{$dir}/pages/bg_main_dmo.jpg)no-repeat;
        // background-size: cover;
        // background-position: center center;
      }
    }
    .charm__container{
      margin-bottom: 8rem;
    }
    .sanriku-kikin{
      list-style: none;
      text-decoration: none;
      padding: 0;
      li{
        margin-bottom: .5em;
        a{
          text-decoration: none;
          color: #333;
          display: block;
          background-color: #e3edf4;
          padding: 1rem 1.5rem 1rem 1rem;
          height: 100%;
          display: flex;
          align-items: center;
          transition: .2s;
          position: relative;
          &:hover{
            background-color: #d1e1ed;
          }
          &:after{
            content: "";
            display: inline-block;
            position: absolute;
            right: 20px;
            top: 0;
            bottom: 0;
            margin: auto;
            width: 10px;
            height: 10px;
            border-bottom: 1px solid #707070;
            border-right: 1px solid #707070;
            transform: rotate(-45deg);
          }
          span{
            display: flex;
            align-items: center;
            &:before{
              content: "";
              display: inline-block;
              background: url(../img/common/icon_new-window.svg) no-repeat;
              background-size: 32px 28px;
              width: 32px;
              height: 28px;
              padding-left: 31px;
              margin-right: 1rem;
              margin-left: .5rem;
            }
          }
        }
      }
    }
    // 三陸の魅力
    .charm__wrap{
      position: relative;
      @include media-breakpoint-up(lg) {
        padding-top: 25%;
      }
      @include media-breakpoint-down(md) {
        margin-bottom: 4rem;
        padding-top: 0;
      }
      &:before{
          content:"";
          display: block;
          background: url(#{$dir}/home/bg_charm_map.png)no-repeat;
          background-size: 495px 740px;
          width:495px;
          height:740px;
          position: absolute;
          bottom: -150px;
          left:-90px;
          z-index: -10;
          margin:auto;
          @include media-breakpoint-down(md) {
            left:-210px;
          }
          @include media-breakpoint-down(sm) {
            left:auto;
            right:20px;
            bottom:-770px;
          }
      }
      .charm__first-bg{
        padding-top: 25%;
        margin:auto;
        @include media-breakpoint-up(lg) {
          position: absolute;
          left:-100%;
          right:-100%;
          top:0;
          background: url(#{$dir}/home/bg_charm01.jpg)no-repeat;
          background-size: 100%;
          background-position: center center;
          width:100vw;
        }
        @include media-breakpoint-down(md) {
          background: url(#{$dir}/home/bg_charm01_sp.jpg)no-repeat;
          background-size: 100%;
          background-position: center center;
          padding-top:65%;
          margin-bottom: 2rem;
        }
      }
      .block__txt{
        @include mintyo();
        span{
          background-color: rgba($white,.8);
          display: inline-block;
          letter-spacing: 3px;
          line-height: 1.5;
          padding:.5rem;
          margin-bottom:3px;
          @include media-breakpoint-down(md) {
            line-height: 1.5;
            padding:0.2rem .5rem;
          }
        }
      }
      .block__img{
        @include media-breakpoint-down(md) {
          text-align: center;
        }
      }
      .charm__intro{
        position: relative;
        border-left: none;
        span{
          display: inline-block;
          transform:scale(1.1)translateY(-30px);
          opacity: 0;
          @media print{
            opacity: 1;
          }
          transition:1s cubic-bezier(0.38, 0.1, 0.1, 0.99);
        }
        @include mintyo();
        font-size: rem-calc(38);
        @include media-breakpoint-up(lg) {
          padding-top: 2rem;
          margin-bottom: 90px;
        }
        @include media-breakpoint-down(md) {
          font-size: rem-calc(30);
          line-height: 1.5;
          margin-bottom: 2rem;
          font-family: $font-family-android;
        }
        &:after{
          content:"";
          display: block;
          background: url(#{$dir}/home/bg_charm_weave.png)no-repeat;
          background-size: cover;
          width:100vw;
          height:175px;
          position: absolute;
          top:0;
          left:-100%;
          right:-100%;
          z-index: -10;
          margin:auto;
          @include media-breakpoint-down(md) {
            background: url(#{$dir}/home/bg_charm_weave_sp.png)no-repeat;
            background-size: cover;
            height:65px;
          }
        }
        &.scroll-in{
          span{
            transform:scale(1)translateY(0);
            opacity: 1;
          }
        }
      }
      .first__block{
        position: relative;
        @include media-breakpoint-up(lg) {
          display: flex;
          flex-direction: row-reverse;
        }
        .block__img{
          opacity: 0;
          @media print{
            opacity: 1;
          }
          transition:1s cubic-bezier(0.38, 0.1, 0.1, 0.99);
          @include media-breakpoint-up(lg) {
            transform:scale(1.1)translate(40px,-40px);
          }
          @include media-breakpoint-down(md) {
            transform: scale(1.1)translateX(-30px);
            width:90%;
          }
          &.scroll-in{
            transform:scale(1)translate(20px,-40px);
            opacity: 1;
            @include media-breakpoint-down(md) {
              transform: scale(1.1)translateX(-10px);
            }
          }
        }
        .block__txt{
          @include media-breakpoint-up(lg) {
            position: absolute;
            left:0;
            z-index: 1;
            transform:translate(100px);
          }
          @include media-breakpoint-down(md) {
            transform:translateY(-50px);
            text-align: right;
            margin-bottom: 0;
            font-family: $font-family-android;
          }
          span{
            position: relative;
            opacity: 0;
            @media print{
            opacity: 1;
          }
            transition:1s cubic-bezier(0.38, 0.1, 0.1, 0.99);
            // transform:translateX(-40px);
            @include media-breakpoint-up(lg) {
              transform:translateX(-150px);
            }
          }
          &.scroll-in{
            span{
              transform: translateX(-100px);
              opacity: 1;
              @include media-breakpoint-down(md) {
                transform: translateX(10px);
              }
            }
          }
        }
      }
      .second__block{
        position: relative;
        display: flex;
        justify-content: flex-end;
        @include media-breakpoint-down(md) {
          display: block;
        }
        .block__img{
          opacity: 0;
          @media print{
            opacity: 1;
          }
          transition:1s cubic-bezier(0.38, 0.1, 0.1, 0.99);
          @include media-breakpoint-up(lg) {
            transform: scale(1.1)translate(-90px,-100px)
          }
          @include media-breakpoint-down(md) {
            text-align: right;
            transform: scale(1.1)translate(-40px,-40px);
            width:90%;
            margin-left: auto;
          }
          &.scroll-in{
            transform: scale(1)translate(-70px,-100px);
            opacity: 1;
            @include media-breakpoint-down(md) {
              transform:scale(1)translate(10px,0);
            }
          }
        }
        .block__txt{
          position: relative;
          opacity: 0;
          @media print{
            opacity: 1;
          }
          transition:1s cubic-bezier(0.38, 0.1, 0.1, 0.99);
          @include media-breakpoint-up(lg) {
            z-index: 1;
            transform:translate(0,-40px);
          }
          @include media-breakpoint-down(md) {
            transform:translateY(-50px);
            font-family: $font-family-android;
          }
          &.scroll-in{
            transform: scale(1)translate(-40px,-40px);
            opacity: 1;
            @include media-breakpoint-down(md) {
              transform: scale(1)translate(-10px,-40px);
            }
          }
        }
      }
      .third__block{
        position: relative;
        @include media-breakpoint-up(lg) {
          display: flex;
          justify-content: flex-end;
          flex-direction: row-reverse;
          transform: translateY(-100px);
          &:after{
            content:"";
            background: url(#{$dir}/home/img_charm_item01.png)no-repeat;
            background-size: 67px 62px;
            width:67px;
            height:62px;
            position: absolute;
            top:30px;
            left:0;
          }
        }
        .block__txt{
          position: relative;
          @media print{
            opacity: 1;
          }
          transition:1s cubic-bezier(0.38, 0.1, 0.1, 0.99);
          transform:translateY(-50px);
          @include media-breakpoint-up(lg) {
            margin: 3rem auto 0;
          }
          @include media-breakpoint-down(md) {
            position: absolute;
            top: 130px;
            left: 0;
            right: 0;
            margin: auto;
            font-family: $font-family-android;
          }
          &.scroll-in{
            transform: translateY(0);
            opacity: 1;
          }
        }
        @media all and (-ms-high-contrast: none) {
          @include media-breakpoint-up(lg) {
            *::-ms-backdrop, .block__txt {
              margin:3rem 0 0;
              width:100%;
            }
          }
        }
        .block__img{
          &.img__first{
            opacity: 0;
            @media print{
            opacity: 1;
          }
            transition:1s cubic-bezier(0.38, 0.1, 0.1, 0.99);
            transform:translateX(50px);
            @include media-breakpoint-up(lg) {
              position: absolute;
              top: -50px;
              right: -50px;
              z-index: -1;
            }
            @include media-breakpoint-down(md) {
              text-align: right;
              transform:translateX(10px);
              width: 90%;
              margin-left: auto;
            }
            &.scroll-in{
              transform: scale(1)translateX(10px);
              opacity: 1;
            }
          }
          &.img__second{
            opacity: 0;
            @media print{
            opacity: 1;
          }
            transition:1s cubic-bezier(0.38, 0.1, 0.1, 0.99);
            transform:scale(1.1)translate(-50px,0);
            @include media-breakpoint-up(lg) {
              position: absolute;
              bottom: -50px;
              left: -50px;
              z-index: -1;
            }
            @include media-breakpoint-down(md) {
              position: relative;
              z-index: -1;
              text-align: left;
              transform: translate(-10px,120px);
              margin-bottom: 3rem;
              width: 90%;
            }
            &.scroll-in{
              transform: scale(1)translate(0,0);
              opacity: 1;
              @include media-breakpoint-down(md) {
                transform:scale(1) translate(-10px,70px);
              }
            }
          }
        }
      }
      .force__block{
        position: relative;
        @include media-breakpoint-up(lg) {
          &:after{
            content:"";
            background: url(#{$dir}/home/img_charm_item02.png)no-repeat;
            background-size: 113px 54px;
            width:113px;
            height:54px;
            position: absolute;
            top: -40px;
            right: 170px;
          }
          &:before{
            content:"";
            background: url(#{$dir}/home/img_charm_item03.png)no-repeat;
            background-size: 130px 109px;
            width:130px;
            height:109px;
            position: absolute;
            top:-60px;
            left:10px;
            z-index: -1;
          }
        }
        .inner{
          display: flex;
          justify-content: center;
          @include media-breakpoint-down(md) {
            flex-direction: column-reverse;
            flex-wrap:wrap;
          }
          figcaption{
            margin-top: 0.5rem;
            @include media-breakpoint-down(md) {
              font-family: $font-family-android;
            }
          }
        }
        .charm__message{
          @include mintyo();
          font-size: rem-calc(22);
          letter-spacing: 3px;
          line-height: 2;
          display: inline-block;
          margin: 9rem 0 9rem 440px;
          opacity: 0;
          @media print{
            opacity: 1;
          }
          @media print{
            opacity: 1;
          }
          transition:2s cubic-bezier(0.38, 0.1, 0.1, 0.99);
          transform:scale(1.1)translate(0,-30px);
          @include media-breakpoint-down(md) {
            font-family: $font-family-android;
            margin: 9rem 0 9rem 300px;
          }
          @include media-breakpoint-down(sm) {
            display: block;
            text-align: center;
            margin: 6rem 0 740px;
          }
          &.scroll-in{
            transform: scale(1)translate(0,0);
            opacity: 1;
          }
        }
        .block__txt{
          position: relative;
          opacity: 0;
          @media print{
            opacity: 1;
          }
          transition:1s cubic-bezier(0.38, 0.1, 0.1, 0.99);
          @include media-breakpoint-up(lg) {
            transform:scale(1.2)translate(40px,60px);
          }
          @include media-breakpoint-down(md) {
            text-align: right;
            transform:translate(50px,0);
            font-family: $font-family-android;
          }
          &.scroll-in{
            transform: scale(1)translate(0,60px);
            opacity: 1;
            @include media-breakpoint-down(md) {
              transform:translate(10px,60px);
            }
          }
        }
        .block__img{
          opacity: 0;
          @media print{
            opacity: 1;
          }
          transition:1s cubic-bezier(0.38, 0.1, 0.1, 0.99);
          transform: scale(1.1)translate(-60px,-60px);
          &.img__first{
            @include media-breakpoint-up(md) {
              margin-right: 1rem;
            }
          }
          &.scroll-in{
            transform: scale(1)translate(0,50px);
            opacity: 1;
          }
        }
      }
    }
  }
  &.area_info{
    .page-header{
      .page-header__inner{
        // background: url(#{$dir}/pages/bg_main_info.jpg)no-repeat;
        // background-size: cover;
        // background-position: center center;
      }
    }
  }
  &.parent_visit{
    .page-header{
      .page-header__inner{
        // background: url(#{$dir}/pages/bg_main_visit.jpg)no-repeat;
        // background-size: cover;
        // background-position: center center;
      }
    }
  }
  &.lodging{
    .page-header{
      .page-header__inner{
        // background: url(#{$dir}/pages/bg_main_odging.jpg)no-repeat;
        // background-size: cover;
        // background-position: center center;
      }
    }
  }
  &.access{
    .page-header{
      .page-header__inner{
        // background: url(#{$dir}/pages/bg_main_access.jpg)no-repeat;
        // background-size: cover;
        // background-position: center center;
      }
    }
  }
  &.fun{
    .page-header{
      .page-header__inner{
      //   background: url(#{$dir}/pages/bg_main_fun.jpg)no-repeat;
      //   background-size: cover;
      //   background-position: center center;
      }
    }
  }
  &.activity{
    .page-header{
      .page-header__inner{
        // background: url(#{$dir}/pages/bg_main_activity.jpg)no-repeat;
        // background-size: cover;
        // background-position: center center;
      }
    }
  }
  &.sitemap{
    .page-header{
      .page-header__inner{
        // background: url(#{$dir}/pages/bg_main_know.jpg)no-repeat;
        // background-size: cover;
        // background-position: center center;
      }
    }
  }
  &.parent_category{
    .page-header{
      padding-top: 35%;
      @include media-breakpoint-down(sm) {
        padding-top: 60%;
      }
    }
  }
  // ページイントロ文章
  .page__intro__wrap{
    position: relative;
    padding-bottom: 3rem;
    .intro__description{
      @include mintyo();
    }
    .intro__period__block{
      letter-spacing: -.4em;
      display: flex;
      flex-wrap:wrap;
      .intro__period{
        letter-spacing: normal;
        background-color: $secondary;
        display: flex;
        align-items: center;
        padding: 0.5rem 1rem;
        @include media-breakpoint-down(sm) {
          width: 100%;
          text-align: center;
        }
      }
      .label__lists{
        letter-spacing: normal;
        list-style: none;
        padding-left: 0;
        display: flex;
        flex-wrap:wrap;
        li{
          margin:.3rem 0 .3rem .5rem;
          background-color: $secondary;
          padding: 0.2rem 0.7rem;
          border-radius: 20px;
        }
      }
    }
  }
  &.detail{
    .page-header{
      padding-top: 30%;
      @include media-breakpoint-down(sm) {
        padding-top: 100%;
      }
    }
    .spot__transportation__lists{
      .spot__item{
        margin-bottom: 1rem;
        @include media-breakpoint-down(sm) {
          margin-bottom: 0.2rem;
          padding:0 .2rem;
          &:nth-of-type(even){
            padding-left: 0;
          }
        }
        &.spot__walk{
          a{
            &:before{
              content:"";
              display: block;
              margin:auto;
              background: url(#{$dir}/pages/icon_walk.svg) no-repeat;
              background-size: 25px 46px;
              width:25px;
              height:46px;
            }
          }
        }
        &.spot__cycle{
          a{
            &:before{
              content:"";
              display: inline-block;
              display: block;
              margin:auto;
              background: url(#{$dir}/pages/icon_bicycle.svg)no-repeat;
              background-size: 64px 42px;
              width:64px;
              height:42px;
            }
          }
        }
        &.spot__bus{
          a{
            &:before{
              content:"";
              display: inline-block;
              display: block;
              margin:auto;
              background: url(#{$dir}/pages/icon_bus.svg)no-repeat;
              background-size: 90px 36px;
              width:90px;
              height:36px;
            }
          }
        }
        &.spot__ferry{
          a{
            &:before{
              content:"";
              display: inline-block;
              display: block;
              margin:auto;
              background: url(#{$dir}/pages/icon_ferry.svg)no-repeat;
              background-size: 78px 43px;
              width:78px;
              height:43px;
            }
          }
        }
        &.spot__car{
          a{
            &:before{
              content:"";
              display: inline-block;
              display: block;
              margin:auto;
              background: url(#{$dir}/pages/icon_car.svg)no-repeat;
              background-size: 74px 28px;
              width:74px;
              height:28px;
            }
          }
        }
        &.spot__train{
          a{
            &:before{
              content:"";
              display: inline-block;
              display: block;
              margin:auto;
              background: url(#{$dir}/pages/icon_train.svg)no-repeat;
              background-size: 93px 32px;
              width:93px;
              height:32px;
            }
          }
        }
        &.spot__taxi{
          a{
            &:before{
              content:"";
              display: inline-block;
              display: block;
              margin:auto;
              background: url(#{$dir}/pages/icon_taxi.svg)no-repeat;
              background-size: 92px 32px;
              width:92px;
              height:32px;
            }
          }
        }
        &.spot__rental-car{
          a{
            &:before{
              content:"";
              display: inline-block;
              display: block;
              margin:auto;
              background: url(#{$dir}/pages/icon_car-rental.svg)no-repeat;
              background-size: 73px 29px;
              width:73px;
              height:29px;
            }
          }
        }
        a{
          text-align: center;
          text-decoration: none;
          display: block;
          border:2px solid #00729C;
          padding: 1rem .5rem;
          border-radius: 10px;
          height:100%;
          transition:.2s;
          &:hover{
            opacity: .7;
          }
          span{
            display: block;
            &:first-of-type{
              font-weight: bold;
              color:$black;
              margin-top: .5rem;
            }
            &:last-of-type{
              &.gmap{
                &:before{
                  content:"";
                  display: inline-block;
                  background: url(#{$dir}/common/icon_googlemap.svg)no-repeat;
                  background-size: 18px 18px;
                  width:18px;
                  height:18px;
                  vertical-align: middle;
                  margin-bottom: 2px;
                  margin-right: 5px;
                }
              }
            }
          }
        }
      }
    }
  }
  &.detail,
  &.general{
    .page__intro__wrap{
      &:after{
        @include media-breakpoint-up(lg) {
          content:"";
          display: block;
          background: url(#{$dir}/pages/bg_fish.png)no-repeat;
          background-size: 159px 115px;
          width:159px;
          height:115px;
          position: absolute;
          left:100%;
          bottom:100px;
          z-index: -1;
        }
      }
      &:before{
        content:"";
        display: block;
        background: url(#{$dir}/pages/bg_pages_separation.svg)repeat-x;
        background-size: 41px 24px;
        width:105vw;
        height:24px;
        position: absolute;
        left:-100%;
        right:-100%;
        bottom:10px;
        margin:auto;
        z-index: -1;
      }
    }
    .inquiry__block{
      padding:1rem;
      background-color: $gray-200;
      margin-bottom: 1rem;
      .inquiry__title{
        font-size: rem-calc(22);
        margin-bottom: 0;
      }
      ul{
        margin-bottom: 0;
      }
    }
    .inquiery__aside{
      list-style: none;
      padding-left: 0;
    }
    .how-to-access{
      position: relative;
      margin-bottom: 5rem;
      &:before{
        content:"";
        display: block;
        width: 100vw;
        height:100%;
        position: absolute;
        left:-50%;
        right:-50%;
        z-index: -1;
        margin:auto;
        background-color: $secondary;
      }
      a{
        display: block;
        text-decoration: none;
        color:$black;
        padding: 1rem 0;
        @include opacity();
        span{
          display: flex;
          align-items: center;
          &:before{
            content:"";
            display: inline-block;
            background: url(#{$dir}/common/icon_access.svg)no-repeat;
            background-size: 31px 40px;
            width:31px;
            height:40px;
            margin-right: .5rem;
          }
          &::after{
            content:"";
            display: inline-block;
            width:8px;
            height:8px;
            border-right: 1px solid $black;
            border-bottom: 1px solid $black;
            transform:rotate(-45deg);
            margin-left: .5rem;
          }
        }
      }
    }
    .aside__article__title{
      &:before{
        content:"";
        background: url(#{$dir}/common/icon_article-list01.svg)no-repeat;
        background-size: 19px 23px;
        height:23px;
        width:19px;
        vertical-align: bottom;
      }
    }
    .aside__spot__title{
      &:before{
        content:"";
        background: url(#{$dir}/common/icon_neighborhood.svg)no-repeat;
        background-size: 26px 24px;
        width:26px;
        height:24px;
        vertical-align: bottom;
      }
    }
  }

  // 親カテゴリページ
  &.parent_category{
    $page_color:$fun_color;
    .page__head{
      @include media-breakpoint-down(sm) {
        text-align: center;
      }
      img{
        @include media-breakpoint-down(sm) {
          max-width:250px;
        }
      }
    }
    .page__intro__wrap{
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      position: relative;
      margin-bottom: 6rem;
      padding-bottom: 0;
      @include media-breakpoint-only(md) {
        margin-top: 5rem;
        padding-top: 1rem;
        align-items: flex-start;
      }
      @include media-breakpoint-down(sm) {
        display: block;
        margin-bottom: 2rem;
      }
      &:before{
        content:"";
        display: block;
        position: absolute;
        top:0;
        right:-100px;
        width:741px;
        height:100px;
        background: url(#{$dir}/pages/bg_main-head01.svg)no-repeat;
        background-size: 741px 100px;
        @include media-breakpoint-down(md) {
          bottom:95%;
          top:initial;
        }
        @include media-breakpoint-down(sm) {
          display: none;
        }
      }
      &:after{
        content:"";
        display: block;
        position: absolute;
        top:100%;
        left:-100px;
        width:1205px;
        height:100px;
        background: url(#{$dir}/pages/bg_main-head02.svg)no-repeat;
        background-size: 1205px 100px;
        @include media-breakpoint-down(sm) {
          display: none;
        }
      }
      .page__head{
        @include media-breakpoint-up(md) {
          width:35%;
          margin-right: 5%;
        }
      }
      .intro{
        @include media-breakpoint-up(md) {
          width:60%;
        }
        .intro__head{
          @include mintyo();
          font-size: rem-calc(37);
          @include media-breakpoint-down(md) {
            font-family: $font-family-android;
          }
          strong{
            font-weight: normal;
          }
        }
        .intro__content{
          line-height: 2;
        }
      }
      &.access{
        margin-bottom: 8rem;
        @include media-breakpoint-down(sm) {
          margin-bottom: 6rem;
        }
      }
    }
    // &.parent_fun{
    //   $page_color:$fun_color;
    //   .page__intro__wrap{
    //     &:before{
    //       background: url(#{$dir}/pages/bg_main-head01.svg)no-repeat;
    //       background-size: 741px 100px;
    //     }
    //     &:after{
    //       background: url(#{$dir}/pages/bg_main-head02.svg)no-repeat;
    //       background-size: 1205px 100px;
    //     }
    //   }
    // }
    // &.parent_visit{
    //   $page_color:$visit_color;
    //   .page__intro__wrap{
    //     &:before{
    //       background: url(#{$dir}/pages/bg_main-head01.svg)no-repeat;
    //       background-size: 741px 100px;
    //     }
    //     &:after{
    //       background: url(#{$dir}/pages/bg_main-head02.svg)no-repeat;
    //       background-size: 1205px 100px;
    //     }
    //   }
    // }
    // &.parent_know{
    //   $page_color:$know_color;
    //   .page__intro__wrap{
    //     &:before{
    //       background: url(#{$dir}/pages/bg_main-head01.svg)no-repeat;
    //       background-size: 741px 100px;
    //     }
    //     &:after{
    //       background: url(#{$dir}/pages/bg_main-head02.svg)no-repeat;
    //       background-size: 1205px 100px;
    //     }
    //   }
    // }
  }
  &.fun,
  &.general{
    $page_color:       $fun_color;
    $page_definition:  rgba($page_color,.4);
    &.none_visual{
      h1{
        padding:1.5rem 0 1.3rem;
        margin-bottom: 0;
      }
      .page-contents{
        margin-top: 2rem;
        @include media-breakpoint-down(sm) {
          margin-top: 6rem;
        }
      }
    }
    &.detail{
      h1{
        padding:1.5rem 0 1.3rem;
        margin-bottom: 0;
      }
    }
    h2{
      border-left: 5px solid $page_color;
    }
    .page__intro__wrap{
      .intro{
        @include media-breakpoint-up(lg) {
          padding-top: 5rem;
        }
      }
    }
    .intro__catch-copy{
      font-size: rem-calc(32);
      @include media-breakpoint-down(sm) {
        font-size: rem-calc(20);
      }
      color:$page_color;
      @include mintyo();
    }
    .p-important__txt{
      border:5px solid $page_color;
    }
    .p-blockquote{
      border-left: 5px solid $page_color;
    }
    .p-definition__site{
      dt {
        background: $page_definition;
      }
    }
    .pickup__head{
      color:$page_color;
    }
  }
  &.visit{
    $page_color:      $visit_color;
    $page_definition: rgba($page_color,.4);
    &.none_visual{
      h1{
        padding:1.5rem 0 1.3rem;
        margin-bottom: 0;
      }
      .page-contents{
        margin-top: 2rem;
        @include media-breakpoint-down(sm) {
          margin-top: 6rem;
        }
      }
    }
    &.detail{
      h1{
        padding:1.5rem 0 1.3rem;
        margin-bottom: 0;
      }
    }
    h2{
      border-left: 5px solid $page_color;
    }
    .page__intro__wrap{
      .intro{
        @include media-breakpoint-up(lg) {
          padding-top: 5rem;
        }
      }
      .intro__catch-copy{
        font-size: rem-calc(32);
        @include media-breakpoint-down(sm) {
          font-size: rem-calc(20);
        }
        color:$page_color;
        @include mintyo();
      }
    }
    .p-important__txt{
      border:5px solid $page_color;
    }
    .p-blockquote{
      border-left: 5px solid $page_color;
    }
    .p-definition__site{
      dt {
        background: $page_definition;
      }
    }
    .pickup__head{
      color:$page_color;
    }
    .piciup__article{
      li{
        &:first-of-type{
          @include media-breakpoint-down(md) {
            text-align: center;
            a{
              display: inline-block;
            }
          }
        }
      }
    }
  }
  &.know{
    $page_color:       $know_color;
    $page_definition:  rgba($page_color,.4);
    &.none_visual{
      h1{
        padding:1.5rem 0 1.3rem;
        margin-bottom: 0;
      }
      .page-contents{
        margin-top: 2rem;
        @include media-breakpoint-down(sm) {
          margin-top: 6rem;
        }
      }
    }
    &.detail{
      h1{
        padding:1.5rem 0 1.3rem;
        margin-bottom: 0;
      }
    }
    .page__intro__wrap{
      .intro{
        @include media-breakpoint-up(lg) {
          padding-top: 5rem;
        }
        .intro__head{
          font-size: rem-calc(28);
        }
      }
      .intro__catch-copy{
        font-size: rem-calc(32);
        @include media-breakpoint-down(sm) {
          font-size: rem-calc(20);
        }
        color:$page_color;
        @include mintyo();
      }
    }
    h2{
      border-left: 5px solid $page_color;
    }

    .p-important__txt{
      border:5px solid $page_color;
    }
    .p-blockquote{
      border-left: 5px solid $page_color;
    }
    .p-definition__site{
      dt {
        background: $page_definition;
      }
    }
    .pickup__head{
      color:$page_color;
    }
  }
  // アクセスページ
  &.access{
    .expantion__btn{
      text-align: right;
      margin-bottom: 0;
      margin-top: 1rem;
      @include media-breakpoint-up(md) {
        display: none;
      }
    }
    .intro__description{
      margin-bottom: 2.5rem;
    }
    h2{
      position: relative;
      color:$primary;
      font-size: rem-calc(25);
      font-weight: bold;
      border-left:none;
      @include gothic();
      &:before{
        content:"";
        display: block;
        position: absolute;
        top:100%;
        left:0;
        width:100%;
        height:3px;
        background-color:#D4D4D4;
      }
      &:after{
        content:"";
        display: block;
        position: absolute;
        top:100%;
        left:0;
        width:30%;
        height:3px;
        background-color:$primary;
        z-index: 10;
      }
    }
    .countrywide__wrap,
    .around__wrap,
    .sanriku__wrap{
      padding: 1rem 0 3rem;
    }
    .countrywide__wrap,
    .sanriku__wrap{
      background-color: $secondary;
      h2{
        color:#227295;
        &:before{
          background-color: $white;
        }
      }
    }

    .countrywide__wrap{
      .access__nationwide{
        background-color: $white;
        padding:1rem .5rem;
        margin-bottom: 3rem;
      }
      h2{
        span{
          &:before{
            content:"";
            display: inline-block;
            background: url(#{$dir}/pages/icon_access01.svg)no-repeat;
            background-size: 45px 49px;
            width:45px;
            height:49px;
            transform: translateY(5px);
            margin-right: 5px;
          }
        }
      }
      .access__countrywide__tab{
        .nav-tabs{
          li{
            width:calc( 98.5% / 4);
            // @include media-breakpoint-down(sm) {
            //   width:100%;
            // }
            @include media-breakpoint-down(sm) {
              // width:calc( / 5);
            }
            &:not(:last-of-type){
              margin-right: 0;
              @include media-breakpoint-up(md) {
                margin-right: .5%;
              }
              @include media-breakpoint-down(sm) {
                margin-right: .5%;
                // margin-right: 1px;
                // margin-bottom: 2px;
              }
            }
            a{
              background-color: $primary;
              color:$white;
              font-weight: bold;
              border:none;
              text-align: center;
              padding: 1rem .5rem;
              height:100%;
              display: flex;
              align-items: center;
              justify-content: center;
              @include media-breakpoint-down(sm) {
                padding:.5rem .1rem;
                font-size: rem-calc(13);
                height: 100%;
                line-height: 1.4;
              }
              &.active{
                background-color: $white;
                color:$primary;
              }
            }
          }
        }
        .tab-content{
          background: $white;
          border:none;
          padding:2rem .5rem;
          @include media-breakpoint-down(sm) {
            padding:2rem .5rem .5rem;
          }
          // #countrywide__tab1,
          // #countrywide__tab2,
          // #countrywide__tab3{
          //   img{
          //     @include media-breakpoint-up(md) {
          //       // width:90%;
          //     }
          //   }
          //   h3{
          //     font-size: rem-calc(24);
          //     color:#BB0900;
          //     font-weight: bold;
          //     margin-bottom: .5rem;
          //     @include gothic();
          //     &:before{
          //       content:none;
          //     }
          //   }
          //   ul{
          //     display: flex;
          //     flex-wrap:wrap;
          //     padding:1rem 0;
          //     border-bottom: 1px solid $gray-400;
          //     border-top: 1px solid $gray-400;
          //     li{
          //       &:not(:last-of-type){
          //         margin-right: 1rem;
          //       }
          //       a{
          //         text-decoration: underline;
          //         font-size: rem-calc(18);
          //       }
          //     }
          //   }
          // }
          #countrywide__tab1,
          #countrywide__tab3{
            img{
              // padding-right: 2.2%;
              // @include media-breakpoint-up(lg) {
              //   padding-right: 21px;
              // }
            }
          }
        }
      }
    }
    .cities__wrap{
      h2{
        span{
          &:before{
            content:"";
            display: inline-block;
            background: url(#{$dir}/pages/icon_access02.svg)no-repeat;
            background-size: 37px 49px;
            width:37px;
            height:49px;
            transform: translateY(5px);
            margin-right: 5px;
          }
        }
      }
      .cities__inner{
        @include media-breakpoint-up(lg) {
          flex-wrap:nowrap;
        }
        @include media-breakpoint-down(md) {
          margin:0;
        }
        .map__block{
          @include media-breakpoint-up(lg) {
            width:40%;
          }
          @include media-breakpoint-down(md) {
            display: none;
          }
        }
        .links__block{
          margin:0;
          @include media-breakpoint-up(lg) {
            width:55%;
            margin-left: 5%;
          }
          @include media-breakpoint-down(md) {
            // justify-content: center;
          }
          li{
            padding:0;
            &:nth-of-type(odd){
              padding-right: .5rem;
            }
          }
        }
      }
    }
    .sanriku__wrap{
      h2{
        span{
          &:before{
            content:"";
            display: inline-block;
            background: url(#{$dir}/pages/icon_access03.svg)no-repeat;
            background-size: 40px 45px;
            width:40px;
            height:45px;
            transform: translateY(5px);
            margin-right: 5px;
          }
        }
      }
    }
    .access__anchor{
      padding-left: 0;
      display: flex;
      flex-wrap:wrap;
      justify-content: center;
      li{
        width:calc((90% - 10%) / 2 );
        text-align: center;
        margin-bottom: 0.5rem;
        @include media-breakpoint-only(md) {
          width:calc((100% - 5%) / 2 );
        }
        @include media-breakpoint-down(sm) {
          width:100%;
          max-width: 300px;
        }
        &:not(:last-of-type){
          @include media-breakpoint-up(lg) {
            margin-right: 3%;
          }
          @include media-breakpoint-only(md) {
            margin-right: 5%;
          }
        }
        a{
          font-size: rem-calc(22);
          text-decoration: none;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-wrap:wrap;
          padding:2rem .5rem;
          height:100%;
          border:2px solid $primary;
          border-radius: 15px;
          position: relative;
          font-weight: bold;
          transition:.2s;
          &:after{
            content:"";
            position: absolute;
            display: block;
            bottom:10px;
            left:0;
            right:0;
            margin:auto;
            width:20px;
            height:20px;
            border-bottom: 2px solid $primary;
            border-left: 2px solid $primary;
            transform:rotate(-45deg);
          }
          &:hover{
            img{
              transform:scale(1.1);
            }
          }
          span{
            display: block;
            width:100%;
            padding-top: 0.5rem;
          }
        }
      }
    }
    .around__wrap{
      h2,
      h3,
      h4{
        @include gothic();
      }
      .around__inner{
        margin:auto;
        @include media-breakpoint-down(sm) {
          padding-right:0;
          padding-left: 0;
        }
        .section-one,
        .section-two{
          width:100%;
        }
        .departure__inner{
          padding:2rem;
          border:2px solid $green;
          border-radius: 10px;
          position: relative;
          margin-bottom: 5rem;
          @include media-breakpoint-down(sm) {
            padding:2rem 1rem;
          }
          &:after{
            content:"";
            display: block;
            position: absolute;
            bottom: -65px;
            left:0;
            right:0;
            margin:auto;
            background: url(#{$dir}/pages/icon_under-arrow.svg)no-repeat;
            background-size: 40px 46px;
            width:40px;
            height:46px;
          }
          .departure__head{
            font-size: rem-calc(24);
            color:$green;
            font-weight: bold;
            margin:0 auto 0;
            &:before{
              background: url(#{$dir}/pages/icon_pin01.svg)no-repeat;
              background-size: 16px 23px;
              width:16px;
              height:23px;
              margin-right: 0.5rem;
            }
          }
          h4{
            font-size: rem-calc(18);
            font-weight: bold;
            border-bottom: 2px solid $green;
            padding-bottom: 0.5rem;
            @include media-breakpoint-down(sm) {
              margin:1rem 0;
            }
          }
          .departure__lists{
            padding-left: 0;
            margin-bottom: 0;
            label{
              margin-bottom: 0;
              &.departure{
                position: relative;
                cursor: pointer;
                span{
                  position: relative;
                  display: flex;
                  align-items: center;
                  padding:5px 5px 5px 30px;
                  &:before{
                    content:"";
                    display: inline-block;
                    position: absolute;
                    left:0;
                    top:0;
                    bottom:0;
                    margin:auto auto auto 5px;
                    width:18px;
                    height:18px;
                    border:1px solid $gray-600;
                    padding-top:5px;
                    padding-bottom: 5px;
                    border-radius: 50%;
                  }
                  &:after{
                    content:"";
                    display: inline-block;
                    position: absolute;
                    left:3px;
                    top:0;
                    bottom:0;
                    width:12px;
                    height:12px;
                    margin:auto auto auto 5px;
                    background-color: $primary;
                    border-radius: 50%;
                    opacity: 0;
                    @media print{
                      opacity: 1;
                    }
                    visibility: hidden;
                    transform:scale(.2);
                    transition:.15s;
                  }
                }
                input{
                  position: absolute;
                  z-index: -1;
                  opacity: 0;
                  @media print{
                    opacity: 1;
                  }
                  &.radio__item{
                    &:checked + span{
                      color:$primary;
                      &:after{
                        opacity: 1;
                        visibility: visible;
                        transform:scale(1);
                      }
                    }
                    &:focus + span{
                      outline:3px solid $gray-500;
                    }
                  }
                }
              }
            }
          }
        }
        .destination__inner{
          padding:2rem;
          border:2px solid $red;
          border-radius: 10px;
          margin-bottom: 3rem;
          .destination__head{
            font-size: rem-calc(24);
            color:$red;
            font-weight: bold;
            margin:0 auto 0;
            &:before{
              background: url(#{$dir}/pages/icon_pin02.svg)no-repeat;
              background-size: 16px 23px;
              width:16px;
              height:23px;
              margin-right: 0.5rem;
            }
          }
          h4{
            font-size: rem-calc(18);
            font-weight: bold;
            border-bottom: 2px solid $red;
            padding-bottom: 0.5rem;
            @include media-breakpoint-down(sm) {
              margin:1rem 0;
            }
          }
          .destination__lists{
            padding-left: 0;
            margin-bottom: 0;
            label{
              margin-bottom: 0;
              &.destination{
                position: relative;
                cursor: pointer;
                span{
                  position: relative;
                  display: flex;
                  align-items: center;
                  padding:5px 5px 5px 30px;
                  &:before{
                    content:"";
                    display: inline-block;
                    position: absolute;
                    left:0;
                    top:0;
                    bottom:0;
                    margin:auto auto auto 5px;
                    width:18px;
                    height:18px;
                    border:1px solid $gray-600;
                    padding-top:5px;
                    padding-bottom: 5px;
                    border-radius: 50%;
                  }
                  &:after{
                    content:"";
                    display: inline-block;
                    position: absolute;
                    left:3px;
                    top:0;
                    bottom:0;
                    width:12px;
                    height:12px;
                    margin:auto auto auto 5px;
                    background-color: $primary;
                    border-radius: 50%;
                    opacity: 0;
                    @media print{
                      opacity: 1;
                    }
                    visibility: hidden;
                    transform:scale(.2);
                    transition:.15s;
                  }
                }
                input{
                  position: absolute;
                  z-index: -1;
                  opacity: 0;
                  @media print{
                    opacity: 1;
                  }
                  &.radio__item{
                    &:checked + span{
                      color:$primary;
                      &:after{
                        opacity: 1;
                        visibility: visible;
                        transform:scale(1);
                      }
                    }
                    &:focus + span{
                      outline:3px solid $gray-500;
                    }
                  }
                }
              }
            }
          }
        }
        .section-three{
          display: none;
          &.is_active{
            display: flex;
          }
          justify-content: space-between;
          width:100%;
          @include media-breakpoint-down(md) {
            flex-wrap:wrap;
          }
          .to-station__inner{
            width:77%;
            margin-right: 2%;
            @include media-breakpoint-down(md) {
              width:100%;
              margin-right: 0;
            }
            ul{
              list-style: none;
              padding:0;
              margin-bottom: 0;
            }
            .root__flow{
              display: flex;
              justify-content: space-between;
              position: relative;
              .start__point,
              .end__point{
                -ms-writing-mode: tb-rl;
                writing-mode: vertical-lr;
                color:$white;
                letter-spacing: 5px;
                font-weight: bold;
                display: flex;
                align-items: center;
                flex-direction: column;
                min-height: 300px;
                font-size: rem-calc(20);
                padding: 0 5px;
                margin:0;
                span{
                  position: relative;
                  &:before{
                    content:"";
                    display: inline-block;
                    position: absolute;
                    bottom:110%;
                    left:0;
                    right:0;
                    margin:auto;
                    background: url(#{$dir}/pages/icon_pin03.svg)no-repeat;
                    background-size: 16px 23px;
                    width:16px;
                    height:23px;
                  }
                }
              }
              .start__point{
                background-color: $green;
              }
              .end__point{
                background-color: $red;
              }
              .root__flow__inner{
                display: flex;
                width:100%;
                overflow: scroll;
                .transportation__car,
                .transportation__bus,
                .transportation__train{
                  font-weight: bold;
                  &:before{
                    content:"";
                    display: inline-block;
                    width:10px;
                    height:4px;
                    vertical-align: middle;
                    margin-right: 5px;
                    margin-bottom: 2px;
                    background-color: currentColor;
                  }
                }
                .transportation__car{
                  color:#E23000;
                }
                .transportation__bus{
                  color:$green;
                }
                .transportation__train{
                  color:$visit_color;
                }
                .time{
                  font-weight: bold;
                }
                .root__flow__sections{
                  width:100%;
                  min-width:400px;
                  display: flex;
                  align-items: center;
                  .flow__block{
                    margin-bottom: 4rem;
                    .transportation__parent{
                      display: flex;
                      flex-wrap:wrap;
                      margin-bottom: 0;
                      margin-left: 1rem;
                    }
                    .transportation__detail{
                      display: flex;
                      li{
                        width:100%;
                        &:first-of-type{
                          padding-left: 1rem;
                        }
                        &:not(:last-of-type){
                          padding-right: 1rem;
                        }
                      }
                    }
                    .transportation__detail--car{
                      $car_color:#F9D398;
                      li{
                        position: relative;
                        &:after{
                          content:"";
                          display: block;
                          position: absolute;
                          bottom:-45px;
                          left:0;
                          width:100%;
                          height:20px;
                          background-color:$car_color;
                        }
                        &:before{
                          content:"";
                          display: block;
                          position: absolute;
                          bottom: -60px;
                          right: 0;
                          z-index: 1;
                          border-top: 25px solid $white;
                          border-bottom: 25px solid $white;
                          border-left: 30px solid $car_color;
                        }
                        .flow__icon{
                          position: absolute;
                          bottom:-40px;
                          left:20px;
                          z-index: 1;
                          background: url(#{$dir}/pages/icon_car-rental.svg)no-repeat;
                          background-size: 60px 24px;
                          width:60px;
                          height:24px;
                        }
                      }
                    }
                    .transportation__detail--bus{
                      $bus_color:#C7EDB8;
                      li{
                        position: relative;
                        &:after{
                          content:"";
                          display: block;
                          position: absolute;
                          z-index: -1;
                          bottom:-45px;
                          left:0;
                          width:100%;
                          height:20px;
                          background-color:$bus_color;
                        }
                        &:before{
                          content:"";
                          display: block;
                          position: absolute;
                          bottom: -60px;
                          right: 0;
                          // z-index: -1;
                          border-top: 25px solid $white;
                          border-bottom: 25px solid $white;
                          border-left: 30px solid $bus_color;
                        }
                        .flow__icon{
                          position: absolute;
                          bottom:-40px;
                          left:20px;
                          background: url(#{$dir}/pages/icon_bus.svg)no-repeat;
                          background-size: 76px 30px;
                          width:76px;
                          height:30px;
                        }
                      }
                    }
                    .transportation__detail--train{
                      $train_color:#A9D3EA;
                      li{
                        position: relative;
                        &:after{
                          content:"";
                          display: block;
                          position: absolute;
                          bottom:-45px;
                          left:0;
                          width:100%;
                          height:20px;
                          background-color:$train_color;
                        }
                        &:before{
                          content:"";
                          display: block;
                          position: absolute;
                          bottom: -60px;
                          right: 0;
                          z-index: 1;
                          border-top: 25px solid $white;
                          border-bottom: 25px solid $white;
                          border-left: 30px solid $train_color;
                        }
                        .flow__icon{
                          position: absolute;
                          bottom:-40px;
                          left:20px;
                          z-index: 1;
                          background: url(#{$dir}/pages/icon_train.svg)no-repeat;
                          background-size: 76px 30px;
                          width:76px;
                          height:30px;
                        }
                      }
                    }
                  }
                }
              }
            }
            .root__detail{
              margin-top: 1rem;
              @include media-breakpoint-down(md) {
                margin-bottom: 1rem;
              }
              .transportation__car,
              .transportation__bus,
              .transportation__train,
              .transportation__ferry{
                font-weight: bold;
                &:before{
                  content:"";
                  display: inline-block;
                  width:10px;
                  height:4px;
                  vertical-align: middle;
                  margin-right: 5px;
                  margin-bottom: 2px;
                  background-color: currentColor;
                }
              }
              .transportation__car{
                color:#E23000;
              }
              .transportation__bus{
                color:$green;
              }
              .transportation__train{
                color:$visit_color;
              }
              .transportation__ferry{
                color:#7d00fd;
              }
              .detail__lists{
                .detail__item{
                  padding:1rem .5rem;
                  border-bottom: 1px solid $gray-500;
                  line-height: 2;
                  &:first-of-type{
                    border-top: 1px solid $gray-500;
                  }
                  ul{
                    letter-spacing: -.4em;
                    li{
                      margin-right: 1rem;
                      letter-spacing: normal;
                      display: inline-block;
                      a{
                        display: block;
                        text-decoration: underline;
                      }
                    }
                  }
                }
              }
            }
          }
          .root__map{
            width:21%;
            @include media-breakpoint-down(md) {
              width:100%;
            }
            .root__img{
              text-align: center;
            }
            .root__spot__link{
              text-align: center;
              a{
                position: relative;
                color:$red;
                text-decoration: none;
                display: block;
                border:2px solid $red;
                padding:1rem .5rem 1.5rem;
                border-radius: 10px;
                font-weight: bold;
                &:after{
                  content:"";
                  display: inline-block;
                  width:15px;
                  height:15px;
                  position: absolute;
                  left:0;
                  right:0;
                  bottom:10px;
                  margin:auto;
                  border-bottom: 2px solid $red;
                  border-right: 2px solid $red;
                  transform:rotate(45deg);
                }
              }
            }
          }
        }
      }
    }
  }
  // アクセス詳細ページ
  &.access_detail{
    .access__spot__map{
      text-align: center;
    }
    .expantion__btn{
      text-align: right;
      margin-bottom: 0;
      margin-top: 1rem;
      @include media-breakpoint-up(md) {
        display: none;
      }
    }
    .intro__description{
      margin-bottom: 2.5rem;
    }

    .asides{
      .aside__spot__title{
        padding-left: 0;
        &:before{
          content:"";
          display: inline-block;
          background: url(#{$dir}/common/icon_neighborhood.svg)no-repeat;
          background-size: 26px 24px;
          width:26px;
          height:24px;
          vertical-align: bottom;
          margin-right: 1rem;
        }
      }
      .other__spot{
        li{
          padding:0;
          &:nth-of-type(odd){
            padding-right: 2rem;
          }
          a{
            &:after{
              content:"";
              display: inline-block;
              background: url(#{$dir}/access/icon_access_external-link.svg)no-repeat;
              background-size: 14px 14px;
              width:14px;
              height:14px;
              margin-left: 0.3em;
            }
          }
        }
      }
      .asides__head{
        border-left: 5px solid $primary;
      }
      .other__access{
        .general__link{
          display: flex;
          flex-wrap: wrap;
          li{
            @include media-breakpoint-up(md) {
              width:calc(96% / 5);
              &:not(:nth-of-type(5n)){
                margin-right: 1%;
              }
            }
            @include media-breakpoint-down(sm) {
              width:calc(98% / 2);
              &:nth-of-type(odd){
                margin-right: 2%;
              }
            }
          }
        }
        .access__back-btn{
          text-align: center;
          margin: 3rem 0;
          p{
            display: inline-block;
            a{
              padding: 0.8rem 1.5rem;
              display: block;
              text-decoration: none;
              color:$primary;
              font-weight: bold;
              background-color: $secondary;
              border-radius:30px;
              transition:.2s;
              &:hover{
                background-color: darken($secondary,10%);
              }
            }
          }
        }
      }
    }
    .countrywide__wrap,
    .around__wrap,
    .sanriku__wrap{
      padding: 1rem 0 3rem;
    }
    .countrywide__wrap,
    .sanriku__wrap{
      background-color: $secondary;
      // h2{
      //   color:#227295;
      //   &:before{
      //     background-color: $white;
      //   }
      // }
    }
    .countrywide__wrap{
      padding:5rem 0;
      h2{
        border-left: 5px solid $primary;
      }
      .access__nationwide{
        background-color: $white;
        padding:1rem .5rem;
        margin-bottom: 3rem;
      }
      // h2{
      //   span{
      //     &:before{
      //       content:"";
      //       display: inline-block;
      //       background: url(#{$dir}/pages/icon_access01.svg)no-repeat;
      //       background-size: 45px 49px;
      //       width:45px;
      //       height:49px;
      //       transform: translateY(5px);
      //       margin-right: 5px;
      //     }
      //   }
      // }
      .access__countrywide__tab{
        margin-bottom: 0;
        .nav-tabs{
          li{
            width:calc( 99% / 3);
            // @include media-breakpoint-down(sm) {
            //   width:100%;
            // }
            &:not(:last-of-type){
              @include media-breakpoint-up(md) {
                margin-right: .5%;
              }
              @include media-breakpoint-down(sm) {
                margin-right: 1px;
                // margin-bottom: 2px;
              }
            }
            a{
              background-color: $primary;
              color:$white;
              font-weight: bold;
              border:none;
              text-align: center;
              padding: 1rem .5rem;
              @include media-breakpoint-down(sm) {
                padding:.5rem .1rem;
                font-size: rem-calc(13);
              }
              &.active{
                background-color: $white;
                color:$primary;
              }
            }
          }
        }
        .tab-content{
          background: $white;
          border:none;
          padding:2rem .5rem;
          @include media-breakpoint-down(sm) {
            padding:2rem .5rem .5rem;
          }
        }
      }
    }
      // .cities__wrap{
      //   h2{
      //     span{
      //       &:before{
      //         content:"";
      //         display: inline-block;
      //         background: url(#{$dir}/pages/icon_access02.svg)no-repeat;
      //         background-size: 37px 49px;
      //         width:37px;
      //         height:49px;
      //         transform: translateY(5px);
      //         margin-right: 5px;
      //       }
      //     }
      //   }
      //   .cities__inner{
      //     @include media-breakpoint-up(lg) {
      //       flex-wrap:nowrap;
      //     }
      //     @include media-breakpoint-down(md) {
      //       margin:0;
      //     }
      //     .map__block{
      //       @include media-breakpoint-down(md) {
      //         display: none;
      //       }
      //     }
      //     .links__block{
      //       margin:0;
      //       @include media-breakpoint-up(lg) {
      //         margin-left: 3rem;
      //       }
      //       @include media-breakpoint-down(md) {
      //         // justify-content: center;
      //       }
      //       li{
      //         padding:0;
      //         &:nth-of-type(odd){
      //           padding-right: .5rem;
      //         }
      //       }
      //     }
      //   }
      // }
      .sanriku__wrap{
        h2{
          span{
            &:before{
              content:"";
              display: inline-block;
              background: url(#{$dir}/pages/icon_access03.svg)no-repeat;
              background-size: 40px 45px;
              width:40px;
              height:45px;
              transform: translateY(5px);
              margin-right: 5px;
            }
          }
        }
      }
      .access__anchor{
        padding-left: 0;
        display: flex;
        flex-wrap:wrap;
        justify-content: center;
        li{
          width:calc((90% - 10%) / 2 );
          text-align: center;
          margin-bottom: 0.5rem;
          @include media-breakpoint-only(md) {
            width:calc((100% - 5%) / 2 );
          }
          @include media-breakpoint-down(sm) {
            width:100%;
            max-width: 300px;
          }
          &:not(:last-of-type){
            @include media-breakpoint-up(lg) {
              margin-right: 3%;
            }
            @include media-breakpoint-only(md) {
              margin-right: 5%;
            }
          }
          a{
            font-size: rem-calc(22);
            text-decoration: none;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap:wrap;
            padding:2rem .5rem;
            height:100%;
            border:2px solid $primary;
            border-radius: 15px;
            position: relative;
            font-weight: bold;
            transition:.2s;
            &:after{
              content:"";
              position: absolute;
              display: block;
              bottom:10px;
              left:0;
              right:0;
              margin:auto;
              width:20px;
              height:20px;
              border-bottom: 2px solid $primary;
              border-left: 2px solid $primary;
              transform:rotate(-45deg);
            }
            span{
              display: block;
              width:100%;
              padding-top: 0.5rem;
            }
          }
        }
      }
      .around__wrap{
        h2,
        h3,
        h4{
          @include gothic();
        }
        .around__inner{
          margin:auto;
          @include media-breakpoint-down(sm) {
            padding-right:0;
            padding-left: 0;
          }
          .section-one,
          .section-two{
            width:100%;
          }
          .departure__inner{
            padding:2rem;
            border:2px solid $green;
            border-radius: 10px;
            position: relative;
            margin-bottom: 5rem;
            @include media-breakpoint-down(sm) {
              padding:2rem 1rem;
            }
            &:after{
              content:"";
              display: block;
              position: absolute;
              bottom: -65px;
              left:0;
              right:0;
              margin:auto;
              background: url(#{$dir}/pages/icon_under-arrow.svg)no-repeat;
              background-size: 40px 46px;
              width:40px;
              height:46px;
            }
            .departure__head{
              font-size: rem-calc(24);
              color:$green;
              font-weight: bold;
              margin:0 auto 0;
              &:before{
                background: url(#{$dir}/pages/icon_pin01.svg)no-repeat;
                background-size: 16px 23px;
                width:16px;
                height:23px;
                margin-right: 0.5rem;
              }
            }
            h4{
              font-size: rem-calc(18);
              font-weight: bold;
              border-bottom: 2px solid $green;
              padding-bottom: 0.5rem;
              @include media-breakpoint-down(sm) {
                margin:1rem 0;
              }
            }
          }
          .destination__inner{
            padding:2rem;
            border:2px solid $red;
            border-radius: 10px;
            margin-bottom: 3rem;
            .destination__head{
              font-size: rem-calc(24);
              color:$red;
              font-weight: bold;
              margin:0 auto 0;
              &:before{
                background: url(#{$dir}/pages/icon_pin02.svg)no-repeat;
                background-size: 16px 23px;
                width:16px;
                height:23px;
                margin-right: 0.5rem;
              }
            }
            h4{
              font-size: rem-calc(18);
              font-weight: bold;
              border-bottom: 2px solid $red;
              padding-bottom: 0.5rem;
              @include media-breakpoint-down(sm) {
                margin:1rem 0;
              }
            }
            .destination__lists{
              padding-left: 0;
              margin-bottom: 0;
              label{
                margin-bottom: 0;
                &.destination{
                  position: relative;
                  cursor: pointer;
                  span{
                    position: relative;
                    display: flex;
                    align-items: center;
                    padding:5px 5px 5px 30px;
                    &:before{
                      content:"";
                      display: inline-block;
                      position: absolute;
                      left:0;
                      top:0;
                      bottom:0;
                      margin:auto auto auto 5px;
                      width:18px;
                      height:18px;
                      border:1px solid $gray-600;
                      padding-top:5px;
                      padding-bottom: 5px;
                      border-radius: 50%;
                    }
                    &:after{
                      content:"";
                      display: inline-block;
                      position: absolute;
                      left:3px;
                      top:0;
                      bottom:0;
                      width:12px;
                      height:12px;
                      margin:auto auto auto 5px;
                      background-color: $primary;
                      border-radius: 50%;
                      opacity: 0;
                      @media print{
                        opacity: 1;
                      }
                      visibility: hidden;
                      transform:scale(.2);
                      transition:.15s;
                    }
                  }
                }
              }
            }
          }
          .section-three{
            display: none;
            &.is_active{
              display: flex;
            }
            justify-content: space-between;
            width:100%;
            @include media-breakpoint-down(md) {
              flex-wrap:wrap;
            }
            .to-station__inner{
              width:77%;
              margin-right: 2%;
              @include media-breakpoint-down(md) {
                width:100%;
                margin-right: 0;
              }
              ul{
                list-style: none;
                padding:0;
                margin-bottom: 0;
              }
              .root__flow{
                display: flex;
                justify-content: space-between;
                position: relative;
                .start__point,
                .end__point{
                  -ms-writing-mode: tb-rl;
                  writing-mode: vertical-lr;
                  color:$white;
                  letter-spacing: 5px;
                  font-weight: bold;
                  display: flex;
                  align-items: center;
                  flex-direction: column;
                  min-height: 300px;
                  font-size: rem-calc(20);
                  padding: 0 5px;
                  margin:0;
                  span{
                    position: relative;
                    &:before{
                      content:"";
                      display: inline-block;
                      position: absolute;
                      bottom:110%;
                      left:0;
                      right:0;
                      margin:auto;
                      background: url(#{$dir}/pages/icon_pin03.svg)no-repeat;
                      background-size: 16px 23px;
                      width:16px;
                      height:23px;
                    }
                  }
                }
                .start__point{
                  background-color: $green;
                }
                .end__point{
                  background-color: $red;
                }
                .root__flow__inner{
                  display: flex;
                  width:100%;
                  overflow: scroll;
                  .transportation__car,
                  .transportation__bus,
                  .transportation__train{
                    font-weight: bold;
                    &:before{
                      content:"";
                      display: inline-block;
                      width:10px;
                      height:4px;
                      vertical-align: middle;
                      margin-right: 5px;
                      margin-bottom: 2px;
                      background-color: currentColor;
                    }
                  }
                  .transportation__car{
                    color:#E23000;
                  }
                  .transportation__bus{
                    color:$green;
                  }
                  .transportation__train{
                    color:$visit_color;
                  }
                  .time{
                    font-weight: bold;
                  }
                  .root__flow__sections{
                    width:100%;
                    min-width:400px;
                    display: flex;
                    align-items: center;
                    .flow__block{
                      margin-bottom: 4rem;
                      .transportation__parent{
                        display: flex;
                        flex-wrap:wrap;
                        margin-bottom: 0;
                        margin-left: 1rem;
                      }
                      .transportation__detail{
                        display: flex;
                        li{
                          width:100%;
                          &:first-of-type{
                            padding-left: 1rem;
                          }
                          &:not(:last-of-type){
                            padding-right: 1rem;
                          }
                        }
                      }
                      .transportation__detail--car{
                        $car_color:#F9D398;
                        li{
                          position: relative;
                          &:after{
                            content:"";
                            display: block;
                            position: absolute;
                            bottom:-45px;
                            left:0;
                            width:100%;
                            height:20px;
                            background-color:$car_color;
                          }
                          &:before{
                            content:"";
                            display: block;
                            position: absolute;
                            bottom: -60px;
                            right: 0;
                            z-index: 1;
                            border-top: 25px solid $white;
                            border-bottom: 25px solid $white;
                            border-left: 30px solid $car_color;
                          }
                          .flow__icon{
                            position: absolute;
                            bottom:-40px;
                            left:20px;
                            z-index: 1;
                            background: url(#{$dir}/pages/icon_car-rental.svg)no-repeat;
                            background-size: 60px 24px;
                            width:60px;
                            height:24px;
                          }
                        }
                      }
                      .transportation__detail--bus{
                        $bus_color:#C7EDB8;
                        li{
                          position: relative;
                          &:after{
                            content:"";
                            display: block;
                            position: absolute;
                            z-index: -1;
                            bottom:-45px;
                            left:0;
                            width:100%;
                            height:20px;
                            background-color:$bus_color;
                          }
                          &:before{
                            content:"";
                            display: block;
                            position: absolute;
                            bottom: -60px;
                            right: 0;
                            // z-index: -1;
                            border-top: 25px solid $white;
                            border-bottom: 25px solid $white;
                            border-left: 30px solid $bus_color;
                          }
                          .flow__icon{
                            position: absolute;
                            bottom:-40px;
                            left:20px;
                            background: url(#{$dir}/pages/icon_bus.svg)no-repeat;
                            background-size: 76px 30px;
                            width:76px;
                            height:30px;
                          }
                        }
                      }
                      .transportation__detail--train{
                        $train_color:#A9D3EA;
                        li{
                          position: relative;
                          &:after{
                            content:"";
                            display: block;
                            position: absolute;
                            bottom:-45px;
                            left:0;
                            width:100%;
                            height:20px;
                            background-color:$train_color;
                          }
                          &:before{
                            content:"";
                            display: block;
                            position: absolute;
                            bottom: -60px;
                            right: 0;
                            z-index: 1;
                            border-top: 25px solid $white;
                            border-bottom: 25px solid $white;
                            border-left: 30px solid $train_color;
                          }
                          .flow__icon{
                            position: absolute;
                            bottom:-40px;
                            left:20px;
                            z-index: 1;
                            background: url(#{$dir}/pages/icon_train.svg)no-repeat;
                            background-size: 76px 30px;
                            width:76px;
                            height:30px;
                          }
                        }
                      }
                    }
                  }
                }
              }
              .root__detail{
                margin-top: 1rem;
                @include media-breakpoint-down(md) {
                  margin-bottom: 1rem;
                }
                .transportation__car,
                .transportation__bus,
                .transportation__train,
                .transportation__ferry{
                  font-weight: bold;
                  &:before{
                    content:"";
                    display: inline-block;
                    width:10px;
                    height:4px;
                    vertical-align: middle;
                    margin-right: 5px;
                    margin-bottom: 2px;
                    background-color: currentColor;
                  }
                }
                .transportation__car{
                  color:#E23000;
                }
                .transportation__bus{
                  color:$green;
                }
                .transportation__train{
                  color:$visit_color;
                }
                .transportation__ferry{
                  color:#7d00fd;
                }
                .detail__lists{
                  .detail__item{
                    padding:1rem .5rem;
                    border-bottom: 1px solid $gray-500;
                    line-height: 2;
                    &:first-of-type{
                      border-top: 1px solid $gray-500;
                    }
                    ul{
                      letter-spacing: -.4em;
                      li{
                        margin-right: 1rem;
                        letter-spacing: normal;
                        display: inline-block;
                        a{
                          display: block;
                          text-decoration: underline;
                        }
                      }
                    }
                  }
                }
              }
            }
            .root__map{
              width:21%;
              @include media-breakpoint-down(md) {
                width:100%;
              }
              .root__img{
                text-align: center;
              }
              .root__spot__link{
                text-align: center;
                a{
                  position: relative;
                  color:$red;
                  text-decoration: none;
                  display: block;
                  border:2px solid $red;
                  padding:1rem .5rem 1.5rem;
                  border-radius: 10px;
                  font-weight: bold;
                  &:after{
                    content:"";
                    display: inline-block;
                    width:15px;
                    height:15px;
                    position: absolute;
                    left:0;
                    right:0;
                    bottom:10px;
                    margin:auto;
                    border-bottom: 2px solid $red;
                    border-right: 2px solid $red;
                    transform:rotate(45deg);
                  }
                }
              }
            }
          }
        }
      }
  }
  &.category_lower{
    @include media-breakpoint-down(md) {
      .page__head{
        padding-bottom: 0;
      }
    }
    .simple__article{
      list-style: none;
      padding-left: 0;
      .caption{
        margin-bottom: 0;
        @include media-breakpoint-down(sm) {
          font-size: rem-calc(12);
        }
      }
      &.title__red{
        .simple__article__item{
          .article__head{
            a{
              color:#8B171B;
            }
          }
        }
      }
      .simple__article__item{
        padding: 1rem .5rem;
        &:nth-of-type(even){
          background-color: #F4FAFF;
        }
        .article__info{
          font-size: rem-calc(14);
          margin-bottom: .5rem;
          letter-spacing: -1em;
          * {
            letter-spacing: normal;
          }
          .article__label{
            display: inline-block;
            margin: .2rem .5rem .2rem 0;
            background-color: $secondary;
            border-radius: 20px;
            padding: 0.2rem 0.6rem;
            // font-size: rem-calc(14);
            @include media-breakpoint-down(sm) {
              font-size: rem-calc(12);
            }
          }
        }
        .article__head{
          @include mintyo();
          margin-bottom: 0;
          a{
            color:$black;
            font-size: rem-calc(18);
            text-decoration: underline;
            @include opacity();
          }
        }
        .article__thumb__block{
          position: relative;
          padding-top: 9%;
          overflow: hidden;
          .article__thumb{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }
        .article__info{
          .date{
            margin-right: 0.5em;
            @include media-breakpoint-down(sm) {
              display: block;
            }
          }
        }
      }
      &.simple__article--lg-thumb{
        .simple__article__item{
          .article__thumb__block{
            padding-top: 16%;
          }
        }
      }
    }
    &.fun{
      .page__head{
        @include media-breakpoint-up(lg) {
          flex:none;
        }
        @include media-breakpoint-down(md) {
          text-align: center;
          max-width:220px;
          margin-left:auto;
          margin-right:auto;
        }
        &:after{
          content:"";
          display: inline-block;
          width:25px;
          height:4px;
          background: $fun_color;
          margin: 0 1.5rem;
          @include media-breakpoint-down(md) {
            display: block;
            margin: 2rem auto 2rem;
            transform: rotate(90deg);
          }
        }
      }
    }
    &.visit{
      .page__head{
        @include media-breakpoint-up(lg) {
          flex:none;
        }
        @include media-breakpoint-down(md) {
          text-align: center;
          max-width:220px;
          margin-left:auto;
          margin-right:auto;
        }
        &:after{
          content:"";
          display: inline-block;
          width:25px;
          height:4px;
          background: $visit_color;
          margin: 0 1.5rem;
          @include media-breakpoint-down(md) {
            display: block;
            margin: 2rem auto 2rem;
            transform: rotate(90deg);
          }
        }
      }
    }
    &.know{
      .page__head{
        @include media-breakpoint-up(lg) {
          flex:none;
        }
        @include media-breakpoint-down(md) {
          text-align: center;
          max-width:220px;
          margin-left:auto;
          margin-right:auto;
        }
        &:after{
          content:"";
          display: inline-block;
          width:25px;
          height:4px;
          background: $know_color;
          margin: 0 1.5rem;
          @include media-breakpoint-down(md) {
            display: block;
            margin: 2rem auto 2rem;
            transform: rotate(90deg);
          }
        }
      }
    }
    .intro__wrap{
      padding: 3rem 0 2rem;
      @include media-breakpoint-up(lg) {
        display: flex;
        align-items: center;
        margin-top: 2rem;
      }
      @include media-breakpoint-down(md) {
        padding: 0;
      }
      .intro__head{
        font-size: rem-calc(37);
        padding:0;
        @include mintyo();
        @include media-breakpoint-down(md) {
          font-family: $font-family-android;
          text-align: center;
          font-size: rem-calc(24);
        }
        &.special-dish{
          font-size: rem-calc(43);
          font-weight: bold;
          @include media-breakpoint-down(md) {
            font-size: rem-calc(30);
          }
        }
      }
    }
    .page__intro__wrap{
      padding-bottom: 1rem;
      margin-bottom: 5rem;
      @include media-breakpoint-down(md) {
        margin-bottom: 2rem;
        padding-bottom: 0;
      }
      &:before{
        content:"";
        display: block;
        position: absolute;
        top: -20px;
        left: 290px;
        width: 70vw;
        height:19px;
        background: url(#{$dir}/pages/bg_category_lower.svg)repeat-x;
        background-size: 39px 19px;
        @include media-breakpoint-down(md) {
          bottom:95%;
          top:initial;
        }
        @include media-breakpoint-down(md) {
          display: none;
        }
      }
      &:after{
        content:"";
        display: block;
        position: absolute;
        top:100%;
        right: 160px;
        width:80vw;
        height:19px;
        background: url(#{$dir}/pages/bg_category_lower.svg)repeat-x;
        background-size: 39px 19px;
        @include media-breakpoint-down(md) {
          display: none;
        }
      }
    }
    // &.event_info{
    //   .simple__article{
    //     .simple__article__item{
    //       .article__head{
    //         a{
    //           color:$know_color;
    //         }
    //       }
    //     }
    //   }
    // }
  }
  .sanriku__wrap{
    .access__sanriku__tab{
      .nav-tabs{
        li{
          width:calc( 94% / 4);
          @include media-breakpoint-down(sm) {
            width:100%;
          }
          &:not(:last-of-type){
            margin-right: 0;
            @include media-breakpoint-up(md) {
              margin-right: 2%;
            }
            @include media-breakpoint-down(sm) {
              margin-bottom: 2px;
            }
          }
          a{
            background-color: $red;
            color:$white;
            font-weight: bold;
            border:none;
            text-align: center;
            padding: 1rem .5rem;
            &.active{
              background-color: $white;
              color:$red;
            }
          }
        }
      }
      .tab-content{
        background: $white;
        border:none;
        padding:1rem;
        @include media-breakpoint-down(sm) {
          padding:.5rem;
        }
        .tab-pane{
          ul{
            list-style: none;
            padding-left: 0;
            &.spot__address{
              margin-bottom: 1rem;
              li{
                border-bottom: 2px dotted $gray;
                padding-bottom: .5rem;
                margin-bottom: .5rem;
                &:first-of-type{
                  margin-top: .5rem;
                }
              }
            }
            &.spot__access__btn{
              text-align: center;
              @include media-breakpoint-up(md) {
                display: flex;
                justify-content: center;
              }
              @include media-breakpoint-down(sm) {
                margin-bottom: 2rem;
              }
              li{
                @include media-breakpoint-down(sm) {
                  margin:0 auto .5rem;
                  width: 240px;
                }
                &:nth-last-of-type(2){
                  @include media-breakpoint-up(lg) {
                    margin-right: 1rem;
                  }
                  @include media-breakpoint-only(md) {
                    margin-right: 0.5rem;
                  }
                }
                a{
                  display: block;
                  padding:.5rem 1.5rem;
                  text-decoration: none;
                  border-radius: 10px;
                  @include opacity();
                  @include media-breakpoint-only(md) {
                    padding:.5rem 1rem;
                  }
                }
                &.article__link{
                  a{
                    border:1px solid #00729C;
                  }
                }
                &.official__link{
                  a{
                    border:1px solid $red;
                    color:$red;
                    &:before{
                      content:"";
                      display: inline-block;
                      background: url(#{$dir}/pages/icon_new-window.svg)no-repeat;
                      background-size: 14px 14px;
                      width:14px;
                      height:14px;
                      margin-right: 0.3rem;
                    }
                  }
                }
              }
            }
            li{
              .spot__item{
                display: block;
                position: relative;
              }
              .spot__title{
                padding-bottom: 0.2rem;
                margin-bottom: 1rem;
                border-bottom: 2px solid $red;
                a{
                  text-decoration: none;
                  color:$black;
                  font-weight: bold;
                  font-size: rem-calc(18);
                  cursor: pointer;
                  span{
                    &:last-of-type{
                      display: inline-block;
                      width:25px;
                      height:25px;
                      border-radius: 50%;
                      background-color: $red;
                      position: relative;
                      vertical-align: sub;
                      margin-left: 0.5rem;
                      &:after{
                        content:"";
                        position: absolute;
                        left:0;
                        right:0;
                        bottom:0;
                        top:0;
                        margin:auto;
                        width:60%;
                        height:2px;
                        display: inline-block;
                        background-color: $white;
                      }
                      &:before{
                        content:"";
                        position: absolute;
                        left:0;
                        right:0;
                        bottom:0;
                        top:0;
                        margin:auto;
                        width:2px;
                        height:60%;
                        display: inline-block;
                        background-color: $white;
                        transition:.4s;
                      }
                    }
                  }
                  &.is_active{
                    span{
                      &:before{
                        transform:rotate(360deg);
                        opacity: 0;
                        @media print{
            opacity: 1;
          }
                      }
                    }
                  }
                }
              }
              .spot__content{
                display: none;
                .spot__img{
                  padding-top: 25%;
                  @include media-breakpoint-down(sm) {
                    padding-top: 55%;
                  }
                }
                .spot__annotation{
                  background-color: $gray-200;
                  padding: .5rem 1rem;
                }
                .station__access__wrap{
                  p{
                    margin-bottom: 0;
                  }
                  .station__access__head{
                    border-left:5px solid $visit_color;
                    margin: 1.5rem 0 1rem;
                    padding: .5rem .5rem .5rem 1rem;
                    font-size: rem-calc(20);
                    font-weight: bold;
                  }
                  .station__access__sub-head{}
                  dl{
                    display: flex;
                    flex-wrap:wrap;
                    dt{
                      background-color: $visit_color;
                      border:1px solid $visit_color;
                      padding:.5rem;
                      color:$white;
                      width:20%;
                      &:not(:last-of-type){
                        border-bottom: 1px solid $white;
                      }
                      @include media-breakpoint-down(sm) {
                        padding:.5rem .3rem;
                        width:30%;
                      }
                    }
                    dd{
                      border:1px solid $gray;
                      padding:.5rem;
                      width:80%;
                      margin-bottom: 0;
                      &:not(:last-of-type){
                        border-bottom: none;
                      }
                      @include media-breakpoint-down(sm) {
                        padding:.5rem .2rem;
                        width:70%;
                      }
                    }
                  }
                }
                .spot__transportation__lists{
                  .spot__item{
                    margin-bottom: 1rem;
                    @include media-breakpoint-down(sm) {
                      margin-bottom: 0.2rem;
                      padding:0 .2rem;
                      &:nth-of-type(even){
                        padding-left: 0;
                      }
                    }
                    &.spot__walk{
                      a{
                        &:before{
                          content:"";
                          display: block;
                          margin:auto;
                          background: url(#{$dir}/pages/icon_walk.svg) no-repeat;
                          background-size: 25px 46px;
                          width:25px;
                          height:46px;
                        }
                      }
                    }
                    &.spot__cycle{
                      a{
                        &:before{
                          content:"";
                          display: inline-block;
                          display: block;
                          margin:auto;
                          background: url(#{$dir}/pages/icon_bicycle.svg)no-repeat;
                          background-size: 64px 42px;
                          width:64px;
                          height:42px;
                        }
                      }
                    }
                    &.spot__bus{
                      a{
                        &:before{
                          content:"";
                          display: inline-block;
                          display: block;
                          margin:auto;
                          background: url(#{$dir}/pages/icon_bus.svg)no-repeat;
                          background-size: 90px 36px;
                          width:90px;
                          height:36px;
                        }
                      }
                    }
                    &.spot__ferry{
                      a{
                        &:before{
                          content:"";
                          display: inline-block;
                          display: block;
                          margin:auto;
                          background: url(#{$dir}/pages/icon_ferry.svg)no-repeat;
                          background-size: 78px 43px;
                          width:78px;
                          height:43px;
                        }
                      }
                    }
                    &.spot__car{
                      a{
                        &:before{
                          content:"";
                          display: inline-block;
                          display: block;
                          margin:auto;
                          background: url(#{$dir}/pages/icon_car.svg)no-repeat;
                          background-size: 74px 28px;
                          width:74px;
                          height:28px;
                        }
                      }
                    }
                    &.spot__train{
                      a{
                        &:before{
                          content:"";
                          display: inline-block;
                          display: block;
                          margin:auto;
                          background: url(#{$dir}/pages/icon_train.svg)no-repeat;
                          background-size: 93px 32px;
                          width:93px;
                          height:32px;
                        }
                      }
                    }
                    &.spot__taxi{
                      a{
                        &:before{
                          content:"";
                          display: inline-block;
                          display: block;
                          margin:auto;
                          background: url(#{$dir}/pages/icon_taxi.svg)no-repeat;
                          background-size: 92px 32px;
                          width:92px;
                          height:32px;
                        }
                      }
                    }
                    &.spot__rental-car{
                      a{
                        &:before{
                          content:"";
                          display: inline-block;
                          display: block;
                          margin:auto;
                          background: url(#{$dir}/pages/icon_car-rental.svg)no-repeat;
                          background-size: 73px 29px;
                          width:73px;
                          height:29px;
                        }
                      }
                    }
                    a{
                      text-align: center;
                      text-decoration: none;
                      display: block;
                      border:2px solid #00729C;
                      padding: 1rem .5rem;
                      border-radius: 10px;
                      height:100%;
                      span{
                        display: block;
                        &:first-of-type{
                          font-weight: bold;
                          color:$black;
                          margin-top: .5rem;
                        }
                        &:last-of-type{
                          &.gmap{
                            &:before{
                              content:"";
                              display: inline-block;
                              background: url(#{$dir}/common/icon_googlemap.svg)no-repeat;
                              background-size: 18px 18px;
                              width:18px;
                              height:18px;
                              vertical-align: middle;
                              margin-bottom: 2px;
                              margin-right: 5px;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  &.sitemap{
    .sitemap__wrap{
      ul{
        list-style: none;
        li{
          margin-bottom: 0.5rem;
        }
      }
      .know__block{
        h2{
          border-left: 5px solid $know_color;
          a{
            color:$know_color;
            text-decoration: underline;
          }
        }
        ul{
          padding-left: 0;
          li{
            letter-spacing: -.4em;
            a{
              letter-spacing: normal;
              &:before{
                content:"";
                display: inline-block;
                width:10px;
                height:3px;
                background: $know_color;
                vertical-align: super;
                margin: 0 10px 0 0;
              }
            }
          }
        }
      }
      .visit__block{
        h2{
          border-left: 5px solid $visit_color;
          a{
            color:$visit_color;
            text-decoration: underline;
          }
        }
        ul{
          padding-left: 0;
          li{
            letter-spacing: -.4em;
            a{
              letter-spacing: normal;
              &:before{
                content:"";
                display: inline-block;
                width:10px;
                height:3px;
                background: $visit_color;
                vertical-align: super;
                margin: 0 10px 0 0;
              }
            }
          }
        }
      }
      .fun__block{
        h2{
          border-left: 5px solid $fun_color;
          a{
            color:$fun_color;
            text-decoration: underline;
          }
        }
        ul{
          padding-left: 0;
          li{
            letter-spacing: -.4em;
            a{
              letter-spacing: normal;
              &:before{
                content:"";
                display: inline-block;
                width:10px;
                height:3px;
                background: $fun_color;
                vertical-align: super;
                margin: 0 10px 0 0;
              }
            }
          }
        }
      }
      .other__block{
        ul{
          padding-left: 0;
          li{
            letter-spacing: -.4em;
            a{
              letter-spacing: normal;
              &:before{
                content:"";
                display: inline-block;
                width:10px;
                height:3px;
                background: $black;
                vertical-align: super;
                margin: 0 10px 0 0;
              }
            }
          }
        }
      }
    }
    .other__head{
      border-left: 5px solid $black;
      a{
        color:$fun_color;
        text-decoration: underline;
      }
    }
  }
  &.news{
    .page__intro__wrap{
      margin-bottom: 1rem;
    }
    .news__list{
      padding:0.5rem 1rem;
      margin-bottom: 0;
      @include media-breakpoint-up(lg) {
        display: flex;
        align-items: center;
        flex-wrap:wrap;
      }
      dt{
        width:15%;
        @include media-breakpoint-down(md) {
          width:100%;
        }
      }
      dd{
        width:85%;
        margin-bottom: 0;
        @include media-breakpoint-up(lg) {
          padding-left: 1rem;
        }
        @include media-breakpoint-down(md) {
          width:100%;
        }
      }
      &:nth-of-type(odd){
        background-color: $secondary;
      }
    }
  }
  &.sanrikubito{
    .main__inner{
      @include mintyo();
      @include media-breakpoint-down(md) {
        font-family: $font-family-android;
      }
      .question__head{
        color:#8B171B;
        padding-bottom: 0.2rem;
        margin-bottom: 1.5rem;
        margin-top: 2rem;
        border-bottom: 1px solid #4E4E4E;
      }
      p{
        font-size: rem-calc(18);
        @include media-breakpoint-down(sm) {
          font-size: rem-calc(16);
        }
      }
    }
  }
  &.tourist_routes{
    .main__inner{
      @include mintyo();
      @include media-breakpoint-down(md) {
        font-family: $font-family-android;
      }
      .content__wrap{
        position: relative;
        padding-left: 1.5rem;
        padding-bottom: 2rem;
        margin-bottom: 0;
        &:before{
          content:"";
          display: block;
          position: absolute;
          left:5px;
          top:0;
          width:3px;
          height:100%;
          background-image: linear-gradient(to bottom, #6B8CB7, #6B8CB7 50%, transparent 50%, transparent 100%);
          background-size: 1px 15px;
        }
        .content__inner{
          padding-top: 1.5rem;
          margin-bottom: 0;
          .inquiry__block{
            margin-bottom: 0;
          }
        }
      }
      h2{
        border-left: 0;
        padding:.5rem 0;
        margin: 0;
        span{
          &.number{
            font-size: rem-calc(36);
            color:$visit_color;
            margin-right: .5rem;
          }
          &.address{
            font-size: rem-calc(16);
            color:$visit_color;
          }
        }
      }
      .inquiry__block{
        background-color: rgba(#888,.1);
        padding:1.5rem;
        .inquiry__title{
          margin-bottom: 0.5rem;
        }
      }
    }
  }

}