//
// Header
//

// Layout
.header {
	position: relative;
	&__inner {
		.header__front{
			z-index: 1000;
			@include media-breakpoint-up(lg) {
				position: fixed;
				top:0;
				left:0;
				height:100%;
				width:65px;
				background-color:$black;
			}
			@include media-breakpoint-down(md) {
				background: initial;
			}
			@include media-breakpoint-up(md) {
				display: flex;
				justify-content: space-between;
				align-items: stretch;
			}
		}
	}
	.language{
		margin-bottom: 0;
		padding-left: 0;
		width: 200px;
		margin: auto;
		text-align: center;
		padding-bottom: 1rem;
		@include media-breakpoint-down(md) {
			padding-bottom: 2rem;
		}
		.language__select-btn{
			cursor: pointer;
			border-radius: 10px;
			padding: .4em .7em;
			background: #eeeeee;
			border-radius: 10px;
			// border:2px solid $gray-500;
			box-shadow: 0px 4px #a2a2a2;
			position: relative;
			transition:.15s;
			&:hover,
			&:active{
					box-shadow:none;
					transform:translateY(4px);
			}
			a{
				text-decoration: none;
				color:$black;
				span{
					display: flex;
					align-items: center;
					justify-content: center;
					&:before{
						content:"";
						display: inline-block;
						background: url(#{$dir}/common/icon_language.svg)no-repeat;
						background-size: 25px 25px;
						width:25px;
						height:25px;
						margin-right: 0.5em;
					}
				}
			}
		}
	}
}

// Logo
.header__logo {
	@include media-breakpoint-up(lg) {
		margin-top: 75px;
		width: 65px;
	}
	margin-bottom: 0;
	font-size: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	top:0;
	left:0;
	z-index: 10;
	@include media-breakpoint-down(md) {
		left: 5px;
	}
	img{
		@include media-breakpoint-up(lg) {
			height: 85vh;
			max-height: 650px;
		}
		&.pc-only{
			@include media-breakpoint-up(lg) {
				max-width:55px;
			}
		}
	}
}

// Button
.header__button-container {
	@include media-breakpoint-up(lg) {
		width: 65px;
	}
	display: block;
	z-index: 10000;
	position: fixed;
	top: 0;
	left: 0;
	@include media-breakpoint-down(md) {
		width: 70px;
		height: 70px;
		right:0;
		left:initial;
	}
	button {
		background-color: #4A7398;
		border:none;
		cursor: pointer;
		color:$white;
		width:100%;
		overflow: hidden;
		@include media-breakpoint-down(md) {
			height:100%;
		}
		span{
			&.button__element{
				width:75%;
				height:2px;
				padding:0 .5rem;
				background-color: $white;
				display: block;
				margin:0 auto .5rem;
				@include media-breakpoint-down(md) {
					width:80%
				}
				&.element--last{
					margin-bottom: 0;
				}
				&:first-of-type{
					margin-top: .5rem;
				}
				&.elemennt--first{}
				&.element--second{
					position: relative;
					transition:.2s;
					&:after{
						content:"";
						position: absolute;
						display: block;
						left:9.5px;
						top:0;
						height:2px;
						width:50%;
						background-color: white;
					}
					&:before{
						content:"";
						position: absolute;
						display: block;
						right:9.5px;
						top:0;
						height:2px;
						width:50%;
						background-color: white;
					}
				}
				&.element--last{}
			}
			&.menu-title{
				font-size: rem-calc(12);
				font-weight: bold;
				letter-spacing: 1px;
			}
		}
		&.is-active{
			background: linear-gradient(135deg, #1cb146 0%,#369ffd 50%,#e71f26 100%);
			background-size: 400% 400%;
			background-position:0% 50%;
			animation: menu-gradation 5s infinite;
			@keyframes menu-gradation{
				0% {
					background-position: 0% 0%;
				}
				33% {
					background-position: 50% 50%;
				}
				66% {
					background-position: 100% 100%;
				}
				100% {
					background-position: 0% 0%;
				}
			}
			span{
				&.elemennt--first{
					transition:.5s;
					opacity: 0;
				}
				&.element--second{
					transform:rotate(45deg);
					&:after{
						content:"";
						// position: absolute;
						// display: block;
						left:0;
						// top:0;
						// height:2px;
						width:100%;
						// transform-origin: center;
						// transform: rotate(-90deg)translateY(10px);
						transform: rotate(-90deg);
						// background-color: white;
							transition:width .5s ease .7s,left .5s ease 0s,transform .1s ease .0s;
					}
					&:before{
						content:"";
						// position: absolute;
						// display: block;
						right:0;
						// top:0;
						// height:2px;
						width:100%;
						// transform-origin: center;
						// transform: rotate(90deg)translateY(10px);
						transform: rotate(90deg);
						// background-color: white;
						transition:width .5s ease .7s,right .5s ease 0s,transform .1s ease .0s;
					}
				}
				&.element--last{
					transition:.5s;
					opacity: 0;
				}

			}
		}
	}
}

// Nav
#header__menu{
	position: fixed;
	left: 65px;
	top:0;
	bottom:0;
	z-index: 100;
	overflow-y: scroll;
	background-color: $white;
	height: 100%;
	transform:translateX(-100%);
	transition:.5s;
	@media all and (-ms-high-contrast: none) {
		@include media-breakpoint-up(lg) {
			*::-ms-backdrop, .block__txt {
				margin:3rem 0 0;
				width:100%;
				transform:translateX(-120%);
			}
		}
	}
	-ms-overflow-style:none;
	@include media-breakpoint-up(lg) {
		width: 360px;
	}
	&::-webkit-scrollbar {
		display: none;
	}

	.nav__inner{
		position: relative;
		min-height: 100%;
		&:after{
			overflow: hidden;
			content:"";
			position: absolute;
			right:0;
			top:0;
			background: linear-gradient(135deg, #1cb146 0%,#369ffd 50%,#e71f26 100%);
			background-size: 400% 400%;
			background-position:0% 50%;
			width:5px;
			height:100%;
			animation: menu-gradation 5s infinite;
			@keyframes menu-gradation{
				0% {
					background-position: 0% 0%;
				}
				33% {
					background-position: 50% 50%;
				}
				66% {
					background-position: 100% 100%;
				}
				100% {
					background-position: 0% 0%;
				}
			}
			@include media-breakpoint-down(md) {
				right:auto;
				left:0;
			}
		}
	}
	@include media-breakpoint-down(md) {
		left:auto;
		right:0;
		transform:translateX(100%);
	}
	.search{
		// margin-bottom: 1rem;
		.search__title{
			text-align: left;
			margin-left: 2rem;
			margin-bottom: 0;
			font-size: rem-calc(14);
			&:before{
				background-size: 16px 16px;
				width: 16px;
				height: 16px;
			}
		}
	}
	.home-back__btn{
		padding-bottom: 1rem;
		p{
			margin-bottom: 0;
		}
		a{
			width: 200px;
			background-color: #333;
			color:$white;
			border-radius: 10px;
			@include opacity();
			&:before{
				content:"";
				display: inline-block;
				background: url(#{$dir}/common/logo_home.svg)no-repeat;
				background-size: 20px 20px;
				width:20px;
				height:20px;
				margin-right: .5rem;
				vertical-align: text-bottom;
			}
		}
	}
	ul{
		list-style: none;
		padding-left: 0;
		a{
			text-decoration: none;
		}
	}
	.header__nav {
		list-style: none;
		margin: 0;
		// padding: 1rem;
		position: relative;
		margin-bottom: 1rem;
		border-bottom: 2px solid $gray-300;
		@include mintyo();
		@include media-breakpoint-down(md) {
			padding-top: 60px;
			 * {
				font-family: $font-family-android;
			}
		}

		> li {
			a {
				display: flex;
				align-items: center;
				padding: .5em 1em;
				height: 100%;
				position: relative;
				@include mintyo();
				@include opacity();
				@include media-breakpoint-down(md) {
					font-family: $font-family-android;
				}
			}
		}
		.header__nav-item{
			padding-bottom: 1.5rem;
			padding:1rem;
			position: relative;
			ul{
				li{
					&:not(:first-of-type){
						a{
							color:$black;
							position: relative;
							display: block;
							padding-left: 1.5rem;
							&:before{
								content:"";
								position: absolute;
								top:21px;
								left: 0;
								margin:auto;
								display: inline-block;
								vertical-align: super;
								width:15px;
								height:2px;
							}
						}
					}
				}
			}
			&.know__item{
				&:after{
					content:"";
					display: block;
					position: absolute;
					bottom:0;
					left:0;
					width:100%;
					height:calc(100% - 45px - 1rem);
					background-color: $know_color;
				}
				ul{
					li{
						&:not(:first-of-type){
							a{
								&:before{
									background-color: $know_color;
								}
							}
						}
					}
				}
			}
			&.visit__item{
				&:after{
					content:"";
					display: block;
					position: absolute;
					bottom:0;
					left:0;
					width:100%;
					height:calc(100% - 45px - 1rem);
					background-color: $visit_color;
				}
				ul{
					li{
						&:not(:first-of-type){
							a{
								&:before{
									background-color: $visit_color;
								}
							}
						}
					}
				}
			}
			&.fun__item{
				&:after{
					content:"";
					display: block;
					position: absolute;
					bottom:0;
					left:0;
					width:100%;
					height:calc(100% - 45px - 1rem);
					background-color: $fun_color;
				}
				ul{
					li{
						&:not(:first-of-type){
							a{
								&:before{
									background-color: $fun_color;
								}
							}
						}
					}
				}
			}
		}
		.parent__category{
			display: flex;
			justify-content: space-between;
			.category__title{
				font-size: rem-calc(25);
			}
			p{
				margin-bottom: 0;
			}
			&.parent__category--know{
				color:$know_color;
				a{
					color:$know_color;
					&:after{
						content:"";
						display: inline-block;
						width:10px;
						height:10px;
						border-bottom: 1px solid $know_color;
						border-right:1px solid $know_color;
						transform:rotate(-45deg);
						margin-left: .5rem;
					}
				}
			}
			&.parent__category--visit{
				color:$visit_color;
				a{
					color:$visit_color;
					&:after{
						content:"";
						display: inline-block;
						width:10px;
						height:10px;
						border-bottom: 1px solid $visit_color;
						border-right:1px solid $visit_color;
						transform:rotate(-45deg);
						margin-left: .5rem;
					}
				}
			}
			&.parent__category--fun{
				color:$fun_color;
				a{
					color:$fun_color;
					&:after{
						content:"";
						display: inline-block;
						width:10px;
						height:10px;
						border-bottom: 1px solid $fun_color;
						border-right:1px solid $fun_color;
						transform:rotate(-45deg);
						margin-left: .5rem;
					}
				}
			}
		}
	}
	&.is-open{
		transform:translateX(0);
		.header__nav{
			.header__nav-item{
				&.know__item,
				&.visit__item,
				&.fun__item{
					&:after{
						height:0;
						transition:height .2s cubic-bezier(0.42, 0, 0.24, 1.04) .5s;
					}
				}
			}
		}
	}
}
// @media all and (-ms-high-contrast:none) {
// 	#header__menu {
// 		transform:translateX(-120%);
// 	}
// }

.overlay{
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	// background: tomato;
	background: rgba(0,0,0,.3);
	opacity: 0;
	z-index: 50;
	transition:.2s;
	cursor: pointer;
	&.is-active{
		display: block;
		opacity: 1;
	}
}

