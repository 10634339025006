﻿//
// Home
//

body.home {

	//
	// Header
	//
	.header {
		margin-bottom: 0;
	}

	main{
		position: relative;
		.language{
			position: absolute;
			top:0;
			left:0;
			z-index: 10;
			margin-bottom: 0;
			padding-left: 0;
			width: 70px;
			height: 61px;
			margin: auto;
			text-align: center;
			// padding-bottom: 1rem;
			font-size: rem-calc(11);
			.language__select-btn{
				height: 100%;
				cursor: pointer;
				// border-radius: 10px;
				padding: .4em .7em;
				background: #EEEEEE;
				position: relative;
				@include opacity();
				a{
					text-decoration: none;
					color:$black;
					span{
						&:before{
							content:"";
							display: block;
							background: url(#{$dir}/common/icon_language.svg)no-repeat;
							background-size: 25px 25px;
							width:25px;
							height:25px;
							margin: 5px auto auto;
						}
					}
				}
			}
		}
	}
	//
	// Hero
	//
	.hero__wrap {
		position: relative;
		padding-top: 2rem;
		margin-bottom: 3rem;
		@include media-breakpoint-down(md) {
			margin-bottom: 1.5rem;
		}
		@include media-breakpoint-up(lg) {
			&:after{
				content:"";
				background: url(#{$dir}/pages/bg_fish.png)no-repeat;
				display: block;
				position: absolute;
				left: 50px;
				bottom: 0;
				background-size: 159px 115px;
				width: 159px;
				height: 115px;
			}
		}
		ul{
			list-style: none;
			padding-left:0;
			margin-bottom: 0;
		}
		.site__title{
			padding-top: .5rem;
			overflow: hidden;
			position: relative;
			@include media-breakpoint-up(lg) {
				position: absolute;
				right:0;
				top:0;
				max-width:380px;
			}
			@include media-breakpoint-down(md) {
				text-align: center;
				max-width: 330px;
				margin: 0 auto 1.5rem;
			}
			img{
				@include media-breakpoint-up(lg) {
					transform:translateY(150px);	
				}
				@include media-breakpoint-down(md) {
					transform:translateY(131px);
				}
			}
			&.is_show{
				img{
					@include media-breakpoint-up(lg) {
						transform:translateY(0);
						transition:transform .6s cubic-bezier(0, 0, 0.18, 1.07) 1.7s;
					}
					@include media-breakpoint-down(md) {
						transform:translateY(0);
						transition:transform .8s cubic-bezier(0, 0, 0.18, 1.07) .2s;
					}
				}
			}
		}
		.main__categories{
			@include media-breakpoint-up(lg) {
				display: flex;
				// padding-bottom: 8rem;
			}
			@include media-breakpoint-down(md) {
				margin-bottom: 1rem;
				padding-bottom: 0.5rem;
			}
			.head__block{
				position: relative;
				.category__link{
					text-decoration: none;
					display: block;
					@include opacity();
				}
				h2{
					&:not(.en){
						position: absolute;
						right:-30px;
						bottom:-80px;
						z-index: 1;
						@include media-breakpoint-up(lg) {
							width:70px;
						}
						@include media-breakpoint-down(md) {
							right:auto;
							left:.5rem;
							bottom:0;
							margin-bottom: 0;
						}
						a{
							text-decoration: none;
							display: block;
							@include opacity();
						}
					}
					&.en{
						position: absolute;
						right:10px;
						bottom:0;
						z-index: 1;
						margin-bottom: 0;
						@include media-breakpoint-down(md) {
							right:auto;
							left:.5rem;
							bottom:0;
							margin-bottom: 0;
						}
						a{
							text-decoration: none;
							display: block;
							@include opacity();
						}
					}
					span{
						&:not(.en){
							&:first-of-type{
								font-size: rem-calc(60);
								text-shadow: 0 0 15px white, 0 0 15px white, 0 0 15px white;
								display: flex;
								@include mintyo();
								@include media-breakpoint-up(lg) {
									-webkit-writing-mode: vertical-rl;
									writing-mode: vertical-rl;
								}
								@include media-breakpoint-down(md) {
									font-family: $font-family-android;
								}
								&:first-letter{
									font-size: rem-calc(70);
								}
							}
							_:-ms-lang(x),&:first-of-type{
								@include media-breakpoint-up(lg) {
									display: inline-block;
								}
							}
							&:last-of-type{
								color:$white;
								font-size: rem-calc(30);
								@include media-breakpoint-up(lg) {
									position: absolute;
									right:100%;
									top:10px;
								}
								@include media-breakpoint-down(md) {
									display: none;
								}
							}
						}
						&.en{
							&:first-of-type{
								font-size: rem-calc(60);
								text-shadow: 0 0 15px white, 0 0 15px white, 0 0 15px white;
								display: flex;
								@include mintyo();
								@include media-breakpoint-down(md) {
									font-family: $font-family-android;
								}
								&:first-letter{
									font-size: rem-calc(70);
								}
							}
							// &:last-of-type{
							// 	color:$white;
							// 	font-size: rem-calc(30);
							// 	@include media-breakpoint-up(lg) {
							// 		position: absolute;
							// 		right:100%;
							// 		top:10px;
							// 	}
							// 	@include media-breakpoint-down(md) {
							// 		display: none;
							// 	}
							// }
						}
					}
				}
			}
			li{
				&.parent__item{
					position: relative;
					opacity: 0;
					transform:translateY(-50px);
					@include media-breakpoint-up(lg) {
						transition:.8s;
						width:calc(90% / 3);
						&:not(:last-of-type){
							margin-right: 5%;
						}
					}
					@include media-breakpoint-down(md) {
						transition:.8s cubic-bezier(0, 0, 0.18, 1.07) 1s;
					}
					&.is_show{
						opacity: 1;
						transform:translateY(0);
					}
					.category__items{
						margin-top: 1rem;
						li{
							margin-bottom: 1rem;
							a{
								display: flex;
								align-items: center;
								font-size: rem-calc(20);
								@include mintyo();
								@include media-breakpoint-down(md) {
									font-family: $font-family-android;
								}
								color:$black;
								position: relative;
								padding-left: 1rem;
								text-decoration: none;
								@include opacity();
								&:before{
									content:"";
									// position: absolute;
									// top:13px;
									// left: 0;
									// margin:auto;
									display: inline-block;
									// vertical-align: super;
									width:15px;
									height:2px;
									margin-right: 10px;
									transition:.2s;
								}
								&:hover{
									@include media-breakpoint-up(lg) {
										&:before{
											height:6px;
										}
									}
								}
							}
						}
					}
				}
				&.category__know,
				&.category__visit,
				&.category__fun{
					.bg__block{
						padding-top: 140%;
						@include media-breakpoint-down(md) {
							padding-top: 55%;
						}
					}
					@include media-breakpoint-up(lg) {
						.category__items{
							li{
								a{
									span{
										&:after{
											transition:.5s;
											transform:rotate(180deg);
										}
									}
									&:hover{
										span{
											&:after{
												transform:translateX(10px)rotate(360deg);
											}
										}
									}
								}
							}
						}
					}
				}
				&.category__know{
					.bg__block{
						// background: url(#{$dir}/home/img_hero_01.jpg)no-repeat;
						background-size: cover;
						background-position: center bottom;
						@include media-breakpoint-down(md) {
							// background: url(#{$dir}/home/img_hero_01_sp.jpg)no-repeat;
							background-size: cover;
							background-position: center;
						}
					}
					.head__block{
						h2{
							span{
								&:first-of-type{
									color:$know-color;
								}
							}
						}
					}
					.category__items{
						li{
							a{
								&:before{
									background-color: $know-color;
								}
								span{
									position: relative;
									&:after{
										content:"";
										background: url(#{$dir}/home/icon_know_arrow.svg)no-repeat;
										background-size: 20px 20px;
										width:20px;
										height:20px;
										display: block;
										position: absolute;
										left:calc(100% + 8px);
										top:0;
										bottom:0;
										margin:auto;
									}
								}
							}
						}
					}
				}
				&.category__visit{
					@include media-breakpoint-up(lg) {
						margin-top: 100px;
					}
					.bg__block{
						// background: url(#{$dir}/home/img_hero_02_pc.jpg)no-repeat;
						background-size: cover;
						background-position: center bottom;
						@include media-breakpoint-down(md) {
							// background: url(#{$dir}/home/img_hero_02_sp.jpg)no-repeat;
							background-size: cover;
							background-position: center;
						}
					}
					.head__block{
						h2{
							span{
								&:first-of-type{
									color:$visit-color;
								}
							}
						}
					}
					.category__items{
						li{
							a{
								&:before{
									background-color: $visit-color;
								}
								span{
									position: relative;
									&:after{
										content:"";
										background: url(#{$dir}/home/icon_visit_arrow.svg)no-repeat;
										background-size: 20px 20px;
										width:20px;
										height:20px;
										display: block;
										position: absolute;
										left:calc(100% + 8px);
										top:0;
										bottom:0;
										margin:auto;
									}
								}
							}
						}
					}
				}
				&.category__fun{
					@include media-breakpoint-up(lg) {
						margin-top: 200px;
					}
					.bg__block{
						// background: url(#{$dir}/home/img_hero_03_pc.jpg)no-repeat;
						background-size: cover;
						background-position: center bottom;
						@include media-breakpoint-down(md) {
							// background: url(#{$dir}/home/img_hero_03_sp.jpg)no-repeat;
							background-size: cover;
							background-position: center;
						}
					}
					.head__block{
						h2{
							span{
								&:first-of-type{
									color:$fun-color;
								}
								&:last-of-type{
									top:60px;
								}
							}
						}
					}
					.category__items{
						li{
							a{
								&:before{
									background-color: $fun-color;
								}
								span{
									position: relative;
									&:after{
										content:"";
										background: url(#{$dir}/home/icon_fun_arrow.svg)no-repeat;
										background-size: 20px 20px;
										width:20px;
										height:20px;
										display: block;
										position: absolute;
										left:calc(100% + 8px);
										top:0;
										bottom:0;
										margin:auto;
									}
								}
							}
						}
					}
				}
			}
		}
		.intro__catch-copy{
			color:#757575;
			@include mintyo();
			font-size: rem-calc(38);
			letter-spacing: 10px;
			@include media-breakpoint-up(lg) {
				position: absolute;
				bottom:0;
				left:0;
				margin-bottom: 0;
			}
			@include media-breakpoint-down(md) {
				font-size: rem-calc(25);
				margin-bottom: 0;
				font-family: $font-family-android;
			}
		}
	}
	// スライド
	// Slick arrows
	#js-carousel{
		max-width: 900px;
		margin:auto;
		display: none;
		padding-left: 0;
		list-style: none;
		&.slick-initialized{
			display: block;
		}
		img {
			width: 100%;
			height: auto;
		}
	}
	.slick-arrow {
		@include image-replacement();
		cursor: pointer;
		outline: 0;
		display: block;
		width: 60px;
		height: 60px;
		border-radius: 50%;
		background-color: transparent;
		position: absolute;
		z-index: 2;
		top: 50%;
		transform:translateY(-50%);
		border: none;
		padding: 0;
		&:after {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			display: block;
			width: 15px;
			height: 15px;
			border-top: 1px solid #777;
			border-left: 1px solid #777;
			transition:.3s;
			@include media-breakpoint-down(md) {
				border-top: 1px solid $white;
				border-left: 1px solid $white;
			}
		}
		&:hover{
			&:after{
				width:23px;
				height:23px;
				@include media-breakpoint-up(md) {
					border-top: 1px solid $primary;
					border-left: 1px solid $primary;
				}
			}
		}

		&.slick-prev {
			right: 100%;
			// margin-left: -15px;
			@include media-breakpoint-down(md) {
				left:-10px;
				right:auto;
			}
			&:after {
				transform: translate(-50%, -50%) rotate(-45deg);
				margin-left: 3px;
			}
		}

		&.slick-next {
			left: 100%;
			// margin-right: -15px;
			@include media-breakpoint-down(md) {
				right:-10px;
				left:auto;
			}
			&:after {
				transform: translate(-50%, -50%) rotate(135deg);
				margin-left: -3px;
			}
		}
	}

	// Slick dots
	.slick-dots {
		display: flex;
		justify-content: center;
		align-items: center;
		list-style: none;
		margin: 10px 0 20px;
		padding: 0;

		> li {
			margin: 0 10px;
			display: flex;
			align-items: center;

			button {
				@include image-replacement();
				border-radius: 50%;
				background-color: #E8E8E8;
				width: rem-calc(15);
				height: rem-calc(15);
				cursor: pointer;
				border: none;
				padding: 0;
			}

			&.slick-active {
				button {
					background-color: #ADADAD;
				}
			}
		}
	}
	// 三陸の魅力
	.charm__wrap{
		position: relative;
		// @include media-breakpoint-up(lg) {
		// 	padding-top: 25%;
		// }
		@include media-breakpoint-down(md) {
			margin-bottom: 4rem;
			padding-top: 0;
		}
		&:before{
			content:"";
			display: block;
			background: url(#{$dir}/home/bg_charm_map.png)no-repeat;
			background-size: 495px 740px;
			width:495px;
			height:740px;
			position: absolute;
			bottom: -40px;
			left:-160px;
			z-index: -10;
			margin:auto;
			@include media-breakpoint-down(md) {
				left:-210px;
			}
			@include media-breakpoint-down(sm) {
				left:auto;
				right:20px;
				bottom:-740px;
			}
		}
		.charm__first-bg{
			padding-top: 25%;
			margin:auto;
			@include media-breakpoint-up(lg) {
				position: absolute;
				left:-100%;
				right:-100%;
				top:0;
				background: url(#{$dir}/home/bg_charm01.jpg)no-repeat;
				background-size: 100%;
				background-position: center center;
				width:100vw;
			}
			@include media-breakpoint-down(md) {
				background: url(#{$dir}/home/bg_charm01_sp.jpg)no-repeat;
				background-size: 100%;
				background-position: center center;
				padding-top:65%;
				margin-bottom: 2rem;
			}
		}
		.block__txt{
			@include mintyo();
			span{
				background-color: rgba($white,.8);
				display: inline-block;
				letter-spacing: 3px;
				line-height: 1.5;
				padding:.5rem;
				margin-bottom:3px;
				@include media-breakpoint-down(md) {
					line-height: 1.5;
					padding:0.2rem .5rem;
				}
			}
		}
		.block__img{
			@include media-breakpoint-down(md) {
				text-align: center;
			}
		}
		.charm__intro{
			position: relative;
			span{
				display: inline-block;
				transform:scale(1.1)translateY(-30px);
				opacity: 0;
				transition:1s cubic-bezier(0.38, 0.1, 0.1, 0.99);
			}
			@include mintyo();
			font-size: rem-calc(38);
			@include media-breakpoint-up(lg) {
				padding-top: 2rem;
				margin-bottom: 90px;
			}
			@include media-breakpoint-down(md) {
				font-size: rem-calc(30);
				line-height: 1.5;
				margin-bottom: 2rem;
				font-family: $font-family-android;
			}
			&:after{
				content:"";
				display: block;
				background: url(#{$dir}/home/bg_charm_weave.png)no-repeat;
				background-size: cover;
				width:100vw;
				height:175px;
				position: absolute;
				top:0;
				left:-100%;
				right:-100%;
				z-index: -10;
				margin:auto;
				@include media-breakpoint-down(md) {
					background: url(#{$dir}/home/bg_charm_weave_sp.png)no-repeat;
					background-size: cover;
					height:65px;
				}
			}
			&.scroll-in{
				span{
					transform:scale(1)translateY(0);
					opacity: 1;
				}
			}
		}
		.first__block{
			position: relative;
			@include media-breakpoint-up(lg) {
				display: flex;
				flex-direction: row-reverse;
			}
			.block__img{
				opacity: 0;
				transition:1s cubic-bezier(0.38, 0.1, 0.1, 0.99);
				@include media-breakpoint-up(lg) {
					transform:scale(1.1)translate(40px,-40px);
				}
				@include media-breakpoint-down(md) {
					transform: scale(1.1)translateX(-30px);
					width:90%;
				}
				&.scroll-in{
					transform:scale(1)translate(20px,-40px);
					opacity: 1;
					@include media-breakpoint-down(md) {
						transform: scale(1.1)translateX(-10px);
					}
				}
			}
			.block__txt{
				@include media-breakpoint-up(lg) {
					position: absolute;
					left:0;
					z-index: 1;
					transform:translate(100px);
				}
				@include media-breakpoint-down(md) {
					transform:translateY(-50px);
					text-align: right;
					margin-bottom: 0;
					font-family: $font-family-android;
				}
				span{
					position: relative;
					opacity: 0;
					transition:1s cubic-bezier(0.38, 0.1, 0.1, 0.99);
					// transform:translateX(-40px);
					@include media-breakpoint-up(lg) {
						transform:translateX(-150px);
					}
				}
				&.scroll-in{
					span{
						transform: translateX(-100px);
						opacity: 1;
						@include media-breakpoint-down(md) {
							transform: translateX(10px);
						}
					}
				}
			}
		}
		.second__block{
			position: relative;
			display: flex;
			justify-content: flex-end;
			@include media-breakpoint-down(md) {
				display: block;
			}
			.block__img{
				opacity: 0;
				transition:1s cubic-bezier(0.38, 0.1, 0.1, 0.99);
				@include media-breakpoint-up(lg) {
					transform: scale(1.1)translate(-90px,-100px)
				}
				@include media-breakpoint-down(md) {
					text-align: right;
					transform: scale(1.1)translate(-40px,-40px);
					width:90%;
					margin-left: auto;
				}
				&.scroll-in{
					transform: scale(1)translate(-70px,-100px);
					opacity: 1;
					@include media-breakpoint-down(md) {
						transform:scale(1)translate(10px,0);
					}
				}
			}
			.block__txt{
				position: relative;
				opacity: 0;
				transition:1s cubic-bezier(0.38, 0.1, 0.1, 0.99);
				@include media-breakpoint-up(lg) {
					z-index: 1;
					transform:translate(0,-40px);
				}
				@include media-breakpoint-down(md) {
					transform:translateY(-50px);
					font-family: $font-family-android;
				}
				&.scroll-in{
					transform: scale(1)translate(-40px,-40px);
					opacity: 1;
					@include media-breakpoint-down(md) {
						transform: scale(1)translate(-10px,-40px);
					}
				}
			}
		}
		.third__block{
			position: relative;
			@include media-breakpoint-up(lg) {
				display: flex;
				justify-content: flex-end;
				flex-direction: row-reverse;
				transform: translateY(-100px);
				&:after{
					content:"";
					background: url(#{$dir}/home/img_charm_item01.png)no-repeat;
					background-size: 67px 62px;
					width:67px;
					height:62px;
					position: absolute;
					top:30px;
					left:0;
				}
			}
			.block__txt{
				position: relative;
				opacity: 0;
				transition:1s cubic-bezier(0.38, 0.1, 0.1, 0.99);
				transform:translateY(-50px);
				@include media-breakpoint-up(lg) {
					margin: 3rem auto 0;
				}
				@include media-breakpoint-down(md) {
					position: absolute;
					top: 130px;
					left: 0;
					right: 0;
					margin: auto;
					font-family: $font-family-android;
				}
				&.scroll-in{
					transform: translateY(0);
					opacity: 1;
				}
			}
			@media all and (-ms-high-contrast: none) {
				@include media-breakpoint-up(lg) {
					*::-ms-backdrop, .block__txt {
						margin:3rem 0 0;
						width:100%;
					}
				}
			}
			.block__img{
				&.img__first{
					opacity: 0;
					transition:1s cubic-bezier(0.38, 0.1, 0.1, 0.99);
					transform:translateX(50px);
					@include media-breakpoint-up(lg) {
						position: absolute;
						top: -50px;
						right: -50px;
						z-index: -1;
					}
					@include media-breakpoint-down(md) {
						text-align: right;
						transform:translateX(10px);
						width: 90%;
						margin-left: auto;
					}
					&.scroll-in{
						transform: scale(1)translateX(10px);
						opacity: 1;
					}
				}
				&.img__second{
					opacity: 0;
					transition:1s cubic-bezier(0.38, 0.1, 0.1, 0.99);
					transform:scale(1.1)translate(-50px,0);
					@include media-breakpoint-up(lg) {
						position: absolute;
						bottom: -50px;
						left: -50px;
						z-index: -1;
					}
					@include media-breakpoint-down(md) {
						position: relative;
						z-index: -1;
						text-align: left;
						transform: translate(-10px,120px);
						margin-bottom: 3rem;
						width: 90%;
					}
					&.scroll-in{
						transform: scale(1)translate(0,0);
						opacity: 1;
						@include media-breakpoint-down(md) {
							transform:scale(1) translate(-10px,70px);
						}
					}
				}
			}
		}
		.force__block{
			position: relative;
			// @include media-breakpoint-up(lg) {
			// 	&:after{
			// 		content:"";
			// 		background: url(#{$dir}/home/img_charm_item02.png)no-repeat;
			// 		background-size: 113px 54px;
			// 		width:113px;
			// 		height:54px;
			// 		position: absolute;
			// 		top: -40px;
			// 		right: 170px;
			// 	}
			// 	&:before{
			// 		content:"";
			// 		background: url(#{$dir}/home/img_charm_item03.png)no-repeat;
			// 		background-size: 130px 109px;
			// 		width:130px;
			// 		height:109px;
			// 		position: absolute;
			// 		top:-60px;
			// 		left:10px;
			// 		z-index: -1;
			// 	}
			// }
			// .inner{
			// 	display: flex;
			// 	justify-content: center;
			// 	@include media-breakpoint-down(md) {
			// 		flex-direction: column-reverse;
			// 		flex-wrap:wrap;
			// 	}
			// 	figcaption{
			// 		margin-top: 0.5rem;
			// 		@include media-breakpoint-down(md) {
			// 			font-family: $font-family-android;
			// 		}
			// 	}
			// }
			.charm__message{
				.first__txt{
					margin-bottom: 1rem;
					display: block;
					font-size: rem-calc(30);
					@include media-breakpoint-down(sm) {
						font-size: rem-calc(25);
					}
				}
				@include mintyo();
				font-size: rem-calc(22);
				letter-spacing: 3px;
				line-height: 2;
				display: inline-block;
				margin: 9rem 0 14rem 380px;
				opacity: 0;
				@media print{
					opacity: 1;
				}
				transition:2s cubic-bezier(0.38, 0.1, 0.1, 0.99);
				transform:scale(1.1)translate(0,-30px);
				@include media-breakpoint-down(md) {
					font-family: $font-family-android;
					margin: 9rem 0 9rem 300px;
				}
				@include media-breakpoint-down(sm) {
					display: block;
					text-align: center;
					// margin: 6rem 0 740px;
					margin:4rem 0 740px;
				}
				&.scroll-in{
					transform: scale(1)translate(0,0);
					opacity: 1;
				}
			}
			.block__txt{
				position: relative;
				opacity: 0;
				transition:1s cubic-bezier(0.38, 0.1, 0.1, 0.99);
				@include media-breakpoint-up(lg) {
					transform:scale(1.2)translate(40px,60px);
				}
				@include media-breakpoint-down(md) {
					text-align: right;
					transform:translate(50px,0);
					font-family: $font-family-android;
				}
				&.scroll-in{
					transform: scale(1)translate(0,60px);
					opacity: 1;
					@include media-breakpoint-down(md) {
						transform:translate(10px,60px);
					}
				}
			}
			.block__img{
				opacity: 0;
				transition:1s cubic-bezier(0.38, 0.1, 0.1, 0.99);
				transform: scale(1.1)translate(-60px,-60px);
				&.img__first{
					@include media-breakpoint-up(md) {
						margin-right: 1rem;
					}
				}
				&.scroll-in{
					transform: scale(1)translate(0,50px);
					opacity: 1;
				}
			}
		}
	}
	// アクセス情報
	.access__wrap{
		margin-bottom: 4rem;
		@include mintyo();
		h2{
			text-align: center;
			margin-bottom: 1.5rem;
			@include media-breakpoint-down(md) {
				font-family: $font-family-android;
			}
		}
		.access__anchor{
      padding-left: 0;
      display: flex;
      flex-wrap:wrap;
      justify-content: center;
      li{
        width:calc((100% - 10%) / 3 );
        text-align: center;
        margin-bottom: 0.5rem;
        @include media-breakpoint-only(md) {
          width:calc((100% - 6%) / 3 );
        }
        @include media-breakpoint-down(sm) {
          width:100%;
          max-width: 300px;
        }
        &:not(:last-of-type){
          @include media-breakpoint-up(lg) {
            margin-right: 5%;
          }
          @include media-breakpoint-only(md) {
            margin-right: 3%;
          }
        }
        a{
          font-size: rem-calc(20);
          text-decoration: none;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-wrap:wrap;
          padding:2rem .5rem;
					height:100%;
					background-color: $secondary;
					// border:2px solid $primary;
					color:$black;
          position: relative;
					transition:.2s;
					@include media-breakpoint-down(md) {
						font-family: $font-family-android;
					}
          &:after{
            content:"";
            position: absolute;
            display: block;
						bottom:10px;
						top:0;
						bottom:0;
            right:10px;
            margin:auto;
            width:15px;
            height:15px;
            border-bottom: 2px solid $primary;
            border-right: 2px solid $primary;
            transform:rotate(-45deg);
          }
          &:hover{
            img{
              transform:scale(1.1);
            }
          }
          span{
            display: block;
            width:100%;
            padding-top: 0.5rem;
          }
        }
      }
    }
	}
	// さんりく新着情報
	.article__info{
		.first__block{
			@include media-breakpoint-up(lg) {
				// text-align: center;
				// display: flex;
			}
			.head__block{
				position: relative;
				letter-spacing: 5px;
				// text-align: center;
				display: inline-block;
				@include mintyo();
				@include media-breakpoint-up(lg) {
					// display: flex;
					// align-items: center;
					// width:30%;
					// -webkit-writing-mode: vertical-rl;
					// writing-mode: vertical-rl;
				}
				@include media-breakpoint-down(md) {
					margin: 0 auto 2rem;
				}
				h2{
					position: relative;
					font-size: rem-calc(30);
					margin-bottom: 2rem;
					line-height: 1.5;
					@include media-breakpoint-down(md) {
						// text-align: center;
						// max-width: 230px;
						margin: auto;
						font-family: $font-family-android;
					}
					&:after{
						content:"";
						display: inline-block;
						background: url(#{$dir}/pages/bg_head_pattern02.svg)no-repeat;
						background-size: 52px 9px;
						width:52px;
						height:9px;
						position: absolute;
						right:-60px;
						bottom:0;
						@include media-breakpoint-down(md) {
							right: -20px;
							bottom: 100%;
						}
					}
					span{
						// display: block;
						&:first-of-type{
							@include media-breakpoint-down(md) {
								// text-align: left;
							}
						}
						&:last-of-type{
							@include media-breakpoint-up(lg) {
								// margin-top: 4rem;
								// margin-right: .5rem;
							}
							@include media-breakpoint-down(md) {
								// text-align: right;
							}
						}
					}
				}
				&:after{
					content:"";
					display: inline-block;
					background: url(#{$dir}/pages/bg_head_pattern02.svg)no-repeat;
					background-size: 52px 9px;
					width:52px;
					height:9px;
					position: absolute;
					left:20px;
					top:-30px;
					@include media-breakpoint-down(md) {
						left: 20%;
						top: -30px;
					}
				}
				&:before{
					content:"";
					display: inline-block;
					background: url(#{$dir}/home/bg_weave_small.svg)no-repeat;
					background-size: 52px 9px;
					width:52px;
					height:9px;
					position: absolute;
					left:-30px;
					top:50%;
					@include media-breakpoint-down(md) {
						left: 25%;
						top: 115%;
					}
				}
			}
			.search{
				position: relative;
				@include media-breakpoint-up(lg) {
					width:70%;
					&:after{
						content:"";
						display: inline-block;
						background: url(#{$dir}/home/img_search_fish.png)no-repeat;
						background-size: 247px 135px;
						width:247px;
						height:135px;
						position: absolute;
						right:0;
						top:0;
					}
				}
			}
		}
	}
	// 新着記事
	section .p-common__article{
		margin-bottom: 1rem;
	}
	// rss
	section .others{
		margin-bottom: 3rem;
	}
	// お知らせ
	.news__wrap{
		margin-bottom: 3rem;
		@include media-breakpoint-down(md) {
		}
		.news__inner{
			margin:auto;
			position: relative;
			@include media-breakpoint-up(lg) {
				width:90%;
			}
			h2{
				@include mintyo();
				font-size: rem-calc(30);
				letter-spacing: 5px;
				position: relative;
				line-height: 1.5;
				margin-bottom: 1rem;
				// &:before{
				// 	content:"";
				// 	display: block;
				// 	background: url(#{$dir}/pages/bg_pages_separation.svg)repeat-x;
				// 	background-size: 42px 24px;
				// 	width:100vw;
				// 	height:24px;
				// 	position: absolute;
				// 	left:-100%;
				// 	right:-100%;
				// 	top:110%;
				// 	margin:auto;
				// 	z-index: -1;
				// 	@include media-breakpoint-down(md) {
				// 		// top: 50px;
				// 	}
				// }
				@include media-breakpoint-up(lg) {
					// -webkit-writing-mode: vertical-rl;
					// writing-mode: vertical-rl;
				}

				@include media-breakpoint-down(md) {
					margin-bottom: 4rem;
					font-family: $font-family-android;
				}
				&:after{
					content:"";
					display: block;
					position: absolute;
					right: 0;
					bottom: 0%;
					background: url(#{$dir}/pages/bg_fish.png)no-repeat;
					background-size: 159px 115px;
					width:159px;
					height:115px;
					@include media-breakpoint-down(md) {
						// left:auto;
						// right:0;
						display: none;
					}
				}
			}
			_:-ms-lang(x),h2{
				@include media-breakpoint-up(md) {
					// height:160px; 
				}
			}
			// .latest__news{
			// 	padding:1rem;
			// 	background-color: $secondary;
			// 	@include media-breakpoint-up(lg) {
			// 		display: flex;
			// 		align-items: center;
			// 	}
			// 	dt{
			// 		width:20%;
			// 		@include media-breakpoint-down(md) {
			// 			width:100%;
			// 		}
			// 	}
			// 	dd{
			// 		width:80%;
			// 		margin-bottom: 0;
			// 		@include media-breakpoint-down(md) {
			// 			width:100%;
			// 		}
			// 	}
			// }
			.news__list{
				padding:0.5rem 1rem;
				margin-bottom: 0;
				@include media-breakpoint-up(lg) {
					display: flex;
					align-items: center;
					flex-wrap:wrap;
				}
				dt{
					width:18%;
					@include media-breakpoint-down(md) {
						width:100%;
					}
				}
				dd{
					width:82%;
					margin-bottom: 0;
					@include media-breakpoint-up(lg) {
						padding-left: 1rem;
					}
					@include media-breakpoint-down(md) {
						width:100%;
					}
				}
				&:nth-of-type(odd){
					background-color: $secondary;
				}
			}
		}
	}
	// sns
	.sns__wrap{
		margin-bottom: 3rem;
		.three__sns{
			justify-content: center;
			margin-bottom: 3rem;
			@include media-breakpoint-down(sm) {
				margin-bottom: 2rem;
			}
		}
		.sns__title{
			font-size: rem-calc(19);
			display: flex;
			align-items: center;
			@include mintyo();
			&:before{
				content:"";
				display: inline-block;
				width:25px;
				height:25px;
				margin-right: .5rem;
			}
		}
		.youtube__content{
			width:83%;
			margin:auto;
			@include media-breakpoint-down(sm) {
				width:100%;
			}
			.sns__title{
				margin:auto;
				font-size: rem-calc(19);
				display: flex;
				align-items: center;
				@include mintyo();
				margin-bottom: 0.5rem;
				&:before{
					content:"";
					display: inline-block;
					margin-right: .5rem;
					background: url(#{$dir}/home/icon_home_youtube.png)no-repeat;
					background-size: 35px 25px;
					width:35px;
					height:25px;
					vertical-align: bottom;
				}
			}
			.p-youtube__wrap{
				// width:83%;
				// margin-bottom: 2rem;
				padding-top: 55%;
				width:100%;
				margin-top:0;
				margin-bottom: 0;
				@include media-breakpoint-down(sm) {
					margin:0;
					padding-top: 75%;
				}
			}
			.youtube__caution{
				font-size: rem-calc(12);
			}
		}
		.instagram__content{
			.sns__title{
				color:#8533AA;
				&:before{
					content:url(#{$dir}/home/icon_home_instagram.png);
					display: inline-block;
				}
			}
			.instagram__list{
				display: flex;
				flex-wrap:wrap;
				li{
					width:20%;
					overflow: hidden;
					@include media-breakpoint-down(sm) {
						width:50%;
					}
					a{
						display: block;
						height:100%;
						position: relative;
						text-decoration: none;
						&:before{
							content:"Instagramで見る";
							cursor: pointer;
							color:$white;
							font-weight: bold;
							font-size: rem-calc(18);
							@include mintyo();
							background-color: rgba(#01AE9A,.6);
							position: absolute;
							left:0;
							top:0;
							display: flex;
							align-items: center;
							justify-content: center;
							width:100%;
							height:100%;
							padding:0 .5rem;
							z-index:-1;
							opacity: 0;
							transform:scale(.5);
							transition:.3s;
							@include media-breakpoint-down(md) {
								font-size: rem-calc(16);
							}
						}
						img{
							width: 100%;
							height: 100%;
							object-fit: cover;
							@include media-breakpoint-up(md) {
								max-height:195px;
							}
							@include media-breakpoint-down(sm) {
								max-height:250px;
							}
						}
						&:hover{
							&:before{
								z-index: 10;
								opacity: 1;
								transform:scale(1);
							}
						}
					}
					&:nth-of-type(n+7){
						@include media-breakpoint-down(sm) {
							display: none;
						}
					}
				}

				@media all and (-ms-high-contrast:none) {
					li a{
						display: flex;
						align-items: center;
						justify-content: center;
						background-color:#d4d4d4;
					}
					li a img{
						transform:scale(1.5);
						height:auto;
					}
				}
				// @supports (-ms-ime-align: auto) {
				// 	li a{
				// 		display: flex;
				// 		align-items: center;
				// 		justify-content: center;
				// 		background-color:#d4d4d4;
				// 	}
				// 	li a img{
				// 		transform:scale(1.5);
				// 		width:100%;
				// 	}
				// }
			}
		}
		.first__block{
			@include media-breakpoint-down(sm) {
				margin-bottom: 2rem;
				text-align: center;
			}
			.sns__title{
				color:#8533AA;
				&:before{
					background: url(#{$dir}/home/icon_home_instagram.png)no-repeat;
					background-size: 25px 25px;
				}
			}
		}
		.second__block{
			@include media-breakpoint-down(sm) {
				margin-bottom: 2rem;
				text-align: center;
				.fb-page{
					margin-left:1rem;
					margin-right: 1rem;
				}
			}
			.sns__title{
				color:#3B579D;
				&:before{
					background: url(#{$dir}/home/icon_home_facebook.png)no-repeat;
					background-size: 25px 25px;
				}
			}
		}
		.third__block{
			@include media-breakpoint-down(sm) {
				margin-bottom: 2rem;
				text-align: center;
				.outside__frame{
					margin-left:1rem;
					margin-right: 1rem;
				}
			}
			.sns__title{
				color:#0E94E6;
				&:before{
					background: url(#{$dir}/home/icon_home_twitter.png)no-repeat;
					background-size: 25px 25px;
				}
			}
		}
		
	}

}
