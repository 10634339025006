//
// components
//
body.home,
body.page{

  .p-article__date{
    font-size: rem-calc(15);
    text-align: right;
  }

	//line through
	.p-line-through{
		text-decoration: line-through;
	}

	//big font
	.p-large__fz{
		font-size: rem-calc(20);
	}

	// imporntat txt
	.p-important__txt{
		padding:1rem;
		margin-bottom: 1rem;
		border:5px solid $primary;
		p{
			margin-bottom: 0;
		}
	}

	//blockquote
	.p-blockquote{
		padding-left: 2rem;
		border-left: 5px solid $primary;
		margin-bottom: 1rem;
		@include media-breakpoint-down(sm) {
			padding-left: 1rem;
		}
		.original{
			text-align: right;
			margin-bottom: 0;
		}
	}

	//custom list
	.p-custom__list{
		padding-left: 0;
		li{
			padding-left: .5rem;
			&:before{
				content:"";
				display: inline-block;
				width:8px;
				height:8px;
				border-radius: 50%;
				background: $primary;
				margin-right: .5rem;
				margin-bottom: 2px;
			}
		}
	}

	//custom list part2
	.p-custom__list__second{
		padding-left: 0;
		li{
			padding-left: .5rem;
			&:before{
				content:"";
				display: inline-block;
				width:8px;
				height:8px;
				vertical-align: initial;
				background: $primary;
				margin-right: .5rem;
				margin-bottom: 2px;
			}
		}
	}

	// definition dotted
	.p-definition__lists__dotted {
		display: flex;
		flex-wrap: wrap;
		dt {
			width: 25%;
			border-bottom: 4px dotted $gray;
			margin-bottom: 0;
			padding:.5em;
			@include media-breakpoint-down(md) {
				width: 100%;
			}
		}
		dd {
			border-bottom: 4px dotted $gray;
			margin-bottom: 0;
			margin-left: 4px;
			padding:.5em;
			width: calc(75% - 4px);
			@include media-breakpoint-down(md) {
				width: 100%;
				margin-left: 0;
			}
		}
	}

	// definition dash
	.p-definition__lists__dashed {
		display: flex;
		flex-wrap: wrap;
		dt {
			width: 25%;
			border-bottom: 3px dashed $gray;
			margin-bottom: 0;
			padding: .5em;
			@include media-breakpoint-down(md) {
				width: 100%;
			}
		}
		dd {
			border-bottom: 3px dashed $gray;
			margin-bottom: 0;
			margin-left: 3px;
			padding: .5em;
			width: calc(75% - 3px);
			@include media-breakpoint-down(md) {
				width: 100%;
			}
		}
	}

	// definition site
	.p-definition__site{
    $page_color: $primary;
    $page_definition:rgba($page_color,.5);
		display: flex;
		flex-wrap: wrap;
		dt {
			width: 25%;
			border-bottom: 1px solid $white;
      padding: .5em;
      background: $page_definition;
      text-align: center;
			@include media-breakpoint-down(md) {
        width: 100%;
        border-bottom: 1px solid $white;
			}
		}
		dd {
			border-bottom: 1px solid $white;
			border-right: 1px solid $white;
      margin-bottom: 0;
      padding: .5em;
      width: calc(75% - 3px);
      background-color:$gray-200;
			@include media-breakpoint-down(md) {
        width: 100%;
        border-right:none;
			}
    }
    dt,
    dd{
      border-left: 1px solid $white;
      &:first-of-type{
        @include media-breakpoint-up(lg) {
          border-top: 1px solid $white;
        }
      }
      &:last-of-type{
        border-bottom: 1px solid $white;
      }
    }
	}

	// table
	// .p-table-wrap {
	// 	overflow: auto;
	// 	.table {
	// 		@include media-breakpoint-down(md) {
	// 			min-width: 550px;
	// 		}
	// 		th,
	// 		td {
	// 			min-width: 8em;
	// 		}
	// 		caption {
	// 			caption-side: initial;
	// 			color: $black;
	// 			font-weight: bold;
	// 		}
	// 		thead {
	// 			th {
	// 				background: $primary;
	// 				color: $white;
	// 				border-bottom: 0;
	// 			}
	// 		}
	// 		tbody {
	// 			th {
	// 				background: $primary;
	// 				color: $white;
	// 			}
	// 		}
	// 	}
	// 	&.p-calendar{
	// 		text-align: center;
	// 		.table{
	// 			min-width: unset;
	// 			tbody{
	// 				td{
	// 					&:first-of-type{
	// 						background-color: $gray-200;
	// 					}
	// 				}
	// 			}
	// 			td,
	// 			th{
	// 				min-width: unset;
	// 			}
	// 		}
	// 	}
	// }
	// .p-table-wrap__second {
	// 	overflow: auto;
	// 	.table {
	// 		@include media-breakpoint-down(md) {
	// 			min-width: 550px;
	// 		}
	// 		th,
	// 		td {
	// 			min-width: 8em;
	// 		}
	// 		caption {
	// 			caption-side: initial;
	// 			color: $black;
	// 			font-weight: bold;
	// 		}
	// 		thead {
	// 			tr{
	// 				border-bottom: 2px solid $gray-500;
	// 				th{
	// 					border-bottom: 0;
	// 					border-top: 0;
	// 				}
	// 			}
	// 		}
	// 		tbody {
	// 			tr{
	// 				border-bottom: 1px solid $gray-500;
	// 			}
	// 		}
	// 	}
	// 	&.p-striped{
	// 		.table{
	// 			thead {
	// 				tr{
	// 					border-bottom: 2px solid $primary;
	// 				}
	// 			}
	// 		}
	// 	}
	// }

	// tab
	.p-tab{
    margin-bottom: 2rem;
    .nav-tabs{
      padding-left: 0;
      border-bottom: 0;
      li{
        &:not(:last-of-type){
          margin-right: 2px;
        }
        a{
          background: $gray-500;
          text-decoration: none;
          color:$white;
          border:1px solid $gray-500;
          &.active{
            background: $primary;
            color:$white;
            border:1px solid $primary;
          }
        }
      }
    }
    .tab-content{
      border:1px solid $gray-500;
    }
	}

	// breadcrumb
	.p-breadcrumb{
    background-color: initial;
    padding-bottom: 0;
    padding-left: 0;
    margin-bottom: 0;
    a{
      color:$primary;
    }
    .breadcrumb-item+.breadcrumb-item:before{
      content:">";
    }
    &:nth-of-type(2){
      border-top: 1px dashed $primary;
      border-radius:0;
      padding-bottom: 1rem;
    }
    &:not(:nth-of-type(2)){
      padding-bottom: 0;
      padding-bottom: 0.75rem;
    }
	}
	// pagination
	.p-pagination{
    padding-left: 0;
    justify-content: center;
    flex-wrap:wrap;
    li{
      @include media-breakpoint-up(lg) {
        margin-bottom: 0.5rem;
      }
      .page-link{
        @include media-breakpoint-down(md) {
          padding:.7rem 1rem;
        }
      }
    }
    .page-item{
      @include media-breakpoint-down(md) {
        margin-bottom: 5px;
      }
      a{
        border:1px solid $primary;
        text-decoration: none;
        color:$primary;
        @include media-breakpoint-down(md) {
          padding:.7rem 1rem;
        }
        &:hover{
          background: $primary;
          color:$white;
        }
      }
    }
    .pagination__move__wrap{
      @include media-breakpoint-up(lg) {
        &:first-of-type{
          margin-right: .5rem;
        }
        &:last-of-type{
          margin-left: .5rem;
        }
      }
      @include media-breakpoint-down(md) {
        width:100%;
        &:first-of-type{
          margin-bottom: 1rem;
        }
        &:last-of-type{
          margin-top: 1rem;
        }
      }
    }
    .move_btn__wrap{
      padding-left: 0;
      display: flex;
      @include media-breakpoint-down(md) {
        justify-content: center;
      }
      ul{
        padding-left: 0;
        list-style:none;
      }
      &.move--prev{
        .move_btn{
          @include media-breakpoint-up(lg) {
            margin-right: 0.2rem;
          }
        }
      }
      &.move--next{
        .move_btn{
          @include media-breakpoint-up(lg) {
            margin-left: 0.2rem;
          }
        }
      }
      .move_btn{
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        @include media-breakpoint-down(md) {
          &:nth-of-type(odd){
            margin-right: 5px;
          }
        }
        a{
          min-height:40px;
          @include media-breakpoint-down(md) {
            width:100%;
          }
          transition:.2s;
          &:hover{
            background-color: darken($primary,5%);
          }
        }
        &.first-page,
        &.last-page{
          span{
            position: absolute;
            top:0;
            bottom:0;
            margin:auto;
            display: inline-block;
            height:15px;
            &:before{
              content:"";
              position: absolute;
              display: inline-block;
              width:0;
              height:0;
              top:0;
              bottom:0;
              margin:auto;
              border-top: 8px solid transparent;
              border-bottom: 8px solid transparent;
            }
            &:after{
              content:"";
              position: absolute;
              display: inline-block;
              width:0;
              height:0;
              top:0;
              bottom:0;
              margin:auto;
              border-top: 8px solid transparent;
              border-bottom: 8px solid transparent;
            }
          }
        }
        &.first-page{
          a{
            padding-left: 40px;
          }
          span{
            left:10px;
            border-left: 2px solid $white;
            &:before{
              left:0;
              border-right: 10px solid $white;
            }
            &:after{
              left:10px;
              border-right: 10px solid $white;
            }
          }
        }
        &.last-page{
          a{
            padding-right: 40px;
          }
          span{
            right:10px;
            border-right: 2px solid $white;
            &:before{
              right:0;
              border-left: 10px solid $white;
            }
            &:after{
              right:10px;
              border-left: 10px solid $white;
            }
          }
        }
        &.prev-page,
        &.next-page{
          a{
            span{
              position: absolute;
              top: 0;
              bottom: 0;
              margin:auto;
              border-bottom: 8px solid transparent;
              border-top: 8px solid transparent;
              width:0;
              height:0;
            }
          }
        }
        &.prev-page{
          a{
            padding-left: 25px;
            span{
              left:10px;
              border-right: 8px solid $white;
            }
          }
        }
        &.next-page{
          a{
            padding-right: 25px;
          }
          span{
            right:10px;
            border-left: 8px solid $white;
          }
        }
        a{
          padding:.4rem .5rem;
          background-color: $primary;
          color:$white;
          text-decoration: none;
          border-radius: 5px;
          position: relative;
          @include media-breakpoint-down(md) {
            text-align: center;
          }
        }
      }
    }
  }

  // youtube iframe
  .p-youtube__wrap{
    position: relative;
    padding-top: 45%;
    width:70%;
    margin:2rem auto;
    @include media-breakpoint-down(md) {
      width:100%;
      padding-top: 55%;
    }
    @include media-breakpoint-down(sm) {
      padding-top: 75%;
    }
    iframe{
      position: absolute;
      left:0;
      top:0;
      width:100%;
      height:100%;

    }
  }

  	// form
	.p-form{
    form{
      dl{
        display: flex;
        flex-wrap:wrap;
        border:1px solid $gray-500;
        dt,
        dd{
          padding:1rem;
        }
        dt{
          width:20%;
          @include media-breakpoint-only(md) {
            width:25%;
          }
          @include media-breakpoint-down(sm) {
            width:100%;
            br{
              display: none;
            }
            span.badge{
              margin-left: .5rem;
            }
          }
          &:not(:last-of-type){
            border-bottom: 1px solid $gray-500;
          }
          background: $gray-300;
          span{
            color:$white;
            background: $red;
          }
        }
        dd{
          width:80%;
          @include media-breakpoint-up(md) {
            border-left: 1px solid $gray-500;
          }
          margin-bottom: 0;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          @include media-breakpoint-only(md) {
            width:75%;
          }
          @include media-breakpoint-down(sm) {
            width:100%;
          }
          &:not(:last-of-type){
            border-bottom: 1px solid $gray-500;
          }
        }
        input{
          border-radius: 5px;
          border:2px solid $gray-500;
          padding: 5px;
          &[type="radio"]{
            margin-right: 5px;
          }
          &:focus{
            outline:3px solid $gray-400;
            @include media-breakpoint-up(md) {
              padding-top:5px;
              padding-bottom: 5px;
            }
          }
        }
        textarea,
        select{
          border-radius: 5px;
          border:2px solid $gray-500;
          &:focus{
            outline:3px solid $gray-400;
          }
        }
        .select__wrap{
          border:2px solid $gray-500;
          overflow: hidden;
          border-radius: 5px;
          padding-right: 30px;
          position: relative;
          &:after{
            content:"";
            display: block;
            position: absolute;
            right:5px;
            top:0;
            bottom:0;
            margin:auto;
            width:0;
            height:0;
            border-top:8px solid $gray-600;
            border-left:5px solid transparent;
            border-right:5px solid transparent;
          }
          select{
            width:200%;
            height: 30px;
            border:none;
            appearance: none;
            padding-left: .5rem;
            &:focus{
              @supports (-ms-ime-align: auto) {
                // Edge のみ適用される
                background: #006ef7;
                color:$white;
              }
            }
          }
        }
        label{
          position: relative;
          margin-bottom: 0.5rem;
          input,
          textarea,
          select{
            &:focus{
              outline:3px solid $gray-400;
              @include media-breakpoint-up(md) {
                padding-top:5px;
                padding-bottom: 5px;
              }
            }
          }
          &.radio{
            margin-right: 1rem;
            position: relative;
            cursor: pointer;
            span{
              position: relative;
              display: flex;
              align-items: center;
              white-space: nowrap;
              padding: 5px 5px 5px 30px;
              &:before{
                content:"";
                display: inline-block;
                position: absolute;
                left:0;
                top:0;
                bottom:0;
                margin:auto auto auto 5px;
                width:18px;
                height:18px;
                border:1px solid $gray-600;
                padding-top:5px;
                padding-bottom: 5px;
                border-radius: 50%;
              }
              &:after{
                content:"";
                display: inline-block;
                position: absolute;
                left:3px;
                top:0;
                bottom:0;
                width:12px;
                height:12px;
                margin:auto auto auto 5px;
                background-color: $primary;
                border-radius: 50%;
                opacity: 0;
                visibility: hidden;
                transform:scale(.2);
                transition:.15s;
              }
            }
            input{
              position: absolute;
              z-index: -1;
              opacity: 0;
              &.radio__item{
                &:checked + span{
                  color:$primary;
                  &:after{
                    opacity: 1;
                    visibility: visible;
                    transform:scale(1);
                  }
                }
                &:focus + span{
                  outline:3px solid $gray-500;
                  @include media-breakpoint-up(md) {
                    padding-top:5px;
                    padding-bottom:5px;
                  }
                }
              }
            }
          }
          &.checkbox{
            margin-right: 1rem;
            cursor: pointer;
            span{
              position: relative;
              display: flex;
              align-items: center;
              white-space: nowrap;
              padding-left: 25px;
              padding-right: 5px;
              &:before{
                content:"";
                display: inline-block;
                position: absolute;
                left:0;
                top:0;
                bottom:0;
                margin:auto;
                width:14px;
                height:14px;
                border:1px solid $gray-600;
                border-radius: 3px;
                margin-left: 5px;
              }
              &:after{
                content:"";
                display: inline-block;
                position: absolute;
                left:12px;
                top:-7px;
                bottom:0;
                width:7px;
                height:15px;
                margin:auto;
                border-right:3px solid $primary;
                border-bottom: 3px solid $primary;
                opacity: 0;
                visibility: hidden;
                transform:rotate(45deg)scale(0);
                transition:.15s;
              }
            }
            input{
              position: absolute;
              left:0;
              top:0;
              opacity: 0;
              &.checkbox__item{
                &:checked + span{
                  color:$primary;
                  &:after{
                    opacity: 1;
                    visibility: visible;
                    animation:checkScale .15s forwards;
                    @keyframes checkScale {
                      0%{
                        transform:rotate(45deg)scale(0);
                      }
                      50%{
                        transform:rotate(45deg)scale(1.4);
                      }
                      100%{
                        transform:rotate(45deg)scale(1);
                      }
                    }
                  }
                }
                &:focus + span{
                  outline:3px solid $gray-500;
                  @include media-breakpoint-up(md) {
                    padding-top:5px;
                    padding-bottom:5px;
                  }
                }
              }
            }
          }
        }
        textarea{
          width:100%;
        }
      }
    }
  }
  
  .p-next-btn__wrap{
    text-align: center;
    margin-bottom: .5rem;
    button{
      color:$white;
      background: $primary;
      padding-left: 30px;
      position: relative;
      transition:.2s;
      &:hover{
        cursor: pointer;
        background: darken($primary,5%);
      }
      &:before{
        content:"";
        display: block;
        position: absolute;
        left:10px;
        top:0;
        bottom:0;
        margin:auto;
        width:15px;
        height:15px;
        background-color: $white;
        border-radius: 50%;
      }
      &:after{
        content:"";
        display: block;
        position: absolute;
        left:15px;
        top:0;
        bottom:0;
        margin:auto;
        width:0;
        height:0;
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent;
        border-left: 5px solid $primary;
      }
    }
  }
  .p-back-btn__wrap{
    text-align: center;
    margin-bottom: .5rem;
    button{
      color:$white;
      background: $gray;
      padding-left: 30px;
      position: relative;
      transition:.2s;
      &:hover{
        cursor: pointer;
        background: darken($gray,5%);
      }
      &:before{
        content:"";
        display: block;
        position: absolute;
        left:10px;
        top:0;
        bottom:0;
        margin:auto;
        width:15px;
        height:15px;
        background-color: $white;
        border-radius: 50%;
      }
      &:after{
        content:"";
        display: block;
        position: absolute;
        left:15px;
        top:0;
        bottom:0;
        margin:auto;
        width:0;
        height:0;
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent;
        border-right: 5px solid $gray;
      }
    }
  }
  .form__btn__wrap{
    @include media-breakpoint-up(md) {
      display: flex;
      justify-content: center;
    }
    .p-back-btn__wrap{
      @include media-breakpoint-up(md) {
        margin-right: 1rem;
      }
    }
  }

}
