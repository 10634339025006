@charset "UTF-8";
img {
  max-width: 100%;
  height: auto;
  transition: all 0.2s ease-in-out;
}

img.alignleft {
  float: left;
  margin-right: 1rem;
}

img.alignright {
  float: right;
  margin-left: 1rem;
}

img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

a:hover img, a:focus img {
  opacity: .8;
}

:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #BB0900;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #3B9810;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #00729C;
  --secondary: #E3EDF4;
  --success: #3B9810;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #BB0900;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 600px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 1025px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(51, 51, 51, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "游ゴシック", "Yu Gothic", YuGothic, "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo, "ＭＳ ゴシック", "ＭＳ Ｐゴシック", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.8;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #1b74b3;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #114971;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 1.5rem;
}

h3, .h3 {
  font-size: 1.4rem;
}

h4, .h4 {
  font-size: 1.4rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(51, 51, 51, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}

.blockquote-footer::before {
  content: "\2014\00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg {
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 600px) {
  .container, .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px;
  }
}

@media (min-width: 1025px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 1020px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col-13, .col-14, .col-15, .col-16, .col-17, .col-18, .col-19, .col-20, .col-21, .col-22, .col-23, .col-24, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-13, .col-sm-14, .col-sm-15, .col-sm-16, .col-sm-17, .col-sm-18, .col-sm-19, .col-sm-20, .col-sm-21, .col-sm-22, .col-sm-23, .col-sm-24, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md-13, .col-md-14, .col-md-15, .col-md-16, .col-md-17, .col-md-18, .col-md-19, .col-md-20, .col-md-21, .col-md-22, .col-md-23, .col-md-24, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-13, .col-lg-14, .col-lg-15, .col-lg-16, .col-lg-17, .col-lg-18, .col-lg-19, .col-lg-20, .col-lg-21, .col-lg-22, .col-lg-23, .col-lg-24, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-13, .col-xl-14, .col-xl-15, .col-xl-16, .col-xl-17, .col-xl-18, .col-xl-19, .col-xl-20, .col-xl-21, .col-xl-22, .col-xl-23, .col-xl-24, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 4.16667%;
  max-width: 4.16667%;
}

.col-2 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.col-3 {
  flex: 0 0 12.5%;
  max-width: 12.5%;
}

.col-4 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-5 {
  flex: 0 0 20.83333%;
  max-width: 20.83333%;
}

.col-6 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-7 {
  flex: 0 0 29.16667%;
  max-width: 29.16667%;
}

.col-8 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.col-9 {
  flex: 0 0 37.5%;
  max-width: 37.5%;
}

.col-10 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.col-11 {
  flex: 0 0 45.83333%;
  max-width: 45.83333%;
}

.col-12 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-13 {
  flex: 0 0 54.16667%;
  max-width: 54.16667%;
}

.col-14 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.col-15 {
  flex: 0 0 62.5%;
  max-width: 62.5%;
}

.col-16 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.col-17 {
  flex: 0 0 70.83333%;
  max-width: 70.83333%;
}

.col-18 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-19 {
  flex: 0 0 79.16667%;
  max-width: 79.16667%;
}

.col-20 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.col-21 {
  flex: 0 0 87.5%;
  max-width: 87.5%;
}

.col-22 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.col-23 {
  flex: 0 0 95.83333%;
  max-width: 95.83333%;
}

.col-24 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 25;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.order-13 {
  order: 13;
}

.order-14 {
  order: 14;
}

.order-15 {
  order: 15;
}

.order-16 {
  order: 16;
}

.order-17 {
  order: 17;
}

.order-18 {
  order: 18;
}

.order-19 {
  order: 19;
}

.order-20 {
  order: 20;
}

.order-21 {
  order: 21;
}

.order-22 {
  order: 22;
}

.order-23 {
  order: 23;
}

.order-24 {
  order: 24;
}

.offset-1 {
  margin-left: 4.16667%;
}

.offset-2 {
  margin-left: 8.33333%;
}

.offset-3 {
  margin-left: 12.5%;
}

.offset-4 {
  margin-left: 16.66667%;
}

.offset-5 {
  margin-left: 20.83333%;
}

.offset-6 {
  margin-left: 25%;
}

.offset-7 {
  margin-left: 29.16667%;
}

.offset-8 {
  margin-left: 33.33333%;
}

.offset-9 {
  margin-left: 37.5%;
}

.offset-10 {
  margin-left: 41.66667%;
}

.offset-11 {
  margin-left: 45.83333%;
}

.offset-12 {
  margin-left: 50%;
}

.offset-13 {
  margin-left: 54.16667%;
}

.offset-14 {
  margin-left: 58.33333%;
}

.offset-15 {
  margin-left: 62.5%;
}

.offset-16 {
  margin-left: 66.66667%;
}

.offset-17 {
  margin-left: 70.83333%;
}

.offset-18 {
  margin-left: 75%;
}

.offset-19 {
  margin-left: 79.16667%;
}

.offset-20 {
  margin-left: 83.33333%;
}

.offset-21 {
  margin-left: 87.5%;
}

.offset-22 {
  margin-left: 91.66667%;
}

.offset-23 {
  margin-left: 95.83333%;
}

@media (min-width: 600px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 4.16667%;
    max-width: 4.16667%;
  }
  .col-sm-2 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-sm-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .col-sm-4 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-5 {
    flex: 0 0 20.83333%;
    max-width: 20.83333%;
  }
  .col-sm-6 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-7 {
    flex: 0 0 29.16667%;
    max-width: 29.16667%;
  }
  .col-sm-8 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-sm-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .col-sm-10 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-sm-11 {
    flex: 0 0 45.83333%;
    max-width: 45.83333%;
  }
  .col-sm-12 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-13 {
    flex: 0 0 54.16667%;
    max-width: 54.16667%;
  }
  .col-sm-14 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-sm-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .col-sm-16 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-sm-17 {
    flex: 0 0 70.83333%;
    max-width: 70.83333%;
  }
  .col-sm-18 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-19 {
    flex: 0 0 79.16667%;
    max-width: 79.16667%;
  }
  .col-sm-20 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-sm-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .col-sm-22 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-sm-23 {
    flex: 0 0 95.83333%;
    max-width: 95.83333%;
  }
  .col-sm-24 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 25;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .order-sm-13 {
    order: 13;
  }
  .order-sm-14 {
    order: 14;
  }
  .order-sm-15 {
    order: 15;
  }
  .order-sm-16 {
    order: 16;
  }
  .order-sm-17 {
    order: 17;
  }
  .order-sm-18 {
    order: 18;
  }
  .order-sm-19 {
    order: 19;
  }
  .order-sm-20 {
    order: 20;
  }
  .order-sm-21 {
    order: 21;
  }
  .order-sm-22 {
    order: 22;
  }
  .order-sm-23 {
    order: 23;
  }
  .order-sm-24 {
    order: 24;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 4.16667%;
  }
  .offset-sm-2 {
    margin-left: 8.33333%;
  }
  .offset-sm-3 {
    margin-left: 12.5%;
  }
  .offset-sm-4 {
    margin-left: 16.66667%;
  }
  .offset-sm-5 {
    margin-left: 20.83333%;
  }
  .offset-sm-6 {
    margin-left: 25%;
  }
  .offset-sm-7 {
    margin-left: 29.16667%;
  }
  .offset-sm-8 {
    margin-left: 33.33333%;
  }
  .offset-sm-9 {
    margin-left: 37.5%;
  }
  .offset-sm-10 {
    margin-left: 41.66667%;
  }
  .offset-sm-11 {
    margin-left: 45.83333%;
  }
  .offset-sm-12 {
    margin-left: 50%;
  }
  .offset-sm-13 {
    margin-left: 54.16667%;
  }
  .offset-sm-14 {
    margin-left: 58.33333%;
  }
  .offset-sm-15 {
    margin-left: 62.5%;
  }
  .offset-sm-16 {
    margin-left: 66.66667%;
  }
  .offset-sm-17 {
    margin-left: 70.83333%;
  }
  .offset-sm-18 {
    margin-left: 75%;
  }
  .offset-sm-19 {
    margin-left: 79.16667%;
  }
  .offset-sm-20 {
    margin-left: 83.33333%;
  }
  .offset-sm-21 {
    margin-left: 87.5%;
  }
  .offset-sm-22 {
    margin-left: 91.66667%;
  }
  .offset-sm-23 {
    margin-left: 95.83333%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 4.16667%;
    max-width: 4.16667%;
  }
  .col-md-2 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-md-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .col-md-4 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-5 {
    flex: 0 0 20.83333%;
    max-width: 20.83333%;
  }
  .col-md-6 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-7 {
    flex: 0 0 29.16667%;
    max-width: 29.16667%;
  }
  .col-md-8 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-md-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .col-md-10 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-md-11 {
    flex: 0 0 45.83333%;
    max-width: 45.83333%;
  }
  .col-md-12 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-13 {
    flex: 0 0 54.16667%;
    max-width: 54.16667%;
  }
  .col-md-14 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-md-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .col-md-16 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-md-17 {
    flex: 0 0 70.83333%;
    max-width: 70.83333%;
  }
  .col-md-18 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-19 {
    flex: 0 0 79.16667%;
    max-width: 79.16667%;
  }
  .col-md-20 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-md-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .col-md-22 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-md-23 {
    flex: 0 0 95.83333%;
    max-width: 95.83333%;
  }
  .col-md-24 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 25;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .order-md-13 {
    order: 13;
  }
  .order-md-14 {
    order: 14;
  }
  .order-md-15 {
    order: 15;
  }
  .order-md-16 {
    order: 16;
  }
  .order-md-17 {
    order: 17;
  }
  .order-md-18 {
    order: 18;
  }
  .order-md-19 {
    order: 19;
  }
  .order-md-20 {
    order: 20;
  }
  .order-md-21 {
    order: 21;
  }
  .order-md-22 {
    order: 22;
  }
  .order-md-23 {
    order: 23;
  }
  .order-md-24 {
    order: 24;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 4.16667%;
  }
  .offset-md-2 {
    margin-left: 8.33333%;
  }
  .offset-md-3 {
    margin-left: 12.5%;
  }
  .offset-md-4 {
    margin-left: 16.66667%;
  }
  .offset-md-5 {
    margin-left: 20.83333%;
  }
  .offset-md-6 {
    margin-left: 25%;
  }
  .offset-md-7 {
    margin-left: 29.16667%;
  }
  .offset-md-8 {
    margin-left: 33.33333%;
  }
  .offset-md-9 {
    margin-left: 37.5%;
  }
  .offset-md-10 {
    margin-left: 41.66667%;
  }
  .offset-md-11 {
    margin-left: 45.83333%;
  }
  .offset-md-12 {
    margin-left: 50%;
  }
  .offset-md-13 {
    margin-left: 54.16667%;
  }
  .offset-md-14 {
    margin-left: 58.33333%;
  }
  .offset-md-15 {
    margin-left: 62.5%;
  }
  .offset-md-16 {
    margin-left: 66.66667%;
  }
  .offset-md-17 {
    margin-left: 70.83333%;
  }
  .offset-md-18 {
    margin-left: 75%;
  }
  .offset-md-19 {
    margin-left: 79.16667%;
  }
  .offset-md-20 {
    margin-left: 83.33333%;
  }
  .offset-md-21 {
    margin-left: 87.5%;
  }
  .offset-md-22 {
    margin-left: 91.66667%;
  }
  .offset-md-23 {
    margin-left: 95.83333%;
  }
}

@media (min-width: 1025px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 4.16667%;
    max-width: 4.16667%;
  }
  .col-lg-2 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-lg-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .col-lg-4 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-5 {
    flex: 0 0 20.83333%;
    max-width: 20.83333%;
  }
  .col-lg-6 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-7 {
    flex: 0 0 29.16667%;
    max-width: 29.16667%;
  }
  .col-lg-8 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-lg-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .col-lg-10 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-lg-11 {
    flex: 0 0 45.83333%;
    max-width: 45.83333%;
  }
  .col-lg-12 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-13 {
    flex: 0 0 54.16667%;
    max-width: 54.16667%;
  }
  .col-lg-14 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-lg-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .col-lg-16 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-lg-17 {
    flex: 0 0 70.83333%;
    max-width: 70.83333%;
  }
  .col-lg-18 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-19 {
    flex: 0 0 79.16667%;
    max-width: 79.16667%;
  }
  .col-lg-20 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-lg-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .col-lg-22 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-lg-23 {
    flex: 0 0 95.83333%;
    max-width: 95.83333%;
  }
  .col-lg-24 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 25;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .order-lg-13 {
    order: 13;
  }
  .order-lg-14 {
    order: 14;
  }
  .order-lg-15 {
    order: 15;
  }
  .order-lg-16 {
    order: 16;
  }
  .order-lg-17 {
    order: 17;
  }
  .order-lg-18 {
    order: 18;
  }
  .order-lg-19 {
    order: 19;
  }
  .order-lg-20 {
    order: 20;
  }
  .order-lg-21 {
    order: 21;
  }
  .order-lg-22 {
    order: 22;
  }
  .order-lg-23 {
    order: 23;
  }
  .order-lg-24 {
    order: 24;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 4.16667%;
  }
  .offset-lg-2 {
    margin-left: 8.33333%;
  }
  .offset-lg-3 {
    margin-left: 12.5%;
  }
  .offset-lg-4 {
    margin-left: 16.66667%;
  }
  .offset-lg-5 {
    margin-left: 20.83333%;
  }
  .offset-lg-6 {
    margin-left: 25%;
  }
  .offset-lg-7 {
    margin-left: 29.16667%;
  }
  .offset-lg-8 {
    margin-left: 33.33333%;
  }
  .offset-lg-9 {
    margin-left: 37.5%;
  }
  .offset-lg-10 {
    margin-left: 41.66667%;
  }
  .offset-lg-11 {
    margin-left: 45.83333%;
  }
  .offset-lg-12 {
    margin-left: 50%;
  }
  .offset-lg-13 {
    margin-left: 54.16667%;
  }
  .offset-lg-14 {
    margin-left: 58.33333%;
  }
  .offset-lg-15 {
    margin-left: 62.5%;
  }
  .offset-lg-16 {
    margin-left: 66.66667%;
  }
  .offset-lg-17 {
    margin-left: 70.83333%;
  }
  .offset-lg-18 {
    margin-left: 75%;
  }
  .offset-lg-19 {
    margin-left: 79.16667%;
  }
  .offset-lg-20 {
    margin-left: 83.33333%;
  }
  .offset-lg-21 {
    margin-left: 87.5%;
  }
  .offset-lg-22 {
    margin-left: 91.66667%;
  }
  .offset-lg-23 {
    margin-left: 95.83333%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 4.16667%;
    max-width: 4.16667%;
  }
  .col-xl-2 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-xl-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .col-xl-4 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-5 {
    flex: 0 0 20.83333%;
    max-width: 20.83333%;
  }
  .col-xl-6 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-7 {
    flex: 0 0 29.16667%;
    max-width: 29.16667%;
  }
  .col-xl-8 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-xl-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .col-xl-10 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-xl-11 {
    flex: 0 0 45.83333%;
    max-width: 45.83333%;
  }
  .col-xl-12 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-13 {
    flex: 0 0 54.16667%;
    max-width: 54.16667%;
  }
  .col-xl-14 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-xl-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .col-xl-16 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-xl-17 {
    flex: 0 0 70.83333%;
    max-width: 70.83333%;
  }
  .col-xl-18 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-19 {
    flex: 0 0 79.16667%;
    max-width: 79.16667%;
  }
  .col-xl-20 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-xl-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .col-xl-22 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-xl-23 {
    flex: 0 0 95.83333%;
    max-width: 95.83333%;
  }
  .col-xl-24 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 25;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .order-xl-13 {
    order: 13;
  }
  .order-xl-14 {
    order: 14;
  }
  .order-xl-15 {
    order: 15;
  }
  .order-xl-16 {
    order: 16;
  }
  .order-xl-17 {
    order: 17;
  }
  .order-xl-18 {
    order: 18;
  }
  .order-xl-19 {
    order: 19;
  }
  .order-xl-20 {
    order: 20;
  }
  .order-xl-21 {
    order: 21;
  }
  .order-xl-22 {
    order: 22;
  }
  .order-xl-23 {
    order: 23;
  }
  .order-xl-24 {
    order: 24;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 4.16667%;
  }
  .offset-xl-2 {
    margin-left: 8.33333%;
  }
  .offset-xl-3 {
    margin-left: 12.5%;
  }
  .offset-xl-4 {
    margin-left: 16.66667%;
  }
  .offset-xl-5 {
    margin-left: 20.83333%;
  }
  .offset-xl-6 {
    margin-left: 25%;
  }
  .offset-xl-7 {
    margin-left: 29.16667%;
  }
  .offset-xl-8 {
    margin-left: 33.33333%;
  }
  .offset-xl-9 {
    margin-left: 37.5%;
  }
  .offset-xl-10 {
    margin-left: 41.66667%;
  }
  .offset-xl-11 {
    margin-left: 45.83333%;
  }
  .offset-xl-12 {
    margin-left: 50%;
  }
  .offset-xl-13 {
    margin-left: 54.16667%;
  }
  .offset-xl-14 {
    margin-left: 58.33333%;
  }
  .offset-xl-15 {
    margin-left: 62.5%;
  }
  .offset-xl-16 {
    margin-left: 66.66667%;
  }
  .offset-xl-17 {
    margin-left: 70.83333%;
  }
  .offset-xl-18 {
    margin-left: 75%;
  }
  .offset-xl-19 {
    margin-left: 79.16667%;
  }
  .offset-xl-20 {
    margin-left: 83.33333%;
  }
  .offset-xl-21 {
    margin-left: 87.5%;
  }
  .offset-xl-22 {
    margin-left: 91.66667%;
  }
  .offset-xl-23 {
    margin-left: 95.83333%;
  }
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  background-color: transparent;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(51, 51, 51, 0.05);
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(51, 51, 51, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8d8e3;
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7ab6cc;
}

.table-hover .table-primary:hover {
  background-color: #a6cedc;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #a6cedc;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #f7fafc;
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #f0f6f9;
}

.table-hover .table-secondary:hover {
  background-color: #e4eef5;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #e4eef5;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c8e2bc;
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #99c983;
}

.table-hover .table-success:hover {
  background-color: #b9daaa;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b9daaa;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #ecbab8;
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #dc7f7a;
}

.table-hover .table-danger:hover {
  background-color: #e7a6a4;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #e7a6a4;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(51, 51, 51, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(38, 38, 38, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(38, 38, 38, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #212529;
  border-color: #32383e;
}

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #212529;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #32383e;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 599.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1024.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(2.55rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.8;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #1dc2ff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 114, 156, 0.25);
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  appearance: none;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.8;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.8;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.6;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.8;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.9rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.6;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(3.25rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.8;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}

.form-row > .col > .valid-tooltip,
.form-row > [class*="col-"] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.form-control .was-validated :valid,
.form-control .is-valid {
  border-color: #28a745;
  padding-right: 2.55rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.form-control .was-validated :valid:focus,
.form-control .is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

textarea.form-control .was-validated :valid,
textarea.form-control .is-valid {
  padding-right: 2.55rem;
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.custom-select .was-validated :valid,
.custom-select .is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}

.custom-select .was-validated :valid:focus,
.custom-select .is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.form-check-input .was-validated :valid ~ .form-check-label,
.form-check-input .is-valid ~ .form-check-label {
  color: #28a745;
}

.form-check-input .was-validated :valid ~ .valid-feedback,
.form-check-input .was-validated :valid ~ .valid-tooltip,
.form-check-input .is-valid ~ .valid-feedback,
.form-check-input .is-valid ~ .valid-tooltip {
  display: block;
}

.custom-control-input .was-validated :valid ~ .custom-control-label,
.custom-control-input .is-valid ~ .custom-control-label {
  color: #28a745;
}

.custom-control-input .was-validated :valid ~ .custom-control-label::before,
.custom-control-input .is-valid ~ .custom-control-label::before {
  border-color: #28a745;
}

.custom-control-input .was-validated :valid:checked ~ .custom-control-label::before,
.custom-control-input .is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}

.custom-control-input .was-validated :valid:focus ~ .custom-control-label::before,
.custom-control-input .is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.custom-control-input .was-validated :valid:focus:not(:checked) ~ .custom-control-label::before,
.custom-control-input .is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745;
}

.custom-file-input .was-validated :valid ~ .custom-file-label,
.custom-file-input .is-valid ~ .custom-file-label {
  border-color: #28a745;
}

.custom-file-input .was-validated :valid:focus ~ .custom-file-label,
.custom-file-input .is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}

.form-row > .col > .invalid-tooltip,
.form-row > [class*="col-"] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.form-control .was-validated :invalid,
.form-control .is-invalid {
  border-color: #dc3545;
  padding-right: 2.55rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.form-control .was-validated :invalid:focus,
.form-control .is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

textarea.form-control .was-validated :invalid,
textarea.form-control .is-invalid {
  padding-right: 2.55rem;
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.custom-select .was-validated :invalid,
.custom-select .is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}

.custom-select .was-validated :invalid:focus,
.custom-select .is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-check-input .was-validated :invalid ~ .form-check-label,
.form-check-input .is-invalid ~ .form-check-label {
  color: #dc3545;
}

.form-check-input .was-validated :invalid ~ .invalid-feedback,
.form-check-input .was-validated :invalid ~ .invalid-tooltip,
.form-check-input .is-invalid ~ .invalid-feedback,
.form-check-input .is-invalid ~ .invalid-tooltip {
  display: block;
}

.custom-control-input .was-validated :invalid ~ .custom-control-label,
.custom-control-input .is-invalid ~ .custom-control-label {
  color: #dc3545;
}

.custom-control-input .was-validated :invalid ~ .custom-control-label::before,
.custom-control-input .is-invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}

.custom-control-input .was-validated :invalid:checked ~ .custom-control-label::before,
.custom-control-input .is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}

.custom-control-input .was-validated :invalid:focus ~ .custom-control-label::before,
.custom-control-input .is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.custom-control-input .was-validated :invalid:focus:not(:checked) ~ .custom-control-label::before,
.custom-control-input .is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545;
}

.custom-file-input .was-validated :invalid ~ .custom-file-label,
.custom-file-input .is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}

.custom-file-input .was-validated :invalid:focus ~ .custom-file-label,
.custom-file-input .is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 600px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.8;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #212529;
  text-decoration: none;
}

.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 114, 156, 0.25);
}

.btn.disabled, .btn:disabled {
  opacity: 0.65;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #00729C;
  border-color: #00729C;
}

.btn-primary:hover {
  color: #fff;
  background-color: #005676;
  border-color: #004d69;
}

.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #005676;
  border-color: #004d69;
  box-shadow: 0 0 0 0.2rem rgba(38, 135, 171, 0.5);
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #00729C;
  border-color: #00729C;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #004d69;
  border-color: #00435c;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 135, 171, 0.5);
}

.btn-secondary {
  color: #212529;
  background-color: #E3EDF4;
  border-color: #E3EDF4;
}

.btn-secondary:hover {
  color: #212529;
  background-color: #c8dbe9;
  border-color: #bed5e6;
}

.btn-secondary:focus, .btn-secondary.focus {
  color: #212529;
  background-color: #c8dbe9;
  border-color: #bed5e6;
  box-shadow: 0 0 0 0.2rem rgba(198, 207, 214, 0.5);
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #212529;
  background-color: #E3EDF4;
  border-color: #E3EDF4;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #212529;
  background-color: #bed5e6;
  border-color: #b5d0e2;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(198, 207, 214, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #3B9810;
  border-color: #3B9810;
}

.btn-success:hover {
  color: #fff;
  background-color: #2e750c;
  border-color: #296a0b;
}

.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #2e750c;
  border-color: #296a0b;
  box-shadow: 0 0 0 0.2rem rgba(88, 167, 52, 0.5);
}

.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #3B9810;
  border-color: #3B9810;
}

.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #296a0b;
  border-color: #255e0a;
}

.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(88, 167, 52, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}

.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}

.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}

.btn-warning:focus, .btn-warning.focus {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}

.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #BB0900;
  border-color: #BB0900;
}

.btn-danger:hover {
  color: #fff;
  background-color: #950700;
  border-color: #880700;
}

.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #950700;
  border-color: #880700;
  box-shadow: 0 0 0 0.2rem rgba(197, 46, 38, 0.5);
}

.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #BB0900;
  border-color: #BB0900;
}

.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #880700;
  border-color: #7b0600;
}

.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(197, 46, 38, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}

.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-outline-primary {
  color: #00729C;
  border-color: #00729C;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #00729C;
  border-color: #00729C;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 114, 156, 0.5);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #00729C;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #00729C;
  border-color: #00729C;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 114, 156, 0.5);
}

.btn-outline-secondary {
  color: #E3EDF4;
  border-color: #E3EDF4;
}

.btn-outline-secondary:hover {
  color: #212529;
  background-color: #E3EDF4;
  border-color: #E3EDF4;
}

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(227, 237, 244, 0.5);
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #E3EDF4;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #212529;
  background-color: #E3EDF4;
  border-color: #E3EDF4;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(227, 237, 244, 0.5);
}

.btn-outline-success {
  color: #3B9810;
  border-color: #3B9810;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #3B9810;
  border-color: #3B9810;
}

.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(59, 152, 16, 0.5);
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #3B9810;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #3B9810;
  border-color: #3B9810;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(59, 152, 16, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #BB0900;
  border-color: #BB0900;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #BB0900;
  border-color: #BB0900;
}

.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(187, 9, 0, 0.5);
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #BB0900;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #BB0900;
  border-color: #BB0900;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(187, 9, 0, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #1b74b3;
  text-decoration: none;
}

.btn-link:hover {
  color: #114971;
  text-decoration: underline;
}

.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}

.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.8;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.6;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(51, 51, 51, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 600px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1025px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #00729C;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}

.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}

.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}

.btn-group-toggle > .btn input[type="radio"],
.btn-group-toggle > .btn input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}

.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}

.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}

.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .custom-file {
  display: flex;
  align-items: center;
}

.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group:not(.has-validation) > .form-control:not(:last-child),
.input-group:not(.has-validation) > .custom-select:not(:last-child),
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group.has-validation > .form-control:nth-last-child(n + 3),
.input-group.has-validation > .custom-select:nth-last-child(n + 3),
.input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}

.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}

.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.8;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(3.25rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.8;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.9rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.6;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.8rem;
  padding-left: 2.5rem;
  color-adjust: exact;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.4rem;
  opacity: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #007bff;
  background-color: #00729C;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 114, 156, 0.25);
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #80bdff;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #50d0ff;
  border-color: #b3d7ff;
}

.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}

.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}

.custom-control-label::before {
  position: absolute;
  top: 0.4rem;
  left: -2.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #dee2e6;
  border: #adb5bd solid 1px;
}

.custom-control-label::after {
  position: absolute;
  top: 0.4rem;
  left: -2.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50% / 50% 50% no-repeat;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #00729C;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 114, 156, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 114, 156, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 114, 156, 0.5);
}

.custom-switch {
  padding-left: 3.25rem;
}

.custom-switch .custom-control-label::before {
  left: -3.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}

.custom-switch .custom-control-label::after {
  top: calc(0.4rem + 2px);
  left: calc(-3.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #dee2e6;
  transform: translateX(0.75rem);
}

.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 114, 156, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.55rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.8;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
}

.custom-select:focus {
  border-color: #1dc2ff;
  outline: 0;
  box-shadow: inset 0 1px 2px rgba(51, 51, 51, 0.075), 0 0 5px rgba(29, 194, 255, 0.5);
}

.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}

.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}

.custom-select::-ms-expand {
  display: none;
}

.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.9rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 75%;
}

.custom-select-lg {
  height: calc(3.25rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 125%;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.55rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.55rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: #1dc2ff;
  box-shadow: 0 0 0 0.2rem rgba(0, 114, 156, 0.25);
}

.custom-file-input[disabled] ~ .custom-file-label,
.custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}

.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.55rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.8;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: 2.55rem;
  padding: 0.375rem 0.75rem;
  line-height: 1.8;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}

.custom-range:focus {
  outline: 0;
}

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 114, 156, 0.25);
}

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 114, 156, 0.25);
}

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 114, 156, 0.25);
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #00729C;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}

.custom-range::-webkit-slider-thumb:active {
  background-color: #50d0ff;
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #00729C;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}

.custom-range::-moz-range-thumb:active {
  background-color: #50d0ff;
}

.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #00729C;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}

.custom-range::-ms-thumb:active {
  background-color: #50d0ff;
}

.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}

.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}

.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-moz-range-track {
  cursor: default;
}

.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #00729C;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

.navbar .container, .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.275rem;
  padding-bottom: 0.275rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50% / 100% 100% no-repeat;
}

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

@media (max-width: 599.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 600px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1024.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1025px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg {
  flex-wrap: nowrap;
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(51, 51, 51, 0.5);
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(51, 51, 51, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(51, 51, 51, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(51, 51, 51, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(51, 51, 51, 0.5);
  border-color: rgba(51, 51, 51, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba%2851, 51, 51, 0.5%29' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-light .navbar-text {
  color: rgba(51, 51, 51, 0.5);
}

.navbar-light .navbar-text a {
  color: rgba(51, 51, 51, 0.9);
}

.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(51, 51, 51, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-text a {
  color: #fff;
}

.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(51, 51, 51, 0.125);
  border-radius: 0.25rem;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(51, 51, 51, 0.03);
  border-bottom: 1px solid rgba(51, 51, 51, 0.125);
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(51, 51, 51, 0.03);
  border-top: 1px solid rgba(51, 51, 51, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck .card {
  margin-bottom: 10px;
}

@media (min-width: 600px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -10px;
    margin-left: -10px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 10px;
    margin-bottom: 0;
    margin-left: 10px;
  }
}

.card-group > .card {
  margin-bottom: 10px;
}

@media (min-width: 600px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 600px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}

.accordion > .card {
  overflow: hidden;
}

.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #1b74b3;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.page-link:hover {
  z-index: 2;
  color: #114971;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 114, 156, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #00729C;
  border-color: #00729C;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.8;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.6;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}

a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #00729C;
}

a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #004d69;
}

a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 114, 156, 0.5);
}

.badge-secondary {
  color: #212529;
  background-color: #E3EDF4;
}

a.badge-secondary:hover, a.badge-secondary:focus {
  color: #212529;
  background-color: #bed5e6;
}

a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(227, 237, 244, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #3B9810;
}

a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: #296a0b;
}

a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(59, 152, 16, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}

a.badge-info:hover, a.badge-info:focus {
  color: #fff;
  background-color: #117a8b;
}

a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}

a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #d39e00;
}

a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #BB0900;
}

a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #880700;
}

a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(187, 9, 0, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}

a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}

a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}

a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #1d2124;
}

a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}

@media (min-width: 600px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #18546a;
  background-color: #cce3eb;
  border-color: #b8d8e3;
}

.alert-primary hr {
  border-top-color: #a6cedc;
}

.alert-primary .alert-link {
  color: #0f3340;
}

.alert-secondary {
  color: #8f9497;
  background-color: #f9fbfd;
  border-color: #f7fafc;
}

.alert-secondary hr {
  border-top-color: #e4eef5;
}

.alert-secondary .alert-link {
  color: #757b7e;
}

.alert-success {
  color: #376821;
  background-color: #d8eacf;
  border-color: #c8e2bc;
}

.alert-success hr {
  border-top-color: #b9daaa;
}

.alert-success .alert-link {
  color: #234115;
}

.alert-info {
  color: #246d78;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}

.alert-info hr {
  border-top-color: #abdde5;
}

.alert-info .alert-link {
  color: #184951;
}

.alert-warning {
  color: #9d7d1c;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.alert-warning hr {
  border-top-color: #ffe8a1;
}

.alert-warning .alert-link {
  color: #725b14;
}

.alert-danger {
  color: #7a1d18;
  background-color: #f1cecc;
  border-color: #ecbab8;
}

.alert-danger hr {
  border-top-color: #e7a6a4;
}

.alert-danger .alert-link {
  color: #4f1310;
}

.alert-light {
  color: #999a9a;
  background-color: #fefefe;
  border-color: #fdfdfe;
}

.alert-light hr {
  border-top-color: #ececf6;
}

.alert-light .alert-link {
  color: #7f8181;
}

.alert-dark {
  color: #34373a;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}

.alert-dark hr {
  border-top-color: #b9bbbe;
}

.alert-dark .alert-link {
  color: #1c1e1f;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #00729C;
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(51, 51, 51, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #00729C;
  border-color: #00729C;
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}

.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 600px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1025px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #18546a;
  background-color: #b8d8e3;
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #18546a;
  background-color: #a6cedc;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #18546a;
  border-color: #18546a;
}

.list-group-item-secondary {
  color: #8f9497;
  background-color: #f7fafc;
}

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #8f9497;
  background-color: #e4eef5;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #8f9497;
  border-color: #8f9497;
}

.list-group-item-success {
  color: #376821;
  background-color: #c8e2bc;
}

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #376821;
  background-color: #b9daaa;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #376821;
  border-color: #376821;
}

.list-group-item-info {
  color: #246d78;
  background-color: #bee5eb;
}

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #246d78;
  background-color: #abdde5;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #246d78;
  border-color: #246d78;
}

.list-group-item-warning {
  color: #9d7d1c;
  background-color: #ffeeba;
}

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #9d7d1c;
  background-color: #ffe8a1;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #9d7d1c;
  border-color: #9d7d1c;
}

.list-group-item-danger {
  color: #7a1d18;
  background-color: #ecbab8;
}

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #7a1d18;
  background-color: #e7a6a4;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #7a1d18;
  border-color: #7a1d18;
}

.list-group-item-light {
  color: #999a9a;
  background-color: #fdfdfe;
}

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #999a9a;
  background-color: #ececf6;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #999a9a;
  border-color: #999a9a;
}

.list-group-item-dark {
  color: #34373a;
  background-color: #c6c8ca;
}

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #34373a;
  background-color: #b9bbbe;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #34373a;
  border-color: #34373a;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #333;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}

.close:hover {
  color: #333;
  text-decoration: none;
}

.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: .75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}

.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(51, 51, 51, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #333;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.modal-header .close {
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.8;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #e9ecef;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}

.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 600px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 1025px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "游ゴシック", "Yu Gothic", YuGothic, "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo, "ＭＳ ゴシック", "ＭＳ Ｐゴシック", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.8;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #333;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem;
}

.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #333;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #333;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem;
}

.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #333;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #333;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "游ゴシック", "Yu Gothic", YuGothic, "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo, "ＭＳ ゴシック", "ＭＳ Ｐゴシック", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.8;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(51, 51, 51, 0.2);
  border-radius: 0.3rem;
}

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}

.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem;
}

.bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
  bottom: calc(-0.5rem - 1px);
}

.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(51, 51, 51, 0.25);
}

.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem;
}

.bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(51, 51, 51, 0.25);
}

.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem;
}

.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
  top: calc(-0.5rem - 1px);
}

.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(51, 51, 51, 0.25);
}

.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem;
}

.bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(51, 51, 51, 0.25);
}

.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner:after {
  content: '';
  clear: both;
  display: block;
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50% / 100% 100% no-repeat;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: .5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #00729C !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #004d69 !important;
}

.bg-secondary {
  background-color: #E3EDF4 !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #bed5e6 !important;
}

.bg-success {
  background-color: #3B9810 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #296a0b !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #BB0900 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #880700 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #00729C !important;
}

.border-secondary {
  border-color: #E3EDF4 !important;
}

.border-success {
  border-color: #3B9810 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #BB0900 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix:after {
  content: '';
  clear: both;
  display: block;
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 600px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1025px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 600px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1025px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 600px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 1025px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(51, 51, 51, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(51, 51, 51, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(51, 51, 51, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 600px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1025px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: transparent;
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 600px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 1025px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #00729C !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #003a50 !important;
}

.text-secondary {
  color: #E3EDF4 !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #accade !important;
}

.text-success {
  color: #3B9810 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #205309 !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #BB0900 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #6f0500 !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(51, 51, 51, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 1025px !important;
  }
  .container {
    min-width: 1025px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #333;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #dee2e6;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}

body.home .p-article__date,
body.page .p-article__date {
  font-size: 0.9375rem;
  text-align: right;
}

body.home .p-line-through,
body.page .p-line-through {
  text-decoration: line-through;
}

body.home .p-large__fz,
body.page .p-large__fz {
  font-size: 1.25rem;
}

body.home .p-important__txt,
body.page .p-important__txt {
  padding: 1rem;
  margin-bottom: 1rem;
  border: 5px solid #00729C;
}

body.home .p-important__txt p,
body.page .p-important__txt p {
  margin-bottom: 0;
}

body.home .p-blockquote,
body.page .p-blockquote {
  padding-left: 2rem;
  border-left: 5px solid #00729C;
  margin-bottom: 1rem;
}

@media (max-width: 767.98px) {
  body.home .p-blockquote,
  body.page .p-blockquote {
    padding-left: 1rem;
  }
}

body.home .p-blockquote .original,
body.page .p-blockquote .original {
  text-align: right;
  margin-bottom: 0;
}

body.home .p-custom__list,
body.page .p-custom__list {
  padding-left: 0;
}

body.home .p-custom__list li,
body.page .p-custom__list li {
  padding-left: .5rem;
}

body.home .p-custom__list li:before,
body.page .p-custom__list li:before {
  content: "";
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #00729C;
  margin-right: .5rem;
  margin-bottom: 2px;
}

body.home .p-custom__list__second,
body.page .p-custom__list__second {
  padding-left: 0;
}

body.home .p-custom__list__second li,
body.page .p-custom__list__second li {
  padding-left: .5rem;
}

body.home .p-custom__list__second li:before,
body.page .p-custom__list__second li:before {
  content: "";
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: initial;
  background: #00729C;
  margin-right: .5rem;
  margin-bottom: 2px;
}

body.home .p-definition__lists__dotted,
body.page .p-definition__lists__dotted {
  display: flex;
  flex-wrap: wrap;
}

body.home .p-definition__lists__dotted dt,
body.page .p-definition__lists__dotted dt {
  width: 25%;
  border-bottom: 4px dotted #888;
  margin-bottom: 0;
  padding: .5em;
}

@media (max-width: 1024.98px) {
  body.home .p-definition__lists__dotted dt,
  body.page .p-definition__lists__dotted dt {
    width: 100%;
  }
}

body.home .p-definition__lists__dotted dd,
body.page .p-definition__lists__dotted dd {
  border-bottom: 4px dotted #888;
  margin-bottom: 0;
  margin-left: 4px;
  padding: .5em;
  width: calc(75% - 4px);
}

@media (max-width: 1024.98px) {
  body.home .p-definition__lists__dotted dd,
  body.page .p-definition__lists__dotted dd {
    width: 100%;
    margin-left: 0;
  }
}

body.home .p-definition__lists__dashed,
body.page .p-definition__lists__dashed {
  display: flex;
  flex-wrap: wrap;
}

body.home .p-definition__lists__dashed dt,
body.page .p-definition__lists__dashed dt {
  width: 25%;
  border-bottom: 3px dashed #888;
  margin-bottom: 0;
  padding: .5em;
}

@media (max-width: 1024.98px) {
  body.home .p-definition__lists__dashed dt,
  body.page .p-definition__lists__dashed dt {
    width: 100%;
  }
}

body.home .p-definition__lists__dashed dd,
body.page .p-definition__lists__dashed dd {
  border-bottom: 3px dashed #888;
  margin-bottom: 0;
  margin-left: 3px;
  padding: .5em;
  width: calc(75% - 3px);
}

@media (max-width: 1024.98px) {
  body.home .p-definition__lists__dashed dd,
  body.page .p-definition__lists__dashed dd {
    width: 100%;
  }
}

body.home .p-definition__site,
body.page .p-definition__site {
  display: flex;
  flex-wrap: wrap;
}

body.home .p-definition__site dt,
body.page .p-definition__site dt {
  width: 25%;
  border-bottom: 1px solid #fff;
  padding: .5em;
  background: rgba(0, 114, 156, 0.5);
  text-align: center;
}

@media (max-width: 1024.98px) {
  body.home .p-definition__site dt,
  body.page .p-definition__site dt {
    width: 100%;
    border-bottom: 1px solid #fff;
  }
}

body.home .p-definition__site dd,
body.page .p-definition__site dd {
  border-bottom: 1px solid #fff;
  border-right: 1px solid #fff;
  margin-bottom: 0;
  padding: .5em;
  width: calc(75% - 3px);
  background-color: #e9ecef;
}

@media (max-width: 1024.98px) {
  body.home .p-definition__site dd,
  body.page .p-definition__site dd {
    width: 100%;
    border-right: none;
  }
}

body.home .p-definition__site dt,
body.home .p-definition__site dd,
body.page .p-definition__site dt,
body.page .p-definition__site dd {
  border-left: 1px solid #fff;
}

@media (min-width: 1025px) {
  body.home .p-definition__site dt:first-of-type,
  body.home .p-definition__site dd:first-of-type,
  body.page .p-definition__site dt:first-of-type,
  body.page .p-definition__site dd:first-of-type {
    border-top: 1px solid #fff;
  }
}

body.home .p-definition__site dt:last-of-type,
body.home .p-definition__site dd:last-of-type,
body.page .p-definition__site dt:last-of-type,
body.page .p-definition__site dd:last-of-type {
  border-bottom: 1px solid #fff;
}

body.home .p-tab,
body.page .p-tab {
  margin-bottom: 2rem;
}

body.home .p-tab .nav-tabs,
body.page .p-tab .nav-tabs {
  padding-left: 0;
  border-bottom: 0;
}

body.home .p-tab .nav-tabs li:not(:last-of-type),
body.page .p-tab .nav-tabs li:not(:last-of-type) {
  margin-right: 2px;
}

body.home .p-tab .nav-tabs li a,
body.page .p-tab .nav-tabs li a {
  background: #adb5bd;
  text-decoration: none;
  color: #fff;
  border: 1px solid #adb5bd;
}

body.home .p-tab .nav-tabs li a.active,
body.page .p-tab .nav-tabs li a.active {
  background: #00729C;
  color: #fff;
  border: 1px solid #00729C;
}

body.home .p-tab .tab-content,
body.page .p-tab .tab-content {
  border: 1px solid #adb5bd;
}

body.home .p-breadcrumb,
body.page .p-breadcrumb {
  background-color: initial;
  padding-bottom: 0;
  padding-left: 0;
  margin-bottom: 0;
}

body.home .p-breadcrumb a,
body.page .p-breadcrumb a {
  color: #00729C;
}

body.home .p-breadcrumb .breadcrumb-item + .breadcrumb-item:before,
body.page .p-breadcrumb .breadcrumb-item + .breadcrumb-item:before {
  content: ">";
}

body.home .p-breadcrumb:nth-of-type(2),
body.page .p-breadcrumb:nth-of-type(2) {
  border-top: 1px dashed #00729C;
  border-radius: 0;
  padding-bottom: 1rem;
}

body.home .p-breadcrumb:not(:nth-of-type(2)),
body.page .p-breadcrumb:not(:nth-of-type(2)) {
  padding-bottom: 0;
  padding-bottom: 0.75rem;
}

body.home .p-pagination,
body.page .p-pagination {
  padding-left: 0;
  justify-content: center;
  flex-wrap: wrap;
}

@media (min-width: 1025px) {
  body.home .p-pagination li,
  body.page .p-pagination li {
    margin-bottom: 0.5rem;
  }
}

@media (max-width: 1024.98px) {
  body.home .p-pagination li .page-link,
  body.page .p-pagination li .page-link {
    padding: .7rem 1rem;
  }
}

@media (max-width: 1024.98px) {
  body.home .p-pagination .page-item,
  body.page .p-pagination .page-item {
    margin-bottom: 5px;
  }
}

body.home .p-pagination .page-item a,
body.page .p-pagination .page-item a {
  border: 1px solid #00729C;
  text-decoration: none;
  color: #00729C;
}

@media (max-width: 1024.98px) {
  body.home .p-pagination .page-item a,
  body.page .p-pagination .page-item a {
    padding: .7rem 1rem;
  }
}

body.home .p-pagination .page-item a:hover,
body.page .p-pagination .page-item a:hover {
  background: #00729C;
  color: #fff;
}

@media (min-width: 1025px) {
  body.home .p-pagination .pagination__move__wrap:first-of-type,
  body.page .p-pagination .pagination__move__wrap:first-of-type {
    margin-right: .5rem;
  }
  body.home .p-pagination .pagination__move__wrap:last-of-type,
  body.page .p-pagination .pagination__move__wrap:last-of-type {
    margin-left: .5rem;
  }
}

@media (max-width: 1024.98px) {
  body.home .p-pagination .pagination__move__wrap,
  body.page .p-pagination .pagination__move__wrap {
    width: 100%;
  }
  body.home .p-pagination .pagination__move__wrap:first-of-type,
  body.page .p-pagination .pagination__move__wrap:first-of-type {
    margin-bottom: 1rem;
  }
  body.home .p-pagination .pagination__move__wrap:last-of-type,
  body.page .p-pagination .pagination__move__wrap:last-of-type {
    margin-top: 1rem;
  }
}

body.home .p-pagination .move_btn__wrap,
body.page .p-pagination .move_btn__wrap {
  padding-left: 0;
  display: flex;
}

@media (max-width: 1024.98px) {
  body.home .p-pagination .move_btn__wrap,
  body.page .p-pagination .move_btn__wrap {
    justify-content: center;
  }
}

body.home .p-pagination .move_btn__wrap ul,
body.page .p-pagination .move_btn__wrap ul {
  padding-left: 0;
  list-style: none;
}

@media (min-width: 1025px) {
  body.home .p-pagination .move_btn__wrap.move--prev .move_btn,
  body.page .p-pagination .move_btn__wrap.move--prev .move_btn {
    margin-right: 0.2rem;
  }
}

@media (min-width: 1025px) {
  body.home .p-pagination .move_btn__wrap.move--next .move_btn,
  body.page .p-pagination .move_btn__wrap.move--next .move_btn {
    margin-left: 0.2rem;
  }
}

body.home .p-pagination .move_btn__wrap .move_btn,
body.page .p-pagination .move_btn__wrap .move_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

@media (max-width: 1024.98px) {
  body.home .p-pagination .move_btn__wrap .move_btn:nth-of-type(odd),
  body.page .p-pagination .move_btn__wrap .move_btn:nth-of-type(odd) {
    margin-right: 5px;
  }
}

body.home .p-pagination .move_btn__wrap .move_btn a,
body.page .p-pagination .move_btn__wrap .move_btn a {
  min-height: 40px;
  transition: .2s;
}

@media (max-width: 1024.98px) {
  body.home .p-pagination .move_btn__wrap .move_btn a,
  body.page .p-pagination .move_btn__wrap .move_btn a {
    width: 100%;
  }
}

body.home .p-pagination .move_btn__wrap .move_btn a:hover,
body.page .p-pagination .move_btn__wrap .move_btn a:hover {
  background-color: #005f83;
}

body.home .p-pagination .move_btn__wrap .move_btn.first-page span, body.home .p-pagination .move_btn__wrap .move_btn.last-page span,
body.page .p-pagination .move_btn__wrap .move_btn.first-page span,
body.page .p-pagination .move_btn__wrap .move_btn.last-page span {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  display: inline-block;
  height: 15px;
}

body.home .p-pagination .move_btn__wrap .move_btn.first-page span:before, body.home .p-pagination .move_btn__wrap .move_btn.last-page span:before,
body.page .p-pagination .move_btn__wrap .move_btn.first-page span:before,
body.page .p-pagination .move_btn__wrap .move_btn.last-page span:before {
  content: "";
  position: absolute;
  display: inline-block;
  width: 0;
  height: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
}

body.home .p-pagination .move_btn__wrap .move_btn.first-page span:after, body.home .p-pagination .move_btn__wrap .move_btn.last-page span:after,
body.page .p-pagination .move_btn__wrap .move_btn.first-page span:after,
body.page .p-pagination .move_btn__wrap .move_btn.last-page span:after {
  content: "";
  position: absolute;
  display: inline-block;
  width: 0;
  height: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
}

body.home .p-pagination .move_btn__wrap .move_btn.first-page a,
body.page .p-pagination .move_btn__wrap .move_btn.first-page a {
  padding-left: 40px;
}

body.home .p-pagination .move_btn__wrap .move_btn.first-page span,
body.page .p-pagination .move_btn__wrap .move_btn.first-page span {
  left: 10px;
  border-left: 2px solid #fff;
}

body.home .p-pagination .move_btn__wrap .move_btn.first-page span:before,
body.page .p-pagination .move_btn__wrap .move_btn.first-page span:before {
  left: 0;
  border-right: 10px solid #fff;
}

body.home .p-pagination .move_btn__wrap .move_btn.first-page span:after,
body.page .p-pagination .move_btn__wrap .move_btn.first-page span:after {
  left: 10px;
  border-right: 10px solid #fff;
}

body.home .p-pagination .move_btn__wrap .move_btn.last-page a,
body.page .p-pagination .move_btn__wrap .move_btn.last-page a {
  padding-right: 40px;
}

body.home .p-pagination .move_btn__wrap .move_btn.last-page span,
body.page .p-pagination .move_btn__wrap .move_btn.last-page span {
  right: 10px;
  border-right: 2px solid #fff;
}

body.home .p-pagination .move_btn__wrap .move_btn.last-page span:before,
body.page .p-pagination .move_btn__wrap .move_btn.last-page span:before {
  right: 0;
  border-left: 10px solid #fff;
}

body.home .p-pagination .move_btn__wrap .move_btn.last-page span:after,
body.page .p-pagination .move_btn__wrap .move_btn.last-page span:after {
  right: 10px;
  border-left: 10px solid #fff;
}

body.home .p-pagination .move_btn__wrap .move_btn.prev-page a span, body.home .p-pagination .move_btn__wrap .move_btn.next-page a span,
body.page .p-pagination .move_btn__wrap .move_btn.prev-page a span,
body.page .p-pagination .move_btn__wrap .move_btn.next-page a span {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  border-bottom: 8px solid transparent;
  border-top: 8px solid transparent;
  width: 0;
  height: 0;
}

body.home .p-pagination .move_btn__wrap .move_btn.prev-page a,
body.page .p-pagination .move_btn__wrap .move_btn.prev-page a {
  padding-left: 25px;
}

body.home .p-pagination .move_btn__wrap .move_btn.prev-page a span,
body.page .p-pagination .move_btn__wrap .move_btn.prev-page a span {
  left: 10px;
  border-right: 8px solid #fff;
}

body.home .p-pagination .move_btn__wrap .move_btn.next-page a,
body.page .p-pagination .move_btn__wrap .move_btn.next-page a {
  padding-right: 25px;
}

body.home .p-pagination .move_btn__wrap .move_btn.next-page span,
body.page .p-pagination .move_btn__wrap .move_btn.next-page span {
  right: 10px;
  border-left: 8px solid #fff;
}

body.home .p-pagination .move_btn__wrap .move_btn a,
body.page .p-pagination .move_btn__wrap .move_btn a {
  padding: .4rem .5rem;
  background-color: #00729C;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  position: relative;
}

@media (max-width: 1024.98px) {
  body.home .p-pagination .move_btn__wrap .move_btn a,
  body.page .p-pagination .move_btn__wrap .move_btn a {
    text-align: center;
  }
}

body.home .p-youtube__wrap,
body.page .p-youtube__wrap {
  position: relative;
  padding-top: 45%;
  width: 70%;
  margin: 2rem auto;
}

@media (max-width: 1024.98px) {
  body.home .p-youtube__wrap,
  body.page .p-youtube__wrap {
    width: 100%;
    padding-top: 55%;
  }
}

@media (max-width: 767.98px) {
  body.home .p-youtube__wrap,
  body.page .p-youtube__wrap {
    padding-top: 75%;
  }
}

body.home .p-youtube__wrap iframe,
body.page .p-youtube__wrap iframe {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

body.home .p-form form dl,
body.page .p-form form dl {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #adb5bd;
}

body.home .p-form form dl dt,
body.home .p-form form dl dd,
body.page .p-form form dl dt,
body.page .p-form form dl dd {
  padding: 1rem;
}

body.home .p-form form dl dt,
body.page .p-form form dl dt {
  width: 20%;
  background: #dee2e6;
}

@media (min-width: 768px) and (max-width: 1024.98px) {
  body.home .p-form form dl dt,
  body.page .p-form form dl dt {
    width: 25%;
  }
}

@media (max-width: 767.98px) {
  body.home .p-form form dl dt,
  body.page .p-form form dl dt {
    width: 100%;
  }
  body.home .p-form form dl dt br,
  body.page .p-form form dl dt br {
    display: none;
  }
  body.home .p-form form dl dt span.badge,
  body.page .p-form form dl dt span.badge {
    margin-left: .5rem;
  }
}

body.home .p-form form dl dt:not(:last-of-type),
body.page .p-form form dl dt:not(:last-of-type) {
  border-bottom: 1px solid #adb5bd;
}

body.home .p-form form dl dt span,
body.page .p-form form dl dt span {
  color: #fff;
  background: #BB0900;
}

body.home .p-form form dl dd,
body.page .p-form form dl dd {
  width: 80%;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

@media (min-width: 768px) {
  body.home .p-form form dl dd,
  body.page .p-form form dl dd {
    border-left: 1px solid #adb5bd;
  }
}

@media (min-width: 768px) and (max-width: 1024.98px) {
  body.home .p-form form dl dd,
  body.page .p-form form dl dd {
    width: 75%;
  }
}

@media (max-width: 767.98px) {
  body.home .p-form form dl dd,
  body.page .p-form form dl dd {
    width: 100%;
  }
}

body.home .p-form form dl dd:not(:last-of-type),
body.page .p-form form dl dd:not(:last-of-type) {
  border-bottom: 1px solid #adb5bd;
}

body.home .p-form form dl input,
body.page .p-form form dl input {
  border-radius: 5px;
  border: 2px solid #adb5bd;
  padding: 5px;
}

body.home .p-form form dl input[type="radio"],
body.page .p-form form dl input[type="radio"] {
  margin-right: 5px;
}

body.home .p-form form dl input:focus,
body.page .p-form form dl input:focus {
  outline: 3px solid #ced4da;
}

@media (min-width: 768px) {
  body.home .p-form form dl input:focus,
  body.page .p-form form dl input:focus {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

body.home .p-form form dl textarea,
body.home .p-form form dl select,
body.page .p-form form dl textarea,
body.page .p-form form dl select {
  border-radius: 5px;
  border: 2px solid #adb5bd;
}

body.home .p-form form dl textarea:focus,
body.home .p-form form dl select:focus,
body.page .p-form form dl textarea:focus,
body.page .p-form form dl select:focus {
  outline: 3px solid #ced4da;
}

body.home .p-form form dl .select__wrap,
body.page .p-form form dl .select__wrap {
  border: 2px solid #adb5bd;
  overflow: hidden;
  border-radius: 5px;
  padding-right: 30px;
  position: relative;
}

body.home .p-form form dl .select__wrap:after,
body.page .p-form form dl .select__wrap:after {
  content: "";
  display: block;
  position: absolute;
  right: 5px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 0;
  height: 0;
  border-top: 8px solid #6c757d;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}

body.home .p-form form dl .select__wrap select,
body.page .p-form form dl .select__wrap select {
  width: 200%;
  height: 30px;
  border: none;
  appearance: none;
  padding-left: .5rem;
}

@supports (-ms-ime-align: auto) {
  body.home .p-form form dl .select__wrap select:focus,
  body.page .p-form form dl .select__wrap select:focus {
    background: #006ef7;
    color: #fff;
  }
}

body.home .p-form form dl label,
body.page .p-form form dl label {
  position: relative;
  margin-bottom: 0.5rem;
}

body.home .p-form form dl label input:focus,
body.home .p-form form dl label textarea:focus,
body.home .p-form form dl label select:focus,
body.page .p-form form dl label input:focus,
body.page .p-form form dl label textarea:focus,
body.page .p-form form dl label select:focus {
  outline: 3px solid #ced4da;
}

@media (min-width: 768px) {
  body.home .p-form form dl label input:focus,
  body.home .p-form form dl label textarea:focus,
  body.home .p-form form dl label select:focus,
  body.page .p-form form dl label input:focus,
  body.page .p-form form dl label textarea:focus,
  body.page .p-form form dl label select:focus {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

body.home .p-form form dl label.radio,
body.page .p-form form dl label.radio {
  margin-right: 1rem;
  position: relative;
  cursor: pointer;
}

body.home .p-form form dl label.radio span,
body.page .p-form form dl label.radio span {
  position: relative;
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding: 5px 5px 5px 30px;
}

body.home .p-form form dl label.radio span:before,
body.page .p-form form dl label.radio span:before {
  content: "";
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto auto auto 5px;
  width: 18px;
  height: 18px;
  border: 1px solid #6c757d;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 50%;
}

body.home .p-form form dl label.radio span:after,
body.page .p-form form dl label.radio span:after {
  content: "";
  display: inline-block;
  position: absolute;
  left: 3px;
  top: 0;
  bottom: 0;
  width: 12px;
  height: 12px;
  margin: auto auto auto 5px;
  background-color: #00729C;
  border-radius: 50%;
  opacity: 0;
  visibility: hidden;
  transform: scale(0.2);
  transition: .15s;
}

body.home .p-form form dl label.radio input,
body.page .p-form form dl label.radio input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

body.home .p-form form dl label.radio input.radio__item:checked + span,
body.page .p-form form dl label.radio input.radio__item:checked + span {
  color: #00729C;
}

body.home .p-form form dl label.radio input.radio__item:checked + span:after,
body.page .p-form form dl label.radio input.radio__item:checked + span:after {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}

body.home .p-form form dl label.radio input.radio__item:focus + span,
body.page .p-form form dl label.radio input.radio__item:focus + span {
  outline: 3px solid #adb5bd;
}

@media (min-width: 768px) {
  body.home .p-form form dl label.radio input.radio__item:focus + span,
  body.page .p-form form dl label.radio input.radio__item:focus + span {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

body.home .p-form form dl label.checkbox,
body.page .p-form form dl label.checkbox {
  margin-right: 1rem;
  cursor: pointer;
}

body.home .p-form form dl label.checkbox span,
body.page .p-form form dl label.checkbox span {
  position: relative;
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding-left: 25px;
  padding-right: 5px;
}

body.home .p-form form dl label.checkbox span:before,
body.page .p-form form dl label.checkbox span:before {
  content: "";
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 14px;
  height: 14px;
  border: 1px solid #6c757d;
  border-radius: 3px;
  margin-left: 5px;
}

body.home .p-form form dl label.checkbox span:after,
body.page .p-form form dl label.checkbox span:after {
  content: "";
  display: inline-block;
  position: absolute;
  left: 12px;
  top: -7px;
  bottom: 0;
  width: 7px;
  height: 15px;
  margin: auto;
  border-right: 3px solid #00729C;
  border-bottom: 3px solid #00729C;
  opacity: 0;
  visibility: hidden;
  transform: rotate(45deg) scale(0);
  transition: .15s;
}

body.home .p-form form dl label.checkbox input,
body.page .p-form form dl label.checkbox input {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

body.home .p-form form dl label.checkbox input.checkbox__item:checked + span,
body.page .p-form form dl label.checkbox input.checkbox__item:checked + span {
  color: #00729C;
}

body.home .p-form form dl label.checkbox input.checkbox__item:checked + span:after,
body.page .p-form form dl label.checkbox input.checkbox__item:checked + span:after {
  opacity: 1;
  visibility: visible;
  animation: checkScale .15s forwards;
}

@keyframes checkScale {
  0% {
    transform: rotate(45deg) scale(0);
  }
  50% {
    transform: rotate(45deg) scale(1.4);
  }
  100% {
    transform: rotate(45deg) scale(1);
  }
}

body.home .p-form form dl label.checkbox input.checkbox__item:focus + span,
body.page .p-form form dl label.checkbox input.checkbox__item:focus + span {
  outline: 3px solid #adb5bd;
}

@media (min-width: 768px) {
  body.home .p-form form dl label.checkbox input.checkbox__item:focus + span,
  body.page .p-form form dl label.checkbox input.checkbox__item:focus + span {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

body.home .p-form form dl textarea,
body.page .p-form form dl textarea {
  width: 100%;
}

body.home .p-next-btn__wrap,
body.page .p-next-btn__wrap {
  text-align: center;
  margin-bottom: .5rem;
}

body.home .p-next-btn__wrap button,
body.page .p-next-btn__wrap button {
  color: #fff;
  background: #00729C;
  padding-left: 30px;
  position: relative;
  transition: .2s;
}

body.home .p-next-btn__wrap button:hover,
body.page .p-next-btn__wrap button:hover {
  cursor: pointer;
  background: #005f83;
}

body.home .p-next-btn__wrap button:before,
body.page .p-next-btn__wrap button:before {
  content: "";
  display: block;
  position: absolute;
  left: 10px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 15px;
  height: 15px;
  background-color: #fff;
  border-radius: 50%;
}

body.home .p-next-btn__wrap button:after,
body.page .p-next-btn__wrap button:after {
  content: "";
  display: block;
  position: absolute;
  left: 15px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 0;
  height: 0;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 5px solid #00729C;
}

body.home .p-back-btn__wrap,
body.page .p-back-btn__wrap {
  text-align: center;
  margin-bottom: .5rem;
}

body.home .p-back-btn__wrap button,
body.page .p-back-btn__wrap button {
  color: #fff;
  background: #888;
  padding-left: 30px;
  position: relative;
  transition: .2s;
}

body.home .p-back-btn__wrap button:hover,
body.page .p-back-btn__wrap button:hover {
  cursor: pointer;
  background: #7b7b7b;
}

body.home .p-back-btn__wrap button:before,
body.page .p-back-btn__wrap button:before {
  content: "";
  display: block;
  position: absolute;
  left: 10px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 15px;
  height: 15px;
  background-color: #fff;
  border-radius: 50%;
}

body.home .p-back-btn__wrap button:after,
body.page .p-back-btn__wrap button:after {
  content: "";
  display: block;
  position: absolute;
  left: 15px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 0;
  height: 0;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-right: 5px solid #888;
}

@media (min-width: 768px) {
  body.home .form__btn__wrap,
  body.page .form__btn__wrap {
    display: flex;
    justify-content: center;
  }
}

@media (min-width: 768px) {
  body.home .form__btn__wrap .p-back-btn__wrap,
  body.page .form__btn__wrap .p-back-btn__wrap {
    margin-right: 1rem;
  }
}

/**
 * State
 */
.is-hidden {
  display: none;
}

.is-show {
  display: block;
}

.is-block {
  display: block;
}

/* utilities
-------------------------------------------------- */
/* float */
.pull-left,
.float-left {
  float: left;
}

.pull-right,
.float-right {
  float: right;
}

/* clear */
.clear {
  clear: both;
}

.clear-left {
  clear: left;
}

.clear-right {
  clear: right;
}

/* clearfix */
.clearfix:after {
  content: '';
  clear: both;
  display: block;
}

/* white space */
.nowrap {
  white-space: nowrap;
}

@media (max-width: 1024.98px) {
  .pc-only {
    display: none;
  }
}

.sp-only {
  display: none;
}

@media (max-width: 1024.98px) {
  .sp-only {
    display: block;
  }
}

footer {
  background-color: #f5f5f5;
  position: relative;
  padding-top: 1.5rem;
}

footer .footer__link-top {
  letter-spacing: -.4em;
}

footer .footer__link-top a {
  letter-spacing: normal;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 100%;
  margin: auto;
  display: inline-block;
  width: 0;
  height: 0;
  border-radius: 0;
  border-top: 0;
  border-bottom: 30px solid #f5f5f5;
  border-right: 30px solid transparent;
  border-left: 30px solid transparent;
  background: none;
}

footer .footer__link-top a:after {
  content: "";
  display: inline-block;
  width: 15px;
  height: 15px;
  border-top: 1px solid #888;
  border-right: 1px solid #888;
  position: absolute;
  top: 10px;
  left: -2px;
  right: 0;
  margin: auto;
  transform: rotate(-45deg) translate(-50%);
}

footer aside ul.row {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

@media (min-width: 768px) and (max-width: 1024.98px) {
  footer aside ul.row {
    justify-content: center;
  }
}

footer aside ul.row li {
  text-align: center;
  margin-bottom: 1rem;
}

footer .footer__nav {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

@media (max-width: 1024.98px) {
  footer .footer__nav {
    display: none;
  }
}

footer .footer__nav dt {
  width: 10%;
  margin-bottom: 1rem;
  font-family: "游明朝", YuMincho, "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
  font-size: 1.125rem;
}

@media all and (-ms-high-contrast: none) {
  footer .footer__nav dt {
    font-family: "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
  }
}

footer .footer__nav dt.footer__nav__know:before, footer .footer__nav dt.footer__nav__visit:before, footer .footer__nav dt.footer__nav__fun:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 15px;
  height: 5px;
  transform: translateY(-50%);
  margin-right: 0.5rem;
}

footer .footer__nav dt.footer__nav__know:before {
  background-color: #E71F26;
}

footer .footer__nav dt.footer__nav__visit:before {
  background-color: #015CAE;
}

footer .footer__nav dt.footer__nav__fun:before {
  background-color: #168836;
}

footer .footer__nav dd {
  width: 90%;
  font-size: 0.875rem;
  margin-bottom: 1rem;
}

footer .footer__nav dd ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  display: flex;
  flex-wrap: wrap;
}

footer .footer__nav dd ul li:not(:last-of-type) {
  margin-right: 1rem;
}

footer .site__footer__nav {
  padding: 1rem 0;
  border-bottom: 1px solid #333;
  border-top: 1px solid #333;
}

@media (min-width: 1025px) {
  footer .site__footer__nav {
    display: flex;
    justify-content: space-between;
  }
}

footer .site__footer__nav ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

footer .site__footer__nav ul.nav__first {
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 1024.98px) {
  footer .site__footer__nav ul.nav__first {
    margin-bottom: 1rem;
  }
}

@media (min-width: 1025px) {
  footer .site__footer__nav ul.nav__first li:not(:last-of-type) {
    margin-right: 2rem;
  }
}

@media (max-width: 1024.98px) {
  footer .site__footer__nav ul.nav__first li:not(:last-of-type) {
    margin-right: 1rem;
  }
}

footer .site__footer__nav ul.nav__first li a:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 7px;
  height: 7px;
  border-bottom: 1px solid #333;
  border-right: 1px solid #333;
  transform: rotate(-45deg);
  margin-right: 1rem;
}

footer .site__footer__nav ul.nav__second {
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 1024.98px) {
  footer .site__footer__nav ul.nav__second {
    justify-content: center;
  }
}

footer .site__footer__nav ul.nav__second li:not(:last-of-type) {
  margin-right: 1rem;
}

footer address {
  padding: 1.5rem 0;
  margin-bottom: 0;
}

@media (min-width: 1025px) {
  footer address {
    display: flex;
    justify-content: space-between;
  }
}

footer address ul {
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;
}

@media (max-width: 767.98px) {
  footer address .contact__address__first {
    margin-bottom: 1rem;
  }
}

footer address .contact__address__first .contact__name {
  font-weight: bold;
  font-size: 1.125rem;
  margin-bottom: 0;
  font-family: "游明朝", YuMincho, "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
}

@media all and (-ms-high-contrast: none) {
  footer address .contact__address__first .contact__name {
    font-family: "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
  }
}

@media (max-width: 1024.98px) {
  footer address .contact__address__first .contact__name {
    text-align: center;
  }
}

footer address .contact__address__first .contact__name img:not(.en) {
  width: 170px;
  margin-left: .5rem;
  vertical-align: text-bottom;
}

footer address .contact__address__first .contact__name img.en {
  width: 120px;
  margin-left: .5rem;
  vertical-align: text-bottom;
}

footer address .contact__address__first ul {
  display: flex;
}

@media (max-width: 1024.98px) {
  footer address .contact__address__first ul {
    justify-content: center;
    flex-wrap: wrap;
  }
}

footer address .contact__address__first ul li:last-of-type {
  margin-left: .5rem;
}

footer address .contact__address__first ul li:last-of-type a:before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url(../img/common/icon_tel.svg) no-repeat;
  background-size: 16px 16px;
  width: 16px;
  height: 16px;
  margin-right: 0.2rem;
}

@media (min-width: 768px) {
  footer address .contact__address__second {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

footer address .contact__address__second li {
  background-color: #E2E2E2;
}

footer address .contact__address__second li a {
  display: block;
  padding: .5rem 1rem;
  color: #333;
}

@media (max-width: 767.98px) {
  footer address .contact__address__second li a {
    text-align: center;
  }
}

@media (min-width: 768px) {
  footer address .contact__address__second li:first-of-type {
    margin-right: 0.5rem;
  }
}

@media (max-width: 767.98px) {
  footer address .contact__address__second li:first-of-type {
    margin-bottom: 0.5rem;
  }
}

footer address .contact__address__second li:first-of-type a:before {
  content: "";
  display: inline-block;
  background: url(../img/common/icon_inquiry.svg) no-repeat;
  background-size: 28px 18px;
  width: 28px;
  height: 18px;
  margin-right: 1rem;
  vertical-align: sub;
}

footer address .contact__address__second li:last-of-type a:before {
  content: "";
  display: inline-block;
  background: url(../img/common/icon_mail.svg) no-repeat;
  background-size: 26px 21px;
  width: 26px;
  height: 21px;
  margin-right: 1rem;
  vertical-align: sub;
}

.header {
  position: relative;
}

.header__inner .header__front {
  z-index: 1000;
}

@media (min-width: 1025px) {
  .header__inner .header__front {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 65px;
    background-color: #333;
  }
}

@media (max-width: 1024.98px) {
  .header__inner .header__front {
    background: initial;
  }
}

@media (min-width: 768px) {
  .header__inner .header__front {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
  }
}

.header .language {
  margin-bottom: 0;
  padding-left: 0;
  width: 200px;
  margin: auto;
  text-align: center;
  padding-bottom: 1rem;
}

@media (max-width: 1024.98px) {
  .header .language {
    padding-bottom: 2rem;
  }
}

.header .language .language__select-btn {
  cursor: pointer;
  border-radius: 10px;
  padding: .4em .7em;
  background: #eeeeee;
  border-radius: 10px;
  box-shadow: 0px 4px #a2a2a2;
  position: relative;
  transition: .15s;
}

.header .language .language__select-btn:hover, .header .language .language__select-btn:active {
  box-shadow: none;
  transform: translateY(4px);
}

.header .language .language__select-btn a {
  text-decoration: none;
  color: #333;
}

.header .language .language__select-btn a span {
  display: flex;
  align-items: center;
  justify-content: center;
}

.header .language .language__select-btn a span:before {
  content: "";
  display: inline-block;
  background: url(../img/common/icon_language.svg) no-repeat;
  background-size: 25px 25px;
  width: 25px;
  height: 25px;
  margin-right: 0.5em;
}

.header__logo {
  margin-bottom: 0;
  font-size: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
}

@media (min-width: 1025px) {
  .header__logo {
    margin-top: 75px;
    width: 65px;
  }
}

@media (max-width: 1024.98px) {
  .header__logo {
    left: 5px;
  }
}

@media (min-width: 1025px) {
  .header__logo img {
    height: 85vh;
    max-height: 650px;
  }
}

@media (min-width: 1025px) {
  .header__logo img.pc-only {
    max-width: 55px;
  }
}

.header__button-container {
  display: block;
  z-index: 10000;
  position: fixed;
  top: 0;
  left: 0;
}

@media (min-width: 1025px) {
  .header__button-container {
    width: 65px;
  }
}

@media (max-width: 1024.98px) {
  .header__button-container {
    width: 70px;
    height: 70px;
    right: 0;
    left: initial;
  }
}

.header__button-container button {
  background-color: #4A7398;
  border: none;
  cursor: pointer;
  color: #fff;
  width: 100%;
  overflow: hidden;
}

@media (max-width: 1024.98px) {
  .header__button-container button {
    height: 100%;
  }
}

.header__button-container button span.button__element {
  width: 75%;
  height: 2px;
  padding: 0 .5rem;
  background-color: #fff;
  display: block;
  margin: 0 auto .5rem;
}

@media (max-width: 1024.98px) {
  .header__button-container button span.button__element {
    width: 80%;
  }
}

.header__button-container button span.button__element.element--last {
  margin-bottom: 0;
}

.header__button-container button span.button__element:first-of-type {
  margin-top: .5rem;
}

.header__button-container button span.button__element.element--second {
  position: relative;
  transition: .2s;
}

.header__button-container button span.button__element.element--second:after {
  content: "";
  position: absolute;
  display: block;
  left: 9.5px;
  top: 0;
  height: 2px;
  width: 50%;
  background-color: white;
}

.header__button-container button span.button__element.element--second:before {
  content: "";
  position: absolute;
  display: block;
  right: 9.5px;
  top: 0;
  height: 2px;
  width: 50%;
  background-color: white;
}

.header__button-container button span.menu-title {
  font-size: 0.75rem;
  font-weight: bold;
  letter-spacing: 1px;
}

.header__button-container button.is-active {
  background: linear-gradient(135deg, #1cb146 0%, #369ffd 50%, #e71f26 100%);
  background-size: 400% 400%;
  background-position: 0% 50%;
  animation: menu-gradation 5s infinite;
}

@keyframes menu-gradation {
  0% {
    background-position: 0% 0%;
  }
  33% {
    background-position: 50% 50%;
  }
  66% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}

.header__button-container button.is-active span.elemennt--first {
  transition: .5s;
  opacity: 0;
}

.header__button-container button.is-active span.element--second {
  transform: rotate(45deg);
}

.header__button-container button.is-active span.element--second:after {
  content: "";
  left: 0;
  width: 100%;
  transform: rotate(-90deg);
  transition: width .5s ease .7s,left .5s ease 0s,transform .1s ease .0s;
}

.header__button-container button.is-active span.element--second:before {
  content: "";
  right: 0;
  width: 100%;
  transform: rotate(90deg);
  transition: width .5s ease .7s,right .5s ease 0s,transform .1s ease .0s;
}

.header__button-container button.is-active span.element--last {
  transition: .5s;
  opacity: 0;
}

#header__menu {
  position: fixed;
  left: 65px;
  top: 0;
  bottom: 0;
  z-index: 100;
  overflow-y: scroll;
  background-color: #fff;
  height: 100%;
  transform: translateX(-100%);
  transition: .5s;
  -ms-overflow-style: none;
}

@media all and (-ms-high-contrast: none) and (min-width: 1025px) {
  #header__menu *::-ms-backdrop, #header__menu .block__txt {
    margin: 3rem 0 0;
    width: 100%;
    transform: translateX(-120%);
  }
}

@media (min-width: 1025px) {
  #header__menu {
    width: 360px;
  }
}

#header__menu::-webkit-scrollbar {
  display: none;
}

#header__menu .nav__inner {
  position: relative;
  min-height: 100%;
}

#header__menu .nav__inner:after {
  overflow: hidden;
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  background: linear-gradient(135deg, #1cb146 0%, #369ffd 50%, #e71f26 100%);
  background-size: 400% 400%;
  background-position: 0% 50%;
  width: 5px;
  height: 100%;
  animation: menu-gradation 5s infinite;
}

@keyframes menu-gradation {
  0% {
    background-position: 0% 0%;
  }
  33% {
    background-position: 50% 50%;
  }
  66% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}

@media (max-width: 1024.98px) {
  #header__menu .nav__inner:after {
    right: auto;
    left: 0;
  }
}

@media (max-width: 1024.98px) {
  #header__menu {
    left: auto;
    right: 0;
    transform: translateX(100%);
  }
}

#header__menu .search .search__title {
  text-align: left;
  margin-left: 2rem;
  margin-bottom: 0;
  font-size: 0.875rem;
}

#header__menu .search .search__title:before {
  background-size: 16px 16px;
  width: 16px;
  height: 16px;
}

#header__menu .home-back__btn {
  padding-bottom: 1rem;
}

#header__menu .home-back__btn p {
  margin-bottom: 0;
}

#header__menu .home-back__btn a {
  width: 200px;
  background-color: #333;
  color: #fff;
  border-radius: 10px;
  transition: .2s;
}

#header__menu .home-back__btn a:hover {
  opacity: .7;
}

#header__menu .home-back__btn a:before {
  content: "";
  display: inline-block;
  background: url(../img/common/logo_home.svg) no-repeat;
  background-size: 20px 20px;
  width: 20px;
  height: 20px;
  margin-right: .5rem;
  vertical-align: text-bottom;
}

#header__menu ul {
  list-style: none;
  padding-left: 0;
}

#header__menu ul a {
  text-decoration: none;
}

#header__menu .header__nav {
  list-style: none;
  margin: 0;
  position: relative;
  margin-bottom: 1rem;
  border-bottom: 2px solid #dee2e6;
  font-family: "游明朝", YuMincho, "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
}

@media all and (-ms-high-contrast: none) {
  #header__menu .header__nav {
    font-family: "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
  }
}

@media (max-width: 1024.98px) {
  #header__menu .header__nav {
    padding-top: 60px;
  }
  #header__menu .header__nav * {
    font-family: ryo-text-plusn, "游明朝", YuMincho, "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
  }
}

#header__menu .header__nav > li a {
  display: flex;
  align-items: center;
  padding: .5em 1em;
  height: 100%;
  position: relative;
  font-family: "游明朝", YuMincho, "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
  transition: .2s;
}

@media all and (-ms-high-contrast: none) {
  #header__menu .header__nav > li a {
    font-family: "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
  }
}

#header__menu .header__nav > li a:hover {
  opacity: .7;
}

@media (max-width: 1024.98px) {
  #header__menu .header__nav > li a {
    font-family: ryo-text-plusn, "游明朝", YuMincho, "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
  }
}

#header__menu .header__nav .header__nav-item {
  padding-bottom: 1.5rem;
  padding: 1rem;
  position: relative;
}

#header__menu .header__nav .header__nav-item ul li:not(:first-of-type) a {
  color: #333;
  position: relative;
  display: block;
  padding-left: 1.5rem;
}

#header__menu .header__nav .header__nav-item ul li:not(:first-of-type) a:before {
  content: "";
  position: absolute;
  top: 21px;
  left: 0;
  margin: auto;
  display: inline-block;
  vertical-align: super;
  width: 15px;
  height: 2px;
}

#header__menu .header__nav .header__nav-item.know__item:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 45px - 1rem);
  background-color: #E71F26;
}

#header__menu .header__nav .header__nav-item.know__item ul li:not(:first-of-type) a:before {
  background-color: #E71F26;
}

#header__menu .header__nav .header__nav-item.visit__item:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 45px - 1rem);
  background-color: #015CAE;
}

#header__menu .header__nav .header__nav-item.visit__item ul li:not(:first-of-type) a:before {
  background-color: #015CAE;
}

#header__menu .header__nav .header__nav-item.fun__item:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 45px - 1rem);
  background-color: #168836;
}

#header__menu .header__nav .header__nav-item.fun__item ul li:not(:first-of-type) a:before {
  background-color: #168836;
}

#header__menu .header__nav .parent__category {
  display: flex;
  justify-content: space-between;
}

#header__menu .header__nav .parent__category .category__title {
  font-size: 1.5625rem;
}

#header__menu .header__nav .parent__category p {
  margin-bottom: 0;
}

#header__menu .header__nav .parent__category.parent__category--know {
  color: #E71F26;
}

#header__menu .header__nav .parent__category.parent__category--know a {
  color: #E71F26;
}

#header__menu .header__nav .parent__category.parent__category--know a:after {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  border-bottom: 1px solid #E71F26;
  border-right: 1px solid #E71F26;
  transform: rotate(-45deg);
  margin-left: .5rem;
}

#header__menu .header__nav .parent__category.parent__category--visit {
  color: #015CAE;
}

#header__menu .header__nav .parent__category.parent__category--visit a {
  color: #015CAE;
}

#header__menu .header__nav .parent__category.parent__category--visit a:after {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  border-bottom: 1px solid #015CAE;
  border-right: 1px solid #015CAE;
  transform: rotate(-45deg);
  margin-left: .5rem;
}

#header__menu .header__nav .parent__category.parent__category--fun {
  color: #168836;
}

#header__menu .header__nav .parent__category.parent__category--fun a {
  color: #168836;
}

#header__menu .header__nav .parent__category.parent__category--fun a:after {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  border-bottom: 1px solid #168836;
  border-right: 1px solid #168836;
  transform: rotate(-45deg);
  margin-left: .5rem;
}

#header__menu.is-open {
  transform: translateX(0);
}

#header__menu.is-open .header__nav .header__nav-item.know__item:after, #header__menu.is-open .header__nav .header__nav-item.visit__item:after, #header__menu.is-open .header__nav .header__nav-item.fun__item:after {
  height: 0;
  transition: height 0.2s cubic-bezier(0.42, 0, 0.24, 1.04) 0.5s;
}

.overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  opacity: 0;
  z-index: 50;
  transition: .2s;
  cursor: pointer;
}

.overlay.is-active {
  display: block;
  opacity: 1;
}

body.home .header {
  margin-bottom: 0;
}

body.home main {
  position: relative;
}

body.home main .language {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  margin-bottom: 0;
  padding-left: 0;
  width: 70px;
  height: 61px;
  margin: auto;
  text-align: center;
  font-size: 0.6875rem;
}

body.home main .language .language__select-btn {
  height: 100%;
  cursor: pointer;
  padding: .4em .7em;
  background: #EEEEEE;
  position: relative;
  transition: .2s;
}

body.home main .language .language__select-btn:hover {
  opacity: .7;
}

body.home main .language .language__select-btn a {
  text-decoration: none;
  color: #333;
}

body.home main .language .language__select-btn a span:before {
  content: "";
  display: block;
  background: url(../img/common/icon_language.svg) no-repeat;
  background-size: 25px 25px;
  width: 25px;
  height: 25px;
  margin: 5px auto auto;
}

body.home .hero__wrap {
  position: relative;
  padding-top: 2rem;
  margin-bottom: 3rem;
}

@media (max-width: 1024.98px) {
  body.home .hero__wrap {
    margin-bottom: 1.5rem;
  }
}

@media (min-width: 1025px) {
  body.home .hero__wrap:after {
    content: "";
    background: url(../img/pages/bg_fish.png) no-repeat;
    display: block;
    position: absolute;
    left: 50px;
    bottom: 0;
    background-size: 159px 115px;
    width: 159px;
    height: 115px;
  }
}

body.home .hero__wrap ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

body.home .hero__wrap .site__title {
  padding-top: .5rem;
  overflow: hidden;
  position: relative;
}

@media (min-width: 1025px) {
  body.home .hero__wrap .site__title {
    position: absolute;
    right: 0;
    top: 0;
    max-width: 380px;
  }
}

@media (max-width: 1024.98px) {
  body.home .hero__wrap .site__title {
    text-align: center;
    max-width: 330px;
    margin: 0 auto 1.5rem;
  }
}

@media (min-width: 1025px) {
  body.home .hero__wrap .site__title img {
    transform: translateY(150px);
  }
}

@media (max-width: 1024.98px) {
  body.home .hero__wrap .site__title img {
    transform: translateY(131px);
  }
}

@media (min-width: 1025px) {
  body.home .hero__wrap .site__title.is_show img {
    transform: translateY(0);
    transition: transform 0.6s cubic-bezier(0, 0, 0.18, 1.07) 1.7s;
  }
}

@media (max-width: 1024.98px) {
  body.home .hero__wrap .site__title.is_show img {
    transform: translateY(0);
    transition: transform 0.8s cubic-bezier(0, 0, 0.18, 1.07) 0.2s;
  }
}

@media (min-width: 1025px) {
  body.home .hero__wrap .main__categories {
    display: flex;
  }
}

@media (max-width: 1024.98px) {
  body.home .hero__wrap .main__categories {
    margin-bottom: 1rem;
    padding-bottom: 0.5rem;
  }
}

body.home .hero__wrap .main__categories .head__block {
  position: relative;
}

body.home .hero__wrap .main__categories .head__block .category__link {
  text-decoration: none;
  display: block;
  transition: .2s;
}

body.home .hero__wrap .main__categories .head__block .category__link:hover {
  opacity: .7;
}

body.home .hero__wrap .main__categories .head__block h2:not(.en) {
  position: absolute;
  right: -30px;
  bottom: -80px;
  z-index: 1;
}

@media (min-width: 1025px) {
  body.home .hero__wrap .main__categories .head__block h2:not(.en) {
    width: 70px;
  }
}

@media (max-width: 1024.98px) {
  body.home .hero__wrap .main__categories .head__block h2:not(.en) {
    right: auto;
    left: .5rem;
    bottom: 0;
    margin-bottom: 0;
  }
}

body.home .hero__wrap .main__categories .head__block h2:not(.en) a {
  text-decoration: none;
  display: block;
  transition: .2s;
}

body.home .hero__wrap .main__categories .head__block h2:not(.en) a:hover {
  opacity: .7;
}

body.home .hero__wrap .main__categories .head__block h2.en {
  position: absolute;
  right: 10px;
  bottom: 0;
  z-index: 1;
  margin-bottom: 0;
}

@media (max-width: 1024.98px) {
  body.home .hero__wrap .main__categories .head__block h2.en {
    right: auto;
    left: .5rem;
    bottom: 0;
    margin-bottom: 0;
  }
}

body.home .hero__wrap .main__categories .head__block h2.en a {
  text-decoration: none;
  display: block;
  transition: .2s;
}

body.home .hero__wrap .main__categories .head__block h2.en a:hover {
  opacity: .7;
}

body.home .hero__wrap .main__categories .head__block h2 span:not(.en):first-of-type {
  font-size: 3.75rem;
  text-shadow: 0 0 15px white, 0 0 15px white, 0 0 15px white;
  display: flex;
  font-family: "游明朝", YuMincho, "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
}

@media all and (-ms-high-contrast: none) {
  body.home .hero__wrap .main__categories .head__block h2 span:not(.en):first-of-type {
    font-family: "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
  }
}

@media (min-width: 1025px) {
  body.home .hero__wrap .main__categories .head__block h2 span:not(.en):first-of-type {
    -webkit-writing-mode: vertical-rl;
    writing-mode: vertical-rl;
  }
}

@media (max-width: 1024.98px) {
  body.home .hero__wrap .main__categories .head__block h2 span:not(.en):first-of-type {
    font-family: ryo-text-plusn, "游明朝", YuMincho, "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
  }
}

body.home .hero__wrap .main__categories .head__block h2 span:not(.en):first-of-type:first-letter {
  font-size: 4.375rem;
}

@media (min-width: 1025px) {
  body.home .hero__wrap .main__categories .head__block h2 span:not(.en) _:-ms-lang(x), body.home .hero__wrap .main__categories .head__block h2 span:not(.en):first-of-type {
    display: inline-block;
  }
}

body.home .hero__wrap .main__categories .head__block h2 span:not(.en):last-of-type {
  color: #fff;
  font-size: 1.875rem;
}

@media (min-width: 1025px) {
  body.home .hero__wrap .main__categories .head__block h2 span:not(.en):last-of-type {
    position: absolute;
    right: 100%;
    top: 10px;
  }
}

@media (max-width: 1024.98px) {
  body.home .hero__wrap .main__categories .head__block h2 span:not(.en):last-of-type {
    display: none;
  }
}

body.home .hero__wrap .main__categories .head__block h2 span.en:first-of-type {
  font-size: 3.75rem;
  text-shadow: 0 0 15px white, 0 0 15px white, 0 0 15px white;
  display: flex;
  font-family: "游明朝", YuMincho, "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
}

@media all and (-ms-high-contrast: none) {
  body.home .hero__wrap .main__categories .head__block h2 span.en:first-of-type {
    font-family: "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
  }
}

@media (max-width: 1024.98px) {
  body.home .hero__wrap .main__categories .head__block h2 span.en:first-of-type {
    font-family: ryo-text-plusn, "游明朝", YuMincho, "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
  }
}

body.home .hero__wrap .main__categories .head__block h2 span.en:first-of-type:first-letter {
  font-size: 4.375rem;
}

body.home .hero__wrap .main__categories li.parent__item {
  position: relative;
  opacity: 0;
  transform: translateY(-50px);
}

@media (min-width: 1025px) {
  body.home .hero__wrap .main__categories li.parent__item {
    transition: .8s;
    width: calc(90% / 3);
  }
  body.home .hero__wrap .main__categories li.parent__item:not(:last-of-type) {
    margin-right: 5%;
  }
}

@media (max-width: 1024.98px) {
  body.home .hero__wrap .main__categories li.parent__item {
    transition: 0.8s cubic-bezier(0, 0, 0.18, 1.07) 1s;
  }
}

body.home .hero__wrap .main__categories li.parent__item.is_show {
  opacity: 1;
  transform: translateY(0);
}

body.home .hero__wrap .main__categories li.parent__item .category__items {
  margin-top: 1rem;
}

body.home .hero__wrap .main__categories li.parent__item .category__items li {
  margin-bottom: 1rem;
}

body.home .hero__wrap .main__categories li.parent__item .category__items li a {
  display: flex;
  align-items: center;
  font-size: 1.25rem;
  font-family: "游明朝", YuMincho, "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
  color: #333;
  position: relative;
  padding-left: 1rem;
  text-decoration: none;
  transition: .2s;
}

@media all and (-ms-high-contrast: none) {
  body.home .hero__wrap .main__categories li.parent__item .category__items li a {
    font-family: "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
  }
}

@media (max-width: 1024.98px) {
  body.home .hero__wrap .main__categories li.parent__item .category__items li a {
    font-family: ryo-text-plusn, "游明朝", YuMincho, "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
  }
}

body.home .hero__wrap .main__categories li.parent__item .category__items li a:hover {
  opacity: .7;
}

body.home .hero__wrap .main__categories li.parent__item .category__items li a:before {
  content: "";
  display: inline-block;
  width: 15px;
  height: 2px;
  margin-right: 10px;
  transition: .2s;
}

@media (min-width: 1025px) {
  body.home .hero__wrap .main__categories li.parent__item .category__items li a:hover:before {
    height: 6px;
  }
}

body.home .hero__wrap .main__categories li.category__know .bg__block, body.home .hero__wrap .main__categories li.category__visit .bg__block, body.home .hero__wrap .main__categories li.category__fun .bg__block {
  padding-top: 140%;
}

@media (max-width: 1024.98px) {
  body.home .hero__wrap .main__categories li.category__know .bg__block, body.home .hero__wrap .main__categories li.category__visit .bg__block, body.home .hero__wrap .main__categories li.category__fun .bg__block {
    padding-top: 55%;
  }
}

@media (min-width: 1025px) {
  body.home .hero__wrap .main__categories li.category__know .category__items li a span:after, body.home .hero__wrap .main__categories li.category__visit .category__items li a span:after, body.home .hero__wrap .main__categories li.category__fun .category__items li a span:after {
    transition: .5s;
    transform: rotate(180deg);
  }
  body.home .hero__wrap .main__categories li.category__know .category__items li a:hover span:after, body.home .hero__wrap .main__categories li.category__visit .category__items li a:hover span:after, body.home .hero__wrap .main__categories li.category__fun .category__items li a:hover span:after {
    transform: translateX(10px) rotate(360deg);
  }
}

body.home .hero__wrap .main__categories li.category__know .bg__block {
  background-size: cover;
  background-position: center bottom;
}

@media (max-width: 1024.98px) {
  body.home .hero__wrap .main__categories li.category__know .bg__block {
    background-size: cover;
    background-position: center;
  }
}

body.home .hero__wrap .main__categories li.category__know .head__block h2 span:first-of-type {
  color: #E71F26;
}

body.home .hero__wrap .main__categories li.category__know .category__items li a:before {
  background-color: #E71F26;
}

body.home .hero__wrap .main__categories li.category__know .category__items li a span {
  position: relative;
}

body.home .hero__wrap .main__categories li.category__know .category__items li a span:after {
  content: "";
  background: url(../img/home/icon_know_arrow.svg) no-repeat;
  background-size: 20px 20px;
  width: 20px;
  height: 20px;
  display: block;
  position: absolute;
  left: calc(100% + 8px);
  top: 0;
  bottom: 0;
  margin: auto;
}

@media (min-width: 1025px) {
  body.home .hero__wrap .main__categories li.category__visit {
    margin-top: 100px;
  }
}

body.home .hero__wrap .main__categories li.category__visit .bg__block {
  background-size: cover;
  background-position: center bottom;
}

@media (max-width: 1024.98px) {
  body.home .hero__wrap .main__categories li.category__visit .bg__block {
    background-size: cover;
    background-position: center;
  }
}

body.home .hero__wrap .main__categories li.category__visit .head__block h2 span:first-of-type {
  color: #015CAE;
}

body.home .hero__wrap .main__categories li.category__visit .category__items li a:before {
  background-color: #015CAE;
}

body.home .hero__wrap .main__categories li.category__visit .category__items li a span {
  position: relative;
}

body.home .hero__wrap .main__categories li.category__visit .category__items li a span:after {
  content: "";
  background: url(../img/home/icon_visit_arrow.svg) no-repeat;
  background-size: 20px 20px;
  width: 20px;
  height: 20px;
  display: block;
  position: absolute;
  left: calc(100% + 8px);
  top: 0;
  bottom: 0;
  margin: auto;
}

@media (min-width: 1025px) {
  body.home .hero__wrap .main__categories li.category__fun {
    margin-top: 200px;
  }
}

body.home .hero__wrap .main__categories li.category__fun .bg__block {
  background-size: cover;
  background-position: center bottom;
}

@media (max-width: 1024.98px) {
  body.home .hero__wrap .main__categories li.category__fun .bg__block {
    background-size: cover;
    background-position: center;
  }
}

body.home .hero__wrap .main__categories li.category__fun .head__block h2 span:first-of-type {
  color: #168836;
}

body.home .hero__wrap .main__categories li.category__fun .head__block h2 span:last-of-type {
  top: 60px;
}

body.home .hero__wrap .main__categories li.category__fun .category__items li a:before {
  background-color: #168836;
}

body.home .hero__wrap .main__categories li.category__fun .category__items li a span {
  position: relative;
}

body.home .hero__wrap .main__categories li.category__fun .category__items li a span:after {
  content: "";
  background: url(../img/home/icon_fun_arrow.svg) no-repeat;
  background-size: 20px 20px;
  width: 20px;
  height: 20px;
  display: block;
  position: absolute;
  left: calc(100% + 8px);
  top: 0;
  bottom: 0;
  margin: auto;
}

body.home .hero__wrap .intro__catch-copy {
  color: #757575;
  font-family: "游明朝", YuMincho, "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
  font-size: 2.375rem;
  letter-spacing: 10px;
}

@media all and (-ms-high-contrast: none) {
  body.home .hero__wrap .intro__catch-copy {
    font-family: "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
  }
}

@media (min-width: 1025px) {
  body.home .hero__wrap .intro__catch-copy {
    position: absolute;
    bottom: 0;
    left: 0;
    margin-bottom: 0;
  }
}

@media (max-width: 1024.98px) {
  body.home .hero__wrap .intro__catch-copy {
    font-size: 1.5625rem;
    margin-bottom: 0;
    font-family: ryo-text-plusn, "游明朝", YuMincho, "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
  }
}

body.home #js-carousel {
  max-width: 900px;
  margin: auto;
  display: none;
  padding-left: 0;
  list-style: none;
}

body.home #js-carousel.slick-initialized {
  display: block;
}

body.home #js-carousel img {
  width: 100%;
  height: auto;
}

body.home .slick-arrow {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  word-wrap: normal;
  cursor: pointer;
  outline: 0;
  display: block;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: transparent;
  position: absolute;
  z-index: 2;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  padding: 0;
}

body.home .slick-arrow:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 15px;
  height: 15px;
  border-top: 1px solid #777;
  border-left: 1px solid #777;
  transition: .3s;
}

@media (max-width: 1024.98px) {
  body.home .slick-arrow:after {
    border-top: 1px solid #fff;
    border-left: 1px solid #fff;
  }
}

body.home .slick-arrow:hover:after {
  width: 23px;
  height: 23px;
}

@media (min-width: 768px) {
  body.home .slick-arrow:hover:after {
    border-top: 1px solid #00729C;
    border-left: 1px solid #00729C;
  }
}

body.home .slick-arrow.slick-prev {
  right: 100%;
}

@media (max-width: 1024.98px) {
  body.home .slick-arrow.slick-prev {
    left: -10px;
    right: auto;
  }
}

body.home .slick-arrow.slick-prev:after {
  transform: translate(-50%, -50%) rotate(-45deg);
  margin-left: 3px;
}

body.home .slick-arrow.slick-next {
  left: 100%;
}

@media (max-width: 1024.98px) {
  body.home .slick-arrow.slick-next {
    right: -10px;
    left: auto;
  }
}

body.home .slick-arrow.slick-next:after {
  transform: translate(-50%, -50%) rotate(135deg);
  margin-left: -3px;
}

body.home .slick-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  margin: 10px 0 20px;
  padding: 0;
}

body.home .slick-dots > li {
  margin: 0 10px;
  display: flex;
  align-items: center;
}

body.home .slick-dots > li button {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  word-wrap: normal;
  border-radius: 50%;
  background-color: #E8E8E8;
  width: 0.9375rem;
  height: 0.9375rem;
  cursor: pointer;
  border: none;
  padding: 0;
}

body.home .slick-dots > li.slick-active button {
  background-color: #ADADAD;
}

body.home .charm__wrap {
  position: relative;
}

@media (max-width: 1024.98px) {
  body.home .charm__wrap {
    margin-bottom: 4rem;
    padding-top: 0;
  }
}

body.home .charm__wrap:before {
  content: "";
  display: block;
  background: url(../img/home/bg_charm_map.png) no-repeat;
  background-size: 495px 740px;
  width: 495px;
  height: 740px;
  position: absolute;
  bottom: -40px;
  left: -160px;
  z-index: -10;
  margin: auto;
}

@media (max-width: 1024.98px) {
  body.home .charm__wrap:before {
    left: -210px;
  }
}

@media (max-width: 767.98px) {
  body.home .charm__wrap:before {
    left: auto;
    right: 20px;
    bottom: -740px;
  }
}

body.home .charm__wrap .charm__first-bg {
  padding-top: 25%;
  margin: auto;
}

@media (min-width: 1025px) {
  body.home .charm__wrap .charm__first-bg {
    position: absolute;
    left: -100%;
    right: -100%;
    top: 0;
    background: url(../img/home/bg_charm01.jpg) no-repeat;
    background-size: 100%;
    background-position: center center;
    width: 100vw;
  }
}

@media (max-width: 1024.98px) {
  body.home .charm__wrap .charm__first-bg {
    background: url(../img/home/bg_charm01_sp.jpg) no-repeat;
    background-size: 100%;
    background-position: center center;
    padding-top: 65%;
    margin-bottom: 2rem;
  }
}

body.home .charm__wrap .block__txt {
  font-family: "游明朝", YuMincho, "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
}

@media all and (-ms-high-contrast: none) {
  body.home .charm__wrap .block__txt {
    font-family: "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
  }
}

body.home .charm__wrap .block__txt span {
  background-color: rgba(255, 255, 255, 0.8);
  display: inline-block;
  letter-spacing: 3px;
  line-height: 1.5;
  padding: .5rem;
  margin-bottom: 3px;
}

@media (max-width: 1024.98px) {
  body.home .charm__wrap .block__txt span {
    line-height: 1.5;
    padding: 0.2rem .5rem;
  }
}

@media (max-width: 1024.98px) {
  body.home .charm__wrap .block__img {
    text-align: center;
  }
}

body.home .charm__wrap .charm__intro {
  position: relative;
  font-family: "游明朝", YuMincho, "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
  font-size: 2.375rem;
}

body.home .charm__wrap .charm__intro span {
  display: inline-block;
  transform: scale(1.1) translateY(-30px);
  opacity: 0;
  transition: 1s cubic-bezier(0.38, 0.1, 0.1, 0.99);
}

@media all and (-ms-high-contrast: none) {
  body.home .charm__wrap .charm__intro {
    font-family: "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
  }
}

@media (min-width: 1025px) {
  body.home .charm__wrap .charm__intro {
    padding-top: 2rem;
    margin-bottom: 90px;
  }
}

@media (max-width: 1024.98px) {
  body.home .charm__wrap .charm__intro {
    font-size: 1.875rem;
    line-height: 1.5;
    margin-bottom: 2rem;
    font-family: ryo-text-plusn, "游明朝", YuMincho, "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
  }
}

body.home .charm__wrap .charm__intro:after {
  content: "";
  display: block;
  background: url(../img/home/bg_charm_weave.png) no-repeat;
  background-size: cover;
  width: 100vw;
  height: 175px;
  position: absolute;
  top: 0;
  left: -100%;
  right: -100%;
  z-index: -10;
  margin: auto;
}

@media (max-width: 1024.98px) {
  body.home .charm__wrap .charm__intro:after {
    background: url(../img/home/bg_charm_weave_sp.png) no-repeat;
    background-size: cover;
    height: 65px;
  }
}

body.home .charm__wrap .charm__intro.scroll-in span {
  transform: scale(1) translateY(0);
  opacity: 1;
}

body.home .charm__wrap .first__block {
  position: relative;
}

@media (min-width: 1025px) {
  body.home .charm__wrap .first__block {
    display: flex;
    flex-direction: row-reverse;
  }
}

body.home .charm__wrap .first__block .block__img {
  opacity: 0;
  transition: 1s cubic-bezier(0.38, 0.1, 0.1, 0.99);
}

@media (min-width: 1025px) {
  body.home .charm__wrap .first__block .block__img {
    transform: scale(1.1) translate(40px, -40px);
  }
}

@media (max-width: 1024.98px) {
  body.home .charm__wrap .first__block .block__img {
    transform: scale(1.1) translateX(-30px);
    width: 90%;
  }
}

body.home .charm__wrap .first__block .block__img.scroll-in {
  transform: scale(1) translate(20px, -40px);
  opacity: 1;
}

@media (max-width: 1024.98px) {
  body.home .charm__wrap .first__block .block__img.scroll-in {
    transform: scale(1.1) translateX(-10px);
  }
}

@media (min-width: 1025px) {
  body.home .charm__wrap .first__block .block__txt {
    position: absolute;
    left: 0;
    z-index: 1;
    transform: translate(100px);
  }
}

@media (max-width: 1024.98px) {
  body.home .charm__wrap .first__block .block__txt {
    transform: translateY(-50px);
    text-align: right;
    margin-bottom: 0;
    font-family: ryo-text-plusn, "游明朝", YuMincho, "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
  }
}

body.home .charm__wrap .first__block .block__txt span {
  position: relative;
  opacity: 0;
  transition: 1s cubic-bezier(0.38, 0.1, 0.1, 0.99);
}

@media (min-width: 1025px) {
  body.home .charm__wrap .first__block .block__txt span {
    transform: translateX(-150px);
  }
}

body.home .charm__wrap .first__block .block__txt.scroll-in span {
  transform: translateX(-100px);
  opacity: 1;
}

@media (max-width: 1024.98px) {
  body.home .charm__wrap .first__block .block__txt.scroll-in span {
    transform: translateX(10px);
  }
}

body.home .charm__wrap .second__block {
  position: relative;
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 1024.98px) {
  body.home .charm__wrap .second__block {
    display: block;
  }
}

body.home .charm__wrap .second__block .block__img {
  opacity: 0;
  transition: 1s cubic-bezier(0.38, 0.1, 0.1, 0.99);
}

@media (min-width: 1025px) {
  body.home .charm__wrap .second__block .block__img {
    transform: scale(1.1) translate(-90px, -100px);
  }
}

@media (max-width: 1024.98px) {
  body.home .charm__wrap .second__block .block__img {
    text-align: right;
    transform: scale(1.1) translate(-40px, -40px);
    width: 90%;
    margin-left: auto;
  }
}

body.home .charm__wrap .second__block .block__img.scroll-in {
  transform: scale(1) translate(-70px, -100px);
  opacity: 1;
}

@media (max-width: 1024.98px) {
  body.home .charm__wrap .second__block .block__img.scroll-in {
    transform: scale(1) translate(10px, 0);
  }
}

body.home .charm__wrap .second__block .block__txt {
  position: relative;
  opacity: 0;
  transition: 1s cubic-bezier(0.38, 0.1, 0.1, 0.99);
}

@media (min-width: 1025px) {
  body.home .charm__wrap .second__block .block__txt {
    z-index: 1;
    transform: translate(0, -40px);
  }
}

@media (max-width: 1024.98px) {
  body.home .charm__wrap .second__block .block__txt {
    transform: translateY(-50px);
    font-family: ryo-text-plusn, "游明朝", YuMincho, "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
  }
}

body.home .charm__wrap .second__block .block__txt.scroll-in {
  transform: scale(1) translate(-40px, -40px);
  opacity: 1;
}

@media (max-width: 1024.98px) {
  body.home .charm__wrap .second__block .block__txt.scroll-in {
    transform: scale(1) translate(-10px, -40px);
  }
}

body.home .charm__wrap .third__block {
  position: relative;
}

@media (min-width: 1025px) {
  body.home .charm__wrap .third__block {
    display: flex;
    justify-content: flex-end;
    flex-direction: row-reverse;
    transform: translateY(-100px);
  }
  body.home .charm__wrap .third__block:after {
    content: "";
    background: url(../img/home/img_charm_item01.png) no-repeat;
    background-size: 67px 62px;
    width: 67px;
    height: 62px;
    position: absolute;
    top: 30px;
    left: 0;
  }
}

body.home .charm__wrap .third__block .block__txt {
  position: relative;
  opacity: 0;
  transition: 1s cubic-bezier(0.38, 0.1, 0.1, 0.99);
  transform: translateY(-50px);
}

@media (min-width: 1025px) {
  body.home .charm__wrap .third__block .block__txt {
    margin: 3rem auto 0;
  }
}

@media (max-width: 1024.98px) {
  body.home .charm__wrap .third__block .block__txt {
    position: absolute;
    top: 130px;
    left: 0;
    right: 0;
    margin: auto;
    font-family: ryo-text-plusn, "游明朝", YuMincho, "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
  }
}

body.home .charm__wrap .third__block .block__txt.scroll-in {
  transform: translateY(0);
  opacity: 1;
}

@media all and (-ms-high-contrast: none) and (min-width: 1025px) {
  body.home .charm__wrap .third__block *::-ms-backdrop, body.home .charm__wrap .third__block .block__txt {
    margin: 3rem 0 0;
    width: 100%;
  }
}

body.home .charm__wrap .third__block .block__img.img__first {
  opacity: 0;
  transition: 1s cubic-bezier(0.38, 0.1, 0.1, 0.99);
  transform: translateX(50px);
}

@media (min-width: 1025px) {
  body.home .charm__wrap .third__block .block__img.img__first {
    position: absolute;
    top: -50px;
    right: -50px;
    z-index: -1;
  }
}

@media (max-width: 1024.98px) {
  body.home .charm__wrap .third__block .block__img.img__first {
    text-align: right;
    transform: translateX(10px);
    width: 90%;
    margin-left: auto;
  }
}

body.home .charm__wrap .third__block .block__img.img__first.scroll-in {
  transform: scale(1) translateX(10px);
  opacity: 1;
}

body.home .charm__wrap .third__block .block__img.img__second {
  opacity: 0;
  transition: 1s cubic-bezier(0.38, 0.1, 0.1, 0.99);
  transform: scale(1.1) translate(-50px, 0);
}

@media (min-width: 1025px) {
  body.home .charm__wrap .third__block .block__img.img__second {
    position: absolute;
    bottom: -50px;
    left: -50px;
    z-index: -1;
  }
}

@media (max-width: 1024.98px) {
  body.home .charm__wrap .third__block .block__img.img__second {
    position: relative;
    z-index: -1;
    text-align: left;
    transform: translate(-10px, 120px);
    margin-bottom: 3rem;
    width: 90%;
  }
}

body.home .charm__wrap .third__block .block__img.img__second.scroll-in {
  transform: scale(1) translate(0, 0);
  opacity: 1;
}

@media (max-width: 1024.98px) {
  body.home .charm__wrap .third__block .block__img.img__second.scroll-in {
    transform: scale(1) translate(-10px, 70px);
  }
}

body.home .charm__wrap .force__block {
  position: relative;
}

body.home .charm__wrap .force__block .charm__message {
  font-family: "游明朝", YuMincho, "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
  font-size: 1.375rem;
  letter-spacing: 3px;
  line-height: 2;
  display: inline-block;
  margin: 9rem 0 14rem 380px;
  opacity: 0;
  transition: 2s cubic-bezier(0.38, 0.1, 0.1, 0.99);
  transform: scale(1.1) translate(0, -30px);
}

body.home .charm__wrap .force__block .charm__message .first__txt {
  margin-bottom: 1rem;
  display: block;
  font-size: 1.875rem;
}

@media (max-width: 767.98px) {
  body.home .charm__wrap .force__block .charm__message .first__txt {
    font-size: 1.5625rem;
  }
}

@media all and (-ms-high-contrast: none) {
  body.home .charm__wrap .force__block .charm__message {
    font-family: "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
  }
}

@media print {
  body.home .charm__wrap .force__block .charm__message {
    opacity: 1;
  }
}

@media (max-width: 1024.98px) {
  body.home .charm__wrap .force__block .charm__message {
    font-family: ryo-text-plusn, "游明朝", YuMincho, "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
    margin: 9rem 0 9rem 300px;
  }
}

@media (max-width: 767.98px) {
  body.home .charm__wrap .force__block .charm__message {
    display: block;
    text-align: center;
    margin: 4rem 0 740px;
  }
}

body.home .charm__wrap .force__block .charm__message.scroll-in {
  transform: scale(1) translate(0, 0);
  opacity: 1;
}

body.home .charm__wrap .force__block .block__txt {
  position: relative;
  opacity: 0;
  transition: 1s cubic-bezier(0.38, 0.1, 0.1, 0.99);
}

@media (min-width: 1025px) {
  body.home .charm__wrap .force__block .block__txt {
    transform: scale(1.2) translate(40px, 60px);
  }
}

@media (max-width: 1024.98px) {
  body.home .charm__wrap .force__block .block__txt {
    text-align: right;
    transform: translate(50px, 0);
    font-family: ryo-text-plusn, "游明朝", YuMincho, "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
  }
}

body.home .charm__wrap .force__block .block__txt.scroll-in {
  transform: scale(1) translate(0, 60px);
  opacity: 1;
}

@media (max-width: 1024.98px) {
  body.home .charm__wrap .force__block .block__txt.scroll-in {
    transform: translate(10px, 60px);
  }
}

body.home .charm__wrap .force__block .block__img {
  opacity: 0;
  transition: 1s cubic-bezier(0.38, 0.1, 0.1, 0.99);
  transform: scale(1.1) translate(-60px, -60px);
}

@media (min-width: 768px) {
  body.home .charm__wrap .force__block .block__img.img__first {
    margin-right: 1rem;
  }
}

body.home .charm__wrap .force__block .block__img.scroll-in {
  transform: scale(1) translate(0, 50px);
  opacity: 1;
}

body.home .access__wrap {
  margin-bottom: 4rem;
  font-family: "游明朝", YuMincho, "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
}

@media all and (-ms-high-contrast: none) {
  body.home .access__wrap {
    font-family: "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
  }
}

body.home .access__wrap h2 {
  text-align: center;
  margin-bottom: 1.5rem;
}

@media (max-width: 1024.98px) {
  body.home .access__wrap h2 {
    font-family: ryo-text-plusn, "游明朝", YuMincho, "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
  }
}

body.home .access__wrap .access__anchor {
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

body.home .access__wrap .access__anchor li {
  width: calc((100% - 10%) / 3);
  text-align: center;
  margin-bottom: 0.5rem;
}

@media (min-width: 768px) and (max-width: 1024.98px) {
  body.home .access__wrap .access__anchor li {
    width: calc((100% - 6%) / 3);
  }
}

@media (max-width: 767.98px) {
  body.home .access__wrap .access__anchor li {
    width: 100%;
    max-width: 300px;
  }
}

@media (min-width: 1025px) {
  body.home .access__wrap .access__anchor li:not(:last-of-type) {
    margin-right: 5%;
  }
}

@media (min-width: 768px) and (max-width: 1024.98px) {
  body.home .access__wrap .access__anchor li:not(:last-of-type) {
    margin-right: 3%;
  }
}

body.home .access__wrap .access__anchor li a {
  font-size: 1.25rem;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 2rem .5rem;
  height: 100%;
  background-color: #E3EDF4;
  color: #333;
  position: relative;
  transition: .2s;
}

@media (max-width: 1024.98px) {
  body.home .access__wrap .access__anchor li a {
    font-family: ryo-text-plusn, "游明朝", YuMincho, "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
  }
}

body.home .access__wrap .access__anchor li a:after {
  content: "";
  position: absolute;
  display: block;
  bottom: 10px;
  top: 0;
  bottom: 0;
  right: 10px;
  margin: auto;
  width: 15px;
  height: 15px;
  border-bottom: 2px solid #00729C;
  border-right: 2px solid #00729C;
  transform: rotate(-45deg);
}

body.home .access__wrap .access__anchor li a:hover img {
  transform: scale(1.1);
}

body.home .access__wrap .access__anchor li a span {
  display: block;
  width: 100%;
  padding-top: 0.5rem;
}

body.home .article__info .first__block .head__block {
  position: relative;
  letter-spacing: 5px;
  display: inline-block;
  font-family: "游明朝", YuMincho, "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
}

@media all and (-ms-high-contrast: none) {
  body.home .article__info .first__block .head__block {
    font-family: "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
  }
}

@media (max-width: 1024.98px) {
  body.home .article__info .first__block .head__block {
    margin: 0 auto 2rem;
  }
}

body.home .article__info .first__block .head__block h2 {
  position: relative;
  font-size: 1.875rem;
  margin-bottom: 2rem;
  line-height: 1.5;
}

@media (max-width: 1024.98px) {
  body.home .article__info .first__block .head__block h2 {
    margin: auto;
    font-family: ryo-text-plusn, "游明朝", YuMincho, "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
  }
}

body.home .article__info .first__block .head__block h2:after {
  content: "";
  display: inline-block;
  background: url(../img/pages/bg_head_pattern02.svg) no-repeat;
  background-size: 52px 9px;
  width: 52px;
  height: 9px;
  position: absolute;
  right: -60px;
  bottom: 0;
}

@media (max-width: 1024.98px) {
  body.home .article__info .first__block .head__block h2:after {
    right: -20px;
    bottom: 100%;
  }
}

body.home .article__info .first__block .head__block:after {
  content: "";
  display: inline-block;
  background: url(../img/pages/bg_head_pattern02.svg) no-repeat;
  background-size: 52px 9px;
  width: 52px;
  height: 9px;
  position: absolute;
  left: 20px;
  top: -30px;
}

@media (max-width: 1024.98px) {
  body.home .article__info .first__block .head__block:after {
    left: 20%;
    top: -30px;
  }
}

body.home .article__info .first__block .head__block:before {
  content: "";
  display: inline-block;
  background: url(../img/home/bg_weave_small.svg) no-repeat;
  background-size: 52px 9px;
  width: 52px;
  height: 9px;
  position: absolute;
  left: -30px;
  top: 50%;
}

@media (max-width: 1024.98px) {
  body.home .article__info .first__block .head__block:before {
    left: 25%;
    top: 115%;
  }
}

body.home .article__info .first__block .search {
  position: relative;
}

@media (min-width: 1025px) {
  body.home .article__info .first__block .search {
    width: 70%;
  }
  body.home .article__info .first__block .search:after {
    content: "";
    display: inline-block;
    background: url(../img/home/img_search_fish.png) no-repeat;
    background-size: 247px 135px;
    width: 247px;
    height: 135px;
    position: absolute;
    right: 0;
    top: 0;
  }
}

body.home section .p-common__article {
  margin-bottom: 1rem;
}

body.home section .others {
  margin-bottom: 3rem;
}

body.home .news__wrap {
  margin-bottom: 3rem;
}

body.home .news__wrap .news__inner {
  margin: auto;
  position: relative;
}

@media (min-width: 1025px) {
  body.home .news__wrap .news__inner {
    width: 90%;
  }
}

body.home .news__wrap .news__inner h2 {
  font-family: "游明朝", YuMincho, "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
  font-size: 1.875rem;
  letter-spacing: 5px;
  position: relative;
  line-height: 1.5;
  margin-bottom: 1rem;
}

@media all and (-ms-high-contrast: none) {
  body.home .news__wrap .news__inner h2 {
    font-family: "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
  }
}

@media (max-width: 1024.98px) {
  body.home .news__wrap .news__inner h2 {
    margin-bottom: 4rem;
    font-family: ryo-text-plusn, "游明朝", YuMincho, "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
  }
}

body.home .news__wrap .news__inner h2:after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  bottom: 0%;
  background: url(../img/pages/bg_fish.png) no-repeat;
  background-size: 159px 115px;
  width: 159px;
  height: 115px;
}

@media (max-width: 1024.98px) {
  body.home .news__wrap .news__inner h2:after {
    display: none;
  }
}

body.home .news__wrap .news__inner .news__list {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
}

@media (min-width: 1025px) {
  body.home .news__wrap .news__inner .news__list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
}

body.home .news__wrap .news__inner .news__list dt {
  width: 18%;
}

@media (max-width: 1024.98px) {
  body.home .news__wrap .news__inner .news__list dt {
    width: 100%;
  }
}

body.home .news__wrap .news__inner .news__list dd {
  width: 82%;
  margin-bottom: 0;
}

@media (min-width: 1025px) {
  body.home .news__wrap .news__inner .news__list dd {
    padding-left: 1rem;
  }
}

@media (max-width: 1024.98px) {
  body.home .news__wrap .news__inner .news__list dd {
    width: 100%;
  }
}

body.home .news__wrap .news__inner .news__list:nth-of-type(odd) {
  background-color: #E3EDF4;
}

body.home .sns__wrap {
  margin-bottom: 3rem;
}

body.home .sns__wrap .three__sns {
  justify-content: center;
  margin-bottom: 3rem;
}

@media (max-width: 767.98px) {
  body.home .sns__wrap .three__sns {
    margin-bottom: 2rem;
  }
}

body.home .sns__wrap .sns__title {
  font-size: 1.1875rem;
  display: flex;
  align-items: center;
  font-family: "游明朝", YuMincho, "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
}

@media all and (-ms-high-contrast: none) {
  body.home .sns__wrap .sns__title {
    font-family: "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
  }
}

body.home .sns__wrap .sns__title:before {
  content: "";
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-right: .5rem;
}

body.home .sns__wrap .youtube__content {
  width: 83%;
  margin: auto;
}

@media (max-width: 767.98px) {
  body.home .sns__wrap .youtube__content {
    width: 100%;
  }
}

body.home .sns__wrap .youtube__content .sns__title {
  margin: auto;
  font-size: 1.1875rem;
  display: flex;
  align-items: center;
  font-family: "游明朝", YuMincho, "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
  margin-bottom: 0.5rem;
}

@media all and (-ms-high-contrast: none) {
  body.home .sns__wrap .youtube__content .sns__title {
    font-family: "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
  }
}

body.home .sns__wrap .youtube__content .sns__title:before {
  content: "";
  display: inline-block;
  margin-right: .5rem;
  background: url(../img/home/icon_home_youtube.png) no-repeat;
  background-size: 35px 25px;
  width: 35px;
  height: 25px;
  vertical-align: bottom;
}

body.home .sns__wrap .youtube__content .p-youtube__wrap {
  padding-top: 55%;
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}

@media (max-width: 767.98px) {
  body.home .sns__wrap .youtube__content .p-youtube__wrap {
    margin: 0;
    padding-top: 75%;
  }
}

body.home .sns__wrap .youtube__content .youtube__caution {
  font-size: 0.75rem;
}

body.home .sns__wrap .instagram__content .sns__title {
  color: #8533AA;
}

body.home .sns__wrap .instagram__content .sns__title:before {
  content: url(../img/home/icon_home_instagram.png);
  display: inline-block;
}

body.home .sns__wrap .instagram__content .instagram__list {
  display: flex;
  flex-wrap: wrap;
}

body.home .sns__wrap .instagram__content .instagram__list li {
  width: 20%;
  overflow: hidden;
}

@media (max-width: 767.98px) {
  body.home .sns__wrap .instagram__content .instagram__list li {
    width: 50%;
  }
}

body.home .sns__wrap .instagram__content .instagram__list li a {
  display: block;
  height: 100%;
  position: relative;
  text-decoration: none;
}

body.home .sns__wrap .instagram__content .instagram__list li a:before {
  content: "Instagramで見る";
  cursor: pointer;
  color: #fff;
  font-weight: bold;
  font-size: 1.125rem;
  font-family: "游明朝", YuMincho, "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
  background-color: rgba(1, 174, 154, 0.6);
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 0 .5rem;
  z-index: -1;
  opacity: 0;
  transform: scale(0.5);
  transition: .3s;
}

@media all and (-ms-high-contrast: none) {
  body.home .sns__wrap .instagram__content .instagram__list li a:before {
    font-family: "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
  }
}

@media (max-width: 1024.98px) {
  body.home .sns__wrap .instagram__content .instagram__list li a:before {
    font-size: 1rem;
  }
}

body.home .sns__wrap .instagram__content .instagram__list li a img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (min-width: 768px) {
  body.home .sns__wrap .instagram__content .instagram__list li a img {
    max-height: 195px;
  }
}

@media (max-width: 767.98px) {
  body.home .sns__wrap .instagram__content .instagram__list li a img {
    max-height: 250px;
  }
}

body.home .sns__wrap .instagram__content .instagram__list li a:hover:before {
  z-index: 10;
  opacity: 1;
  transform: scale(1);
}

@media (max-width: 767.98px) {
  body.home .sns__wrap .instagram__content .instagram__list li:nth-of-type(n+7) {
    display: none;
  }
}

@media all and (-ms-high-contrast: none) {
  body.home .sns__wrap .instagram__content .instagram__list li a {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #d4d4d4;
  }
  body.home .sns__wrap .instagram__content .instagram__list li a img {
    transform: scale(1.5);
    height: auto;
  }
}

@media (max-width: 767.98px) {
  body.home .sns__wrap .first__block {
    margin-bottom: 2rem;
    text-align: center;
  }
}

body.home .sns__wrap .first__block .sns__title {
  color: #8533AA;
}

body.home .sns__wrap .first__block .sns__title:before {
  background: url(../img/home/icon_home_instagram.png) no-repeat;
  background-size: 25px 25px;
}

@media (max-width: 767.98px) {
  body.home .sns__wrap .second__block {
    margin-bottom: 2rem;
    text-align: center;
  }
  body.home .sns__wrap .second__block .fb-page {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

body.home .sns__wrap .second__block .sns__title {
  color: #3B579D;
}

body.home .sns__wrap .second__block .sns__title:before {
  background: url(../img/home/icon_home_facebook.png) no-repeat;
  background-size: 25px 25px;
}

@media (max-width: 767.98px) {
  body.home .sns__wrap .third__block {
    margin-bottom: 2rem;
    text-align: center;
  }
  body.home .sns__wrap .third__block .outside__frame {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

body.home .sns__wrap .third__block .sns__title {
  color: #0E94E6;
}

body.home .sns__wrap .third__block .sns__title:before {
  background: url(../img/home/icon_home_twitter.png) no-repeat;
  background-size: 25px 25px;
}

@media (max-width: 767.98px) {
  body.page:not([class="parent_category"]) h1 {
    font-size: 1.8rem;
  }
}

body.page main {
  margin-bottom: 5rem;
}

@media (max-width: 1024.98px) {
  body.page main {
    margin-bottom: 3rem;
  }
}

body.page h1,
body.page h2,
body.page h3,
body.page h4,
body.page h5 {
  font-family: "游明朝", YuMincho, "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
}

@media all and (-ms-high-contrast: none) {
  body.page h1,
  body.page h2,
  body.page h3,
  body.page h4,
  body.page h5 {
    font-family: "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
  }
}

@media (max-width: 1024.98px) {
  body.page h1,
  body.page h2,
  body.page h3,
  body.page h4,
  body.page h5 {
    font-family: ryo-text-plusn, "游明朝", YuMincho, "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
  }
}

body.page h1 {
  padding: 1.5rem 0 2rem;
}

body.page h2 {
  margin: 1.5rem 0 1.5rem;
  padding: .5rem .5rem .5rem 1rem;
}

body.page h3 {
  margin: 1.5rem 0 1.5rem;
}

body.page h3:before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 21px;
  background: url(../img/pages/bg_head_pattern01.svg) no-repeat;
  background-size: 20px 21px;
  margin-right: 1rem;
}

body.page h4 {
  margin: 1.5rem 0 1.5rem;
}

body.page h4 span:after {
  content: "";
  display: inline-block;
  width: 39px;
  height: 9px;
  background: url(../img/pages/bg_head_pattern02.svg) no-repeat;
  background-size: 39px 9px;
  margin-left: 1rem;
  margin-bottom: .2rem;
}

body.page h5 {
  margin: 1.5rem 0 1rem;
}

body.page section {
  margin-bottom: 2rem;
}

body.page .breadcrumb {
  background-color: transparent;
  padding: 0.75rem 1rem 1.5rem 0;
}

body.page .single__img .img__item,
body.page .single__img__add-caption .img__item,
body.page .two__img .img__item,
body.page .two__img__add-caption .img__item,
body.page .three__img .img__item,
body.page .three__img__add-caption .img__item,
body.page .four__img .img__item,
body.page .four__img__add-caption .img__item {
  margin-bottom: 1rem;
}

body.page .single__img {
  margin-bottom: 1rem;
}

body.page .two__img__add-caption2 figcaption p.bold {
  font-weight: bold;
  font-size: 1.5rem;
  margin: .5rem 0;
}

body.page ol.bold {
  font-weight: bold;
}

body.page .gmap a:before {
  content: "";
  display: inline-block;
  background: url(../img/common/icon_googlemap.svg) no-repeat;
  background-size: 18px 18px;
  width: 18px;
  height: 18px;
  vertical-align: middle;
  margin-bottom: 2px;
  margin-right: 5px;
}

body.page .page-header {
  padding-top: 30%;
  position: relative;
  width: 100%;
  height: 100%;
}

@media (max-width: 767.98px) {
  body.page .page-header {
    padding-top: 60%;
  }
}

body.page .page-header .page-header__inner {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

body.page .page-header .page-header__inner:after {
  content: "";
  display: block;
  background: url(../img/pages/bg_white_wave.svg) repeat-x;
  background-size: 20px 10px;
  width: 100%;
  height: 10px;
  position: absolute;
  bottom: 0;
}

body.page .page-header .page-header__inner .catch-copy {
  font-family: "游明朝", YuMincho, "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
  font-size: 1.5rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  background: rgba(0, 0, 0, 0.7);
  margin-bottom: 0;
  padding: 1rem 3rem;
  text-align: center;
}

@media all and (-ms-high-contrast: none) {
  body.page .page-header .page-header__inner .catch-copy {
    font-family: "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
  }
}

@media (max-width: 767.98px) {
  body.page .page-header .page-header__inner .catch-copy {
    padding: 1rem;
    width: 90%;
    font-size: 1.1rem;
  }
}

body.page.dmo-about .charm__container {
  margin-bottom: 8rem;
}

body.page.dmo-about .sanriku-kikin {
  list-style: none;
  text-decoration: none;
  padding: 0;
}

body.page.dmo-about .sanriku-kikin li {
  margin-bottom: .5em;
}

body.page.dmo-about .sanriku-kikin li a {
  text-decoration: none;
  color: #333;
  display: block;
  background-color: #e3edf4;
  padding: 1rem 1.5rem 1rem 1rem;
  height: 100%;
  display: flex;
  align-items: center;
  transition: .2s;
  position: relative;
}

body.page.dmo-about .sanriku-kikin li a:hover {
  background-color: #d1e1ed;
}

body.page.dmo-about .sanriku-kikin li a:after {
  content: "";
  display: inline-block;
  position: absolute;
  right: 20px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 10px;
  height: 10px;
  border-bottom: 1px solid #707070;
  border-right: 1px solid #707070;
  transform: rotate(-45deg);
}

body.page.dmo-about .sanriku-kikin li a span {
  display: flex;
  align-items: center;
}

body.page.dmo-about .sanriku-kikin li a span:before {
  content: "";
  display: inline-block;
  background: url(../img/common/icon_new-window.svg) no-repeat;
  background-size: 32px 28px;
  width: 32px;
  height: 28px;
  padding-left: 31px;
  margin-right: 1rem;
  margin-left: .5rem;
}

body.page.dmo-about .charm__wrap {
  position: relative;
}

@media (min-width: 1025px) {
  body.page.dmo-about .charm__wrap {
    padding-top: 25%;
  }
}

@media (max-width: 1024.98px) {
  body.page.dmo-about .charm__wrap {
    margin-bottom: 4rem;
    padding-top: 0;
  }
}

body.page.dmo-about .charm__wrap:before {
  content: "";
  display: block;
  background: url(../img/home/bg_charm_map.png) no-repeat;
  background-size: 495px 740px;
  width: 495px;
  height: 740px;
  position: absolute;
  bottom: -150px;
  left: -90px;
  z-index: -10;
  margin: auto;
}

@media (max-width: 1024.98px) {
  body.page.dmo-about .charm__wrap:before {
    left: -210px;
  }
}

@media (max-width: 767.98px) {
  body.page.dmo-about .charm__wrap:before {
    left: auto;
    right: 20px;
    bottom: -770px;
  }
}

body.page.dmo-about .charm__wrap .charm__first-bg {
  padding-top: 25%;
  margin: auto;
}

@media (min-width: 1025px) {
  body.page.dmo-about .charm__wrap .charm__first-bg {
    position: absolute;
    left: -100%;
    right: -100%;
    top: 0;
    background: url(../img/home/bg_charm01.jpg) no-repeat;
    background-size: 100%;
    background-position: center center;
    width: 100vw;
  }
}

@media (max-width: 1024.98px) {
  body.page.dmo-about .charm__wrap .charm__first-bg {
    background: url(../img/home/bg_charm01_sp.jpg) no-repeat;
    background-size: 100%;
    background-position: center center;
    padding-top: 65%;
    margin-bottom: 2rem;
  }
}

body.page.dmo-about .charm__wrap .block__txt {
  font-family: "游明朝", YuMincho, "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
}

@media all and (-ms-high-contrast: none) {
  body.page.dmo-about .charm__wrap .block__txt {
    font-family: "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
  }
}

body.page.dmo-about .charm__wrap .block__txt span {
  background-color: rgba(255, 255, 255, 0.8);
  display: inline-block;
  letter-spacing: 3px;
  line-height: 1.5;
  padding: .5rem;
  margin-bottom: 3px;
}

@media (max-width: 1024.98px) {
  body.page.dmo-about .charm__wrap .block__txt span {
    line-height: 1.5;
    padding: 0.2rem .5rem;
  }
}

@media (max-width: 1024.98px) {
  body.page.dmo-about .charm__wrap .block__img {
    text-align: center;
  }
}

body.page.dmo-about .charm__wrap .charm__intro {
  position: relative;
  border-left: none;
  font-family: "游明朝", YuMincho, "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
  font-size: 2.375rem;
}

body.page.dmo-about .charm__wrap .charm__intro span {
  display: inline-block;
  transform: scale(1.1) translateY(-30px);
  opacity: 0;
  transition: 1s cubic-bezier(0.38, 0.1, 0.1, 0.99);
}

@media print {
  body.page.dmo-about .charm__wrap .charm__intro span {
    opacity: 1;
  }
}

@media all and (-ms-high-contrast: none) {
  body.page.dmo-about .charm__wrap .charm__intro {
    font-family: "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
  }
}

@media (min-width: 1025px) {
  body.page.dmo-about .charm__wrap .charm__intro {
    padding-top: 2rem;
    margin-bottom: 90px;
  }
}

@media (max-width: 1024.98px) {
  body.page.dmo-about .charm__wrap .charm__intro {
    font-size: 1.875rem;
    line-height: 1.5;
    margin-bottom: 2rem;
    font-family: ryo-text-plusn, "游明朝", YuMincho, "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
  }
}

body.page.dmo-about .charm__wrap .charm__intro:after {
  content: "";
  display: block;
  background: url(../img/home/bg_charm_weave.png) no-repeat;
  background-size: cover;
  width: 100vw;
  height: 175px;
  position: absolute;
  top: 0;
  left: -100%;
  right: -100%;
  z-index: -10;
  margin: auto;
}

@media (max-width: 1024.98px) {
  body.page.dmo-about .charm__wrap .charm__intro:after {
    background: url(../img/home/bg_charm_weave_sp.png) no-repeat;
    background-size: cover;
    height: 65px;
  }
}

body.page.dmo-about .charm__wrap .charm__intro.scroll-in span {
  transform: scale(1) translateY(0);
  opacity: 1;
}

body.page.dmo-about .charm__wrap .first__block {
  position: relative;
}

@media (min-width: 1025px) {
  body.page.dmo-about .charm__wrap .first__block {
    display: flex;
    flex-direction: row-reverse;
  }
}

body.page.dmo-about .charm__wrap .first__block .block__img {
  opacity: 0;
  transition: 1s cubic-bezier(0.38, 0.1, 0.1, 0.99);
}

@media print {
  body.page.dmo-about .charm__wrap .first__block .block__img {
    opacity: 1;
  }
}

@media (min-width: 1025px) {
  body.page.dmo-about .charm__wrap .first__block .block__img {
    transform: scale(1.1) translate(40px, -40px);
  }
}

@media (max-width: 1024.98px) {
  body.page.dmo-about .charm__wrap .first__block .block__img {
    transform: scale(1.1) translateX(-30px);
    width: 90%;
  }
}

body.page.dmo-about .charm__wrap .first__block .block__img.scroll-in {
  transform: scale(1) translate(20px, -40px);
  opacity: 1;
}

@media (max-width: 1024.98px) {
  body.page.dmo-about .charm__wrap .first__block .block__img.scroll-in {
    transform: scale(1.1) translateX(-10px);
  }
}

@media (min-width: 1025px) {
  body.page.dmo-about .charm__wrap .first__block .block__txt {
    position: absolute;
    left: 0;
    z-index: 1;
    transform: translate(100px);
  }
}

@media (max-width: 1024.98px) {
  body.page.dmo-about .charm__wrap .first__block .block__txt {
    transform: translateY(-50px);
    text-align: right;
    margin-bottom: 0;
    font-family: ryo-text-plusn, "游明朝", YuMincho, "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
  }
}

body.page.dmo-about .charm__wrap .first__block .block__txt span {
  position: relative;
  opacity: 0;
  transition: 1s cubic-bezier(0.38, 0.1, 0.1, 0.99);
}

@media print {
  body.page.dmo-about .charm__wrap .first__block .block__txt span {
    opacity: 1;
  }
}

@media (min-width: 1025px) {
  body.page.dmo-about .charm__wrap .first__block .block__txt span {
    transform: translateX(-150px);
  }
}

body.page.dmo-about .charm__wrap .first__block .block__txt.scroll-in span {
  transform: translateX(-100px);
  opacity: 1;
}

@media (max-width: 1024.98px) {
  body.page.dmo-about .charm__wrap .first__block .block__txt.scroll-in span {
    transform: translateX(10px);
  }
}

body.page.dmo-about .charm__wrap .second__block {
  position: relative;
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 1024.98px) {
  body.page.dmo-about .charm__wrap .second__block {
    display: block;
  }
}

body.page.dmo-about .charm__wrap .second__block .block__img {
  opacity: 0;
  transition: 1s cubic-bezier(0.38, 0.1, 0.1, 0.99);
}

@media print {
  body.page.dmo-about .charm__wrap .second__block .block__img {
    opacity: 1;
  }
}

@media (min-width: 1025px) {
  body.page.dmo-about .charm__wrap .second__block .block__img {
    transform: scale(1.1) translate(-90px, -100px);
  }
}

@media (max-width: 1024.98px) {
  body.page.dmo-about .charm__wrap .second__block .block__img {
    text-align: right;
    transform: scale(1.1) translate(-40px, -40px);
    width: 90%;
    margin-left: auto;
  }
}

body.page.dmo-about .charm__wrap .second__block .block__img.scroll-in {
  transform: scale(1) translate(-70px, -100px);
  opacity: 1;
}

@media (max-width: 1024.98px) {
  body.page.dmo-about .charm__wrap .second__block .block__img.scroll-in {
    transform: scale(1) translate(10px, 0);
  }
}

body.page.dmo-about .charm__wrap .second__block .block__txt {
  position: relative;
  opacity: 0;
  transition: 1s cubic-bezier(0.38, 0.1, 0.1, 0.99);
}

@media print {
  body.page.dmo-about .charm__wrap .second__block .block__txt {
    opacity: 1;
  }
}

@media (min-width: 1025px) {
  body.page.dmo-about .charm__wrap .second__block .block__txt {
    z-index: 1;
    transform: translate(0, -40px);
  }
}

@media (max-width: 1024.98px) {
  body.page.dmo-about .charm__wrap .second__block .block__txt {
    transform: translateY(-50px);
    font-family: ryo-text-plusn, "游明朝", YuMincho, "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
  }
}

body.page.dmo-about .charm__wrap .second__block .block__txt.scroll-in {
  transform: scale(1) translate(-40px, -40px);
  opacity: 1;
}

@media (max-width: 1024.98px) {
  body.page.dmo-about .charm__wrap .second__block .block__txt.scroll-in {
    transform: scale(1) translate(-10px, -40px);
  }
}

body.page.dmo-about .charm__wrap .third__block {
  position: relative;
}

@media (min-width: 1025px) {
  body.page.dmo-about .charm__wrap .third__block {
    display: flex;
    justify-content: flex-end;
    flex-direction: row-reverse;
    transform: translateY(-100px);
  }
  body.page.dmo-about .charm__wrap .third__block:after {
    content: "";
    background: url(../img/home/img_charm_item01.png) no-repeat;
    background-size: 67px 62px;
    width: 67px;
    height: 62px;
    position: absolute;
    top: 30px;
    left: 0;
  }
}

body.page.dmo-about .charm__wrap .third__block .block__txt {
  position: relative;
  transition: 1s cubic-bezier(0.38, 0.1, 0.1, 0.99);
  transform: translateY(-50px);
}

@media print {
  body.page.dmo-about .charm__wrap .third__block .block__txt {
    opacity: 1;
  }
}

@media (min-width: 1025px) {
  body.page.dmo-about .charm__wrap .third__block .block__txt {
    margin: 3rem auto 0;
  }
}

@media (max-width: 1024.98px) {
  body.page.dmo-about .charm__wrap .third__block .block__txt {
    position: absolute;
    top: 130px;
    left: 0;
    right: 0;
    margin: auto;
    font-family: ryo-text-plusn, "游明朝", YuMincho, "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
  }
}

body.page.dmo-about .charm__wrap .third__block .block__txt.scroll-in {
  transform: translateY(0);
  opacity: 1;
}

@media all and (-ms-high-contrast: none) and (min-width: 1025px) {
  body.page.dmo-about .charm__wrap .third__block *::-ms-backdrop, body.page.dmo-about .charm__wrap .third__block .block__txt {
    margin: 3rem 0 0;
    width: 100%;
  }
}

body.page.dmo-about .charm__wrap .third__block .block__img.img__first {
  opacity: 0;
  transition: 1s cubic-bezier(0.38, 0.1, 0.1, 0.99);
  transform: translateX(50px);
}

@media print {
  body.page.dmo-about .charm__wrap .third__block .block__img.img__first {
    opacity: 1;
  }
}

@media (min-width: 1025px) {
  body.page.dmo-about .charm__wrap .third__block .block__img.img__first {
    position: absolute;
    top: -50px;
    right: -50px;
    z-index: -1;
  }
}

@media (max-width: 1024.98px) {
  body.page.dmo-about .charm__wrap .third__block .block__img.img__first {
    text-align: right;
    transform: translateX(10px);
    width: 90%;
    margin-left: auto;
  }
}

body.page.dmo-about .charm__wrap .third__block .block__img.img__first.scroll-in {
  transform: scale(1) translateX(10px);
  opacity: 1;
}

body.page.dmo-about .charm__wrap .third__block .block__img.img__second {
  opacity: 0;
  transition: 1s cubic-bezier(0.38, 0.1, 0.1, 0.99);
  transform: scale(1.1) translate(-50px, 0);
}

@media print {
  body.page.dmo-about .charm__wrap .third__block .block__img.img__second {
    opacity: 1;
  }
}

@media (min-width: 1025px) {
  body.page.dmo-about .charm__wrap .third__block .block__img.img__second {
    position: absolute;
    bottom: -50px;
    left: -50px;
    z-index: -1;
  }
}

@media (max-width: 1024.98px) {
  body.page.dmo-about .charm__wrap .third__block .block__img.img__second {
    position: relative;
    z-index: -1;
    text-align: left;
    transform: translate(-10px, 120px);
    margin-bottom: 3rem;
    width: 90%;
  }
}

body.page.dmo-about .charm__wrap .third__block .block__img.img__second.scroll-in {
  transform: scale(1) translate(0, 0);
  opacity: 1;
}

@media (max-width: 1024.98px) {
  body.page.dmo-about .charm__wrap .third__block .block__img.img__second.scroll-in {
    transform: scale(1) translate(-10px, 70px);
  }
}

body.page.dmo-about .charm__wrap .force__block {
  position: relative;
}

@media (min-width: 1025px) {
  body.page.dmo-about .charm__wrap .force__block:after {
    content: "";
    background: url(../img/home/img_charm_item02.png) no-repeat;
    background-size: 113px 54px;
    width: 113px;
    height: 54px;
    position: absolute;
    top: -40px;
    right: 170px;
  }
  body.page.dmo-about .charm__wrap .force__block:before {
    content: "";
    background: url(../img/home/img_charm_item03.png) no-repeat;
    background-size: 130px 109px;
    width: 130px;
    height: 109px;
    position: absolute;
    top: -60px;
    left: 10px;
    z-index: -1;
  }
}

body.page.dmo-about .charm__wrap .force__block .inner {
  display: flex;
  justify-content: center;
}

@media (max-width: 1024.98px) {
  body.page.dmo-about .charm__wrap .force__block .inner {
    flex-direction: column-reverse;
    flex-wrap: wrap;
  }
}

body.page.dmo-about .charm__wrap .force__block .inner figcaption {
  margin-top: 0.5rem;
}

@media (max-width: 1024.98px) {
  body.page.dmo-about .charm__wrap .force__block .inner figcaption {
    font-family: ryo-text-plusn, "游明朝", YuMincho, "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
  }
}

body.page.dmo-about .charm__wrap .force__block .charm__message {
  font-family: "游明朝", YuMincho, "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
  font-size: 1.375rem;
  letter-spacing: 3px;
  line-height: 2;
  display: inline-block;
  margin: 9rem 0 9rem 440px;
  opacity: 0;
  transition: 2s cubic-bezier(0.38, 0.1, 0.1, 0.99);
  transform: scale(1.1) translate(0, -30px);
}

@media all and (-ms-high-contrast: none) {
  body.page.dmo-about .charm__wrap .force__block .charm__message {
    font-family: "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
  }
}

@media print {
  body.page.dmo-about .charm__wrap .force__block .charm__message {
    opacity: 1;
  }
}

@media print {
  body.page.dmo-about .charm__wrap .force__block .charm__message {
    opacity: 1;
  }
}

@media (max-width: 1024.98px) {
  body.page.dmo-about .charm__wrap .force__block .charm__message {
    font-family: ryo-text-plusn, "游明朝", YuMincho, "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
    margin: 9rem 0 9rem 300px;
  }
}

@media (max-width: 767.98px) {
  body.page.dmo-about .charm__wrap .force__block .charm__message {
    display: block;
    text-align: center;
    margin: 6rem 0 740px;
  }
}

body.page.dmo-about .charm__wrap .force__block .charm__message.scroll-in {
  transform: scale(1) translate(0, 0);
  opacity: 1;
}

body.page.dmo-about .charm__wrap .force__block .block__txt {
  position: relative;
  opacity: 0;
  transition: 1s cubic-bezier(0.38, 0.1, 0.1, 0.99);
}

@media print {
  body.page.dmo-about .charm__wrap .force__block .block__txt {
    opacity: 1;
  }
}

@media (min-width: 1025px) {
  body.page.dmo-about .charm__wrap .force__block .block__txt {
    transform: scale(1.2) translate(40px, 60px);
  }
}

@media (max-width: 1024.98px) {
  body.page.dmo-about .charm__wrap .force__block .block__txt {
    text-align: right;
    transform: translate(50px, 0);
    font-family: ryo-text-plusn, "游明朝", YuMincho, "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
  }
}

body.page.dmo-about .charm__wrap .force__block .block__txt.scroll-in {
  transform: scale(1) translate(0, 60px);
  opacity: 1;
}

@media (max-width: 1024.98px) {
  body.page.dmo-about .charm__wrap .force__block .block__txt.scroll-in {
    transform: translate(10px, 60px);
  }
}

body.page.dmo-about .charm__wrap .force__block .block__img {
  opacity: 0;
  transition: 1s cubic-bezier(0.38, 0.1, 0.1, 0.99);
  transform: scale(1.1) translate(-60px, -60px);
}

@media print {
  body.page.dmo-about .charm__wrap .force__block .block__img {
    opacity: 1;
  }
}

@media (min-width: 768px) {
  body.page.dmo-about .charm__wrap .force__block .block__img.img__first {
    margin-right: 1rem;
  }
}

body.page.dmo-about .charm__wrap .force__block .block__img.scroll-in {
  transform: scale(1) translate(0, 50px);
  opacity: 1;
}

body.page.parent_category .page-header {
  padding-top: 35%;
}

@media (max-width: 767.98px) {
  body.page.parent_category .page-header {
    padding-top: 60%;
  }
}

body.page .page__intro__wrap {
  position: relative;
  padding-bottom: 3rem;
}

body.page .page__intro__wrap .intro__description {
  font-family: "游明朝", YuMincho, "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
}

@media all and (-ms-high-contrast: none) {
  body.page .page__intro__wrap .intro__description {
    font-family: "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
  }
}

body.page .page__intro__wrap .intro__period__block {
  letter-spacing: -.4em;
  display: flex;
  flex-wrap: wrap;
}

body.page .page__intro__wrap .intro__period__block .intro__period {
  letter-spacing: normal;
  background-color: #E3EDF4;
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
}

@media (max-width: 767.98px) {
  body.page .page__intro__wrap .intro__period__block .intro__period {
    width: 100%;
    text-align: center;
  }
}

body.page .page__intro__wrap .intro__period__block .label__lists {
  letter-spacing: normal;
  list-style: none;
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
}

body.page .page__intro__wrap .intro__period__block .label__lists li {
  margin: .3rem 0 .3rem .5rem;
  background-color: #E3EDF4;
  padding: 0.2rem 0.7rem;
  border-radius: 20px;
}

body.page.detail .page-header {
  padding-top: 30%;
}

@media (max-width: 767.98px) {
  body.page.detail .page-header {
    padding-top: 100%;
  }
}

body.page.detail .spot__transportation__lists .spot__item {
  margin-bottom: 1rem;
}

@media (max-width: 767.98px) {
  body.page.detail .spot__transportation__lists .spot__item {
    margin-bottom: 0.2rem;
    padding: 0 .2rem;
  }
  body.page.detail .spot__transportation__lists .spot__item:nth-of-type(even) {
    padding-left: 0;
  }
}

body.page.detail .spot__transportation__lists .spot__item.spot__walk a:before {
  content: "";
  display: block;
  margin: auto;
  background: url(../img/pages/icon_walk.svg) no-repeat;
  background-size: 25px 46px;
  width: 25px;
  height: 46px;
}

body.page.detail .spot__transportation__lists .spot__item.spot__cycle a:before {
  content: "";
  display: inline-block;
  display: block;
  margin: auto;
  background: url(../img/pages/icon_bicycle.svg) no-repeat;
  background-size: 64px 42px;
  width: 64px;
  height: 42px;
}

body.page.detail .spot__transportation__lists .spot__item.spot__bus a:before {
  content: "";
  display: inline-block;
  display: block;
  margin: auto;
  background: url(../img/pages/icon_bus.svg) no-repeat;
  background-size: 90px 36px;
  width: 90px;
  height: 36px;
}

body.page.detail .spot__transportation__lists .spot__item.spot__ferry a:before {
  content: "";
  display: inline-block;
  display: block;
  margin: auto;
  background: url(../img/pages/icon_ferry.svg) no-repeat;
  background-size: 78px 43px;
  width: 78px;
  height: 43px;
}

body.page.detail .spot__transportation__lists .spot__item.spot__car a:before {
  content: "";
  display: inline-block;
  display: block;
  margin: auto;
  background: url(../img/pages/icon_car.svg) no-repeat;
  background-size: 74px 28px;
  width: 74px;
  height: 28px;
}

body.page.detail .spot__transportation__lists .spot__item.spot__train a:before {
  content: "";
  display: inline-block;
  display: block;
  margin: auto;
  background: url(../img/pages/icon_train.svg) no-repeat;
  background-size: 93px 32px;
  width: 93px;
  height: 32px;
}

body.page.detail .spot__transportation__lists .spot__item.spot__taxi a:before {
  content: "";
  display: inline-block;
  display: block;
  margin: auto;
  background: url(../img/pages/icon_taxi.svg) no-repeat;
  background-size: 92px 32px;
  width: 92px;
  height: 32px;
}

body.page.detail .spot__transportation__lists .spot__item.spot__rental-car a:before {
  content: "";
  display: inline-block;
  display: block;
  margin: auto;
  background: url(../img/pages/icon_car-rental.svg) no-repeat;
  background-size: 73px 29px;
  width: 73px;
  height: 29px;
}

body.page.detail .spot__transportation__lists .spot__item a {
  text-align: center;
  text-decoration: none;
  display: block;
  border: 2px solid #00729C;
  padding: 1rem .5rem;
  border-radius: 10px;
  height: 100%;
  transition: .2s;
}

body.page.detail .spot__transportation__lists .spot__item a:hover {
  opacity: .7;
}

body.page.detail .spot__transportation__lists .spot__item a span {
  display: block;
}

body.page.detail .spot__transportation__lists .spot__item a span:first-of-type {
  font-weight: bold;
  color: #333;
  margin-top: .5rem;
}

body.page.detail .spot__transportation__lists .spot__item a span:last-of-type.gmap:before {
  content: "";
  display: inline-block;
  background: url(../img/common/icon_googlemap.svg) no-repeat;
  background-size: 18px 18px;
  width: 18px;
  height: 18px;
  vertical-align: middle;
  margin-bottom: 2px;
  margin-right: 5px;
}

@media (min-width: 1025px) {
  body.page.detail .page__intro__wrap:after, body.page.general .page__intro__wrap:after {
    content: "";
    display: block;
    background: url(../img/pages/bg_fish.png) no-repeat;
    background-size: 159px 115px;
    width: 159px;
    height: 115px;
    position: absolute;
    left: 100%;
    bottom: 100px;
    z-index: -1;
  }
}

body.page.detail .page__intro__wrap:before, body.page.general .page__intro__wrap:before {
  content: "";
  display: block;
  background: url(../img/pages/bg_pages_separation.svg) repeat-x;
  background-size: 41px 24px;
  width: 105vw;
  height: 24px;
  position: absolute;
  left: -100%;
  right: -100%;
  bottom: 10px;
  margin: auto;
  z-index: -1;
}

body.page.detail .inquiry__block, body.page.general .inquiry__block {
  padding: 1rem;
  background-color: #e9ecef;
  margin-bottom: 1rem;
}

body.page.detail .inquiry__block .inquiry__title, body.page.general .inquiry__block .inquiry__title {
  font-size: 1.375rem;
  margin-bottom: 0;
}

body.page.detail .inquiry__block ul, body.page.general .inquiry__block ul {
  margin-bottom: 0;
}

body.page.detail .inquiery__aside, body.page.general .inquiery__aside {
  list-style: none;
  padding-left: 0;
}

body.page.detail .how-to-access, body.page.general .how-to-access {
  position: relative;
  margin-bottom: 5rem;
}

body.page.detail .how-to-access:before, body.page.general .how-to-access:before {
  content: "";
  display: block;
  width: 100vw;
  height: 100%;
  position: absolute;
  left: -50%;
  right: -50%;
  z-index: -1;
  margin: auto;
  background-color: #E3EDF4;
}

body.page.detail .how-to-access a, body.page.general .how-to-access a {
  display: block;
  text-decoration: none;
  color: #333;
  padding: 1rem 0;
  transition: .2s;
}

body.page.detail .how-to-access a:hover, body.page.general .how-to-access a:hover {
  opacity: .7;
}

body.page.detail .how-to-access a span, body.page.general .how-to-access a span {
  display: flex;
  align-items: center;
}

body.page.detail .how-to-access a span:before, body.page.general .how-to-access a span:before {
  content: "";
  display: inline-block;
  background: url(../img/common/icon_access.svg) no-repeat;
  background-size: 31px 40px;
  width: 31px;
  height: 40px;
  margin-right: .5rem;
}

body.page.detail .how-to-access a span::after, body.page.general .how-to-access a span::after {
  content: "";
  display: inline-block;
  width: 8px;
  height: 8px;
  border-right: 1px solid #333;
  border-bottom: 1px solid #333;
  transform: rotate(-45deg);
  margin-left: .5rem;
}

body.page.detail .aside__article__title:before, body.page.general .aside__article__title:before {
  content: "";
  background: url(../img/common/icon_article-list01.svg) no-repeat;
  background-size: 19px 23px;
  height: 23px;
  width: 19px;
  vertical-align: bottom;
}

body.page.detail .aside__spot__title:before, body.page.general .aside__spot__title:before {
  content: "";
  background: url(../img/common/icon_neighborhood.svg) no-repeat;
  background-size: 26px 24px;
  width: 26px;
  height: 24px;
  vertical-align: bottom;
}

@media (max-width: 767.98px) {
  body.page.parent_category .page__head {
    text-align: center;
  }
}

@media (max-width: 767.98px) {
  body.page.parent_category .page__head img {
    max-width: 250px;
  }
}

body.page.parent_category .page__intro__wrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  position: relative;
  margin-bottom: 6rem;
  padding-bottom: 0;
}

@media (min-width: 768px) and (max-width: 1024.98px) {
  body.page.parent_category .page__intro__wrap {
    margin-top: 5rem;
    padding-top: 1rem;
    align-items: flex-start;
  }
}

@media (max-width: 767.98px) {
  body.page.parent_category .page__intro__wrap {
    display: block;
    margin-bottom: 2rem;
  }
}

body.page.parent_category .page__intro__wrap:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: -100px;
  width: 741px;
  height: 100px;
  background: url(../img/pages/bg_main-head01.svg) no-repeat;
  background-size: 741px 100px;
}

@media (max-width: 1024.98px) {
  body.page.parent_category .page__intro__wrap:before {
    bottom: 95%;
    top: initial;
  }
}

@media (max-width: 767.98px) {
  body.page.parent_category .page__intro__wrap:before {
    display: none;
  }
}

body.page.parent_category .page__intro__wrap:after {
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  left: -100px;
  width: 1205px;
  height: 100px;
  background: url(../img/pages/bg_main-head02.svg) no-repeat;
  background-size: 1205px 100px;
}

@media (max-width: 767.98px) {
  body.page.parent_category .page__intro__wrap:after {
    display: none;
  }
}

@media (min-width: 768px) {
  body.page.parent_category .page__intro__wrap .page__head {
    width: 35%;
    margin-right: 5%;
  }
}

@media (min-width: 768px) {
  body.page.parent_category .page__intro__wrap .intro {
    width: 60%;
  }
}

body.page.parent_category .page__intro__wrap .intro .intro__head {
  font-family: "游明朝", YuMincho, "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
  font-size: 2.3125rem;
}

@media all and (-ms-high-contrast: none) {
  body.page.parent_category .page__intro__wrap .intro .intro__head {
    font-family: "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
  }
}

@media (max-width: 1024.98px) {
  body.page.parent_category .page__intro__wrap .intro .intro__head {
    font-family: ryo-text-plusn, "游明朝", YuMincho, "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
  }
}

body.page.parent_category .page__intro__wrap .intro .intro__head strong {
  font-weight: normal;
}

body.page.parent_category .page__intro__wrap .intro .intro__content {
  line-height: 2;
}

body.page.parent_category .page__intro__wrap.access {
  margin-bottom: 8rem;
}

@media (max-width: 767.98px) {
  body.page.parent_category .page__intro__wrap.access {
    margin-bottom: 6rem;
  }
}

body.page.fun.none_visual h1, body.page.general.none_visual h1 {
  padding: 1.5rem 0 1.3rem;
  margin-bottom: 0;
}

body.page.fun.none_visual .page-contents, body.page.general.none_visual .page-contents {
  margin-top: 2rem;
}

@media (max-width: 767.98px) {
  body.page.fun.none_visual .page-contents, body.page.general.none_visual .page-contents {
    margin-top: 6rem;
  }
}

body.page.fun.detail h1, body.page.general.detail h1 {
  padding: 1.5rem 0 1.3rem;
  margin-bottom: 0;
}

body.page.fun h2, body.page.general h2 {
  border-left: 5px solid #168836;
}

@media (min-width: 1025px) {
  body.page.fun .page__intro__wrap .intro, body.page.general .page__intro__wrap .intro {
    padding-top: 5rem;
  }
}

body.page.fun .intro__catch-copy, body.page.general .intro__catch-copy {
  font-size: 2rem;
  color: #168836;
  font-family: "游明朝", YuMincho, "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
}

@media (max-width: 767.98px) {
  body.page.fun .intro__catch-copy, body.page.general .intro__catch-copy {
    font-size: 1.25rem;
  }
}

@media all and (-ms-high-contrast: none) {
  body.page.fun .intro__catch-copy, body.page.general .intro__catch-copy {
    font-family: "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
  }
}

body.page.fun .p-important__txt, body.page.general .p-important__txt {
  border: 5px solid #168836;
}

body.page.fun .p-blockquote, body.page.general .p-blockquote {
  border-left: 5px solid #168836;
}

body.page.fun .p-definition__site dt, body.page.general .p-definition__site dt {
  background: rgba(22, 136, 54, 0.4);
}

body.page.fun .pickup__head, body.page.general .pickup__head {
  color: #168836;
}

body.page.visit.none_visual h1 {
  padding: 1.5rem 0 1.3rem;
  margin-bottom: 0;
}

body.page.visit.none_visual .page-contents {
  margin-top: 2rem;
}

@media (max-width: 767.98px) {
  body.page.visit.none_visual .page-contents {
    margin-top: 6rem;
  }
}

body.page.visit.detail h1 {
  padding: 1.5rem 0 1.3rem;
  margin-bottom: 0;
}

body.page.visit h2 {
  border-left: 5px solid #015CAE;
}

@media (min-width: 1025px) {
  body.page.visit .page__intro__wrap .intro {
    padding-top: 5rem;
  }
}

body.page.visit .page__intro__wrap .intro__catch-copy {
  font-size: 2rem;
  color: #015CAE;
  font-family: "游明朝", YuMincho, "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
}

@media (max-width: 767.98px) {
  body.page.visit .page__intro__wrap .intro__catch-copy {
    font-size: 1.25rem;
  }
}

@media all and (-ms-high-contrast: none) {
  body.page.visit .page__intro__wrap .intro__catch-copy {
    font-family: "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
  }
}

body.page.visit .p-important__txt {
  border: 5px solid #015CAE;
}

body.page.visit .p-blockquote {
  border-left: 5px solid #015CAE;
}

body.page.visit .p-definition__site dt {
  background: rgba(1, 92, 174, 0.4);
}

body.page.visit .pickup__head {
  color: #015CAE;
}

@media (max-width: 1024.98px) {
  body.page.visit .piciup__article li:first-of-type {
    text-align: center;
  }
  body.page.visit .piciup__article li:first-of-type a {
    display: inline-block;
  }
}

body.page.know.none_visual h1 {
  padding: 1.5rem 0 1.3rem;
  margin-bottom: 0;
}

body.page.know.none_visual .page-contents {
  margin-top: 2rem;
}

@media (max-width: 767.98px) {
  body.page.know.none_visual .page-contents {
    margin-top: 6rem;
  }
}

body.page.know.detail h1 {
  padding: 1.5rem 0 1.3rem;
  margin-bottom: 0;
}

@media (min-width: 1025px) {
  body.page.know .page__intro__wrap .intro {
    padding-top: 5rem;
  }
}

body.page.know .page__intro__wrap .intro .intro__head {
  font-size: 1.75rem;
}

body.page.know .page__intro__wrap .intro__catch-copy {
  font-size: 2rem;
  color: #E71F26;
  font-family: "游明朝", YuMincho, "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
}

@media (max-width: 767.98px) {
  body.page.know .page__intro__wrap .intro__catch-copy {
    font-size: 1.25rem;
  }
}

@media all and (-ms-high-contrast: none) {
  body.page.know .page__intro__wrap .intro__catch-copy {
    font-family: "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
  }
}

body.page.know h2 {
  border-left: 5px solid #E71F26;
}

body.page.know .p-important__txt {
  border: 5px solid #E71F26;
}

body.page.know .p-blockquote {
  border-left: 5px solid #E71F26;
}

body.page.know .p-definition__site dt {
  background: rgba(231, 31, 38, 0.4);
}

body.page.know .pickup__head {
  color: #E71F26;
}

body.page.access .expantion__btn {
  text-align: right;
  margin-bottom: 0;
  margin-top: 1rem;
}

@media (min-width: 768px) {
  body.page.access .expantion__btn {
    display: none;
  }
}

body.page.access .intro__description {
  margin-bottom: 2.5rem;
}

body.page.access h2 {
  position: relative;
  color: #00729C;
  font-size: 1.5625rem;
  font-weight: bold;
  border-left: none;
  font-family: "游ゴシック", "Yu Gothic", YuGothic, "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo, "ＭＳ ゴシック", "ＭＳ Ｐゴシック", sans-serif;
}

@media all and (-ms-high-contrast: none) {
  body.page.access h2 {
    font-family: "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo, "ＭＳ ゴシック", "ＭＳ Ｐゴシック", sans-serif;
  }
}

body.page.access h2:before {
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #D4D4D4;
}

body.page.access h2:after {
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  width: 30%;
  height: 3px;
  background-color: #00729C;
  z-index: 10;
}

body.page.access .countrywide__wrap,
body.page.access .around__wrap,
body.page.access .sanriku__wrap {
  padding: 1rem 0 3rem;
}

body.page.access .countrywide__wrap,
body.page.access .sanriku__wrap {
  background-color: #E3EDF4;
}

body.page.access .countrywide__wrap h2,
body.page.access .sanriku__wrap h2 {
  color: #227295;
}

body.page.access .countrywide__wrap h2:before,
body.page.access .sanriku__wrap h2:before {
  background-color: #fff;
}

body.page.access .countrywide__wrap .access__nationwide {
  background-color: #fff;
  padding: 1rem .5rem;
  margin-bottom: 3rem;
}

body.page.access .countrywide__wrap h2 span:before {
  content: "";
  display: inline-block;
  background: url(../img/pages/icon_access01.svg) no-repeat;
  background-size: 45px 49px;
  width: 45px;
  height: 49px;
  transform: translateY(5px);
  margin-right: 5px;
}

body.page.access .countrywide__wrap .access__countrywide__tab .nav-tabs li {
  width: calc( 98.5% / 4);
}

body.page.access .countrywide__wrap .access__countrywide__tab .nav-tabs li:not(:last-of-type) {
  margin-right: 0;
}

@media (min-width: 768px) {
  body.page.access .countrywide__wrap .access__countrywide__tab .nav-tabs li:not(:last-of-type) {
    margin-right: .5%;
  }
}

@media (max-width: 767.98px) {
  body.page.access .countrywide__wrap .access__countrywide__tab .nav-tabs li:not(:last-of-type) {
    margin-right: .5%;
  }
}

body.page.access .countrywide__wrap .access__countrywide__tab .nav-tabs li a {
  background-color: #00729C;
  color: #fff;
  font-weight: bold;
  border: none;
  text-align: center;
  padding: 1rem .5rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 767.98px) {
  body.page.access .countrywide__wrap .access__countrywide__tab .nav-tabs li a {
    padding: .5rem .1rem;
    font-size: 0.8125rem;
    height: 100%;
    line-height: 1.4;
  }
}

body.page.access .countrywide__wrap .access__countrywide__tab .nav-tabs li a.active {
  background-color: #fff;
  color: #00729C;
}

body.page.access .countrywide__wrap .access__countrywide__tab .tab-content {
  background: #fff;
  border: none;
  padding: 2rem .5rem;
}

@media (max-width: 767.98px) {
  body.page.access .countrywide__wrap .access__countrywide__tab .tab-content {
    padding: 2rem .5rem .5rem;
  }
}

body.page.access .cities__wrap h2 span:before {
  content: "";
  display: inline-block;
  background: url(../img/pages/icon_access02.svg) no-repeat;
  background-size: 37px 49px;
  width: 37px;
  height: 49px;
  transform: translateY(5px);
  margin-right: 5px;
}

@media (min-width: 1025px) {
  body.page.access .cities__wrap .cities__inner {
    flex-wrap: nowrap;
  }
}

@media (max-width: 1024.98px) {
  body.page.access .cities__wrap .cities__inner {
    margin: 0;
  }
}

@media (min-width: 1025px) {
  body.page.access .cities__wrap .cities__inner .map__block {
    width: 40%;
  }
}

@media (max-width: 1024.98px) {
  body.page.access .cities__wrap .cities__inner .map__block {
    display: none;
  }
}

body.page.access .cities__wrap .cities__inner .links__block {
  margin: 0;
}

@media (min-width: 1025px) {
  body.page.access .cities__wrap .cities__inner .links__block {
    width: 55%;
    margin-left: 5%;
  }
}

body.page.access .cities__wrap .cities__inner .links__block li {
  padding: 0;
}

body.page.access .cities__wrap .cities__inner .links__block li:nth-of-type(odd) {
  padding-right: .5rem;
}

body.page.access .sanriku__wrap h2 span:before {
  content: "";
  display: inline-block;
  background: url(../img/pages/icon_access03.svg) no-repeat;
  background-size: 40px 45px;
  width: 40px;
  height: 45px;
  transform: translateY(5px);
  margin-right: 5px;
}

body.page.access .access__anchor {
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

body.page.access .access__anchor li {
  width: calc((90% - 10%) / 2);
  text-align: center;
  margin-bottom: 0.5rem;
}

@media (min-width: 768px) and (max-width: 1024.98px) {
  body.page.access .access__anchor li {
    width: calc((100% - 5%) / 2);
  }
}

@media (max-width: 767.98px) {
  body.page.access .access__anchor li {
    width: 100%;
    max-width: 300px;
  }
}

@media (min-width: 1025px) {
  body.page.access .access__anchor li:not(:last-of-type) {
    margin-right: 3%;
  }
}

@media (min-width: 768px) and (max-width: 1024.98px) {
  body.page.access .access__anchor li:not(:last-of-type) {
    margin-right: 5%;
  }
}

body.page.access .access__anchor li a {
  font-size: 1.375rem;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 2rem .5rem;
  height: 100%;
  border: 2px solid #00729C;
  border-radius: 15px;
  position: relative;
  font-weight: bold;
  transition: .2s;
}

body.page.access .access__anchor li a:after {
  content: "";
  position: absolute;
  display: block;
  bottom: 10px;
  left: 0;
  right: 0;
  margin: auto;
  width: 20px;
  height: 20px;
  border-bottom: 2px solid #00729C;
  border-left: 2px solid #00729C;
  transform: rotate(-45deg);
}

body.page.access .access__anchor li a:hover img {
  transform: scale(1.1);
}

body.page.access .access__anchor li a span {
  display: block;
  width: 100%;
  padding-top: 0.5rem;
}

body.page.access .around__wrap h2,
body.page.access .around__wrap h3,
body.page.access .around__wrap h4 {
  font-family: "游ゴシック", "Yu Gothic", YuGothic, "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo, "ＭＳ ゴシック", "ＭＳ Ｐゴシック", sans-serif;
}

@media all and (-ms-high-contrast: none) {
  body.page.access .around__wrap h2,
  body.page.access .around__wrap h3,
  body.page.access .around__wrap h4 {
    font-family: "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo, "ＭＳ ゴシック", "ＭＳ Ｐゴシック", sans-serif;
  }
}

body.page.access .around__wrap .around__inner {
  margin: auto;
}

@media (max-width: 767.98px) {
  body.page.access .around__wrap .around__inner {
    padding-right: 0;
    padding-left: 0;
  }
}

body.page.access .around__wrap .around__inner .section-one,
body.page.access .around__wrap .around__inner .section-two {
  width: 100%;
}

body.page.access .around__wrap .around__inner .departure__inner {
  padding: 2rem;
  border: 2px solid #3B9810;
  border-radius: 10px;
  position: relative;
  margin-bottom: 5rem;
}

@media (max-width: 767.98px) {
  body.page.access .around__wrap .around__inner .departure__inner {
    padding: 2rem 1rem;
  }
}

body.page.access .around__wrap .around__inner .departure__inner:after {
  content: "";
  display: block;
  position: absolute;
  bottom: -65px;
  left: 0;
  right: 0;
  margin: auto;
  background: url(../img/pages/icon_under-arrow.svg) no-repeat;
  background-size: 40px 46px;
  width: 40px;
  height: 46px;
}

body.page.access .around__wrap .around__inner .departure__inner .departure__head {
  font-size: 1.5rem;
  color: #3B9810;
  font-weight: bold;
  margin: 0 auto 0;
}

body.page.access .around__wrap .around__inner .departure__inner .departure__head:before {
  background: url(../img/pages/icon_pin01.svg) no-repeat;
  background-size: 16px 23px;
  width: 16px;
  height: 23px;
  margin-right: 0.5rem;
}

body.page.access .around__wrap .around__inner .departure__inner h4 {
  font-size: 1.125rem;
  font-weight: bold;
  border-bottom: 2px solid #3B9810;
  padding-bottom: 0.5rem;
}

@media (max-width: 767.98px) {
  body.page.access .around__wrap .around__inner .departure__inner h4 {
    margin: 1rem 0;
  }
}

body.page.access .around__wrap .around__inner .departure__inner .departure__lists {
  padding-left: 0;
  margin-bottom: 0;
}

body.page.access .around__wrap .around__inner .departure__inner .departure__lists label {
  margin-bottom: 0;
}

body.page.access .around__wrap .around__inner .departure__inner .departure__lists label.departure {
  position: relative;
  cursor: pointer;
}

body.page.access .around__wrap .around__inner .departure__inner .departure__lists label.departure span {
  position: relative;
  display: flex;
  align-items: center;
  padding: 5px 5px 5px 30px;
}

body.page.access .around__wrap .around__inner .departure__inner .departure__lists label.departure span:before {
  content: "";
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto auto auto 5px;
  width: 18px;
  height: 18px;
  border: 1px solid #6c757d;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 50%;
}

body.page.access .around__wrap .around__inner .departure__inner .departure__lists label.departure span:after {
  content: "";
  display: inline-block;
  position: absolute;
  left: 3px;
  top: 0;
  bottom: 0;
  width: 12px;
  height: 12px;
  margin: auto auto auto 5px;
  background-color: #00729C;
  border-radius: 50%;
  opacity: 0;
  visibility: hidden;
  transform: scale(0.2);
  transition: .15s;
}

@media print {
  body.page.access .around__wrap .around__inner .departure__inner .departure__lists label.departure span:after {
    opacity: 1;
  }
}

body.page.access .around__wrap .around__inner .departure__inner .departure__lists label.departure input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

@media print {
  body.page.access .around__wrap .around__inner .departure__inner .departure__lists label.departure input {
    opacity: 1;
  }
}

body.page.access .around__wrap .around__inner .departure__inner .departure__lists label.departure input.radio__item:checked + span {
  color: #00729C;
}

body.page.access .around__wrap .around__inner .departure__inner .departure__lists label.departure input.radio__item:checked + span:after {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}

body.page.access .around__wrap .around__inner .departure__inner .departure__lists label.departure input.radio__item:focus + span {
  outline: 3px solid #adb5bd;
}

body.page.access .around__wrap .around__inner .destination__inner {
  padding: 2rem;
  border: 2px solid #BB0900;
  border-radius: 10px;
  margin-bottom: 3rem;
}

body.page.access .around__wrap .around__inner .destination__inner .destination__head {
  font-size: 1.5rem;
  color: #BB0900;
  font-weight: bold;
  margin: 0 auto 0;
}

body.page.access .around__wrap .around__inner .destination__inner .destination__head:before {
  background: url(../img/pages/icon_pin02.svg) no-repeat;
  background-size: 16px 23px;
  width: 16px;
  height: 23px;
  margin-right: 0.5rem;
}

body.page.access .around__wrap .around__inner .destination__inner h4 {
  font-size: 1.125rem;
  font-weight: bold;
  border-bottom: 2px solid #BB0900;
  padding-bottom: 0.5rem;
}

@media (max-width: 767.98px) {
  body.page.access .around__wrap .around__inner .destination__inner h4 {
    margin: 1rem 0;
  }
}

body.page.access .around__wrap .around__inner .destination__inner .destination__lists {
  padding-left: 0;
  margin-bottom: 0;
}

body.page.access .around__wrap .around__inner .destination__inner .destination__lists label {
  margin-bottom: 0;
}

body.page.access .around__wrap .around__inner .destination__inner .destination__lists label.destination {
  position: relative;
  cursor: pointer;
}

body.page.access .around__wrap .around__inner .destination__inner .destination__lists label.destination span {
  position: relative;
  display: flex;
  align-items: center;
  padding: 5px 5px 5px 30px;
}

body.page.access .around__wrap .around__inner .destination__inner .destination__lists label.destination span:before {
  content: "";
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto auto auto 5px;
  width: 18px;
  height: 18px;
  border: 1px solid #6c757d;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 50%;
}

body.page.access .around__wrap .around__inner .destination__inner .destination__lists label.destination span:after {
  content: "";
  display: inline-block;
  position: absolute;
  left: 3px;
  top: 0;
  bottom: 0;
  width: 12px;
  height: 12px;
  margin: auto auto auto 5px;
  background-color: #00729C;
  border-radius: 50%;
  opacity: 0;
  visibility: hidden;
  transform: scale(0.2);
  transition: .15s;
}

@media print {
  body.page.access .around__wrap .around__inner .destination__inner .destination__lists label.destination span:after {
    opacity: 1;
  }
}

body.page.access .around__wrap .around__inner .destination__inner .destination__lists label.destination input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

@media print {
  body.page.access .around__wrap .around__inner .destination__inner .destination__lists label.destination input {
    opacity: 1;
  }
}

body.page.access .around__wrap .around__inner .destination__inner .destination__lists label.destination input.radio__item:checked + span {
  color: #00729C;
}

body.page.access .around__wrap .around__inner .destination__inner .destination__lists label.destination input.radio__item:checked + span:after {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}

body.page.access .around__wrap .around__inner .destination__inner .destination__lists label.destination input.radio__item:focus + span {
  outline: 3px solid #adb5bd;
}

body.page.access .around__wrap .around__inner .section-three {
  display: none;
  justify-content: space-between;
  width: 100%;
}

body.page.access .around__wrap .around__inner .section-three.is_active {
  display: flex;
}

@media (max-width: 1024.98px) {
  body.page.access .around__wrap .around__inner .section-three {
    flex-wrap: wrap;
  }
}

body.page.access .around__wrap .around__inner .section-three .to-station__inner {
  width: 77%;
  margin-right: 2%;
}

@media (max-width: 1024.98px) {
  body.page.access .around__wrap .around__inner .section-three .to-station__inner {
    width: 100%;
    margin-right: 0;
  }
}

body.page.access .around__wrap .around__inner .section-three .to-station__inner ul {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
}

body.page.access .around__wrap .around__inner .section-three .to-station__inner .root__flow {
  display: flex;
  justify-content: space-between;
  position: relative;
}

body.page.access .around__wrap .around__inner .section-three .to-station__inner .root__flow .start__point,
body.page.access .around__wrap .around__inner .section-three .to-station__inner .root__flow .end__point {
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-lr;
  color: #fff;
  letter-spacing: 5px;
  font-weight: bold;
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 300px;
  font-size: 1.25rem;
  padding: 0 5px;
  margin: 0;
}

body.page.access .around__wrap .around__inner .section-three .to-station__inner .root__flow .start__point span,
body.page.access .around__wrap .around__inner .section-three .to-station__inner .root__flow .end__point span {
  position: relative;
}

body.page.access .around__wrap .around__inner .section-three .to-station__inner .root__flow .start__point span:before,
body.page.access .around__wrap .around__inner .section-three .to-station__inner .root__flow .end__point span:before {
  content: "";
  display: inline-block;
  position: absolute;
  bottom: 110%;
  left: 0;
  right: 0;
  margin: auto;
  background: url(../img/pages/icon_pin03.svg) no-repeat;
  background-size: 16px 23px;
  width: 16px;
  height: 23px;
}

body.page.access .around__wrap .around__inner .section-three .to-station__inner .root__flow .start__point {
  background-color: #3B9810;
}

body.page.access .around__wrap .around__inner .section-three .to-station__inner .root__flow .end__point {
  background-color: #BB0900;
}

body.page.access .around__wrap .around__inner .section-three .to-station__inner .root__flow .root__flow__inner {
  display: flex;
  width: 100%;
  overflow: scroll;
}

body.page.access .around__wrap .around__inner .section-three .to-station__inner .root__flow .root__flow__inner .transportation__car,
body.page.access .around__wrap .around__inner .section-three .to-station__inner .root__flow .root__flow__inner .transportation__bus,
body.page.access .around__wrap .around__inner .section-three .to-station__inner .root__flow .root__flow__inner .transportation__train {
  font-weight: bold;
}

body.page.access .around__wrap .around__inner .section-three .to-station__inner .root__flow .root__flow__inner .transportation__car:before,
body.page.access .around__wrap .around__inner .section-three .to-station__inner .root__flow .root__flow__inner .transportation__bus:before,
body.page.access .around__wrap .around__inner .section-three .to-station__inner .root__flow .root__flow__inner .transportation__train:before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 4px;
  vertical-align: middle;
  margin-right: 5px;
  margin-bottom: 2px;
  background-color: currentColor;
}

body.page.access .around__wrap .around__inner .section-three .to-station__inner .root__flow .root__flow__inner .transportation__car {
  color: #E23000;
}

body.page.access .around__wrap .around__inner .section-three .to-station__inner .root__flow .root__flow__inner .transportation__bus {
  color: #3B9810;
}

body.page.access .around__wrap .around__inner .section-three .to-station__inner .root__flow .root__flow__inner .transportation__train {
  color: #015CAE;
}

body.page.access .around__wrap .around__inner .section-three .to-station__inner .root__flow .root__flow__inner .time {
  font-weight: bold;
}

body.page.access .around__wrap .around__inner .section-three .to-station__inner .root__flow .root__flow__inner .root__flow__sections {
  width: 100%;
  min-width: 400px;
  display: flex;
  align-items: center;
}

body.page.access .around__wrap .around__inner .section-three .to-station__inner .root__flow .root__flow__inner .root__flow__sections .flow__block {
  margin-bottom: 4rem;
}

body.page.access .around__wrap .around__inner .section-three .to-station__inner .root__flow .root__flow__inner .root__flow__sections .flow__block .transportation__parent {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0;
  margin-left: 1rem;
}

body.page.access .around__wrap .around__inner .section-three .to-station__inner .root__flow .root__flow__inner .root__flow__sections .flow__block .transportation__detail {
  display: flex;
}

body.page.access .around__wrap .around__inner .section-three .to-station__inner .root__flow .root__flow__inner .root__flow__sections .flow__block .transportation__detail li {
  width: 100%;
}

body.page.access .around__wrap .around__inner .section-three .to-station__inner .root__flow .root__flow__inner .root__flow__sections .flow__block .transportation__detail li:first-of-type {
  padding-left: 1rem;
}

body.page.access .around__wrap .around__inner .section-three .to-station__inner .root__flow .root__flow__inner .root__flow__sections .flow__block .transportation__detail li:not(:last-of-type) {
  padding-right: 1rem;
}

body.page.access .around__wrap .around__inner .section-three .to-station__inner .root__flow .root__flow__inner .root__flow__sections .flow__block .transportation__detail--car li {
  position: relative;
}

body.page.access .around__wrap .around__inner .section-three .to-station__inner .root__flow .root__flow__inner .root__flow__sections .flow__block .transportation__detail--car li:after {
  content: "";
  display: block;
  position: absolute;
  bottom: -45px;
  left: 0;
  width: 100%;
  height: 20px;
  background-color: #F9D398;
}

body.page.access .around__wrap .around__inner .section-three .to-station__inner .root__flow .root__flow__inner .root__flow__sections .flow__block .transportation__detail--car li:before {
  content: "";
  display: block;
  position: absolute;
  bottom: -60px;
  right: 0;
  z-index: 1;
  border-top: 25px solid #fff;
  border-bottom: 25px solid #fff;
  border-left: 30px solid #F9D398;
}

body.page.access .around__wrap .around__inner .section-three .to-station__inner .root__flow .root__flow__inner .root__flow__sections .flow__block .transportation__detail--car li .flow__icon {
  position: absolute;
  bottom: -40px;
  left: 20px;
  z-index: 1;
  background: url(../img/pages/icon_car-rental.svg) no-repeat;
  background-size: 60px 24px;
  width: 60px;
  height: 24px;
}

body.page.access .around__wrap .around__inner .section-three .to-station__inner .root__flow .root__flow__inner .root__flow__sections .flow__block .transportation__detail--bus li {
  position: relative;
}

body.page.access .around__wrap .around__inner .section-three .to-station__inner .root__flow .root__flow__inner .root__flow__sections .flow__block .transportation__detail--bus li:after {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  bottom: -45px;
  left: 0;
  width: 100%;
  height: 20px;
  background-color: #C7EDB8;
}

body.page.access .around__wrap .around__inner .section-three .to-station__inner .root__flow .root__flow__inner .root__flow__sections .flow__block .transportation__detail--bus li:before {
  content: "";
  display: block;
  position: absolute;
  bottom: -60px;
  right: 0;
  border-top: 25px solid #fff;
  border-bottom: 25px solid #fff;
  border-left: 30px solid #C7EDB8;
}

body.page.access .around__wrap .around__inner .section-three .to-station__inner .root__flow .root__flow__inner .root__flow__sections .flow__block .transportation__detail--bus li .flow__icon {
  position: absolute;
  bottom: -40px;
  left: 20px;
  background: url(../img/pages/icon_bus.svg) no-repeat;
  background-size: 76px 30px;
  width: 76px;
  height: 30px;
}

body.page.access .around__wrap .around__inner .section-three .to-station__inner .root__flow .root__flow__inner .root__flow__sections .flow__block .transportation__detail--train li {
  position: relative;
}

body.page.access .around__wrap .around__inner .section-three .to-station__inner .root__flow .root__flow__inner .root__flow__sections .flow__block .transportation__detail--train li:after {
  content: "";
  display: block;
  position: absolute;
  bottom: -45px;
  left: 0;
  width: 100%;
  height: 20px;
  background-color: #A9D3EA;
}

body.page.access .around__wrap .around__inner .section-three .to-station__inner .root__flow .root__flow__inner .root__flow__sections .flow__block .transportation__detail--train li:before {
  content: "";
  display: block;
  position: absolute;
  bottom: -60px;
  right: 0;
  z-index: 1;
  border-top: 25px solid #fff;
  border-bottom: 25px solid #fff;
  border-left: 30px solid #A9D3EA;
}

body.page.access .around__wrap .around__inner .section-three .to-station__inner .root__flow .root__flow__inner .root__flow__sections .flow__block .transportation__detail--train li .flow__icon {
  position: absolute;
  bottom: -40px;
  left: 20px;
  z-index: 1;
  background: url(../img/pages/icon_train.svg) no-repeat;
  background-size: 76px 30px;
  width: 76px;
  height: 30px;
}

body.page.access .around__wrap .around__inner .section-three .to-station__inner .root__detail {
  margin-top: 1rem;
}

@media (max-width: 1024.98px) {
  body.page.access .around__wrap .around__inner .section-three .to-station__inner .root__detail {
    margin-bottom: 1rem;
  }
}

body.page.access .around__wrap .around__inner .section-three .to-station__inner .root__detail .transportation__car,
body.page.access .around__wrap .around__inner .section-three .to-station__inner .root__detail .transportation__bus,
body.page.access .around__wrap .around__inner .section-three .to-station__inner .root__detail .transportation__train,
body.page.access .around__wrap .around__inner .section-three .to-station__inner .root__detail .transportation__ferry {
  font-weight: bold;
}

body.page.access .around__wrap .around__inner .section-three .to-station__inner .root__detail .transportation__car:before,
body.page.access .around__wrap .around__inner .section-three .to-station__inner .root__detail .transportation__bus:before,
body.page.access .around__wrap .around__inner .section-three .to-station__inner .root__detail .transportation__train:before,
body.page.access .around__wrap .around__inner .section-three .to-station__inner .root__detail .transportation__ferry:before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 4px;
  vertical-align: middle;
  margin-right: 5px;
  margin-bottom: 2px;
  background-color: currentColor;
}

body.page.access .around__wrap .around__inner .section-three .to-station__inner .root__detail .transportation__car {
  color: #E23000;
}

body.page.access .around__wrap .around__inner .section-three .to-station__inner .root__detail .transportation__bus {
  color: #3B9810;
}

body.page.access .around__wrap .around__inner .section-three .to-station__inner .root__detail .transportation__train {
  color: #015CAE;
}

body.page.access .around__wrap .around__inner .section-three .to-station__inner .root__detail .transportation__ferry {
  color: #7d00fd;
}

body.page.access .around__wrap .around__inner .section-three .to-station__inner .root__detail .detail__lists .detail__item {
  padding: 1rem .5rem;
  border-bottom: 1px solid #adb5bd;
  line-height: 2;
}

body.page.access .around__wrap .around__inner .section-three .to-station__inner .root__detail .detail__lists .detail__item:first-of-type {
  border-top: 1px solid #adb5bd;
}

body.page.access .around__wrap .around__inner .section-three .to-station__inner .root__detail .detail__lists .detail__item ul {
  letter-spacing: -.4em;
}

body.page.access .around__wrap .around__inner .section-three .to-station__inner .root__detail .detail__lists .detail__item ul li {
  margin-right: 1rem;
  letter-spacing: normal;
  display: inline-block;
}

body.page.access .around__wrap .around__inner .section-three .to-station__inner .root__detail .detail__lists .detail__item ul li a {
  display: block;
  text-decoration: underline;
}

body.page.access .around__wrap .around__inner .section-three .root__map {
  width: 21%;
}

@media (max-width: 1024.98px) {
  body.page.access .around__wrap .around__inner .section-three .root__map {
    width: 100%;
  }
}

body.page.access .around__wrap .around__inner .section-three .root__map .root__img {
  text-align: center;
}

body.page.access .around__wrap .around__inner .section-three .root__map .root__spot__link {
  text-align: center;
}

body.page.access .around__wrap .around__inner .section-three .root__map .root__spot__link a {
  position: relative;
  color: #BB0900;
  text-decoration: none;
  display: block;
  border: 2px solid #BB0900;
  padding: 1rem .5rem 1.5rem;
  border-radius: 10px;
  font-weight: bold;
}

body.page.access .around__wrap .around__inner .section-three .root__map .root__spot__link a:after {
  content: "";
  display: inline-block;
  width: 15px;
  height: 15px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 10px;
  margin: auto;
  border-bottom: 2px solid #BB0900;
  border-right: 2px solid #BB0900;
  transform: rotate(45deg);
}

body.page.access_detail .access__spot__map {
  text-align: center;
}

body.page.access_detail .expantion__btn {
  text-align: right;
  margin-bottom: 0;
  margin-top: 1rem;
}

@media (min-width: 768px) {
  body.page.access_detail .expantion__btn {
    display: none;
  }
}

body.page.access_detail .intro__description {
  margin-bottom: 2.5rem;
}

body.page.access_detail .asides .aside__spot__title {
  padding-left: 0;
}

body.page.access_detail .asides .aside__spot__title:before {
  content: "";
  display: inline-block;
  background: url(../img/common/icon_neighborhood.svg) no-repeat;
  background-size: 26px 24px;
  width: 26px;
  height: 24px;
  vertical-align: bottom;
  margin-right: 1rem;
}

body.page.access_detail .asides .other__spot li {
  padding: 0;
}

body.page.access_detail .asides .other__spot li:nth-of-type(odd) {
  padding-right: 2rem;
}

body.page.access_detail .asides .other__spot li a:after {
  content: "";
  display: inline-block;
  background: url(../img/access/icon_access_external-link.svg) no-repeat;
  background-size: 14px 14px;
  width: 14px;
  height: 14px;
  margin-left: 0.3em;
}

body.page.access_detail .asides .asides__head {
  border-left: 5px solid #00729C;
}

body.page.access_detail .asides .other__access .general__link {
  display: flex;
  flex-wrap: wrap;
}

@media (min-width: 768px) {
  body.page.access_detail .asides .other__access .general__link li {
    width: calc(96% / 5);
  }
  body.page.access_detail .asides .other__access .general__link li:not(:nth-of-type(5n)) {
    margin-right: 1%;
  }
}

@media (max-width: 767.98px) {
  body.page.access_detail .asides .other__access .general__link li {
    width: calc(98% / 2);
  }
  body.page.access_detail .asides .other__access .general__link li:nth-of-type(odd) {
    margin-right: 2%;
  }
}

body.page.access_detail .asides .other__access .access__back-btn {
  text-align: center;
  margin: 3rem 0;
}

body.page.access_detail .asides .other__access .access__back-btn p {
  display: inline-block;
}

body.page.access_detail .asides .other__access .access__back-btn p a {
  padding: 0.8rem 1.5rem;
  display: block;
  text-decoration: none;
  color: #00729C;
  font-weight: bold;
  background-color: #E3EDF4;
  border-radius: 30px;
  transition: .2s;
}

body.page.access_detail .asides .other__access .access__back-btn p a:hover {
  background-color: #bed5e6;
}

body.page.access_detail .countrywide__wrap,
body.page.access_detail .around__wrap,
body.page.access_detail .sanriku__wrap {
  padding: 1rem 0 3rem;
}

body.page.access_detail .countrywide__wrap,
body.page.access_detail .sanriku__wrap {
  background-color: #E3EDF4;
}

body.page.access_detail .countrywide__wrap {
  padding: 5rem 0;
}

body.page.access_detail .countrywide__wrap h2 {
  border-left: 5px solid #00729C;
}

body.page.access_detail .countrywide__wrap .access__nationwide {
  background-color: #fff;
  padding: 1rem .5rem;
  margin-bottom: 3rem;
}

body.page.access_detail .countrywide__wrap .access__countrywide__tab {
  margin-bottom: 0;
}

body.page.access_detail .countrywide__wrap .access__countrywide__tab .nav-tabs li {
  width: calc( 99% / 3);
}

@media (min-width: 768px) {
  body.page.access_detail .countrywide__wrap .access__countrywide__tab .nav-tabs li:not(:last-of-type) {
    margin-right: .5%;
  }
}

@media (max-width: 767.98px) {
  body.page.access_detail .countrywide__wrap .access__countrywide__tab .nav-tabs li:not(:last-of-type) {
    margin-right: 1px;
  }
}

body.page.access_detail .countrywide__wrap .access__countrywide__tab .nav-tabs li a {
  background-color: #00729C;
  color: #fff;
  font-weight: bold;
  border: none;
  text-align: center;
  padding: 1rem .5rem;
}

@media (max-width: 767.98px) {
  body.page.access_detail .countrywide__wrap .access__countrywide__tab .nav-tabs li a {
    padding: .5rem .1rem;
    font-size: 0.8125rem;
  }
}

body.page.access_detail .countrywide__wrap .access__countrywide__tab .nav-tabs li a.active {
  background-color: #fff;
  color: #00729C;
}

body.page.access_detail .countrywide__wrap .access__countrywide__tab .tab-content {
  background: #fff;
  border: none;
  padding: 2rem .5rem;
}

@media (max-width: 767.98px) {
  body.page.access_detail .countrywide__wrap .access__countrywide__tab .tab-content {
    padding: 2rem .5rem .5rem;
  }
}

body.page.access_detail .sanriku__wrap h2 span:before {
  content: "";
  display: inline-block;
  background: url(../img/pages/icon_access03.svg) no-repeat;
  background-size: 40px 45px;
  width: 40px;
  height: 45px;
  transform: translateY(5px);
  margin-right: 5px;
}

body.page.access_detail .access__anchor {
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

body.page.access_detail .access__anchor li {
  width: calc((90% - 10%) / 2);
  text-align: center;
  margin-bottom: 0.5rem;
}

@media (min-width: 768px) and (max-width: 1024.98px) {
  body.page.access_detail .access__anchor li {
    width: calc((100% - 5%) / 2);
  }
}

@media (max-width: 767.98px) {
  body.page.access_detail .access__anchor li {
    width: 100%;
    max-width: 300px;
  }
}

@media (min-width: 1025px) {
  body.page.access_detail .access__anchor li:not(:last-of-type) {
    margin-right: 3%;
  }
}

@media (min-width: 768px) and (max-width: 1024.98px) {
  body.page.access_detail .access__anchor li:not(:last-of-type) {
    margin-right: 5%;
  }
}

body.page.access_detail .access__anchor li a {
  font-size: 1.375rem;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 2rem .5rem;
  height: 100%;
  border: 2px solid #00729C;
  border-radius: 15px;
  position: relative;
  font-weight: bold;
  transition: .2s;
}

body.page.access_detail .access__anchor li a:after {
  content: "";
  position: absolute;
  display: block;
  bottom: 10px;
  left: 0;
  right: 0;
  margin: auto;
  width: 20px;
  height: 20px;
  border-bottom: 2px solid #00729C;
  border-left: 2px solid #00729C;
  transform: rotate(-45deg);
}

body.page.access_detail .access__anchor li a span {
  display: block;
  width: 100%;
  padding-top: 0.5rem;
}

body.page.access_detail .around__wrap h2,
body.page.access_detail .around__wrap h3,
body.page.access_detail .around__wrap h4 {
  font-family: "游ゴシック", "Yu Gothic", YuGothic, "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo, "ＭＳ ゴシック", "ＭＳ Ｐゴシック", sans-serif;
}

@media all and (-ms-high-contrast: none) {
  body.page.access_detail .around__wrap h2,
  body.page.access_detail .around__wrap h3,
  body.page.access_detail .around__wrap h4 {
    font-family: "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo, "ＭＳ ゴシック", "ＭＳ Ｐゴシック", sans-serif;
  }
}

body.page.access_detail .around__wrap .around__inner {
  margin: auto;
}

@media (max-width: 767.98px) {
  body.page.access_detail .around__wrap .around__inner {
    padding-right: 0;
    padding-left: 0;
  }
}

body.page.access_detail .around__wrap .around__inner .section-one,
body.page.access_detail .around__wrap .around__inner .section-two {
  width: 100%;
}

body.page.access_detail .around__wrap .around__inner .departure__inner {
  padding: 2rem;
  border: 2px solid #3B9810;
  border-radius: 10px;
  position: relative;
  margin-bottom: 5rem;
}

@media (max-width: 767.98px) {
  body.page.access_detail .around__wrap .around__inner .departure__inner {
    padding: 2rem 1rem;
  }
}

body.page.access_detail .around__wrap .around__inner .departure__inner:after {
  content: "";
  display: block;
  position: absolute;
  bottom: -65px;
  left: 0;
  right: 0;
  margin: auto;
  background: url(../img/pages/icon_under-arrow.svg) no-repeat;
  background-size: 40px 46px;
  width: 40px;
  height: 46px;
}

body.page.access_detail .around__wrap .around__inner .departure__inner .departure__head {
  font-size: 1.5rem;
  color: #3B9810;
  font-weight: bold;
  margin: 0 auto 0;
}

body.page.access_detail .around__wrap .around__inner .departure__inner .departure__head:before {
  background: url(../img/pages/icon_pin01.svg) no-repeat;
  background-size: 16px 23px;
  width: 16px;
  height: 23px;
  margin-right: 0.5rem;
}

body.page.access_detail .around__wrap .around__inner .departure__inner h4 {
  font-size: 1.125rem;
  font-weight: bold;
  border-bottom: 2px solid #3B9810;
  padding-bottom: 0.5rem;
}

@media (max-width: 767.98px) {
  body.page.access_detail .around__wrap .around__inner .departure__inner h4 {
    margin: 1rem 0;
  }
}

body.page.access_detail .around__wrap .around__inner .destination__inner {
  padding: 2rem;
  border: 2px solid #BB0900;
  border-radius: 10px;
  margin-bottom: 3rem;
}

body.page.access_detail .around__wrap .around__inner .destination__inner .destination__head {
  font-size: 1.5rem;
  color: #BB0900;
  font-weight: bold;
  margin: 0 auto 0;
}

body.page.access_detail .around__wrap .around__inner .destination__inner .destination__head:before {
  background: url(../img/pages/icon_pin02.svg) no-repeat;
  background-size: 16px 23px;
  width: 16px;
  height: 23px;
  margin-right: 0.5rem;
}

body.page.access_detail .around__wrap .around__inner .destination__inner h4 {
  font-size: 1.125rem;
  font-weight: bold;
  border-bottom: 2px solid #BB0900;
  padding-bottom: 0.5rem;
}

@media (max-width: 767.98px) {
  body.page.access_detail .around__wrap .around__inner .destination__inner h4 {
    margin: 1rem 0;
  }
}

body.page.access_detail .around__wrap .around__inner .destination__inner .destination__lists {
  padding-left: 0;
  margin-bottom: 0;
}

body.page.access_detail .around__wrap .around__inner .destination__inner .destination__lists label {
  margin-bottom: 0;
}

body.page.access_detail .around__wrap .around__inner .destination__inner .destination__lists label.destination {
  position: relative;
  cursor: pointer;
}

body.page.access_detail .around__wrap .around__inner .destination__inner .destination__lists label.destination span {
  position: relative;
  display: flex;
  align-items: center;
  padding: 5px 5px 5px 30px;
}

body.page.access_detail .around__wrap .around__inner .destination__inner .destination__lists label.destination span:before {
  content: "";
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto auto auto 5px;
  width: 18px;
  height: 18px;
  border: 1px solid #6c757d;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 50%;
}

body.page.access_detail .around__wrap .around__inner .destination__inner .destination__lists label.destination span:after {
  content: "";
  display: inline-block;
  position: absolute;
  left: 3px;
  top: 0;
  bottom: 0;
  width: 12px;
  height: 12px;
  margin: auto auto auto 5px;
  background-color: #00729C;
  border-radius: 50%;
  opacity: 0;
  visibility: hidden;
  transform: scale(0.2);
  transition: .15s;
}

@media print {
  body.page.access_detail .around__wrap .around__inner .destination__inner .destination__lists label.destination span:after {
    opacity: 1;
  }
}

body.page.access_detail .around__wrap .around__inner .section-three {
  display: none;
  justify-content: space-between;
  width: 100%;
}

body.page.access_detail .around__wrap .around__inner .section-three.is_active {
  display: flex;
}

@media (max-width: 1024.98px) {
  body.page.access_detail .around__wrap .around__inner .section-three {
    flex-wrap: wrap;
  }
}

body.page.access_detail .around__wrap .around__inner .section-three .to-station__inner {
  width: 77%;
  margin-right: 2%;
}

@media (max-width: 1024.98px) {
  body.page.access_detail .around__wrap .around__inner .section-three .to-station__inner {
    width: 100%;
    margin-right: 0;
  }
}

body.page.access_detail .around__wrap .around__inner .section-three .to-station__inner ul {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
}

body.page.access_detail .around__wrap .around__inner .section-three .to-station__inner .root__flow {
  display: flex;
  justify-content: space-between;
  position: relative;
}

body.page.access_detail .around__wrap .around__inner .section-three .to-station__inner .root__flow .start__point,
body.page.access_detail .around__wrap .around__inner .section-three .to-station__inner .root__flow .end__point {
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-lr;
  color: #fff;
  letter-spacing: 5px;
  font-weight: bold;
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 300px;
  font-size: 1.25rem;
  padding: 0 5px;
  margin: 0;
}

body.page.access_detail .around__wrap .around__inner .section-three .to-station__inner .root__flow .start__point span,
body.page.access_detail .around__wrap .around__inner .section-three .to-station__inner .root__flow .end__point span {
  position: relative;
}

body.page.access_detail .around__wrap .around__inner .section-three .to-station__inner .root__flow .start__point span:before,
body.page.access_detail .around__wrap .around__inner .section-three .to-station__inner .root__flow .end__point span:before {
  content: "";
  display: inline-block;
  position: absolute;
  bottom: 110%;
  left: 0;
  right: 0;
  margin: auto;
  background: url(../img/pages/icon_pin03.svg) no-repeat;
  background-size: 16px 23px;
  width: 16px;
  height: 23px;
}

body.page.access_detail .around__wrap .around__inner .section-three .to-station__inner .root__flow .start__point {
  background-color: #3B9810;
}

body.page.access_detail .around__wrap .around__inner .section-three .to-station__inner .root__flow .end__point {
  background-color: #BB0900;
}

body.page.access_detail .around__wrap .around__inner .section-three .to-station__inner .root__flow .root__flow__inner {
  display: flex;
  width: 100%;
  overflow: scroll;
}

body.page.access_detail .around__wrap .around__inner .section-three .to-station__inner .root__flow .root__flow__inner .transportation__car,
body.page.access_detail .around__wrap .around__inner .section-three .to-station__inner .root__flow .root__flow__inner .transportation__bus,
body.page.access_detail .around__wrap .around__inner .section-three .to-station__inner .root__flow .root__flow__inner .transportation__train {
  font-weight: bold;
}

body.page.access_detail .around__wrap .around__inner .section-three .to-station__inner .root__flow .root__flow__inner .transportation__car:before,
body.page.access_detail .around__wrap .around__inner .section-three .to-station__inner .root__flow .root__flow__inner .transportation__bus:before,
body.page.access_detail .around__wrap .around__inner .section-three .to-station__inner .root__flow .root__flow__inner .transportation__train:before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 4px;
  vertical-align: middle;
  margin-right: 5px;
  margin-bottom: 2px;
  background-color: currentColor;
}

body.page.access_detail .around__wrap .around__inner .section-three .to-station__inner .root__flow .root__flow__inner .transportation__car {
  color: #E23000;
}

body.page.access_detail .around__wrap .around__inner .section-three .to-station__inner .root__flow .root__flow__inner .transportation__bus {
  color: #3B9810;
}

body.page.access_detail .around__wrap .around__inner .section-three .to-station__inner .root__flow .root__flow__inner .transportation__train {
  color: #015CAE;
}

body.page.access_detail .around__wrap .around__inner .section-three .to-station__inner .root__flow .root__flow__inner .time {
  font-weight: bold;
}

body.page.access_detail .around__wrap .around__inner .section-three .to-station__inner .root__flow .root__flow__inner .root__flow__sections {
  width: 100%;
  min-width: 400px;
  display: flex;
  align-items: center;
}

body.page.access_detail .around__wrap .around__inner .section-three .to-station__inner .root__flow .root__flow__inner .root__flow__sections .flow__block {
  margin-bottom: 4rem;
}

body.page.access_detail .around__wrap .around__inner .section-three .to-station__inner .root__flow .root__flow__inner .root__flow__sections .flow__block .transportation__parent {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0;
  margin-left: 1rem;
}

body.page.access_detail .around__wrap .around__inner .section-three .to-station__inner .root__flow .root__flow__inner .root__flow__sections .flow__block .transportation__detail {
  display: flex;
}

body.page.access_detail .around__wrap .around__inner .section-three .to-station__inner .root__flow .root__flow__inner .root__flow__sections .flow__block .transportation__detail li {
  width: 100%;
}

body.page.access_detail .around__wrap .around__inner .section-three .to-station__inner .root__flow .root__flow__inner .root__flow__sections .flow__block .transportation__detail li:first-of-type {
  padding-left: 1rem;
}

body.page.access_detail .around__wrap .around__inner .section-three .to-station__inner .root__flow .root__flow__inner .root__flow__sections .flow__block .transportation__detail li:not(:last-of-type) {
  padding-right: 1rem;
}

body.page.access_detail .around__wrap .around__inner .section-three .to-station__inner .root__flow .root__flow__inner .root__flow__sections .flow__block .transportation__detail--car li {
  position: relative;
}

body.page.access_detail .around__wrap .around__inner .section-three .to-station__inner .root__flow .root__flow__inner .root__flow__sections .flow__block .transportation__detail--car li:after {
  content: "";
  display: block;
  position: absolute;
  bottom: -45px;
  left: 0;
  width: 100%;
  height: 20px;
  background-color: #F9D398;
}

body.page.access_detail .around__wrap .around__inner .section-three .to-station__inner .root__flow .root__flow__inner .root__flow__sections .flow__block .transportation__detail--car li:before {
  content: "";
  display: block;
  position: absolute;
  bottom: -60px;
  right: 0;
  z-index: 1;
  border-top: 25px solid #fff;
  border-bottom: 25px solid #fff;
  border-left: 30px solid #F9D398;
}

body.page.access_detail .around__wrap .around__inner .section-three .to-station__inner .root__flow .root__flow__inner .root__flow__sections .flow__block .transportation__detail--car li .flow__icon {
  position: absolute;
  bottom: -40px;
  left: 20px;
  z-index: 1;
  background: url(../img/pages/icon_car-rental.svg) no-repeat;
  background-size: 60px 24px;
  width: 60px;
  height: 24px;
}

body.page.access_detail .around__wrap .around__inner .section-three .to-station__inner .root__flow .root__flow__inner .root__flow__sections .flow__block .transportation__detail--bus li {
  position: relative;
}

body.page.access_detail .around__wrap .around__inner .section-three .to-station__inner .root__flow .root__flow__inner .root__flow__sections .flow__block .transportation__detail--bus li:after {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  bottom: -45px;
  left: 0;
  width: 100%;
  height: 20px;
  background-color: #C7EDB8;
}

body.page.access_detail .around__wrap .around__inner .section-three .to-station__inner .root__flow .root__flow__inner .root__flow__sections .flow__block .transportation__detail--bus li:before {
  content: "";
  display: block;
  position: absolute;
  bottom: -60px;
  right: 0;
  border-top: 25px solid #fff;
  border-bottom: 25px solid #fff;
  border-left: 30px solid #C7EDB8;
}

body.page.access_detail .around__wrap .around__inner .section-three .to-station__inner .root__flow .root__flow__inner .root__flow__sections .flow__block .transportation__detail--bus li .flow__icon {
  position: absolute;
  bottom: -40px;
  left: 20px;
  background: url(../img/pages/icon_bus.svg) no-repeat;
  background-size: 76px 30px;
  width: 76px;
  height: 30px;
}

body.page.access_detail .around__wrap .around__inner .section-three .to-station__inner .root__flow .root__flow__inner .root__flow__sections .flow__block .transportation__detail--train li {
  position: relative;
}

body.page.access_detail .around__wrap .around__inner .section-three .to-station__inner .root__flow .root__flow__inner .root__flow__sections .flow__block .transportation__detail--train li:after {
  content: "";
  display: block;
  position: absolute;
  bottom: -45px;
  left: 0;
  width: 100%;
  height: 20px;
  background-color: #A9D3EA;
}

body.page.access_detail .around__wrap .around__inner .section-three .to-station__inner .root__flow .root__flow__inner .root__flow__sections .flow__block .transportation__detail--train li:before {
  content: "";
  display: block;
  position: absolute;
  bottom: -60px;
  right: 0;
  z-index: 1;
  border-top: 25px solid #fff;
  border-bottom: 25px solid #fff;
  border-left: 30px solid #A9D3EA;
}

body.page.access_detail .around__wrap .around__inner .section-three .to-station__inner .root__flow .root__flow__inner .root__flow__sections .flow__block .transportation__detail--train li .flow__icon {
  position: absolute;
  bottom: -40px;
  left: 20px;
  z-index: 1;
  background: url(../img/pages/icon_train.svg) no-repeat;
  background-size: 76px 30px;
  width: 76px;
  height: 30px;
}

body.page.access_detail .around__wrap .around__inner .section-three .to-station__inner .root__detail {
  margin-top: 1rem;
}

@media (max-width: 1024.98px) {
  body.page.access_detail .around__wrap .around__inner .section-three .to-station__inner .root__detail {
    margin-bottom: 1rem;
  }
}

body.page.access_detail .around__wrap .around__inner .section-three .to-station__inner .root__detail .transportation__car,
body.page.access_detail .around__wrap .around__inner .section-three .to-station__inner .root__detail .transportation__bus,
body.page.access_detail .around__wrap .around__inner .section-three .to-station__inner .root__detail .transportation__train,
body.page.access_detail .around__wrap .around__inner .section-three .to-station__inner .root__detail .transportation__ferry {
  font-weight: bold;
}

body.page.access_detail .around__wrap .around__inner .section-three .to-station__inner .root__detail .transportation__car:before,
body.page.access_detail .around__wrap .around__inner .section-three .to-station__inner .root__detail .transportation__bus:before,
body.page.access_detail .around__wrap .around__inner .section-three .to-station__inner .root__detail .transportation__train:before,
body.page.access_detail .around__wrap .around__inner .section-three .to-station__inner .root__detail .transportation__ferry:before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 4px;
  vertical-align: middle;
  margin-right: 5px;
  margin-bottom: 2px;
  background-color: currentColor;
}

body.page.access_detail .around__wrap .around__inner .section-three .to-station__inner .root__detail .transportation__car {
  color: #E23000;
}

body.page.access_detail .around__wrap .around__inner .section-three .to-station__inner .root__detail .transportation__bus {
  color: #3B9810;
}

body.page.access_detail .around__wrap .around__inner .section-three .to-station__inner .root__detail .transportation__train {
  color: #015CAE;
}

body.page.access_detail .around__wrap .around__inner .section-three .to-station__inner .root__detail .transportation__ferry {
  color: #7d00fd;
}

body.page.access_detail .around__wrap .around__inner .section-three .to-station__inner .root__detail .detail__lists .detail__item {
  padding: 1rem .5rem;
  border-bottom: 1px solid #adb5bd;
  line-height: 2;
}

body.page.access_detail .around__wrap .around__inner .section-three .to-station__inner .root__detail .detail__lists .detail__item:first-of-type {
  border-top: 1px solid #adb5bd;
}

body.page.access_detail .around__wrap .around__inner .section-three .to-station__inner .root__detail .detail__lists .detail__item ul {
  letter-spacing: -.4em;
}

body.page.access_detail .around__wrap .around__inner .section-three .to-station__inner .root__detail .detail__lists .detail__item ul li {
  margin-right: 1rem;
  letter-spacing: normal;
  display: inline-block;
}

body.page.access_detail .around__wrap .around__inner .section-three .to-station__inner .root__detail .detail__lists .detail__item ul li a {
  display: block;
  text-decoration: underline;
}

body.page.access_detail .around__wrap .around__inner .section-three .root__map {
  width: 21%;
}

@media (max-width: 1024.98px) {
  body.page.access_detail .around__wrap .around__inner .section-three .root__map {
    width: 100%;
  }
}

body.page.access_detail .around__wrap .around__inner .section-three .root__map .root__img {
  text-align: center;
}

body.page.access_detail .around__wrap .around__inner .section-three .root__map .root__spot__link {
  text-align: center;
}

body.page.access_detail .around__wrap .around__inner .section-three .root__map .root__spot__link a {
  position: relative;
  color: #BB0900;
  text-decoration: none;
  display: block;
  border: 2px solid #BB0900;
  padding: 1rem .5rem 1.5rem;
  border-radius: 10px;
  font-weight: bold;
}

body.page.access_detail .around__wrap .around__inner .section-three .root__map .root__spot__link a:after {
  content: "";
  display: inline-block;
  width: 15px;
  height: 15px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 10px;
  margin: auto;
  border-bottom: 2px solid #BB0900;
  border-right: 2px solid #BB0900;
  transform: rotate(45deg);
}

@media (max-width: 1024.98px) {
  body.page.category_lower .page__head {
    padding-bottom: 0;
  }
}

body.page.category_lower .simple__article {
  list-style: none;
  padding-left: 0;
}

body.page.category_lower .simple__article .caption {
  margin-bottom: 0;
}

@media (max-width: 767.98px) {
  body.page.category_lower .simple__article .caption {
    font-size: 0.75rem;
  }
}

body.page.category_lower .simple__article.title__red .simple__article__item .article__head a {
  color: #8B171B;
}

body.page.category_lower .simple__article .simple__article__item {
  padding: 1rem .5rem;
}

body.page.category_lower .simple__article .simple__article__item:nth-of-type(even) {
  background-color: #F4FAFF;
}

body.page.category_lower .simple__article .simple__article__item .article__info {
  font-size: 0.875rem;
  margin-bottom: .5rem;
  letter-spacing: -1em;
}

body.page.category_lower .simple__article .simple__article__item .article__info * {
  letter-spacing: normal;
}

body.page.category_lower .simple__article .simple__article__item .article__info .article__label {
  display: inline-block;
  margin: .2rem .5rem .2rem 0;
  background-color: #E3EDF4;
  border-radius: 20px;
  padding: 0.2rem 0.6rem;
}

@media (max-width: 767.98px) {
  body.page.category_lower .simple__article .simple__article__item .article__info .article__label {
    font-size: 0.75rem;
  }
}

body.page.category_lower .simple__article .simple__article__item .article__head {
  font-family: "游明朝", YuMincho, "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
  margin-bottom: 0;
}

@media all and (-ms-high-contrast: none) {
  body.page.category_lower .simple__article .simple__article__item .article__head {
    font-family: "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
  }
}

body.page.category_lower .simple__article .simple__article__item .article__head a {
  color: #333;
  font-size: 1.125rem;
  text-decoration: underline;
  transition: .2s;
}

body.page.category_lower .simple__article .simple__article__item .article__head a:hover {
  opacity: .7;
}

body.page.category_lower .simple__article .simple__article__item .article__thumb__block {
  position: relative;
  padding-top: 9%;
  overflow: hidden;
}

body.page.category_lower .simple__article .simple__article__item .article__thumb__block .article__thumb {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

body.page.category_lower .simple__article .simple__article__item .article__info .date {
  margin-right: 0.5em;
}

@media (max-width: 767.98px) {
  body.page.category_lower .simple__article .simple__article__item .article__info .date {
    display: block;
  }
}

body.page.category_lower .simple__article.simple__article--lg-thumb .simple__article__item .article__thumb__block {
  padding-top: 16%;
}

@media (min-width: 1025px) {
  body.page.category_lower.fun .page__head {
    flex: none;
  }
}

@media (max-width: 1024.98px) {
  body.page.category_lower.fun .page__head {
    text-align: center;
    max-width: 220px;
    margin-left: auto;
    margin-right: auto;
  }
}

body.page.category_lower.fun .page__head:after {
  content: "";
  display: inline-block;
  width: 25px;
  height: 4px;
  background: #168836;
  margin: 0 1.5rem;
}

@media (max-width: 1024.98px) {
  body.page.category_lower.fun .page__head:after {
    display: block;
    margin: 2rem auto 2rem;
    transform: rotate(90deg);
  }
}

@media (min-width: 1025px) {
  body.page.category_lower.visit .page__head {
    flex: none;
  }
}

@media (max-width: 1024.98px) {
  body.page.category_lower.visit .page__head {
    text-align: center;
    max-width: 220px;
    margin-left: auto;
    margin-right: auto;
  }
}

body.page.category_lower.visit .page__head:after {
  content: "";
  display: inline-block;
  width: 25px;
  height: 4px;
  background: #015CAE;
  margin: 0 1.5rem;
}

@media (max-width: 1024.98px) {
  body.page.category_lower.visit .page__head:after {
    display: block;
    margin: 2rem auto 2rem;
    transform: rotate(90deg);
  }
}

@media (min-width: 1025px) {
  body.page.category_lower.know .page__head {
    flex: none;
  }
}

@media (max-width: 1024.98px) {
  body.page.category_lower.know .page__head {
    text-align: center;
    max-width: 220px;
    margin-left: auto;
    margin-right: auto;
  }
}

body.page.category_lower.know .page__head:after {
  content: "";
  display: inline-block;
  width: 25px;
  height: 4px;
  background: #E71F26;
  margin: 0 1.5rem;
}

@media (max-width: 1024.98px) {
  body.page.category_lower.know .page__head:after {
    display: block;
    margin: 2rem auto 2rem;
    transform: rotate(90deg);
  }
}

body.page.category_lower .intro__wrap {
  padding: 3rem 0 2rem;
}

@media (min-width: 1025px) {
  body.page.category_lower .intro__wrap {
    display: flex;
    align-items: center;
    margin-top: 2rem;
  }
}

@media (max-width: 1024.98px) {
  body.page.category_lower .intro__wrap {
    padding: 0;
  }
}

body.page.category_lower .intro__wrap .intro__head {
  font-size: 2.3125rem;
  padding: 0;
  font-family: "游明朝", YuMincho, "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
}

@media all and (-ms-high-contrast: none) {
  body.page.category_lower .intro__wrap .intro__head {
    font-family: "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
  }
}

@media (max-width: 1024.98px) {
  body.page.category_lower .intro__wrap .intro__head {
    font-family: ryo-text-plusn, "游明朝", YuMincho, "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
    text-align: center;
    font-size: 1.5rem;
  }
}

body.page.category_lower .intro__wrap .intro__head.special-dish {
  font-size: 2.6875rem;
  font-weight: bold;
}

@media (max-width: 1024.98px) {
  body.page.category_lower .intro__wrap .intro__head.special-dish {
    font-size: 1.875rem;
  }
}

body.page.category_lower .page__intro__wrap {
  padding-bottom: 1rem;
  margin-bottom: 5rem;
}

@media (max-width: 1024.98px) {
  body.page.category_lower .page__intro__wrap {
    margin-bottom: 2rem;
    padding-bottom: 0;
  }
}

body.page.category_lower .page__intro__wrap:before {
  content: "";
  display: block;
  position: absolute;
  top: -20px;
  left: 290px;
  width: 70vw;
  height: 19px;
  background: url(../img/pages/bg_category_lower.svg) repeat-x;
  background-size: 39px 19px;
}

@media (max-width: 1024.98px) {
  body.page.category_lower .page__intro__wrap:before {
    bottom: 95%;
    top: initial;
  }
}

@media (max-width: 1024.98px) {
  body.page.category_lower .page__intro__wrap:before {
    display: none;
  }
}

body.page.category_lower .page__intro__wrap:after {
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  right: 160px;
  width: 80vw;
  height: 19px;
  background: url(../img/pages/bg_category_lower.svg) repeat-x;
  background-size: 39px 19px;
}

@media (max-width: 1024.98px) {
  body.page.category_lower .page__intro__wrap:after {
    display: none;
  }
}

body.page .sanriku__wrap .access__sanriku__tab .nav-tabs li {
  width: calc( 94% / 4);
}

@media (max-width: 767.98px) {
  body.page .sanriku__wrap .access__sanriku__tab .nav-tabs li {
    width: 100%;
  }
}

body.page .sanriku__wrap .access__sanriku__tab .nav-tabs li:not(:last-of-type) {
  margin-right: 0;
}

@media (min-width: 768px) {
  body.page .sanriku__wrap .access__sanriku__tab .nav-tabs li:not(:last-of-type) {
    margin-right: 2%;
  }
}

@media (max-width: 767.98px) {
  body.page .sanriku__wrap .access__sanriku__tab .nav-tabs li:not(:last-of-type) {
    margin-bottom: 2px;
  }
}

body.page .sanriku__wrap .access__sanriku__tab .nav-tabs li a {
  background-color: #BB0900;
  color: #fff;
  font-weight: bold;
  border: none;
  text-align: center;
  padding: 1rem .5rem;
}

body.page .sanriku__wrap .access__sanriku__tab .nav-tabs li a.active {
  background-color: #fff;
  color: #BB0900;
}

body.page .sanriku__wrap .access__sanriku__tab .tab-content {
  background: #fff;
  border: none;
  padding: 1rem;
}

@media (max-width: 767.98px) {
  body.page .sanriku__wrap .access__sanriku__tab .tab-content {
    padding: .5rem;
  }
}

body.page .sanriku__wrap .access__sanriku__tab .tab-content .tab-pane ul {
  list-style: none;
  padding-left: 0;
}

body.page .sanriku__wrap .access__sanriku__tab .tab-content .tab-pane ul.spot__address {
  margin-bottom: 1rem;
}

body.page .sanriku__wrap .access__sanriku__tab .tab-content .tab-pane ul.spot__address li {
  border-bottom: 2px dotted #888;
  padding-bottom: .5rem;
  margin-bottom: .5rem;
}

body.page .sanriku__wrap .access__sanriku__tab .tab-content .tab-pane ul.spot__address li:first-of-type {
  margin-top: .5rem;
}

body.page .sanriku__wrap .access__sanriku__tab .tab-content .tab-pane ul.spot__access__btn {
  text-align: center;
}

@media (min-width: 768px) {
  body.page .sanriku__wrap .access__sanriku__tab .tab-content .tab-pane ul.spot__access__btn {
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 767.98px) {
  body.page .sanriku__wrap .access__sanriku__tab .tab-content .tab-pane ul.spot__access__btn {
    margin-bottom: 2rem;
  }
}

@media (max-width: 767.98px) {
  body.page .sanriku__wrap .access__sanriku__tab .tab-content .tab-pane ul.spot__access__btn li {
    margin: 0 auto .5rem;
    width: 240px;
  }
}

@media (min-width: 1025px) {
  body.page .sanriku__wrap .access__sanriku__tab .tab-content .tab-pane ul.spot__access__btn li:nth-last-of-type(2) {
    margin-right: 1rem;
  }
}

@media (min-width: 768px) and (max-width: 1024.98px) {
  body.page .sanriku__wrap .access__sanriku__tab .tab-content .tab-pane ul.spot__access__btn li:nth-last-of-type(2) {
    margin-right: 0.5rem;
  }
}

body.page .sanriku__wrap .access__sanriku__tab .tab-content .tab-pane ul.spot__access__btn li a {
  display: block;
  padding: .5rem 1.5rem;
  text-decoration: none;
  border-radius: 10px;
  transition: .2s;
}

body.page .sanriku__wrap .access__sanriku__tab .tab-content .tab-pane ul.spot__access__btn li a:hover {
  opacity: .7;
}

@media (min-width: 768px) and (max-width: 1024.98px) {
  body.page .sanriku__wrap .access__sanriku__tab .tab-content .tab-pane ul.spot__access__btn li a {
    padding: .5rem 1rem;
  }
}

body.page .sanriku__wrap .access__sanriku__tab .tab-content .tab-pane ul.spot__access__btn li.article__link a {
  border: 1px solid #00729C;
}

body.page .sanriku__wrap .access__sanriku__tab .tab-content .tab-pane ul.spot__access__btn li.official__link a {
  border: 1px solid #BB0900;
  color: #BB0900;
}

body.page .sanriku__wrap .access__sanriku__tab .tab-content .tab-pane ul.spot__access__btn li.official__link a:before {
  content: "";
  display: inline-block;
  background: url(../img/pages/icon_new-window.svg) no-repeat;
  background-size: 14px 14px;
  width: 14px;
  height: 14px;
  margin-right: 0.3rem;
}

body.page .sanriku__wrap .access__sanriku__tab .tab-content .tab-pane ul li .spot__item {
  display: block;
  position: relative;
}

body.page .sanriku__wrap .access__sanriku__tab .tab-content .tab-pane ul li .spot__title {
  padding-bottom: 0.2rem;
  margin-bottom: 1rem;
  border-bottom: 2px solid #BB0900;
}

body.page .sanriku__wrap .access__sanriku__tab .tab-content .tab-pane ul li .spot__title a {
  text-decoration: none;
  color: #333;
  font-weight: bold;
  font-size: 1.125rem;
  cursor: pointer;
}

body.page .sanriku__wrap .access__sanriku__tab .tab-content .tab-pane ul li .spot__title a span:last-of-type {
  display: inline-block;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #BB0900;
  position: relative;
  vertical-align: sub;
  margin-left: 0.5rem;
}

body.page .sanriku__wrap .access__sanriku__tab .tab-content .tab-pane ul li .spot__title a span:last-of-type:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  width: 60%;
  height: 2px;
  display: inline-block;
  background-color: #fff;
}

body.page .sanriku__wrap .access__sanriku__tab .tab-content .tab-pane ul li .spot__title a span:last-of-type:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  width: 2px;
  height: 60%;
  display: inline-block;
  background-color: #fff;
  transition: .4s;
}

body.page .sanriku__wrap .access__sanriku__tab .tab-content .tab-pane ul li .spot__title a.is_active span:before {
  transform: rotate(360deg);
  opacity: 0;
}

@media print {
  body.page .sanriku__wrap .access__sanriku__tab .tab-content .tab-pane ul li .spot__title a.is_active span:before {
    opacity: 1;
  }
}

body.page .sanriku__wrap .access__sanriku__tab .tab-content .tab-pane ul li .spot__content {
  display: none;
}

body.page .sanriku__wrap .access__sanriku__tab .tab-content .tab-pane ul li .spot__content .spot__img {
  padding-top: 25%;
}

@media (max-width: 767.98px) {
  body.page .sanriku__wrap .access__sanriku__tab .tab-content .tab-pane ul li .spot__content .spot__img {
    padding-top: 55%;
  }
}

body.page .sanriku__wrap .access__sanriku__tab .tab-content .tab-pane ul li .spot__content .spot__annotation {
  background-color: #e9ecef;
  padding: .5rem 1rem;
}

body.page .sanriku__wrap .access__sanriku__tab .tab-content .tab-pane ul li .spot__content .station__access__wrap p {
  margin-bottom: 0;
}

body.page .sanriku__wrap .access__sanriku__tab .tab-content .tab-pane ul li .spot__content .station__access__wrap .station__access__head {
  border-left: 5px solid #015CAE;
  margin: 1.5rem 0 1rem;
  padding: .5rem .5rem .5rem 1rem;
  font-size: 1.25rem;
  font-weight: bold;
}

body.page .sanriku__wrap .access__sanriku__tab .tab-content .tab-pane ul li .spot__content .station__access__wrap dl {
  display: flex;
  flex-wrap: wrap;
}

body.page .sanriku__wrap .access__sanriku__tab .tab-content .tab-pane ul li .spot__content .station__access__wrap dl dt {
  background-color: #015CAE;
  border: 1px solid #015CAE;
  padding: .5rem;
  color: #fff;
  width: 20%;
}

body.page .sanriku__wrap .access__sanriku__tab .tab-content .tab-pane ul li .spot__content .station__access__wrap dl dt:not(:last-of-type) {
  border-bottom: 1px solid #fff;
}

@media (max-width: 767.98px) {
  body.page .sanriku__wrap .access__sanriku__tab .tab-content .tab-pane ul li .spot__content .station__access__wrap dl dt {
    padding: .5rem .3rem;
    width: 30%;
  }
}

body.page .sanriku__wrap .access__sanriku__tab .tab-content .tab-pane ul li .spot__content .station__access__wrap dl dd {
  border: 1px solid #888;
  padding: .5rem;
  width: 80%;
  margin-bottom: 0;
}

body.page .sanriku__wrap .access__sanriku__tab .tab-content .tab-pane ul li .spot__content .station__access__wrap dl dd:not(:last-of-type) {
  border-bottom: none;
}

@media (max-width: 767.98px) {
  body.page .sanriku__wrap .access__sanriku__tab .tab-content .tab-pane ul li .spot__content .station__access__wrap dl dd {
    padding: .5rem .2rem;
    width: 70%;
  }
}

body.page .sanriku__wrap .access__sanriku__tab .tab-content .tab-pane ul li .spot__content .spot__transportation__lists .spot__item {
  margin-bottom: 1rem;
}

@media (max-width: 767.98px) {
  body.page .sanriku__wrap .access__sanriku__tab .tab-content .tab-pane ul li .spot__content .spot__transportation__lists .spot__item {
    margin-bottom: 0.2rem;
    padding: 0 .2rem;
  }
  body.page .sanriku__wrap .access__sanriku__tab .tab-content .tab-pane ul li .spot__content .spot__transportation__lists .spot__item:nth-of-type(even) {
    padding-left: 0;
  }
}

body.page .sanriku__wrap .access__sanriku__tab .tab-content .tab-pane ul li .spot__content .spot__transportation__lists .spot__item.spot__walk a:before {
  content: "";
  display: block;
  margin: auto;
  background: url(../img/pages/icon_walk.svg) no-repeat;
  background-size: 25px 46px;
  width: 25px;
  height: 46px;
}

body.page .sanriku__wrap .access__sanriku__tab .tab-content .tab-pane ul li .spot__content .spot__transportation__lists .spot__item.spot__cycle a:before {
  content: "";
  display: inline-block;
  display: block;
  margin: auto;
  background: url(../img/pages/icon_bicycle.svg) no-repeat;
  background-size: 64px 42px;
  width: 64px;
  height: 42px;
}

body.page .sanriku__wrap .access__sanriku__tab .tab-content .tab-pane ul li .spot__content .spot__transportation__lists .spot__item.spot__bus a:before {
  content: "";
  display: inline-block;
  display: block;
  margin: auto;
  background: url(../img/pages/icon_bus.svg) no-repeat;
  background-size: 90px 36px;
  width: 90px;
  height: 36px;
}

body.page .sanriku__wrap .access__sanriku__tab .tab-content .tab-pane ul li .spot__content .spot__transportation__lists .spot__item.spot__ferry a:before {
  content: "";
  display: inline-block;
  display: block;
  margin: auto;
  background: url(../img/pages/icon_ferry.svg) no-repeat;
  background-size: 78px 43px;
  width: 78px;
  height: 43px;
}

body.page .sanriku__wrap .access__sanriku__tab .tab-content .tab-pane ul li .spot__content .spot__transportation__lists .spot__item.spot__car a:before {
  content: "";
  display: inline-block;
  display: block;
  margin: auto;
  background: url(../img/pages/icon_car.svg) no-repeat;
  background-size: 74px 28px;
  width: 74px;
  height: 28px;
}

body.page .sanriku__wrap .access__sanriku__tab .tab-content .tab-pane ul li .spot__content .spot__transportation__lists .spot__item.spot__train a:before {
  content: "";
  display: inline-block;
  display: block;
  margin: auto;
  background: url(../img/pages/icon_train.svg) no-repeat;
  background-size: 93px 32px;
  width: 93px;
  height: 32px;
}

body.page .sanriku__wrap .access__sanriku__tab .tab-content .tab-pane ul li .spot__content .spot__transportation__lists .spot__item.spot__taxi a:before {
  content: "";
  display: inline-block;
  display: block;
  margin: auto;
  background: url(../img/pages/icon_taxi.svg) no-repeat;
  background-size: 92px 32px;
  width: 92px;
  height: 32px;
}

body.page .sanriku__wrap .access__sanriku__tab .tab-content .tab-pane ul li .spot__content .spot__transportation__lists .spot__item.spot__rental-car a:before {
  content: "";
  display: inline-block;
  display: block;
  margin: auto;
  background: url(../img/pages/icon_car-rental.svg) no-repeat;
  background-size: 73px 29px;
  width: 73px;
  height: 29px;
}

body.page .sanriku__wrap .access__sanriku__tab .tab-content .tab-pane ul li .spot__content .spot__transportation__lists .spot__item a {
  text-align: center;
  text-decoration: none;
  display: block;
  border: 2px solid #00729C;
  padding: 1rem .5rem;
  border-radius: 10px;
  height: 100%;
}

body.page .sanriku__wrap .access__sanriku__tab .tab-content .tab-pane ul li .spot__content .spot__transportation__lists .spot__item a span {
  display: block;
}

body.page .sanriku__wrap .access__sanriku__tab .tab-content .tab-pane ul li .spot__content .spot__transportation__lists .spot__item a span:first-of-type {
  font-weight: bold;
  color: #333;
  margin-top: .5rem;
}

body.page .sanriku__wrap .access__sanriku__tab .tab-content .tab-pane ul li .spot__content .spot__transportation__lists .spot__item a span:last-of-type.gmap:before {
  content: "";
  display: inline-block;
  background: url(../img/common/icon_googlemap.svg) no-repeat;
  background-size: 18px 18px;
  width: 18px;
  height: 18px;
  vertical-align: middle;
  margin-bottom: 2px;
  margin-right: 5px;
}

body.page.sitemap .sitemap__wrap ul {
  list-style: none;
}

body.page.sitemap .sitemap__wrap ul li {
  margin-bottom: 0.5rem;
}

body.page.sitemap .sitemap__wrap .know__block h2 {
  border-left: 5px solid #E71F26;
}

body.page.sitemap .sitemap__wrap .know__block h2 a {
  color: #E71F26;
  text-decoration: underline;
}

body.page.sitemap .sitemap__wrap .know__block ul {
  padding-left: 0;
}

body.page.sitemap .sitemap__wrap .know__block ul li {
  letter-spacing: -.4em;
}

body.page.sitemap .sitemap__wrap .know__block ul li a {
  letter-spacing: normal;
}

body.page.sitemap .sitemap__wrap .know__block ul li a:before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 3px;
  background: #E71F26;
  vertical-align: super;
  margin: 0 10px 0 0;
}

body.page.sitemap .sitemap__wrap .visit__block h2 {
  border-left: 5px solid #015CAE;
}

body.page.sitemap .sitemap__wrap .visit__block h2 a {
  color: #015CAE;
  text-decoration: underline;
}

body.page.sitemap .sitemap__wrap .visit__block ul {
  padding-left: 0;
}

body.page.sitemap .sitemap__wrap .visit__block ul li {
  letter-spacing: -.4em;
}

body.page.sitemap .sitemap__wrap .visit__block ul li a {
  letter-spacing: normal;
}

body.page.sitemap .sitemap__wrap .visit__block ul li a:before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 3px;
  background: #015CAE;
  vertical-align: super;
  margin: 0 10px 0 0;
}

body.page.sitemap .sitemap__wrap .fun__block h2 {
  border-left: 5px solid #168836;
}

body.page.sitemap .sitemap__wrap .fun__block h2 a {
  color: #168836;
  text-decoration: underline;
}

body.page.sitemap .sitemap__wrap .fun__block ul {
  padding-left: 0;
}

body.page.sitemap .sitemap__wrap .fun__block ul li {
  letter-spacing: -.4em;
}

body.page.sitemap .sitemap__wrap .fun__block ul li a {
  letter-spacing: normal;
}

body.page.sitemap .sitemap__wrap .fun__block ul li a:before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 3px;
  background: #168836;
  vertical-align: super;
  margin: 0 10px 0 0;
}

body.page.sitemap .sitemap__wrap .other__block ul {
  padding-left: 0;
}

body.page.sitemap .sitemap__wrap .other__block ul li {
  letter-spacing: -.4em;
}

body.page.sitemap .sitemap__wrap .other__block ul li a {
  letter-spacing: normal;
}

body.page.sitemap .sitemap__wrap .other__block ul li a:before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 3px;
  background: #333;
  vertical-align: super;
  margin: 0 10px 0 0;
}

body.page.sitemap .other__head {
  border-left: 5px solid #333;
}

body.page.sitemap .other__head a {
  color: #168836;
  text-decoration: underline;
}

body.page.news .page__intro__wrap {
  margin-bottom: 1rem;
}

body.page.news .news__list {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
}

@media (min-width: 1025px) {
  body.page.news .news__list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
}

body.page.news .news__list dt {
  width: 15%;
}

@media (max-width: 1024.98px) {
  body.page.news .news__list dt {
    width: 100%;
  }
}

body.page.news .news__list dd {
  width: 85%;
  margin-bottom: 0;
}

@media (min-width: 1025px) {
  body.page.news .news__list dd {
    padding-left: 1rem;
  }
}

@media (max-width: 1024.98px) {
  body.page.news .news__list dd {
    width: 100%;
  }
}

body.page.news .news__list:nth-of-type(odd) {
  background-color: #E3EDF4;
}

body.page.sanrikubito .main__inner {
  font-family: "游明朝", YuMincho, "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
}

@media all and (-ms-high-contrast: none) {
  body.page.sanrikubito .main__inner {
    font-family: "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
  }
}

@media (max-width: 1024.98px) {
  body.page.sanrikubito .main__inner {
    font-family: ryo-text-plusn, "游明朝", YuMincho, "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
  }
}

body.page.sanrikubito .main__inner .question__head {
  color: #8B171B;
  padding-bottom: 0.2rem;
  margin-bottom: 1.5rem;
  margin-top: 2rem;
  border-bottom: 1px solid #4E4E4E;
}

body.page.sanrikubito .main__inner p {
  font-size: 1.125rem;
}

@media (max-width: 767.98px) {
  body.page.sanrikubito .main__inner p {
    font-size: 1rem;
  }
}

body.page.tourist_routes .main__inner {
  font-family: "游明朝", YuMincho, "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
}

@media all and (-ms-high-contrast: none) {
  body.page.tourist_routes .main__inner {
    font-family: "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
  }
}

@media (max-width: 1024.98px) {
  body.page.tourist_routes .main__inner {
    font-family: ryo-text-plusn, "游明朝", YuMincho, "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
  }
}

body.page.tourist_routes .main__inner .content__wrap {
  position: relative;
  padding-left: 1.5rem;
  padding-bottom: 2rem;
  margin-bottom: 0;
}

body.page.tourist_routes .main__inner .content__wrap:before {
  content: "";
  display: block;
  position: absolute;
  left: 5px;
  top: 0;
  width: 3px;
  height: 100%;
  background-image: linear-gradient(to bottom, #6B8CB7, #6B8CB7 50%, transparent 50%, transparent 100%);
  background-size: 1px 15px;
}

body.page.tourist_routes .main__inner .content__wrap .content__inner {
  padding-top: 1.5rem;
  margin-bottom: 0;
}

body.page.tourist_routes .main__inner .content__wrap .content__inner .inquiry__block {
  margin-bottom: 0;
}

body.page.tourist_routes .main__inner h2 {
  border-left: 0;
  padding: .5rem 0;
  margin: 0;
}

body.page.tourist_routes .main__inner h2 span.number {
  font-size: 2.25rem;
  color: #015CAE;
  margin-right: .5rem;
}

body.page.tourist_routes .main__inner h2 span.address {
  font-size: 1rem;
  color: #015CAE;
}

body.page.tourist_routes .main__inner .inquiry__block {
  background-color: rgba(136, 136, 136, 0.1);
  padding: 1.5rem;
}

body.page.tourist_routes .main__inner .inquiry__block .inquiry__title {
  margin-bottom: 0.5rem;
}

.important-news {
  border: 5px solid #ff0d00;
  padding: 0 1rem 2rem;
  max-width: 800px;
  margin: 0 auto 2rem;
}

.important-news h2 {
  color: #ff0d00 !important;
  text-align: center;
  margin-bottom: 1rem !important;
}

.important-news h2:after, .important-news h2:before {
  content: none !important;
}

.important-news .important__content {
  text-align: center;
}

.chat-bot .chat-bot__front {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 10;
  transition: .5s;
}

.chat-bot .chat-bot__front .chat-bot__trigger,
.chat-bot .chat-bot__front .chat-bot__close-mini {
  cursor: pointer;
  background: none;
  border: none;
}

.chat-bot .chat-bot__front .chat-bot__close-mini {
  position: absolute;
  top: -10px;
  right: 0;
}

.chat-bot .chat-bot__front.is_hidden {
  transform: translateY(100%);
}

.chat-bot .chat-bot__front.is_none {
  display: none;
}

.chat-bot .chat-bot__wrap .chat-bot__content {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 10;
  transition: .5s;
  transform: translateY(100%);
  width: 300px;
}

.chat-bot .chat-bot__wrap .chat-bot__content.is_open {
  transform: translateY(0);
}

.chat-bot .chat-bot__wrap .chat-bot__content .header {
  background-color: #015CAE;
  display: flex;
  justify-content: space-between;
}

.chat-bot .chat-bot__wrap .chat-bot__content .header .chat-bot__title {
  margin-bottom: 0;
  padding: 0.2rem .5rem;
  color: #fff;
  font-size: 1.1875rem;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.chat-bot .chat-bot__wrap .chat-bot__content .header .chat-bot__title:before {
  content: url(../img/home/icon_chat-bot2.png);
  vertical-align: middle;
  width: 56px;
  height: 56px;
  margin-right: 0.5rem;
}

.chat-bot .chat-bot__wrap .chat-bot__content .header .chat-bot__close {
  color: #fff;
  background-color: #015CAE;
  border: none;
  cursor: pointer;
  width: 50px;
  position: relative;
}

.chat-bot .chat-bot__wrap .chat-bot__content .header .chat-bot__close:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 30px;
  height: 1px;
  transform: rotate(45deg);
  background-color: #fff;
}

.chat-bot .chat-bot__wrap .chat-bot__content .header .chat-bot__close:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 30px;
  height: 1px;
  transform: rotate(-45deg);
  background-color: #fff;
}

.chat-bot .chat-bot__wrap .chat-bot__content .main__content {
  font-size: 0.8125rem;
}

.chat-bot .chat-bot__wrap .chat-bot__content .main__content .content__inner {
  background-color: #DEDEDE;
  padding: 10px 5px;
  max-height: 320px;
  min-height: 320px;
  overflow: scroll;
  -ms-overflow-style: none;
}

.chat-bot .chat-bot__wrap .chat-bot__content .main__content .content__inner .bot__block {
  display: flex;
  align-items: flex-start;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.chat-bot .chat-bot__wrap .chat-bot__content .main__content .content__inner .bot__block .bot__text {
  position: relative;
  padding: 1rem;
  border-radius: 15px;
  background-color: #fff;
  box-shadow: 0px 3px 9px 0px #9c9c9c;
  margin-left: 15px;
  margin-bottom: 0;
  border: 2px solid #015CAE;
  min-height: 3rem;
}

.chat-bot .chat-bot__wrap .chat-bot__content .main__content .content__inner .bot__block .bot__text a {
  word-break: break-all;
}

.chat-bot .chat-bot__wrap .chat-bot__content .main__content .content__inner .bot__block .bot__text:before {
  content: "";
  display: block;
  position: absolute;
  top: 25px;
  right: 100%;
  width: 0;
  height: 0;
  margin: auto;
  border-left: 8px solid transparent;
  border-top: 5px solid #015CAE;
  border-right: 8px solid #015CAE;
  border-bottom: 5px solid transparent;
}

.chat-bot .chat-bot__wrap .chat-bot__content .main__content .content__inner .bot__block .bot__text:after {
  content: "";
  display: block;
  position: absolute;
  top: 27px;
  right: calc(100% - 7px);
  width: 0;
  height: 0;
  margin: auto;
  border-left: 8px solid transparent;
  border-top: 5px solid #fff;
  border-right: 8px solid #fff;
  border-bottom: 5px solid transparent;
}

.chat-bot .chat-bot__wrap .chat-bot__content .main__content .content__inner .bot__block img {
  flex-shrink: 0;
}

.chat-bot .chat-bot__wrap .chat-bot__content .main__content .content__inner .user__block {
  color: #fff;
  min-height: 3rem;
  padding: 1rem;
  background-color: #00941F;
  border-radius: 15px;
  margin-left: 3rem;
  margin-right: 15px;
  position: relative;
  margin-bottom: 1rem;
  box-shadow: 0px 3px 9px 0px #9c9c9c;
}

.chat-bot .chat-bot__wrap .chat-bot__content .main__content .content__inner .user__block:before {
  content: "";
  display: block;
  position: absolute;
  top: 15px;
  left: 100%;
  width: 0;
  height: 0;
  margin: auto;
  border-left: 8px solid #00941F;
  border-top: 5px solid #00941F;
  border-right: 8px solid transparent;
  border-bottom: 5px solid transparent;
}

.chat-bot .chat-bot__wrap .chat-bot__content .main__content .content__inner .user__block .user__text {
  margin-bottom: 0;
  word-break: break-all;
}

.chat-bot .chat-bot__wrap .chat-bot__content .footer__input {
  border: 1px solid #9c9c9c;
}

.chat-bot .chat-bot__wrap .chat-bot__content .footer__input form {
  display: flex;
  justify-content: space-between;
}

.chat-bot .chat-bot__wrap .chat-bot__content .footer__input form input {
  flex-grow: 1;
  padding: .5rem;
  border: none;
  width: calc(100% - 50px);
}

.chat-bot .chat-bot__wrap .chat-bot__content .footer__input form button {
  background-color: #fff;
  border: none;
  width: 50px;
}

@media print {
  body {
    -webkit-print-color-adjust: exact;
    width: 1300px;
  }
}

html,
body {
  overflow-x: hidden;
}

ol,
ul {
  padding-left: 1.5rem;
}

@media (min-width: 1025px) {
  body.home,
  body.lp,
  body.page {
    margin-left: 65px;
  }
}

@media all and (-ms-high-contrast: none) {
  body.home,
  body.lp,
  body.page {
    font-family: "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo, "ＭＳ ゴシック", "ＭＳ Ｐゴシック", sans-serif;
  }
}

body.home .search .search__wrap .search__title,
body.lp .search .search__wrap .search__title,
body.page .search .search__wrap .search__title {
  font-size: 1.125rem;
}

body.home .search .search__wrap .search__title:before,
body.lp .search .search__wrap .search__title:before,
body.page .search .search__wrap .search__title:before {
  content: "";
  display: inline-block;
  background: url(../img/common/icon_search01.svg) no-repeat;
  background-size: 25px 25px;
  width: 25px;
  height: 25px;
  vertical-align: middle;
  margin-right: 0.5rem;
}

body.home .search .search__wrap form .search__box,
body.lp .search .search__wrap form .search__box,
body.page .search .search__wrap form .search__box {
  position: relative;
  display: inline-block;
}

body.home .search .search__wrap form .search__box input,
body.lp .search .search__wrap form .search__box input,
body.page .search .search__wrap form .search__box input {
  background-color: #E3EDF4;
  border-radius: 20px;
  height: 40px;
  width: 300px;
  border: none;
  padding: 0 40px 0 20px;
}

@media (max-width: 767.98px) {
  body.home .search .search__wrap form .search__box input,
  body.lp .search .search__wrap form .search__box input,
  body.page .search .search__wrap form .search__box input {
    width: auto;
  }
}

body.home .search .search__wrap form .search__box button,
body.lp .search .search__wrap form .search__box button,
body.page .search .search__wrap form .search__box button {
  display: inline-block;
  background: url(../img/common/icon_search02.svg) no-repeat;
  background-size: 40px 40px;
  width: 40px;
  height: 40px;
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  border: none;
  cursor: pointer;
}

body.home .search .tag__wrap .tag__title,
body.lp .search .tag__wrap .tag__title,
body.page .search .tag__wrap .tag__title {
  font-size: 1.125rem;
}

body.home .search .tag__wrap .tag__title:before,
body.lp .search .tag__wrap .tag__title:before,
body.page .search .tag__wrap .tag__title:before {
  content: "";
  display: inline-block;
  background: url(../img/common/icon_tag.svg) no-repeat;
  background-size: 25px 25px;
  width: 25px;
  height: 25px;
  vertical-align: middle;
  margin-right: 0.5rem;
}

body.home .search .tag__wrap .label__lists,
body.lp .search .tag__wrap .label__lists,
body.page .search .tag__wrap .label__lists {
  letter-spacing: normal;
  list-style: none;
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
}

body.home .search .tag__wrap .label__lists li,
body.lp .search .tag__wrap .label__lists li,
body.page .search .tag__wrap .label__lists li {
  margin: .3rem 0 .3rem .5rem;
}

body.home .search .tag__wrap .label__lists li a,
body.lp .search .tag__wrap .label__lists li a,
body.page .search .tag__wrap .label__lists li a {
  background-color: #E3EDF4;
  border-radius: 20px;
  padding: 0.5rem 1rem;
  display: block;
  text-decoration: none;
  color: #333;
}

body.home.use_article .pickup__head,
body.lp.use_article .pickup__head,
body.page.use_article .pickup__head {
  font-size: 2.4375rem;
  font-family: "游明朝", YuMincho, "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
  border-left: none;
  padding-left: 0;
}

@media all and (-ms-high-contrast: none) {
  body.home.use_article .pickup__head,
  body.lp.use_article .pickup__head,
  body.page.use_article .pickup__head {
    font-family: "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
  }
}

@media (max-width: 1024.98px) {
  body.home.use_article .pickup__head,
  body.lp.use_article .pickup__head,
  body.page.use_article .pickup__head {
    font-family: ryo-text-plusn, "游明朝", YuMincho, "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
  }
}

body.home.use_article .pickup__head span,
body.lp.use_article .pickup__head span,
body.page.use_article .pickup__head span {
  color: #727272;
  font-size: 1.0625rem;
  margin-left: .5rem;
  font-weight: bold;
}

body.home.use_article .filter .filter__title,
body.lp.use_article .filter .filter__title,
body.page.use_article .filter .filter__title {
  font-size: 1.125rem;
  border-bottom: 2px solid #00729C;
  padding-bottom: .5em;
  margin-bottom: 2rem;
}

body.home.use_article .filter a,
body.lp.use_article .filter a,
body.page.use_article .filter a {
  text-decoration: none;
  color: #333;
  padding: .5rem 0;
  transition: .2s;
}

body.home.use_article .filter a:hover,
body.lp.use_article .filter a:hover,
body.page.use_article .filter a:hover {
  opacity: .7;
}

body.home.use_article .filter a:before,
body.lp.use_article .filter a:before,
body.page.use_article .filter a:before {
  content: "";
  display: inline-block;
  background: url(../img/common/icon_filter.svg) no-repeat;
  background-size: 24px 13px;
  width: 24px;
  height: 13px;
  vertical-align: middle;
  margin-bottom: 2px;
  margin-right: 0.5rem;
}

body.home.use_article .filter a:after,
body.lp.use_article .filter a:after,
body.page.use_article .filter a:after {
  content: "";
  display: inline-block;
  border-top: 8px solid #333;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  vertical-align: middle;
  margin-bottom: 2px;
  margin-left: .5rem;
  transition: .2s;
}

body.home.use_article .filter a.is_active:after,
body.lp.use_article .filter a.is_active:after,
body.page.use_article .filter a.is_active:after {
  transform: rotate(180deg);
}

body.home.use_article .filter .filter__content,
body.lp.use_article .filter .filter__content,
body.page.use_article .filter .filter__content {
  display: none;
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid #888;
}

body.home.use_article .filter .filter__content__title,
body.lp.use_article .filter .filter__content__title,
body.page.use_article .filter .filter__content__title {
  letter-spacing: -.4em;
}

body.home.use_article .filter .filter__content__title > *,
body.lp.use_article .filter .filter__content__title > *,
body.page.use_article .filter .filter__content__title > * {
  letter-spacing: normal;
  display: inline-block;
}

@media (max-width: 767.98px) {
  body.home.use_article .filter .filter__content__title .check__select,
  body.lp.use_article .filter .filter__content__title .check__select,
  body.page.use_article .filter .filter__content__title .check__select {
    margin-left: 0;
  }
}

body.home.use_article .filter .filter__content__title .check__select,
body.lp.use_article .filter .filter__content__title .check__select,
body.page.use_article .filter .filter__content__title .check__select {
  background-color: #015CAE;
  font-weight: bold;
  margin-left: 1rem;
  padding: .5rem 2rem;
  cursor: pointer;
  color: #fff;
  margin-bottom: 1rem;
}

@media (max-width: 767.98px) {
  body.home.use_article .filter .filter__content__title .check__select,
  body.lp.use_article .filter .filter__content__title .check__select,
  body.page.use_article .filter .filter__content__title .check__select {
    margin-left: 0;
  }
}

body.home.use_article .filter .filter__content__title .check__release,
body.lp.use_article .filter .filter__content__title .check__release,
body.page.use_article .filter .filter__content__title .check__release {
  letter-spacing: normal;
  background-color: #888;
  color: #fff;
  font-weight: bold;
  margin-left: .5rem;
  padding: .5rem 2rem;
  position: relative;
  cursor: pointer;
  margin-bottom: 1rem;
}

body.home.use_article .filter .filter__content__title .check__release:before,
body.lp.use_article .filter .filter__content__title .check__release:before,
body.page.use_article .filter .filter__content__title .check__release:before {
  content: "";
  display: inline-block;
  width: 15px;
  height: 2px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 10px;
  margin: auto;
  background-color: #fff;
  transform: rotate(45deg);
}

body.home.use_article .filter .filter__content__title .check__release:after,
body.lp.use_article .filter .filter__content__title .check__release:after,
body.page.use_article .filter .filter__content__title .check__release:after {
  content: "";
  display: inline-block;
  width: 15px;
  height: 2px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 10px;
  margin: auto;
  background-color: #fff;
  transform: rotate(-45deg);
}

body.home.use_article .filter .filter__content__title .title,
body.lp.use_article .filter .filter__content__title .title,
body.page.use_article .filter .filter__content__title .title {
  position: relative;
  padding-left: 25px;
  font-weight: bold;
  font-size: 1.125rem;
}

@media (max-width: 767.98px) {
  body.home.use_article .filter .filter__content__title .title,
  body.lp.use_article .filter .filter__content__title .title,
  body.page.use_article .filter .filter__content__title .title {
    display: block;
  }
}

body.home.use_article .filter .filter__content__title .title:before,
body.lp.use_article .filter .filter__content__title .title:before,
body.page.use_article .filter .filter__content__title .title:before {
  content: "";
  position: absolute;
  display: inline-block;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  vertical-align: middle;
  background-color: #015CAE;
}

body.home.use_article .filter .filter__content__title .title:after,
body.lp.use_article .filter .filter__content__title .title:after,
body.page.use_article .filter .filter__content__title .title:after {
  content: "";
  position: absolute;
  display: inline-block;
  top: 0;
  bottom: 0;
  left: 4px;
  margin: auto;
  width: 7px;
  height: 7px;
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
  transform: rotate(-45deg);
}

body.home.use_article .filter .filter__year__content,
body.lp.use_article .filter .filter__year__content,
body.page.use_article .filter .filter__year__content {
  margin-bottom: 2rem;
}

@media (max-width: 767.98px) {
  body.home.use_article .filter .filter__year__content,
  body.lp.use_article .filter .filter__year__content,
  body.page.use_article .filter .filter__year__content {
    margin-bottom: 1rem;
  }
}

body.home.use_article .filter .filter__year__content select,
body.lp.use_article .filter .filter__year__content select,
body.page.use_article .filter .filter__year__content select {
  width: 100px;
  height: 40px;
  font-size: 1.125rem;
  background: #E3EDF4;
  border: none;
}

body.home.use_article .filter .filter__period,
body.lp.use_article .filter .filter__period,
body.page.use_article .filter .filter__period {
  margin-bottom: 3rem;
}

body.home.use_article .filter .filter__period .filter__period__content,
body.lp.use_article .filter .filter__period .filter__period__content,
body.page.use_article .filter .filter__period .filter__period__content {
  font-weight: bold;
}

body.home.use_article .filter .filter__period .filter__period__content ul,
body.lp.use_article .filter .filter__period .filter__period__content ul,
body.page.use_article .filter .filter__period .filter__period__content ul {
  padding-left: 0;
  list-style: none;
}

body.home.use_article .filter .filter__period .filter__period__content .period__items,
body.lp.use_article .filter .filter__period .filter__period__content .period__items,
body.page.use_article .filter .filter__period .filter__period__content .period__items {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
}

body.home.use_article .filter .filter__period .filter__period__content .period__items .all__season,
body.lp.use_article .filter .filter__period .filter__period__content .period__items .all__season,
body.page.use_article .filter .filter__period .filter__period__content .period__items .all__season {
  width: 100%;
  margin-bottom: .5rem;
}

body.home.use_article .filter .filter__period .filter__period__content .period__items .period__item,
body.lp.use_article .filter .filter__period .filter__period__content .period__items .period__item,
body.page.use_article .filter .filter__period .filter__period__content .period__items .period__item {
  padding-bottom: .5rem;
}

@media (min-width: 1025px) {
  body.home.use_article .filter .filter__period .filter__period__content .period__items .period__item,
  body.lp.use_article .filter .filter__period .filter__period__content .period__items .period__item,
  body.page.use_article .filter .filter__period .filter__period__content .period__items .period__item {
    width: calc( 100% / 4);
  }
  body.home.use_article .filter .filter__period .filter__period__content .period__items .period__item:not(:nth-of-type(4n+5)),
  body.lp.use_article .filter .filter__period .filter__period__content .period__items .period__item:not(:nth-of-type(4n+5)),
  body.page.use_article .filter .filter__period .filter__period__content .period__items .period__item:not(:nth-of-type(4n+5)) {
    padding-right: .5rem;
  }
}

@media (max-width: 1024.98px) {
  body.home.use_article .filter .filter__period .filter__period__content .period__items .period__item,
  body.lp.use_article .filter .filter__period .filter__period__content .period__items .period__item,
  body.page.use_article .filter .filter__period .filter__period__content .period__items .period__item {
    width: 50%;
  }
  body.home.use_article .filter .filter__period .filter__period__content .period__items .period__item:not(:nth-of-type(2n+3)),
  body.lp.use_article .filter .filter__period .filter__period__content .period__items .period__item:not(:nth-of-type(2n+3)),
  body.page.use_article .filter .filter__period .filter__period__content .period__items .period__item:not(:nth-of-type(2n+3)) {
    padding-right: .5rem;
  }
}

body.home.use_article .filter .filter__period .filter__period__content .period__items .period__item .period__item__block,
body.lp.use_article .filter .filter__period .filter__period__content .period__items .period__item .period__item__block,
body.page.use_article .filter .filter__period .filter__period__content .period__items .period__item .period__item__block {
  display: flex;
  flex-wrap: wrap;
}

body.home.use_article .filter .filter__period .filter__period__content .period__items .period__item .period__item__block li,
body.lp.use_article .filter .filter__period .filter__period__content .period__items .period__item .period__item__block li,
body.page.use_article .filter .filter__period .filter__period__content .period__items .period__item .period__item__block li {
  width: calc(100% / 3);
}

body.home.use_article .filter .filter__period .filter__period__content .period__items .period__item .period__item__block li:first-of-type,
body.lp.use_article .filter .filter__period .filter__period__content .period__items .period__item .period__item__block li:first-of-type,
body.page.use_article .filter .filter__period .filter__period__content .period__items .period__item .period__item__block li:first-of-type {
  width: 100%;
  padding-bottom: 2px;
}

body.home.use_article .filter .filter__period .filter__period__content .period__items .period__item .period__item__block li:not(:first-of-type):not(:last-of-type),
body.lp.use_article .filter .filter__period .filter__period__content .period__items .period__item .period__item__block li:not(:first-of-type):not(:last-of-type),
body.page.use_article .filter .filter__period .filter__period__content .period__items .period__item .period__item__block li:not(:first-of-type):not(:last-of-type) {
  padding-right: 2px;
}

body.home.use_article .filter .filter__period .filter__period__content .period__items .period__check-item,
body.lp.use_article .filter .filter__period .filter__period__content .period__items .period__check-item,
body.page.use_article .filter .filter__period .filter__period__content .period__items .period__check-item {
  background-color: #E3EDF4;
  display: block;
  margin-bottom: 0;
  cursor: pointer;
  position: relative;
  transition: .2s;
  height: 100%;
}

body.home.use_article .filter .filter__period .filter__period__content .period__items .period__check-item.is_checked,
body.lp.use_article .filter .filter__period .filter__period__content .period__items .period__check-item.is_checked,
body.page.use_article .filter .filter__period .filter__period__content .period__items .period__check-item.is_checked {
  background-color: #015CAE;
  color: #fff;
}

body.home.use_article .filter .filter__period .filter__period__content .period__items .period__check-item input,
body.lp.use_article .filter .filter__period .filter__period__content .period__items .period__check-item input,
body.page.use_article .filter .filter__period .filter__period__content .period__items .period__check-item input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

body.home.use_article .filter .filter__period .filter__period__content .period__items .period__check-item input:focus + .checkbox__txt,
body.lp.use_article .filter .filter__period .filter__period__content .period__items .period__check-item input:focus + .checkbox__txt,
body.page.use_article .filter .filter__period .filter__period__content .period__items .period__check-item input:focus + .checkbox__txt {
  outline: 2px solid #adb5bd;
}

body.home.use_article .filter .filter__period .filter__period__content .period__items .checkbox__txt,
body.lp.use_article .filter .filter__period .filter__period__content .period__items .checkbox__txt,
body.page.use_article .filter .filter__period .filter__period__content .period__items .checkbox__txt {
  margin-bottom: 0;
  padding: .5rem;
  height: 100%;
}

@media (max-width: 767.98px) {
  body.home.use_article .filter .filter__period .filter__period__content .period__items .checkbox__txt,
  body.lp.use_article .filter .filter__period .filter__period__content .period__items .checkbox__txt,
  body.page.use_article .filter .filter__period .filter__period__content .period__items .checkbox__txt {
    padding: .5rem .2rem;
  }
}

body.home.use_article .filter .filter__category,
body.lp.use_article .filter .filter__category,
body.page.use_article .filter .filter__category {
  margin-bottom: 3rem;
}

body.home.use_article .filter .filter__category .filter__category__content,
body.lp.use_article .filter .filter__category .filter__category__content,
body.page.use_article .filter .filter__category .filter__category__content {
  font-weight: bold;
}

@media (min-width: 768px) {
  body.home.use_article .filter .filter__category .filter__category__content,
  body.lp.use_article .filter .filter__category .filter__category__content,
  body.page.use_article .filter .filter__category .filter__category__content {
    display: flex;
    flex-wrap: wrap;
  }
}

body.home.use_article .filter .filter__category .filter__category__content ul,
body.lp.use_article .filter .filter__category .filter__category__content ul,
body.page.use_article .filter .filter__category .filter__category__content ul {
  padding-left: 0;
  list-style: none;
}

body.home.use_article .filter .filter__category .filter__category__content .category__parent,
body.lp.use_article .filter .filter__category .filter__category__content .category__parent,
body.page.use_article .filter .filter__category .filter__category__content .category__parent {
  width: calc(94% / 3);
}

body.home.use_article .filter .filter__category .filter__category__content .category__parent:not(:last-of-type),
body.lp.use_article .filter .filter__category .filter__category__content .category__parent:not(:last-of-type),
body.page.use_article .filter .filter__category .filter__category__content .category__parent:not(:last-of-type) {
  margin-right: 3%;
}

@media (max-width: 767.98px) {
  body.home.use_article .filter .filter__category .filter__category__content .category__parent,
  body.lp.use_article .filter .filter__category .filter__category__content .category__parent,
  body.page.use_article .filter .filter__category .filter__category__content .category__parent {
    margin-right: 0;
    width: 100%;
  }
}

body.home.use_article .filter .filter__category .filter__category__content .category__parent .category__head,
body.lp.use_article .filter .filter__category .filter__category__content .category__parent .category__head,
body.page.use_article .filter .filter__category .filter__category__content .category__parent .category__head {
  font-size: 2.1875rem;
  font-family: "游明朝", YuMincho, "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
  font-weight: normal;
}

@media all and (-ms-high-contrast: none) {
  body.home.use_article .filter .filter__category .filter__category__content .category__parent .category__head,
  body.lp.use_article .filter .filter__category .filter__category__content .category__parent .category__head,
  body.page.use_article .filter .filter__category .filter__category__content .category__parent .category__head {
    font-family: "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
  }
}

body.home.use_article .filter .filter__category .filter__category__content .category__parent .category__items .category__item,
body.lp.use_article .filter .filter__category .filter__category__content .category__parent .category__items .category__item,
body.page.use_article .filter .filter__category .filter__category__content .category__parent .category__items .category__item {
  margin-bottom: 1px;
}

body.home.use_article .filter .filter__category .filter__category__content .category__parent .category__items .category__item .category__check-item,
body.lp.use_article .filter .filter__category .filter__category__content .category__parent .category__items .category__item .category__check-item,
body.page.use_article .filter .filter__category .filter__category__content .category__parent .category__items .category__item .category__check-item {
  display: block;
  margin-bottom: 0;
  cursor: pointer;
  position: relative;
  transition: .2s;
}

body.home.use_article .filter .filter__category .filter__category__content .category__parent .category__items .category__item .category__check-item.is_checked,
body.lp.use_article .filter .filter__category .filter__category__content .category__parent .category__items .category__item .category__check-item.is_checked,
body.page.use_article .filter .filter__category .filter__category__content .category__parent .category__items .category__item .category__check-item.is_checked {
  color: #fff;
}

body.home.use_article .filter .filter__category .filter__category__content .category__parent .category__items .category__item .category__check-item input,
body.lp.use_article .filter .filter__category .filter__category__content .category__parent .category__items .category__item .category__check-item input,
body.page.use_article .filter .filter__category .filter__category__content .category__parent .category__items .category__item .category__check-item input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

body.home.use_article .filter .filter__category .filter__category__content .category__parent .category__items .category__item .category__check-item input:focus + .checkbox__txt,
body.lp.use_article .filter .filter__category .filter__category__content .category__parent .category__items .category__item .category__check-item input:focus + .checkbox__txt,
body.page.use_article .filter .filter__category .filter__category__content .category__parent .category__items .category__item .category__check-item input:focus + .checkbox__txt {
  outline: 2px solid #adb5bd;
}

body.home.use_article .filter .filter__category .filter__category__content .category__parent .category__items .category__item .checkbox__txt,
body.lp.use_article .filter .filter__category .filter__category__content .category__parent .category__items .category__item .checkbox__txt,
body.page.use_article .filter .filter__category .filter__category__content .category__parent .category__items .category__item .checkbox__txt {
  margin-bottom: 0;
  padding: .5rem;
  height: 100%;
}

@media (max-width: 767.98px) {
  body.home.use_article .filter .filter__category .filter__category__content .category__parent .category__items .category__item .checkbox__txt,
  body.lp.use_article .filter .filter__category .filter__category__content .category__parent .category__items .category__item .checkbox__txt,
  body.page.use_article .filter .filter__category .filter__category__content .category__parent .category__items .category__item .checkbox__txt {
    padding: .5rem;
  }
}

body.home.use_article .filter .filter__category .filter__category__content .category__parent.category__know .category__head,
body.lp.use_article .filter .filter__category .filter__category__content .category__parent.category__know .category__head,
body.page.use_article .filter .filter__category .filter__category__content .category__parent.category__know .category__head {
  color: #E71F26;
}

@media (max-width: 767.98px) {
  body.home.use_article .filter .filter__category .filter__category__content .category__parent.category__know .category__head,
  body.lp.use_article .filter .filter__category .filter__category__content .category__parent.category__know .category__head,
  body.page.use_article .filter .filter__category .filter__category__content .category__parent.category__know .category__head {
    margin-bottom: 0;
  }
}

body.home.use_article .filter .filter__category .filter__category__content .category__parent.category__know .category__items .category__item .category__check-item,
body.lp.use_article .filter .filter__category .filter__category__content .category__parent.category__know .category__items .category__item .category__check-item,
body.page.use_article .filter .filter__category .filter__category__content .category__parent.category__know .category__items .category__item .category__check-item {
  background-color: rgba(231, 31, 38, 0.2);
}

body.home.use_article .filter .filter__category .filter__category__content .category__parent.category__know .category__items .category__item .category__check-item.is_checked,
body.lp.use_article .filter .filter__category .filter__category__content .category__parent.category__know .category__items .category__item .category__check-item.is_checked,
body.page.use_article .filter .filter__category .filter__category__content .category__parent.category__know .category__items .category__item .category__check-item.is_checked {
  background-color: #E71F26;
}

body.home.use_article .filter .filter__category .filter__category__content .category__parent.category__visit .category__head,
body.lp.use_article .filter .filter__category .filter__category__content .category__parent.category__visit .category__head,
body.page.use_article .filter .filter__category .filter__category__content .category__parent.category__visit .category__head {
  color: #015CAE;
}

@media (max-width: 767.98px) {
  body.home.use_article .filter .filter__category .filter__category__content .category__parent.category__visit .category__head,
  body.lp.use_article .filter .filter__category .filter__category__content .category__parent.category__visit .category__head,
  body.page.use_article .filter .filter__category .filter__category__content .category__parent.category__visit .category__head {
    margin-bottom: 0;
  }
}

body.home.use_article .filter .filter__category .filter__category__content .category__parent.category__visit .category__items .category__item .category__check-item,
body.lp.use_article .filter .filter__category .filter__category__content .category__parent.category__visit .category__items .category__item .category__check-item,
body.page.use_article .filter .filter__category .filter__category__content .category__parent.category__visit .category__items .category__item .category__check-item {
  background-color: rgba(1, 92, 174, 0.2);
}

body.home.use_article .filter .filter__category .filter__category__content .category__parent.category__visit .category__items .category__item .category__check-item.is_checked,
body.lp.use_article .filter .filter__category .filter__category__content .category__parent.category__visit .category__items .category__item .category__check-item.is_checked,
body.page.use_article .filter .filter__category .filter__category__content .category__parent.category__visit .category__items .category__item .category__check-item.is_checked {
  background-color: #015CAE;
}

body.home.use_article .filter .filter__category .filter__category__content .category__parent.category__fun .category__head,
body.lp.use_article .filter .filter__category .filter__category__content .category__parent.category__fun .category__head,
body.page.use_article .filter .filter__category .filter__category__content .category__parent.category__fun .category__head {
  color: #168836;
}

@media (max-width: 767.98px) {
  body.home.use_article .filter .filter__category .filter__category__content .category__parent.category__fun .category__head,
  body.lp.use_article .filter .filter__category .filter__category__content .category__parent.category__fun .category__head,
  body.page.use_article .filter .filter__category .filter__category__content .category__parent.category__fun .category__head {
    margin-bottom: 0;
  }
}

body.home.use_article .filter .filter__category .filter__category__content .category__parent.category__fun .category__items .category__item .category__check-item,
body.lp.use_article .filter .filter__category .filter__category__content .category__parent.category__fun .category__items .category__item .category__check-item,
body.page.use_article .filter .filter__category .filter__category__content .category__parent.category__fun .category__items .category__item .category__check-item {
  background-color: rgba(22, 136, 54, 0.2);
}

body.home.use_article .filter .filter__category .filter__category__content .category__parent.category__fun .category__items .category__item .category__check-item.is_checked,
body.lp.use_article .filter .filter__category .filter__category__content .category__parent.category__fun .category__items .category__item .category__check-item.is_checked,
body.page.use_article .filter .filter__category .filter__category__content .category__parent.category__fun .category__items .category__item .category__check-item.is_checked {
  background-color: #168836;
}

body.home.use_article .filter .filter__region,
body.lp.use_article .filter .filter__region,
body.page.use_article .filter .filter__region {
  margin-bottom: 3rem;
}

body.home.use_article .filter .filter__region .filter__region__content,
body.lp.use_article .filter .filter__region .filter__region__content,
body.page.use_article .filter .filter__region .filter__region__content {
  font-weight: bold;
}

@media (min-width: 768px) {
  body.home.use_article .filter .filter__region .filter__region__content,
  body.lp.use_article .filter .filter__region .filter__region__content,
  body.page.use_article .filter .filter__region .filter__region__content {
    display: flex;
    flex-wrap: wrap;
  }
}

body.home.use_article .filter .filter__region .filter__region__content ul,
body.lp.use_article .filter .filter__region .filter__region__content ul,
body.page.use_article .filter .filter__region .filter__region__content ul {
  padding-left: 0;
  list-style: none;
}

@media (min-width: 1025px) {
  body.home.use_article .filter .filter__region .filter__region__content .region__parent,
  body.lp.use_article .filter .filter__region .filter__region__content .region__parent,
  body.page.use_article .filter .filter__region .filter__region__content .region__parent {
    width: calc(94% / 3);
  }
  body.home.use_article .filter .filter__region .filter__region__content .region__parent:not(:last-of-type),
  body.lp.use_article .filter .filter__region .filter__region__content .region__parent:not(:last-of-type),
  body.page.use_article .filter .filter__region .filter__region__content .region__parent:not(:last-of-type) {
    margin-right: 3%;
  }
}

@media (min-width: 768px) and (max-width: 1024.98px) {
  body.home.use_article .filter .filter__region .filter__region__content .region__parent,
  body.lp.use_article .filter .filter__region .filter__region__content .region__parent,
  body.page.use_article .filter .filter__region .filter__region__content .region__parent {
    width: calc(95% / 2);
  }
  body.home.use_article .filter .filter__region .filter__region__content .region__parent:first-of-type,
  body.lp.use_article .filter .filter__region .filter__region__content .region__parent:first-of-type,
  body.page.use_article .filter .filter__region .filter__region__content .region__parent:first-of-type {
    margin-right: 5%;
  }
}

@media (max-width: 767.98px) {
  body.home.use_article .filter .filter__region .filter__region__content .region__parent,
  body.lp.use_article .filter .filter__region .filter__region__content .region__parent,
  body.page.use_article .filter .filter__region .filter__region__content .region__parent {
    margin-right: 0;
    width: 100%;
  }
}

body.home.use_article .filter .filter__region .filter__region__content .region__parent .region__head,
body.lp.use_article .filter .filter__region .filter__region__content .region__parent .region__head,
body.page.use_article .filter .filter__region .filter__region__content .region__parent .region__head {
  display: flex;
}

body.home.use_article .filter .filter__region .filter__region__content .region__parent .region__head .region__head__txt,
body.lp.use_article .filter .filter__region .filter__region__content .region__parent .region__head .region__head__txt,
body.page.use_article .filter .filter__region .filter__region__content .region__parent .region__head .region__head__txt {
  padding: .2rem 0;
  color: #00729C;
  font-size: 1.125rem;
}

body.home.use_article .filter .filter__region .filter__region__content .region__parent .region__head .check__select,
body.lp.use_article .filter .filter__region .filter__region__content .region__parent .region__head .check__select,
body.page.use_article .filter .filter__region .filter__region__content .region__parent .region__head .check__select {
  background-color: #015CAE;
  font-weight: bold;
  margin-left: 1rem;
  margin-bottom: 1rem;
  padding: .5rem;
  cursor: pointer;
  color: #fff;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
}

body.home.use_article .filter .filter__region .filter__region__content .region__parent .region__head .check__release,
body.lp.use_article .filter .filter__region .filter__region__content .region__parent .region__head .check__release,
body.page.use_article .filter .filter__region .filter__region__content .region__parent .region__head .check__release {
  letter-spacing: normal;
  background-color: #888;
  color: #fff;
  font-weight: bold;
  margin-left: .5rem;
  margin-bottom: 1rem;
  padding: .5rem;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 0.875rem;
}

body.home.use_article .filter .filter__region .filter__region__content .region__parent .region__items,
body.lp.use_article .filter .filter__region .filter__region__content .region__parent .region__items,
body.page.use_article .filter .filter__region .filter__region__content .region__parent .region__items {
  display: flex;
  flex-wrap: wrap;
}

body.home.use_article .filter .filter__region .filter__region__content .region__parent .region__items .region__item,
body.lp.use_article .filter .filter__region .filter__region__content .region__parent .region__items .region__item,
body.page.use_article .filter .filter__region .filter__region__content .region__parent .region__items .region__item {
  width: calc( (100% - 4px) / 3);
  margin-bottom: 2px;
}

body.home.use_article .filter .filter__region .filter__region__content .region__parent .region__items .region__item:not(:nth-of-type(3n)),
body.lp.use_article .filter .filter__region .filter__region__content .region__parent .region__items .region__item:not(:nth-of-type(3n)),
body.page.use_article .filter .filter__region .filter__region__content .region__parent .region__items .region__item:not(:nth-of-type(3n)) {
  margin-right: 2px;
}

body.home.use_article .filter .filter__region .filter__region__content .region__parent .region__items .region__item .region__check-item,
body.lp.use_article .filter .filter__region .filter__region__content .region__parent .region__items .region__item .region__check-item,
body.page.use_article .filter .filter__region .filter__region__content .region__parent .region__items .region__item .region__check-item {
  display: block;
  margin-bottom: 0;
  cursor: pointer;
  position: relative;
  transition: .2s;
}

body.home.use_article .filter .filter__region .filter__region__content .region__parent .region__items .region__item .region__check-item.is_checked,
body.lp.use_article .filter .filter__region .filter__region__content .region__parent .region__items .region__item .region__check-item.is_checked,
body.page.use_article .filter .filter__region .filter__region__content .region__parent .region__items .region__item .region__check-item.is_checked {
  color: #fff;
}

body.home.use_article .filter .filter__region .filter__region__content .region__parent .region__items .region__item .region__check-item input,
body.lp.use_article .filter .filter__region .filter__region__content .region__parent .region__items .region__item .region__check-item input,
body.page.use_article .filter .filter__region .filter__region__content .region__parent .region__items .region__item .region__check-item input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

body.home.use_article .filter .filter__region .filter__region__content .region__parent .region__items .region__item .region__check-item input:focus + .checkbox__txt,
body.lp.use_article .filter .filter__region .filter__region__content .region__parent .region__items .region__item .region__check-item input:focus + .checkbox__txt,
body.page.use_article .filter .filter__region .filter__region__content .region__parent .region__items .region__item .region__check-item input:focus + .checkbox__txt {
  outline: 2px solid #adb5bd;
}

body.home.use_article .filter .filter__region .filter__region__content .region__parent .region__items .region__item .checkbox__txt,
body.lp.use_article .filter .filter__region .filter__region__content .region__parent .region__items .region__item .checkbox__txt,
body.page.use_article .filter .filter__region .filter__region__content .region__parent .region__items .region__item .checkbox__txt {
  margin-bottom: 0;
  padding: .5rem;
  text-align: center;
  height: 100%;
}

@media (max-width: 767.98px) {
  body.home.use_article .filter .filter__region .filter__region__content .region__parent .region__items .region__item .checkbox__txt,
  body.lp.use_article .filter .filter__region .filter__region__content .region__parent .region__items .region__item .checkbox__txt,
  body.page.use_article .filter .filter__region .filter__region__content .region__parent .region__items .region__item .checkbox__txt {
    padding: .5rem;
  }
}

body.home.use_article .filter .filter__region .filter__region__content .region__parent .region__items .region__item .region__check-item,
body.lp.use_article .filter .filter__region .filter__region__content .region__parent .region__items .region__item .region__check-item,
body.page.use_article .filter .filter__region .filter__region__content .region__parent .region__items .region__item .region__check-item {
  background-color: #E3EDF4;
  height: 100%;
}

body.home.use_article .filter .filter__region .filter__region__content .region__parent .region__items .region__item .region__check-item.is_checked,
body.lp.use_article .filter .filter__region .filter__region__content .region__parent .region__items .region__item .region__check-item.is_checked,
body.page.use_article .filter .filter__region .filter__region__content .region__parent .region__items .region__item .region__check-item.is_checked {
  background-color: #00729C;
  color: #fff;
}

body.home.use_article .filter .filter__road-genre__wrap,
body.lp.use_article .filter .filter__road-genre__wrap,
body.page.use_article .filter .filter__road-genre__wrap {
  display: flex;
  flex-wrap: wrap;
}

body.home.use_article .filter .filter__road-genre__wrap .filter__road-station,
body.lp.use_article .filter .filter__road-genre__wrap .filter__road-station,
body.page.use_article .filter .filter__road-genre__wrap .filter__road-station {
  width: calc(97% / 3);
}

@media (min-width: 768px) and (max-width: 1024.98px) {
  body.home.use_article .filter .filter__road-genre__wrap .filter__road-station,
  body.lp.use_article .filter .filter__road-genre__wrap .filter__road-station,
  body.page.use_article .filter .filter__road-genre__wrap .filter__road-station {
    width: calc(97% / 2);
  }
}

@media (max-width: 767.98px) {
  body.home.use_article .filter .filter__road-genre__wrap .filter__road-station,
  body.lp.use_article .filter .filter__road-genre__wrap .filter__road-station,
  body.page.use_article .filter .filter__road-genre__wrap .filter__road-station {
    width: 100%;
  }
}

body.home.use_article .filter .filter__road-genre__wrap .filter__road-station,
body.lp.use_article .filter .filter__road-genre__wrap .filter__road-station,
body.page.use_article .filter .filter__road-genre__wrap .filter__road-station {
  margin-bottom: 3rem;
}

@media (min-width: 768px) {
  body.home.use_article .filter .filter__road-genre__wrap .filter__road-station,
  body.lp.use_article .filter .filter__road-genre__wrap .filter__road-station,
  body.page.use_article .filter .filter__road-genre__wrap .filter__road-station {
    margin-right: 3%;
  }
}

body.home.use_article .filter .filter__road-genre__wrap .filter__road-station .filter__content__title .title,
body.lp.use_article .filter .filter__road-genre__wrap .filter__road-station .filter__content__title .title,
body.page.use_article .filter .filter__road-genre__wrap .filter__road-station .filter__content__title .title {
  width: 100%;
}

body.home.use_article .filter .filter__road-genre__wrap .filter__road-station .filter__content__title .check__select,
body.lp.use_article .filter .filter__road-genre__wrap .filter__road-station .filter__content__title .check__select,
body.page.use_article .filter .filter__road-genre__wrap .filter__road-station .filter__content__title .check__select {
  margin-left: 0;
}

body.home.use_article .filter .filter__road-genre__wrap .filter__road-station .filter__road-station__content,
body.lp.use_article .filter .filter__road-genre__wrap .filter__road-station .filter__road-station__content,
body.page.use_article .filter .filter__road-genre__wrap .filter__road-station .filter__road-station__content {
  font-weight: bold;
}

@media (min-width: 768px) {
  body.home.use_article .filter .filter__road-genre__wrap .filter__road-station .filter__road-station__content,
  body.lp.use_article .filter .filter__road-genre__wrap .filter__road-station .filter__road-station__content,
  body.page.use_article .filter .filter__road-genre__wrap .filter__road-station .filter__road-station__content {
    display: flex;
    flex-wrap: wrap;
  }
}

body.home.use_article .filter .filter__road-genre__wrap .filter__road-station .filter__road-station__content ul,
body.lp.use_article .filter .filter__road-genre__wrap .filter__road-station .filter__road-station__content ul,
body.page.use_article .filter .filter__road-genre__wrap .filter__road-station .filter__road-station__content ul {
  padding-left: 0;
  list-style: none;
}

body.home.use_article .filter .filter__road-genre__wrap .filter__road-station .filter__road-station__content .road-station__parent .check__select,
body.lp.use_article .filter .filter__road-genre__wrap .filter__road-station .filter__road-station__content .road-station__parent .check__select,
body.page.use_article .filter .filter__road-genre__wrap .filter__road-station .filter__road-station__content .road-station__parent .check__select {
  background-color: #015CAE;
  font-weight: bold;
  margin-left: 1rem;
  padding: .5rem;
  cursor: pointer;
  color: #fff;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
}

body.home.use_article .filter .filter__road-genre__wrap .filter__road-station .filter__road-station__content .road-station__parent .check__release,
body.lp.use_article .filter .filter__road-genre__wrap .filter__road-station .filter__road-station__content .road-station__parent .check__release,
body.page.use_article .filter .filter__road-genre__wrap .filter__road-station .filter__road-station__content .road-station__parent .check__release {
  letter-spacing: normal;
  background-color: #888;
  color: #fff;
  font-weight: bold;
  margin-left: .5rem;
  padding: .5rem;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 0.875rem;
}

body.home.use_article .filter .filter__road-genre__wrap .filter__road-station .filter__road-station__content .road-station__parent .road-station__items,
body.lp.use_article .filter .filter__road-genre__wrap .filter__road-station .filter__road-station__content .road-station__parent .road-station__items,
body.page.use_article .filter .filter__road-genre__wrap .filter__road-station .filter__road-station__content .road-station__parent .road-station__items {
  display: flex;
  flex-wrap: wrap;
}

body.home.use_article .filter .filter__road-genre__wrap .filter__road-station .filter__road-station__content .road-station__parent .road-station__items .road-station__item,
body.lp.use_article .filter .filter__road-genre__wrap .filter__road-station .filter__road-station__content .road-station__parent .road-station__items .road-station__item,
body.page.use_article .filter .filter__road-genre__wrap .filter__road-station .filter__road-station__content .road-station__parent .road-station__items .road-station__item {
  width: calc( (100% - 4px) / 3);
  margin-bottom: 2px;
}

body.home.use_article .filter .filter__road-genre__wrap .filter__road-station .filter__road-station__content .road-station__parent .road-station__items .road-station__item:not(:nth-of-type(3n)),
body.lp.use_article .filter .filter__road-genre__wrap .filter__road-station .filter__road-station__content .road-station__parent .road-station__items .road-station__item:not(:nth-of-type(3n)),
body.page.use_article .filter .filter__road-genre__wrap .filter__road-station .filter__road-station__content .road-station__parent .road-station__items .road-station__item:not(:nth-of-type(3n)) {
  margin-right: 2px;
}

body.home.use_article .filter .filter__road-genre__wrap .filter__road-station .filter__road-station__content .road-station__parent .road-station__items .road-station__item .road-station__check-item,
body.lp.use_article .filter .filter__road-genre__wrap .filter__road-station .filter__road-station__content .road-station__parent .road-station__items .road-station__item .road-station__check-item,
body.page.use_article .filter .filter__road-genre__wrap .filter__road-station .filter__road-station__content .road-station__parent .road-station__items .road-station__item .road-station__check-item {
  display: block;
  margin-bottom: 0;
  cursor: pointer;
  position: relative;
  transition: .2s;
}

body.home.use_article .filter .filter__road-genre__wrap .filter__road-station .filter__road-station__content .road-station__parent .road-station__items .road-station__item .road-station__check-item.is_checked,
body.lp.use_article .filter .filter__road-genre__wrap .filter__road-station .filter__road-station__content .road-station__parent .road-station__items .road-station__item .road-station__check-item.is_checked,
body.page.use_article .filter .filter__road-genre__wrap .filter__road-station .filter__road-station__content .road-station__parent .road-station__items .road-station__item .road-station__check-item.is_checked {
  color: #fff;
}

body.home.use_article .filter .filter__road-genre__wrap .filter__road-station .filter__road-station__content .road-station__parent .road-station__items .road-station__item .road-station__check-item input,
body.lp.use_article .filter .filter__road-genre__wrap .filter__road-station .filter__road-station__content .road-station__parent .road-station__items .road-station__item .road-station__check-item input,
body.page.use_article .filter .filter__road-genre__wrap .filter__road-station .filter__road-station__content .road-station__parent .road-station__items .road-station__item .road-station__check-item input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

body.home.use_article .filter .filter__road-genre__wrap .filter__road-station .filter__road-station__content .road-station__parent .road-station__items .road-station__item .road-station__check-item input:focus + .checkbox__txt,
body.lp.use_article .filter .filter__road-genre__wrap .filter__road-station .filter__road-station__content .road-station__parent .road-station__items .road-station__item .road-station__check-item input:focus + .checkbox__txt,
body.page.use_article .filter .filter__road-genre__wrap .filter__road-station .filter__road-station__content .road-station__parent .road-station__items .road-station__item .road-station__check-item input:focus + .checkbox__txt {
  outline: 2px solid #adb5bd;
}

body.home.use_article .filter .filter__road-genre__wrap .filter__road-station .filter__road-station__content .road-station__parent .road-station__items .road-station__item .checkbox__txt,
body.lp.use_article .filter .filter__road-genre__wrap .filter__road-station .filter__road-station__content .road-station__parent .road-station__items .road-station__item .checkbox__txt,
body.page.use_article .filter .filter__road-genre__wrap .filter__road-station .filter__road-station__content .road-station__parent .road-station__items .road-station__item .checkbox__txt {
  margin-bottom: 0;
  padding: .5rem;
  text-align: center;
  height: 100%;
}

@media (max-width: 767.98px) {
  body.home.use_article .filter .filter__road-genre__wrap .filter__road-station .filter__road-station__content .road-station__parent .road-station__items .road-station__item .checkbox__txt,
  body.lp.use_article .filter .filter__road-genre__wrap .filter__road-station .filter__road-station__content .road-station__parent .road-station__items .road-station__item .checkbox__txt,
  body.page.use_article .filter .filter__road-genre__wrap .filter__road-station .filter__road-station__content .road-station__parent .road-station__items .road-station__item .checkbox__txt {
    padding: .5rem;
  }
}

body.home.use_article .filter .filter__road-genre__wrap .filter__road-station .filter__road-station__content .road-station__parent .road-station__items .road-station__item .road-station__check-item,
body.lp.use_article .filter .filter__road-genre__wrap .filter__road-station .filter__road-station__content .road-station__parent .road-station__items .road-station__item .road-station__check-item,
body.page.use_article .filter .filter__road-genre__wrap .filter__road-station .filter__road-station__content .road-station__parent .road-station__items .road-station__item .road-station__check-item {
  background-color: #E3EDF4;
  height: 100%;
}

body.home.use_article .filter .filter__road-genre__wrap .filter__road-station .filter__road-station__content .road-station__parent .road-station__items .road-station__item .road-station__check-item.is_checked,
body.lp.use_article .filter .filter__road-genre__wrap .filter__road-station .filter__road-station__content .road-station__parent .road-station__items .road-station__item .road-station__check-item.is_checked,
body.page.use_article .filter .filter__road-genre__wrap .filter__road-station .filter__road-station__content .road-station__parent .road-station__items .road-station__item .road-station__check-item.is_checked {
  background-color: #00729C;
  color: #fff;
}

body.home.use_article .filter .filter__road-genre__wrap .filter__genre,
body.lp.use_article .filter .filter__road-genre__wrap .filter__genre,
body.page.use_article .filter .filter__road-genre__wrap .filter__genre {
  margin-bottom: 3rem;
}

@media (max-width: 767.98px) {
  body.home.use_article .filter .filter__road-genre__wrap .filter__genre,
  body.lp.use_article .filter .filter__road-genre__wrap .filter__genre,
  body.page.use_article .filter .filter__road-genre__wrap .filter__genre {
    margin-bottom: 1rem;
  }
}

body.home.use_article .filter .filter__road-genre__wrap .filter__genre .filter__content__title .title,
body.lp.use_article .filter .filter__road-genre__wrap .filter__genre .filter__content__title .title,
body.page.use_article .filter .filter__road-genre__wrap .filter__genre .filter__content__title .title {
  width: 100%;
}

body.home.use_article .filter .filter__road-genre__wrap .filter__genre .filter__content__title .check__select,
body.lp.use_article .filter .filter__road-genre__wrap .filter__genre .filter__content__title .check__select,
body.page.use_article .filter .filter__road-genre__wrap .filter__genre .filter__content__title .check__select {
  margin-left: 0;
}

body.home.use_article .filter .filter__road-genre__wrap .filter__genre .filter__genre__content,
body.lp.use_article .filter .filter__road-genre__wrap .filter__genre .filter__genre__content,
body.page.use_article .filter .filter__road-genre__wrap .filter__genre .filter__genre__content {
  font-weight: bold;
}

@media (min-width: 768px) {
  body.home.use_article .filter .filter__road-genre__wrap .filter__genre .filter__genre__content,
  body.lp.use_article .filter .filter__road-genre__wrap .filter__genre .filter__genre__content,
  body.page.use_article .filter .filter__road-genre__wrap .filter__genre .filter__genre__content {
    display: flex;
    flex-wrap: wrap;
  }
}

body.home.use_article .filter .filter__road-genre__wrap .filter__genre .filter__genre__content ul,
body.lp.use_article .filter .filter__road-genre__wrap .filter__genre .filter__genre__content ul,
body.page.use_article .filter .filter__road-genre__wrap .filter__genre .filter__genre__content ul {
  padding-left: 0;
  list-style: none;
}

body.home.use_article .filter .filter__road-genre__wrap .filter__genre .filter__genre__content .filter__genre__parent .check__select,
body.lp.use_article .filter .filter__road-genre__wrap .filter__genre .filter__genre__content .filter__genre__parent .check__select,
body.page.use_article .filter .filter__road-genre__wrap .filter__genre .filter__genre__content .filter__genre__parent .check__select {
  background-color: #015CAE;
  font-weight: bold;
  margin-left: 1rem;
  padding: .5rem;
  cursor: pointer;
  color: #fff;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
}

body.home.use_article .filter .filter__road-genre__wrap .filter__genre .filter__genre__content .filter__genre__parent .check__release,
body.lp.use_article .filter .filter__road-genre__wrap .filter__genre .filter__genre__content .filter__genre__parent .check__release,
body.page.use_article .filter .filter__road-genre__wrap .filter__genre .filter__genre__content .filter__genre__parent .check__release {
  letter-spacing: normal;
  background-color: #888;
  color: #fff;
  font-weight: bold;
  margin-left: .5rem;
  padding: .5rem;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 0.875rem;
}

body.home.use_article .filter .filter__road-genre__wrap .filter__genre .filter__genre__content .filter__genre__parent .genre__items,
body.lp.use_article .filter .filter__road-genre__wrap .filter__genre .filter__genre__content .filter__genre__parent .genre__items,
body.page.use_article .filter .filter__road-genre__wrap .filter__genre .filter__genre__content .filter__genre__parent .genre__items {
  display: flex;
  flex-wrap: wrap;
}

body.home.use_article .filter .filter__road-genre__wrap .filter__genre .filter__genre__content .filter__genre__parent .genre__items .genre__item,
body.lp.use_article .filter .filter__road-genre__wrap .filter__genre .filter__genre__content .filter__genre__parent .genre__items .genre__item,
body.page.use_article .filter .filter__road-genre__wrap .filter__genre .filter__genre__content .filter__genre__parent .genre__items .genre__item {
  margin-bottom: 2px;
}

@media (min-width: 768px) {
  body.home.use_article .filter .filter__road-genre__wrap .filter__genre .filter__genre__content .filter__genre__parent .genre__items .genre__item,
  body.lp.use_article .filter .filter__road-genre__wrap .filter__genre .filter__genre__content .filter__genre__parent .genre__items .genre__item,
  body.page.use_article .filter .filter__road-genre__wrap .filter__genre .filter__genre__content .filter__genre__parent .genre__items .genre__item {
    width: calc( (100% - 10px) / 6);
  }
  body.home.use_article .filter .filter__road-genre__wrap .filter__genre .filter__genre__content .filter__genre__parent .genre__items .genre__item:not(:nth-of-type(6n+6)),
  body.lp.use_article .filter .filter__road-genre__wrap .filter__genre .filter__genre__content .filter__genre__parent .genre__items .genre__item:not(:nth-of-type(6n+6)),
  body.page.use_article .filter .filter__road-genre__wrap .filter__genre .filter__genre__content .filter__genre__parent .genre__items .genre__item:not(:nth-of-type(6n+6)) {
    margin-right: 2px;
  }
}

@media (max-width: 767.98px) {
  body.home.use_article .filter .filter__road-genre__wrap .filter__genre .filter__genre__content .filter__genre__parent .genre__items .genre__item,
  body.lp.use_article .filter .filter__road-genre__wrap .filter__genre .filter__genre__content .filter__genre__parent .genre__items .genre__item,
  body.page.use_article .filter .filter__road-genre__wrap .filter__genre .filter__genre__content .filter__genre__parent .genre__items .genre__item {
    width: calc( (100% - 4px) / 3);
  }
  body.home.use_article .filter .filter__road-genre__wrap .filter__genre .filter__genre__content .filter__genre__parent .genre__items .genre__item:not(:nth-of-type(3n+3)),
  body.lp.use_article .filter .filter__road-genre__wrap .filter__genre .filter__genre__content .filter__genre__parent .genre__items .genre__item:not(:nth-of-type(3n+3)),
  body.page.use_article .filter .filter__road-genre__wrap .filter__genre .filter__genre__content .filter__genre__parent .genre__items .genre__item:not(:nth-of-type(3n+3)) {
    margin-right: 2px;
  }
}

body.home.use_article .filter .filter__road-genre__wrap .filter__genre .filter__genre__content .filter__genre__parent .genre__items .genre__item .genre__check-item,
body.lp.use_article .filter .filter__road-genre__wrap .filter__genre .filter__genre__content .filter__genre__parent .genre__items .genre__item .genre__check-item,
body.page.use_article .filter .filter__road-genre__wrap .filter__genre .filter__genre__content .filter__genre__parent .genre__items .genre__item .genre__check-item {
  display: block;
  margin-bottom: 0;
  cursor: pointer;
  position: relative;
  transition: .2s;
}

body.home.use_article .filter .filter__road-genre__wrap .filter__genre .filter__genre__content .filter__genre__parent .genre__items .genre__item .genre__check-item.is_checked,
body.lp.use_article .filter .filter__road-genre__wrap .filter__genre .filter__genre__content .filter__genre__parent .genre__items .genre__item .genre__check-item.is_checked,
body.page.use_article .filter .filter__road-genre__wrap .filter__genre .filter__genre__content .filter__genre__parent .genre__items .genre__item .genre__check-item.is_checked {
  color: #fff;
}

body.home.use_article .filter .filter__road-genre__wrap .filter__genre .filter__genre__content .filter__genre__parent .genre__items .genre__item .genre__check-item input,
body.lp.use_article .filter .filter__road-genre__wrap .filter__genre .filter__genre__content .filter__genre__parent .genre__items .genre__item .genre__check-item input,
body.page.use_article .filter .filter__road-genre__wrap .filter__genre .filter__genre__content .filter__genre__parent .genre__items .genre__item .genre__check-item input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

body.home.use_article .filter .filter__road-genre__wrap .filter__genre .filter__genre__content .filter__genre__parent .genre__items .genre__item .genre__check-item input:focus + .checkbox__txt,
body.lp.use_article .filter .filter__road-genre__wrap .filter__genre .filter__genre__content .filter__genre__parent .genre__items .genre__item .genre__check-item input:focus + .checkbox__txt,
body.page.use_article .filter .filter__road-genre__wrap .filter__genre .filter__genre__content .filter__genre__parent .genre__items .genre__item .genre__check-item input:focus + .checkbox__txt {
  outline: 2px solid #adb5bd;
}

body.home.use_article .filter .filter__road-genre__wrap .filter__genre .filter__genre__content .filter__genre__parent .genre__items .genre__item .checkbox__txt,
body.lp.use_article .filter .filter__road-genre__wrap .filter__genre .filter__genre__content .filter__genre__parent .genre__items .genre__item .checkbox__txt,
body.page.use_article .filter .filter__road-genre__wrap .filter__genre .filter__genre__content .filter__genre__parent .genre__items .genre__item .checkbox__txt {
  margin-bottom: 0;
  padding: .5rem;
  text-align: center;
  height: 100%;
}

@media (max-width: 767.98px) {
  body.home.use_article .filter .filter__road-genre__wrap .filter__genre .filter__genre__content .filter__genre__parent .genre__items .genre__item .checkbox__txt,
  body.lp.use_article .filter .filter__road-genre__wrap .filter__genre .filter__genre__content .filter__genre__parent .genre__items .genre__item .checkbox__txt,
  body.page.use_article .filter .filter__road-genre__wrap .filter__genre .filter__genre__content .filter__genre__parent .genre__items .genre__item .checkbox__txt {
    padding: .5rem;
  }
}

body.home.use_article .filter .filter__road-genre__wrap .filter__genre .filter__genre__content .filter__genre__parent .genre__items .genre__item .genre__check-item,
body.lp.use_article .filter .filter__road-genre__wrap .filter__genre .filter__genre__content .filter__genre__parent .genre__items .genre__item .genre__check-item,
body.page.use_article .filter .filter__road-genre__wrap .filter__genre .filter__genre__content .filter__genre__parent .genre__items .genre__item .genre__check-item {
  background-color: #E3EDF4;
  height: 100%;
}

body.home.use_article .filter .filter__road-genre__wrap .filter__genre .filter__genre__content .filter__genre__parent .genre__items .genre__item .genre__check-item.is_checked,
body.lp.use_article .filter .filter__road-genre__wrap .filter__genre .filter__genre__content .filter__genre__parent .genre__items .genre__item .genre__check-item.is_checked,
body.page.use_article .filter .filter__road-genre__wrap .filter__genre .filter__genre__content .filter__genre__parent .genre__items .genre__item .genre__check-item.is_checked {
  background-color: #00729C;
  color: #fff;
}

body.home.use_article .filter .form__btns ul,
body.lp.use_article .filter .form__btns ul,
body.page.use_article .filter .form__btns ul {
  list-style: none;
  padding-left: 0;
  display: flex;
  justify-content: center;
  margin-bottom: 0;
  flex-wrap: wrap;
}

@media (max-width: 767.98px) {
  body.home.use_article .filter .form__btns ul li,
  body.lp.use_article .filter .form__btns ul li,
  body.page.use_article .filter .form__btns ul li {
    width: 100%;
  }
}

@media (min-width: 768px) {
  body.home.use_article .filter .form__btns ul li:first-of-type,
  body.lp.use_article .filter .form__btns ul li:first-of-type,
  body.page.use_article .filter .form__btns ul li:first-of-type {
    margin-right: 0.5rem;
  }
}

@media (max-width: 767.98px) {
  body.home.use_article .filter .form__btns ul li:first-of-type,
  body.lp.use_article .filter .form__btns ul li:first-of-type,
  body.page.use_article .filter .form__btns ul li:first-of-type {
    margin-bottom: 0.5rem;
  }
}

body.home.use_article .filter .form__btns ul li button,
body.lp.use_article .filter .form__btns ul li button,
body.page.use_article .filter .form__btns ul li button {
  cursor: pointer;
  color: #fff;
  font-weight: bold;
  border: none;
  padding: 1rem 2rem;
  transition: .2s;
}

@media (max-width: 767.98px) {
  body.home.use_article .filter .form__btns ul li button,
  body.lp.use_article .filter .form__btns ul li button,
  body.page.use_article .filter .form__btns ul li button {
    width: 100%;
  }
}

body.home.use_article .filter .form__btns ul li button.all__release,
body.lp.use_article .filter .form__btns ul li button.all__release,
body.page.use_article .filter .form__btns ul li button.all__release {
  background-color: #343a40;
}

body.home.use_article .filter .form__btns ul li button.filter__submit,
body.lp.use_article .filter .form__btns ul li button.filter__submit,
body.page.use_article .filter .form__btns ul li button.filter__submit {
  background-color: #BB0900;
}

body.home.use_article .filter .form__btns ul li button:hover.all__release,
body.lp.use_article .filter .form__btns ul li button:hover.all__release,
body.page.use_article .filter .form__btns ul li button:hover.all__release {
  background-color: #444c54;
}

body.home.use_article .filter .form__btns ul li button:hover.filter__submit,
body.lp.use_article .filter .form__btns ul li button:hover.filter__submit,
body.page.use_article .filter .form__btns ul li button:hover.filter__submit {
  background-color: #df0b00;
}

body.home .p-common__article,
body.lp .p-common__article,
body.page .p-common__article {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

body.home .p-common__article li,
body.lp .p-common__article li,
body.page .p-common__article li {
  margin-bottom: 1rem;
}

body.home .p-common__article li a,
body.lp .p-common__article li a,
body.page .p-common__article li a {
  text-decoration: none;
  color: #333;
  display: block;
}

body.home .p-common__article li a figure,
body.lp .p-common__article li a figure,
body.page .p-common__article li a figure {
  position: relative;
  margin-bottom: 0;
}

@media (max-width: 767.98px) {
  body.home .p-common__article li a figure,
  body.lp .p-common__article li a figure,
  body.page .p-common__article li a figure {
    letter-spacing: -.4em;
    display: flex;
  }
}

body.home .p-common__article li a figure .article__img__wrap,
body.lp .p-common__article li a figure .article__img__wrap,
body.page .p-common__article li a figure .article__img__wrap {
  position: relative;
  padding-top: 70%;
  overflow: hidden;
  transition: .2s;
}

@media (max-width: 767.98px) {
  body.home .p-common__article li a figure .article__img__wrap,
  body.lp .p-common__article li a figure .article__img__wrap,
  body.page .p-common__article li a figure .article__img__wrap {
    padding-top: 40%;
    width: 50%;
    display: inline-block;
    letter-spacing: normal;
  }
}

body.home .p-common__article li a figure .article__img__wrap:after,
body.lp .p-common__article li a figure .article__img__wrap:after,
body.page .p-common__article li a figure .article__img__wrap:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
  transition: .2s;
  background-color: #00729C;
}

body.home .p-common__article li a figure .article__img__wrap:before,
body.lp .p-common__article li a figure .article__img__wrap:before,
body.page .p-common__article li a figure .article__img__wrap:before {
  content: "";
  display: block;
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  border-bottom: 15px solid #00729C;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  transform: translateY(15px);
  width: 0;
  height: 0;
  transition: .2s;
}

body.home .p-common__article li a figure .article__img__wrap .article__img,
body.lp .p-common__article li a figure .article__img__wrap .article__img,
body.page .p-common__article li a figure .article__img__wrap .article__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

body.home .p-common__article li a figure figcaption,
body.lp .p-common__article li a figure figcaption,
body.page .p-common__article li a figure figcaption {
  padding: 1rem;
}

@media (max-width: 767.98px) {
  body.home .p-common__article li a figure figcaption,
  body.lp .p-common__article li a figure figcaption,
  body.page .p-common__article li a figure figcaption {
    width: 50%;
    display: inline-block;
    letter-spacing: normal;
    vertical-align: top;
    text-align: left;
    padding: .5rem 0 0 1rem;
  }
}

body.home .p-common__article li a figure figcaption .category__block,
body.lp .p-common__article li a figure figcaption .category__block,
body.page .p-common__article li a figure figcaption .category__block {
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  font-family: "游明朝", YuMincho, "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
}

@media all and (-ms-high-contrast: none) {
  body.home .p-common__article li a figure figcaption .category__block,
  body.lp .p-common__article li a figure figcaption .category__block,
  body.page .p-common__article li a figure figcaption .category__block {
    font-family: "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
  }
}

@media (max-width: 767.98px) {
  body.home .p-common__article li a figure figcaption .category__block,
  body.lp .p-common__article li a figure figcaption .category__block,
  body.page .p-common__article li a figure figcaption .category__block {
    font-family: ryo-text-plusn, "游明朝", YuMincho, "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
  }
}

body.home .p-common__article li a figure figcaption .category__block span.parent__label,
body.lp .p-common__article li a figure figcaption .category__block span.parent__label,
body.page .p-common__article li a figure figcaption .category__block span.parent__label {
  color: #00729C;
}

body.home .p-common__article li a figure figcaption .category__block span.parent__label:after,
body.lp .p-common__article li a figure figcaption .category__block span.parent__label:after,
body.page .p-common__article li a figure figcaption .category__block span.parent__label:after {
  content: "";
  display: inline-block;
  width: 15px;
  height: 2px;
  background-color: #00729C;
  vertical-align: middle;
  margin: 0 5px 2px;
}

body.home .p-common__article li a figure figcaption .article__titile,
body.lp .p-common__article li a figure figcaption .article__titile,
body.page .p-common__article li a figure figcaption .article__titile {
  font-size: 1.125rem;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

body.home .p-common__article li a figure figcaption .article__day,
body.lp .p-common__article li a figure figcaption .article__day,
body.page .p-common__article li a figure figcaption .article__day {
  margin-bottom: 0;
}

body.home .p-common__article li a figure figcaption .article__label__lists,
body.lp .p-common__article li a figure figcaption .article__label__lists,
body.page .p-common__article li a figure figcaption .article__label__lists {
  letter-spacing: normal;
  list-style: none;
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0.5rem;
}

body.home .p-common__article li a figure figcaption .article__label__lists li,
body.lp .p-common__article li a figure figcaption .article__label__lists li,
body.page .p-common__article li a figure figcaption .article__label__lists li {
  margin: .2rem .5rem .2rem 0;
  background-color: #E3EDF4;
  border-radius: 20px;
  padding: 0.2rem 0.7rem;
  font-size: 0.8125rem;
}

body.home .p-common__article li a:hover .article__img__wrap,
body.lp .p-common__article li a:hover .article__img__wrap,
body.page .p-common__article li a:hover .article__img__wrap {
  opacity: .7;
}

body.home .p-common__article li a:hover .article__img__wrap:after,
body.lp .p-common__article li a:hover .article__img__wrap:after,
body.page .p-common__article li a:hover .article__img__wrap:after {
  height: 6px;
}

body.home .p-common__article li a:hover .article__img__wrap:before,
body.lp .p-common__article li a:hover .article__img__wrap:before,
body.page .p-common__article li a:hover .article__img__wrap:before {
  transform: translateY(-2px);
}

body.home .p-common__article li.fun__article .article__img__wrap:after,
body.lp .p-common__article li.fun__article .article__img__wrap:after,
body.page .p-common__article li.fun__article .article__img__wrap:after {
  background-color: #168836;
}

body.home .p-common__article li.fun__article .article__img__wrap:before,
body.lp .p-common__article li.fun__article .article__img__wrap:before,
body.page .p-common__article li.fun__article .article__img__wrap:before {
  border-bottom: 15px solid #168836;
}

body.home .p-common__article li.fun__article figcaption .category__block span.parent__label,
body.lp .p-common__article li.fun__article figcaption .category__block span.parent__label,
body.page .p-common__article li.fun__article figcaption .category__block span.parent__label {
  color: #168836;
}

body.home .p-common__article li.fun__article figcaption .category__block span.parent__label:after,
body.lp .p-common__article li.fun__article figcaption .category__block span.parent__label:after,
body.page .p-common__article li.fun__article figcaption .category__block span.parent__label:after {
  background-color: #168836;
}

body.home .p-common__article li.visit__article .article__img__wrap:after,
body.lp .p-common__article li.visit__article .article__img__wrap:after,
body.page .p-common__article li.visit__article .article__img__wrap:after {
  background-color: #015CAE;
}

body.home .p-common__article li.visit__article .article__img__wrap:before,
body.lp .p-common__article li.visit__article .article__img__wrap:before,
body.page .p-common__article li.visit__article .article__img__wrap:before {
  border-bottom: 15px solid #015CAE;
}

body.home .p-common__article li.visit__article figcaption .category__block span.parent__label,
body.lp .p-common__article li.visit__article figcaption .category__block span.parent__label,
body.page .p-common__article li.visit__article figcaption .category__block span.parent__label {
  color: #015CAE;
}

body.home .p-common__article li.visit__article figcaption .category__block span.parent__label:after,
body.lp .p-common__article li.visit__article figcaption .category__block span.parent__label:after,
body.page .p-common__article li.visit__article figcaption .category__block span.parent__label:after {
  background-color: #015CAE;
}

body.home .p-common__article li.know__article .article__img__wrap:after,
body.lp .p-common__article li.know__article .article__img__wrap:after,
body.page .p-common__article li.know__article .article__img__wrap:after {
  background-color: #E71F26;
}

body.home .p-common__article li.know__article .article__img__wrap:before,
body.lp .p-common__article li.know__article .article__img__wrap:before,
body.page .p-common__article li.know__article .article__img__wrap:before {
  border-bottom: 15px solid #E71F26;
}

body.home .p-common__article li.know__article figcaption .category__block span.parent__label,
body.lp .p-common__article li.know__article figcaption .category__block span.parent__label,
body.page .p-common__article li.know__article figcaption .category__block span.parent__label {
  color: #E71F26;
}

body.home .p-common__article li.know__article figcaption .category__block span.parent__label:after,
body.lp .p-common__article li.know__article figcaption .category__block span.parent__label:after,
body.page .p-common__article li.know__article figcaption .category__block span.parent__label:after {
  background-color: #E71F26;
}

body.home .p-common__new-article,
body.lp .p-common__new-article,
body.page .p-common__new-article {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

body.home .p-common__new-article li a,
body.lp .p-common__new-article li a,
body.page .p-common__new-article li a {
  text-decoration: none;
  color: #333;
  display: block;
}

body.home .p-common__new-article li a figure,
body.lp .p-common__new-article li a figure,
body.page .p-common__new-article li a figure {
  position: relative;
}

body.home .p-common__new-article li a figure .article__img__wrap,
body.lp .p-common__new-article li a figure .article__img__wrap,
body.page .p-common__new-article li a figure .article__img__wrap {
  position: relative;
  padding-top: 70%;
  overflow: hidden;
  transition: .2s;
}

body.home .p-common__new-article li a figure .article__img__wrap:after,
body.lp .p-common__new-article li a figure .article__img__wrap:after,
body.page .p-common__new-article li a figure .article__img__wrap:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
  transition: .2s;
  background-color: #00729C;
}

body.home .p-common__new-article li a figure .article__img__wrap:before,
body.lp .p-common__new-article li a figure .article__img__wrap:before,
body.page .p-common__new-article li a figure .article__img__wrap:before {
  content: "";
  display: block;
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  border-bottom: 15px solid #00729C;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  transform: translateY(15px);
  width: 0;
  height: 0;
  transition: .2s;
}

body.home .p-common__new-article li a figure .article__img__wrap .article__img,
body.lp .p-common__new-article li a figure .article__img__wrap .article__img,
body.page .p-common__new-article li a figure .article__img__wrap .article__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

body.home .p-common__new-article li a figure figcaption,
body.lp .p-common__new-article li a figure figcaption,
body.page .p-common__new-article li a figure figcaption {
  padding: 1rem;
}

body.home .p-common__new-article li a figure figcaption .category__block,
body.lp .p-common__new-article li a figure figcaption .category__block,
body.page .p-common__new-article li a figure figcaption .category__block {
  margin-bottom: 0.5rem;
  font-family: "游明朝", YuMincho, "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
}

@media all and (-ms-high-contrast: none) {
  body.home .p-common__new-article li a figure figcaption .category__block,
  body.lp .p-common__new-article li a figure figcaption .category__block,
  body.page .p-common__new-article li a figure figcaption .category__block {
    font-family: "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
  }
}

@media (max-width: 767.98px) {
  body.home .p-common__new-article li a figure figcaption .category__block,
  body.lp .p-common__new-article li a figure figcaption .category__block,
  body.page .p-common__new-article li a figure figcaption .category__block {
    font-family: ryo-text-plusn, "游明朝", YuMincho, "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
  }
}

body.home .p-common__new-article li a figure figcaption .category__block span.parent__label,
body.lp .p-common__new-article li a figure figcaption .category__block span.parent__label,
body.page .p-common__new-article li a figure figcaption .category__block span.parent__label {
  color: #00729C;
}

body.home .p-common__new-article li a figure figcaption .category__block span.parent__label:after,
body.lp .p-common__new-article li a figure figcaption .category__block span.parent__label:after,
body.page .p-common__new-article li a figure figcaption .category__block span.parent__label:after {
  content: "";
  display: inline-block;
  width: 15px;
  height: 2px;
  background-color: #00729C;
  vertical-align: middle;
  margin: 0 5px 2px;
}

body.home .p-common__new-article li a figure .article__titile,
body.lp .p-common__new-article li a figure .article__titile,
body.page .p-common__new-article li a figure .article__titile {
  font-size: 1.125rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  text-align: center;
}

body.home .p-common__new-article li a figure .article__day,
body.lp .p-common__new-article li a figure .article__day,
body.page .p-common__new-article li a figure .article__day {
  margin-bottom: 0;
}

body.home .p-common__new-article li a figure .article__label__lists,
body.lp .p-common__new-article li a figure .article__label__lists,
body.page .p-common__new-article li a figure .article__label__lists {
  letter-spacing: normal;
  list-style: none;
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0.5rem;
}

body.home .p-common__new-article li a figure .article__label__lists li,
body.lp .p-common__new-article li a figure .article__label__lists li,
body.page .p-common__new-article li a figure .article__label__lists li {
  margin: .2rem .5rem .2rem 0;
  background-color: #E3EDF4;
  border-radius: 20px;
  padding: 0.2rem 0.7rem;
  font-size: 0.8125rem;
}

body.home .p-common__new-article li a:hover .article__img__wrap, body.home .p-common__new-article li a:focus .article__img__wrap,
body.lp .p-common__new-article li a:hover .article__img__wrap,
body.lp .p-common__new-article li a:focus .article__img__wrap,
body.page .p-common__new-article li a:hover .article__img__wrap,
body.page .p-common__new-article li a:focus .article__img__wrap {
  opacity: .7;
}

body.home .p-common__new-article li a:hover .article__img__wrap:after, body.home .p-common__new-article li a:focus .article__img__wrap:after,
body.lp .p-common__new-article li a:hover .article__img__wrap:after,
body.lp .p-common__new-article li a:focus .article__img__wrap:after,
body.page .p-common__new-article li a:hover .article__img__wrap:after,
body.page .p-common__new-article li a:focus .article__img__wrap:after {
  height: 6px;
}

body.home .p-common__new-article li a:hover .article__img__wrap:before, body.home .p-common__new-article li a:focus .article__img__wrap:before,
body.lp .p-common__new-article li a:hover .article__img__wrap:before,
body.lp .p-common__new-article li a:focus .article__img__wrap:before,
body.page .p-common__new-article li a:hover .article__img__wrap:before,
body.page .p-common__new-article li a:focus .article__img__wrap:before {
  transform: translateY(-2px);
}

body.home .p-common__new-article li.fun__article .article__img__wrap:after,
body.lp .p-common__new-article li.fun__article .article__img__wrap:after,
body.page .p-common__new-article li.fun__article .article__img__wrap:after {
  background-color: #168836;
}

body.home .p-common__new-article li.fun__article .article__img__wrap:before,
body.lp .p-common__new-article li.fun__article .article__img__wrap:before,
body.page .p-common__new-article li.fun__article .article__img__wrap:before {
  border-bottom: 15px solid #168836;
}

body.home .p-common__new-article li.fun__article figcaption .category__block span.parent__label,
body.lp .p-common__new-article li.fun__article figcaption .category__block span.parent__label,
body.page .p-common__new-article li.fun__article figcaption .category__block span.parent__label {
  color: #168836;
}

body.home .p-common__new-article li.fun__article figcaption .category__block span.parent__label:after,
body.lp .p-common__new-article li.fun__article figcaption .category__block span.parent__label:after,
body.page .p-common__new-article li.fun__article figcaption .category__block span.parent__label:after {
  background-color: #168836;
}

body.home .p-common__new-article li.visit__article .article__img__wrap:after,
body.lp .p-common__new-article li.visit__article .article__img__wrap:after,
body.page .p-common__new-article li.visit__article .article__img__wrap:after {
  background-color: #015CAE;
}

body.home .p-common__new-article li.visit__article .article__img__wrap:before,
body.lp .p-common__new-article li.visit__article .article__img__wrap:before,
body.page .p-common__new-article li.visit__article .article__img__wrap:before {
  border-bottom: 15px solid #015CAE;
}

body.home .p-common__new-article li.visit__article figcaption .category__block span.parent__label,
body.lp .p-common__new-article li.visit__article figcaption .category__block span.parent__label,
body.page .p-common__new-article li.visit__article figcaption .category__block span.parent__label {
  color: #015CAE;
}

body.home .p-common__new-article li.visit__article figcaption .category__block span.parent__label:after,
body.lp .p-common__new-article li.visit__article figcaption .category__block span.parent__label:after,
body.page .p-common__new-article li.visit__article figcaption .category__block span.parent__label:after {
  background-color: #015CAE;
}

body.home .p-common__new-article li.know__article .article__img__wrap:after,
body.lp .p-common__new-article li.know__article .article__img__wrap:after,
body.page .p-common__new-article li.know__article .article__img__wrap:after {
  background-color: #E71F26;
}

body.home .p-common__new-article li.know__article .article__img__wrap:before,
body.lp .p-common__new-article li.know__article .article__img__wrap:before,
body.page .p-common__new-article li.know__article .article__img__wrap:before {
  border-bottom: 15px solid #E71F26;
}

body.home .p-common__new-article li.know__article figcaption .category__block span.parent__label,
body.lp .p-common__new-article li.know__article figcaption .category__block span.parent__label,
body.page .p-common__new-article li.know__article figcaption .category__block span.parent__label {
  color: #E71F26;
}

body.home .p-common__new-article li.know__article figcaption .category__block span.parent__label:after,
body.lp .p-common__new-article li.know__article figcaption .category__block span.parent__label:after,
body.page .p-common__new-article li.know__article figcaption .category__block span.parent__label:after {
  background-color: #E71F26;
}

body.home .general__link,
body.lp .general__link,
body.page .general__link {
  list-style: none;
  padding-left: 0;
}

body.home .general__link li,
body.lp .general__link li,
body.page .general__link li {
  margin-bottom: 0.5em;
}

body.home .general__link li a,
body.lp .general__link li a,
body.page .general__link li a {
  text-decoration: none;
  color: #333;
  display: block;
  background-color: #E3EDF4;
  padding: 1rem 1.5rem 1rem 1rem;
  height: 100%;
  display: flex;
  align-items: center;
  transition: .2s;
  position: relative;
  color: #00729C;
  font-weight: bold;
}

body.home .general__link li a:after,
body.lp .general__link li a:after,
body.page .general__link li a:after {
  content: "";
  display: inline-block;
  position: absolute;
  right: 20px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 10px;
  height: 10px;
  border-bottom: 1px solid #707070;
  border-right: 1px solid #707070;
  transform: rotate(-45deg);
}

body.home .general__link li a:hover,
body.lp .general__link li a:hover,
body.page .general__link li a:hover {
  background-color: #d1e1ed;
}

body.home .general__link li a span,
body.lp .general__link li a span,
body.page .general__link li a span {
  display: flex;
  align-items: center;
}

body.home .general__link__ver2,
body.lp .general__link__ver2,
body.page .general__link__ver2 {
  list-style: none;
  padding-left: 0;
  justify-content: center;
}

body.home .general__link__ver2 li,
body.lp .general__link__ver2 li,
body.page .general__link__ver2 li {
  margin-bottom: 0.5em;
}

body.home .general__link__ver2 li a,
body.lp .general__link__ver2 li a,
body.page .general__link__ver2 li a {
  text-decoration: none;
  color: #333;
  display: block;
  background-color: #E3EDF4;
  padding: 1rem 1.5rem 1rem 1rem;
  height: 100%;
  display: flex;
  align-items: center;
  transition: .2s;
  position: relative;
}

body.home .general__link__ver2 li a:after,
body.lp .general__link__ver2 li a:after,
body.page .general__link__ver2 li a:after {
  content: "";
  display: inline-block;
  position: absolute;
  right: 20px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 10px;
  height: 10px;
  border-bottom: 1px solid #707070;
  border-right: 1px solid #707070;
  transform: rotate(-45deg);
}

body.home .general__link__ver2 li a:hover,
body.lp .general__link__ver2 li a:hover,
body.page .general__link__ver2 li a:hover {
  background-color: #d1e1ed;
}

body.home .general__link__ver2 li a span,
body.lp .general__link__ver2 li a span,
body.page .general__link__ver2 li a span {
  display: flex;
  align-items: center;
}

body.home .others,
body.lp .others,
body.page .others {
  list-style: none;
  padding-left: 0;
  padding-bottom: 2rem;
}

body.home .others li,
body.lp .others li,
body.page .others li {
  margin-bottom: 0.5em;
}

body.home .others li a,
body.lp .others li a,
body.page .others li a {
  text-decoration: none;
  color: #333;
  display: block;
  background-color: #E3EDF4;
  padding: 1rem 1.5rem 1rem 1rem;
  height: 100%;
  display: flex;
  align-items: center;
  transition: .2s;
  position: relative;
}

body.home .others li a:after,
body.lp .others li a:after,
body.page .others li a:after {
  content: "";
  display: inline-block;
  position: absolute;
  right: 20px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 10px;
  height: 10px;
  border-bottom: 1px solid #707070;
  border-right: 1px solid #707070;
  transform: rotate(-45deg);
}

body.home .others li a:hover,
body.lp .others li a:hover,
body.page .others li a:hover {
  background-color: #d1e1ed;
}

body.home .others li a span,
body.lp .others li a span,
body.page .others li a span {
  display: flex;
  align-items: center;
}

body.home .others li a span:before,
body.lp .others li a span:before,
body.page .others li a span:before {
  content: "";
  margin-right: 1rem;
  margin-left: 0.5rem;
}

body.home .others li:nth-of-type(1) a span:before,
body.lp .others li:nth-of-type(1) a span:before,
body.page .others li:nth-of-type(1) a span:before {
  content: "";
  display: inline-block;
  background: url(../img/common/icon_article-list02.svg) no-repeat;
  background-size: 31px 24px;
  width: 31px;
  height: 24px;
  padding-left: 31px;
}

body.home .others li:nth-of-type(2) a span:before,
body.lp .others li:nth-of-type(2) a span:before,
body.page .others li:nth-of-type(2) a span:before {
  content: "";
  display: inline-block;
  background: url(../img/common/icon_apps.svg) no-repeat;
  background-size: 18px 31px;
  width: 18px;
  height: 31px;
  padding-left: 18px;
}

body.home .others li:nth-of-type(3) a span:before,
body.lp .others li:nth-of-type(3) a span:before,
body.page .others li:nth-of-type(3) a span:before {
  content: "";
  display: inline-block;
  background: url(../img/common/icon_rss.svg) no-repeat;
  background-size: 31px 31px;
  width: 31px;
  height: 31px;
  padding-left: 31px;
}

body.home .common__link-btn,
body.lp .common__link-btn,
body.page .common__link-btn {
  list-style: none;
  padding-left: 0;
  padding-bottom: 2rem;
}

body.home .common__link-btn li,
body.lp .common__link-btn li,
body.page .common__link-btn li {
  margin-bottom: 0.5em;
}

body.home .common__link-btn li a,
body.lp .common__link-btn li a,
body.page .common__link-btn li a {
  text-decoration: none;
  color: #333;
  display: block;
  background-color: #E3EDF4;
  padding: 1rem 1.5rem 1rem 1rem;
  height: 100%;
  display: flex;
  align-items: center;
  transition: .2s;
  position: relative;
}

body.home .common__link-btn li a:after,
body.lp .common__link-btn li a:after,
body.page .common__link-btn li a:after {
  content: "";
  display: inline-block;
  position: absolute;
  right: 20px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 10px;
  height: 10px;
  border-bottom: 1px solid #707070;
  border-right: 1px solid #707070;
  transform: rotate(-45deg);
}

body.home .common__link-btn li a:hover,
body.lp .common__link-btn li a:hover,
body.page .common__link-btn li a:hover {
  background-color: #d1e1ed;
}

body.home .one__ellipse-large,
body.lp .one__ellipse-large,
body.page .one__ellipse-large {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

@media (max-width: 767.98px) {
  body.home .one__ellipse-small,
  body.lp .one__ellipse-small,
  body.page .one__ellipse-small {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

@media (max-width: 767.98px) {
  body.home .two__ellipse__responsive,
  body.lp .two__ellipse__responsive,
  body.page .two__ellipse__responsive {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

@media (min-width: 1025px) {
  body.home .left_img__block,
  body.lp .left_img__block,
  body.page .left_img__block {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
}

body.home .left_img__block .img__block,
body.lp .left_img__block .img__block,
body.page .left_img__block .img__block {
  text-align: center;
  margin-bottom: 1rem;
  width: 30%;
}

@media (max-width: 1024.98px) {
  body.home .left_img__block .img__block,
  body.lp .left_img__block .img__block,
  body.page .left_img__block .img__block {
    width: 100%;
  }
}

body.home .left_img__block .text__block,
body.lp .left_img__block .text__block,
body.page .left_img__block .text__block {
  margin-bottom: 1rem;
  width: 70%;
}

@media (min-width: 1025px) {
  body.home .left_img__block .text__block,
  body.lp .left_img__block .text__block,
  body.page .left_img__block .text__block {
    padding-left: 1rem;
  }
}

@media (max-width: 1024.98px) {
  body.home .left_img__block .text__block,
  body.lp .left_img__block .text__block,
  body.page .left_img__block .text__block {
    width: 100%;
  }
}

body.home .left_img__block .text__block .profile__block,
body.lp .left_img__block .text__block .profile__block,
body.page .left_img__block .text__block .profile__block {
  display: flex;
  flex-wrap: wrap;
}

body.home .left_img__block .text__block .profile__block dt,
body.lp .left_img__block .text__block .profile__block dt,
body.page .left_img__block .text__block .profile__block dt {
  width: 20%;
  text-align: center;
  padding: 0.6rem .2rem;
  font-weight: normal;
}

@media (max-width: 767.98px) {
  body.home .left_img__block .text__block .profile__block dt,
  body.lp .left_img__block .text__block .profile__block dt,
  body.page .left_img__block .text__block .profile__block dt {
    width: 30%;
  }
}

body.home .left_img__block .text__block .profile__block dt:nth-of-type(odd),
body.lp .left_img__block .text__block .profile__block dt:nth-of-type(odd),
body.page .left_img__block .text__block .profile__block dt:nth-of-type(odd) {
  background-color: rgba(136, 136, 136, 0.2);
}

body.home .left_img__block .text__block .profile__block dd,
body.lp .left_img__block .text__block .profile__block dd,
body.page .left_img__block .text__block .profile__block dd {
  width: 80%;
  margin-bottom: 0;
  padding: 0.6rem .2rem .6rem 1rem;
}

@media (max-width: 767.98px) {
  body.home .left_img__block .text__block .profile__block dd,
  body.lp .left_img__block .text__block .profile__block dd,
  body.page .left_img__block .text__block .profile__block dd {
    width: 70%;
  }
}

body.home .left_img__block .text__block .profile__block dd:nth-of-type(odd),
body.lp .left_img__block .text__block .profile__block dd:nth-of-type(odd),
body.page .left_img__block .text__block .profile__block dd:nth-of-type(odd) {
  background-color: rgba(136, 136, 136, 0.2);
}

@media (min-width: 1025px) {
  body.home .right_img__block,
  body.lp .right_img__block,
  body.page .right_img__block {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
}

body.home .right_img__block .img__block,
body.lp .right_img__block .img__block,
body.page .right_img__block .img__block {
  text-align: center;
  flex: 0 0 auto;
  margin-bottom: 1rem;
  width: 30%;
}

@media (max-width: 1024.98px) {
  body.home .right_img__block .img__block,
  body.lp .right_img__block .img__block,
  body.page .right_img__block .img__block {
    width: 100%;
  }
}

body.home .right_img__block .text__block,
body.lp .right_img__block .text__block,
body.page .right_img__block .text__block {
  margin-bottom: 1rem;
  width: 70%;
}

@media (max-width: 1024.98px) {
  body.home .right_img__block .text__block,
  body.lp .right_img__block .text__block,
  body.page .right_img__block .text__block {
    width: 100%;
  }
}

@media (min-width: 1025px) {
  body.home .right_img__block .text__block,
  body.lp .right_img__block .text__block,
  body.page .right_img__block .text__block {
    padding-right: 1rem;
  }
}

body.home .right_img__block .text__block .profile__block,
body.lp .right_img__block .text__block .profile__block,
body.page .right_img__block .text__block .profile__block {
  display: flex;
  flex-wrap: wrap;
}

body.home .right_img__block .text__block .profile__block dt,
body.lp .right_img__block .text__block .profile__block dt,
body.page .right_img__block .text__block .profile__block dt {
  width: 20%;
  text-align: center;
  padding: 0.6rem .2rem;
  font-weight: normal;
}

@media (max-width: 767.98px) {
  body.home .right_img__block .text__block .profile__block dt,
  body.lp .right_img__block .text__block .profile__block dt,
  body.page .right_img__block .text__block .profile__block dt {
    width: 30%;
  }
}

body.home .right_img__block .text__block .profile__block dt:nth-of-type(odd),
body.lp .right_img__block .text__block .profile__block dt:nth-of-type(odd),
body.page .right_img__block .text__block .profile__block dt:nth-of-type(odd) {
  background-color: rgba(136, 136, 136, 0.2);
}

body.home .right_img__block .text__block .profile__block dd,
body.lp .right_img__block .text__block .profile__block dd,
body.page .right_img__block .text__block .profile__block dd {
  width: 80%;
  margin-bottom: 0;
  padding: 0.6rem .2rem .6rem 1rem;
}

@media (max-width: 767.98px) {
  body.home .right_img__block .text__block .profile__block dd,
  body.lp .right_img__block .text__block .profile__block dd,
  body.page .right_img__block .text__block .profile__block dd {
    width: 70%;
  }
}

body.home .right_img__block .text__block .profile__block dd:nth-of-type(odd),
body.lp .right_img__block .text__block .profile__block dd:nth-of-type(odd),
body.page .right_img__block .text__block .profile__block dd:nth-of-type(odd) {
  background-color: rgba(136, 136, 136, 0.2);
}

body.lp {
  font-family: 游明朝,YuMincho,Yu Mincho,ヒラギノ明朝 ProN W3,Hiragino Mincho ProN,HG明朝E,ＭＳ\ Ｐ明朝,serif;
}

body.lp.expo2022 .ex__title {
  font-weight: 500;
  font-size: 1.875rem;
  line-height: calc(42 / 30);
  position: relative;
}

@media (min-width: 768px) {
  body.lp.expo2022 .ex__title {
    font-size: 2.25rem;
  }
}

body.lp.expo2022 .ex__title::before {
  content: '';
  display: inline-block;
  width: 2.5rem;
  height: 1.5rem;
  background: url("../img/lp/item-common-titleicon.svg") center/cover no-repeat;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: -2.1875rem;
}

body.lp.expo2022 .ex__title2 {
  border-bottom: 6px double #C1E4FF;
  font-size: 1.25rem;
  font-weight: 500;
}

@media (min-width: 768px) {
  body.lp.expo2022 .ex__title2 {
    font-size: 1.5625rem;
    margin-top: 3.125rem;
    display: table;
    margin-left: auto;
    margin-right: auto;
    padding-left: 3.125rem;
    padding-right: 3.125rem;
  }
}

body.lp.expo2022 .ex01__logo {
  max-width: 80%;
}

body.lp.expo2022 .ex01__slider {
  width: 100%;
  height: calc(100vh - 135px);
  position: relative;
}

body.lp.expo2022 .ex01__title {
  width: 93%;
  max-width: 39.3125rem;
  position: absolute;
  bottom: 57px;
  right: 5px;
  z-index: 10;
}

@media (min-width: 1025px) {
  body.lp.expo2022 .ex01__title {
    width: 100%;
    max-width: 48%;
    bottom: 49px;
    right: 42px;
  }
}

body.lp.expo2022 .ex01__slider-items {
  width: 100%;
  height: inherit;
}

body.lp.expo2022 .ex01__slider-item {
  width: 100% !important;
  height: inherit;
}

body.lp.expo2022 .ex01__slider-item:nth-of-type(1) {
  background: url("../img/lp/bg-ex01-slide01.jpg") center/cover no-repeat;
}

body.lp.expo2022 .slick-slider .slick-track, body.lp.expo2022 .slick-slider .slick-list {
  height: 100%;
  width: 100% !important;
}

body.lp.expo2022 .ex02__title {
  margin-top: 4.5625rem;
}

@media (min-width: 768px) {
  body.lp.expo2022 .ex02__title {
    margin-top: 9rem;
  }
}

body.lp.expo2022 .ex02__row {
  margin-bottom: 3.75rem;
}

@media (min-width: 768px) {
  body.lp.expo2022 .ex02__row {
    margin-bottom: 6.4375rem;
  }
}

body.lp.expo2022 .ex02__img {
  max-width: 35.625rem;
}

body.lp.expo2022 .ex02__img img {
  width: 100%;
}

body.lp.expo2022 .ex02__text {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: calc(28/14);
}

@media (min-width: 1025px) {
  body.lp.expo2022 .ex02__text {
    font-size: 1rem;
  }
}

body.lp.expo2022 .ex02__decoration--wave {
  height: 1.875rem;
  background: url("../img/lp/item-common-wave.png") center/contain repeat-x;
}

body.lp.expo2022 .ex02__decoration--fish {
  max-width: 13.8125rem;
  position: absolute;
  bottom: 0;
  right: 0;
}

@media (min-width: 768px) {
  body.lp.expo2022 .ex03 {
    position: relative;
  }
}

body.lp.expo2022 .ex03__inner {
  padding-bottom: 2.5rem;
}

@media (min-width: 768px) {
  body.lp.expo2022 .ex03__inner {
    padding-bottom: 6.25rem;
  }
}

body.lp.expo2022 .ex03__title {
  margin-top: 4.5625rem;
}

@media (min-width: 768px) {
  body.lp.expo2022 .ex03__title {
    margin-top: 7.75rem;
  }
}

body.lp.expo2022 .ex03__text {
  max-width: 83%;
  margin: 0 auto;
  font-size: 0.875rem;
  font-weight: 500;
}

@media (min-width: 768px) {
  body.lp.expo2022 .ex03__text {
    max-width: 100%;
  }
}

body.lp.expo2022 .ex03__decorations {
  position: relative;
  height: 11.25rem;
}

@media (min-width: 768px) {
  body.lp.expo2022 .ex03__decorations {
    position: static;
    height: 3.75rem;
  }
}

body.lp.expo2022 .ex03__item {
  position: absolute;
}

body.lp.expo2022 .ex03__item--01 {
  width: 100%;
  max-width: 4.625rem;
  top: 0;
  left: 7%;
}

@media (min-width: 1200px) {
  body.lp.expo2022 .ex03__item--01 {
    max-width: 8.625rem;
  }
}

body.lp.expo2022 .ex03__item--02 {
  max-width: 4.0625rem;
  top: -1.25rem;
  right: 5%;
}

@media (min-width: 1200px) {
  body.lp.expo2022 .ex03__item--02 {
    max-width: 7.8125rem;
  }
}

body.lp.expo2022 .ex03__item--03 {
  max-width: 27.5625rem;
  width: auto;
  bottom: 1.25rem;
  left: -8.125rem;
}

@media (min-width: 768px) {
  body.lp.expo2022 .ex03__item--03 {
    max-width: 21.3125rem;
    bottom: 0;
  }
}

@media (min-width: 1200px) {
  body.lp.expo2022 .ex03__item--03 {
    max-width: 36.1875rem;
    left: -14.375rem;
    bottom: 6.25rem;
  }
}

body.lp.expo2022 .ex03__item--04 {
  max-width: 11.25rem;
  top: 0;
  right: 0;
}

@media (min-width: 768px) {
  body.lp.expo2022 .ex03__item--04 {
    max-width: 8.75rem;
    top: auto;
    bottom: 0;
  }
}

@media (min-width: 1200px) {
  body.lp.expo2022 .ex03__item--04 {
    max-width: 16.8125rem;
    bottom: 5rem;
    right: 2.375rem;
  }
}

body.lp.expo2022 .ex04 {
  margin-bottom: 100px;
}
