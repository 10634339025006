﻿//
// common
//

.important-news{
  border: 5px solid #ff0d00;
  padding: 0 1rem 2rem;
  max-width: 800px;
  margin:0 auto 2rem;
  h2{
    color:#ff0d00!important;
    text-align: center;
    margin-bottom: 1rem!important;
    &:after,
    &:before{
      content:none!important;
    }
  }
  .important__content{
    text-align: center;
  }
}

.chat-bot{
  .chat-bot__front{
    position: fixed;
    right:0;
    bottom:0;
    z-index: 10;
    transition: .5s;
    // position: relative;
    .chat-bot__trigger,
    .chat-bot__close-mini{
      cursor: pointer;
      background: none;
      border:none;
    }
    .chat-bot__close-mini{
      position: absolute;
      top:-10px;
      right:0;
    }
    &.is_hidden{
      transform: translateY(100%);
    }
    &.is_none{
      display: none;
    }
  }
  .chat-bot__wrap{
    .chat-bot__content{
      position: fixed;
      right:0;
      bottom:0;
      z-index: 10;
      transition: .5s;
      transform: translateY(100%);
      width:300px;
      &.is_open{
        transform: translateY(0);
      }
      .header{
        background-color: #015CAE;
        display: flex;
        justify-content: space-between;
        .chat-bot__title{
          margin-bottom: 0;
          padding: 0.2rem .5rem;
          color:$white;
          font-size: rem-calc(19);
          font-weight: bold;
          display: flex;
          align-items:center;
          &:before{
            content:url(#{$dir}/home/icon_chat-bot2.png);
            vertical-align: middle;
            width:56px;
            height:56px;
            margin-right: 0.5rem;
          }
        }
        .chat-bot__close{
          color:$white;
          background-color: #015CAE;
          border:none;
          cursor: pointer;
          width:50px;
          position: relative;
          &:before{
            content:"";
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin:auto;
            width:30px;
            height:1px;
            transform: rotate(45deg);
            background-color: $white;
          }
          &:after{
            content:"";
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin:auto;
            width:30px;
            height:1px;
            transform: rotate(-45deg);
            background-color: $white;
          }
        }
      }
      .main__content{
        font-size: rem-calc(13);
        .content__inner{
          background-color: #DEDEDE;
          padding: 10px 5px;
          max-height: 320px;
          min-height: 320px;
          overflow: scroll;
          -ms-overflow-style:none;
          .bot__block{
            display: flex;
            align-items:flex-start;
            margin-right: 1rem;
            margin-bottom: 1rem;
            // margin-right: 15px;
            .bot__text{
              position: relative;
              padding: 1rem;
              border-radius: 15px;
              background-color: #fff;
              box-shadow: 0px 3px 9px 0px #9c9c9c;
              margin-left: 15px;
              margin-bottom: 0;
              border:2px solid #015CAE;
              min-height: 3rem;
              a{
                word-break: break-all;
              }
              &:before{
                content:"";
                display: block;
                position: absolute;
                top:25px;
                right:100%;
                width: 0;
                height: 0;
                margin: auto;
                border-left: 8px solid transparent;
                border-top: 5px solid #015CAE;
                border-right: 8px solid #015CAE;
                border-bottom: 5px solid transparent;
              }
              &:after{
                content:"";
                display: block;
                position: absolute;
                top:27px;
                right:calc(100% - 7px);
                width: 0;
                height: 0;
                margin: auto;
                border-left: 8px solid transparent;
                border-top: 5px solid $white;
                border-right: 8px solid $white;
                border-bottom: 5px solid transparent;
              }
            }
            img{
              flex-shrink:0;
            }
          }
          .user__block{
            color:$white;
            min-height: 3rem;
            padding: 1rem;
            background-color: #00941F;
            border-radius: 15px;
            margin-left: 3rem;
            margin-right: 15px;
            position: relative;
            margin-bottom: 1rem;
            box-shadow: 0px 3px 9px 0px #9c9c9c;
            &:before{
              content:"";
              display: block;
              position: absolute;
              top:15px;
              left:100%;
              width: 0;
              height: 0;
              margin: auto;
              border-left: 8px solid #00941F;
              border-top: 5px solid #00941F;
              border-right: 8px solid transparent;
              border-bottom: 5px solid transparent;
            }
            .user__text{
              margin-bottom: 0;
              word-break: break-all;
            }
          }
        }
      }
      .footer__input{
        border: 1px solid #9c9c9c;
        form{
          display: flex;
          justify-content: space-between;
          input{
            flex-grow: 1;
            padding: .5rem;
            border: none;
            width:calc(100% - 50px);
          }
          button{
            background-color: $white;
            border:none;
            width:50px;
          }
        }
      }
    }
  }
}


// print css
@media print{
    body{
    -webkit-print-color-adjust: exact;
    width:1300px;
    }
}


html,
body{
  overflow-x: hidden;
}
ol,
ul{
  padding-left: 1.5rem;
}

body.home,
body.lp,
body.page {
  @include media-breakpoint-up(lg) {
    margin-left: 65px;
  }
  @media all and (-ms-high-contrast:none) {
    font-family: $font-family-ie-gothic;
  }
  .search{
    .search__wrap{
      .search__title{
        font-size: rem-calc(18);
        &:before{
          content:"";
          display: inline-block;
          background: url(#{$dir}/common/icon_search01.svg)no-repeat;
          background-size: 25px 25px;
          width:25px;
          height:25px;
          vertical-align: middle;
          margin-right: 0.5rem;
        }
      }
      form{
        .search__box{
          position: relative;
          display: inline-block;
          input{
            background-color: $secondary;
            border-radius: 20px;
            height:40px;
            width:300px;
            border:none;
            padding:0 40px 0 20px;
            @include media-breakpoint-down(sm) {
              width:auto;
            }
          }
          button{
            display: inline-block;
            background: url(#{$dir}/common/icon_search02.svg)no-repeat;
            background-size: 40px 40px;
            width:40px;
            height:40px;
            position: absolute;
            right:0;
            bottom:0;
            top:0;
            margin:auto;
            border:none;
            cursor: pointer;
          }
        }
      }
    }
    .tag__wrap{
      .tag__title{
        font-size: rem-calc(18);
        &:before{
          content:"";
          display: inline-block;
          background: url(#{$dir}/common/icon_tag.svg)no-repeat;
          background-size: 25px 25px;
          width:25px;
          height:25px;
          vertical-align: middle;
          margin-right: 0.5rem;
        }
      }
      .label__lists{
        letter-spacing: normal;
        list-style: none;
        padding-left: 0;
        display: flex;
        flex-wrap:wrap;
        li{
          margin:.3rem 0 .3rem .5rem;
          a{
            background-color: $secondary;
            border-radius: 20px;
            padding: 0.5rem 1rem;
            display: block;
            text-decoration: none;
            color:$black;
          }
        }
      }
    }
  }

  // 記事一覧表示
  &.use_article{
    .pickup__head{
      font-size: rem-calc(39);
      @include mintyo();
      border-left: none;
      padding-left: 0;
      @include media-breakpoint-down(md) {
        font-family: $font-family-android;
      }
      span{
        color:$font-gray;
        font-size: rem-calc(17);
        margin-left: .5rem;
        font-weight: bold;
      }
    }

    .filter{
      //common
      .filter__title{
        font-size: rem-calc(18);
        border-bottom: 2px solid $primary;
        padding-bottom: .5em;
        margin-bottom: 2rem;
      }
      a{
        text-decoration: none;
        color:$black;
        padding: .5rem 0;
        @include opacity();
        &:before{
          content:"";
          display: inline-block;
          background: url(#{$dir}/common/icon_filter.svg)no-repeat;
          background-size: 24px 13px;
          width:24px;
          height:13px;
          vertical-align: middle;
          margin-bottom: 2px;
          margin-right: 0.5rem;
        }
        &:after{
          content:"";
          display: inline-block;
          border-top: 8px solid $black;
          border-right: 6px solid transparent;
          border-left: 6px solid transparent;
          vertical-align: middle;
          margin-bottom: 2px;
          margin-left: .5rem;
          transition:.2s;
        }
        &.is_active{
          &:after{
            transform:rotate(180deg);
          }
        }
      }
      .filter__content{
        display: none;
        padding-bottom: 2rem;
        margin-bottom: 2rem;
        border-bottom: 1px solid $gray;
      }
      .filter__content__title{
        letter-spacing: -.4em;
        > *{
          letter-spacing: normal;
          display: inline-block;
        }
        .check__select{
          @include media-breakpoint-down(sm) {
            margin-left: 0;
          }
        }
        .check__select{
          background-color: $visit_color;
          font-weight: bold;
          margin-left: 1rem;
          padding: .5rem 2rem;
          cursor: pointer;
          color:$white;
          margin-bottom: 1rem;
          @include media-breakpoint-down(sm) {
            margin-left: 0;
          }
        }
        .check__release{
          letter-spacing: normal;
          background-color: $gray;
          color:$white;
          font-weight: bold;
          margin-left: .5rem;
          padding: .5rem 2rem;
          position: relative;
          cursor: pointer;
          margin-bottom: 1rem;
          &:before{
            content:"";
            display: inline-block;
            width:15px;
            height:2px;
            position: absolute;
            top:0;
            bottom:0;
            left:10px;
            margin:auto;
            background-color: $white;
            transform: rotate(45deg);
          }
          &:after{
            content:"";
            display: inline-block;
            width:15px;
            height:2px;
            position: absolute;
            top:0;
            bottom:0;
            left:10px;
            margin:auto;
            background-color: $white;
            transform: rotate(-45deg);
          }
        }
        // filter name common
        .title{
          position: relative;
          padding-left: 25px;
          font-weight: bold;
          font-size: rem-calc(18);
          @include media-breakpoint-down(sm) {
            display: block;
          }
          &:before{
            content:"";
            position: absolute;
            display: inline-block;
            top:0;
            bottom:0;
            left:0;
            margin:auto;
            width:18px;
            height:18px;
            border-radius: 50%;
            vertical-align: middle;
            background-color: $visit_color;
          }
          &:after{
            content:"";
            position: absolute;
            display: inline-block;
            top:0;
            bottom:0;
            left:4px;
            margin:auto;
            width:7px;
            height:7px;
            border-bottom: 2px solid $white;
            border-right: 2px solid $white;
            transform:rotate(-45deg);
          }
        }
        // /common
      }
      // 年で選ぶ
      .filter__year__content{
        margin-bottom: 2rem;
        @include media-breakpoint-down(sm) {
          margin-bottom: 1rem;
        }
        select{
          width: 100px;
          height: 40px;
          font-size: rem-calc(18);
          background: $secondary;
          border: none;
        }
        .select__wrap{}
      }
      // 期間を選ぶ
      .filter__period{
        margin-bottom: 3rem;
        .filter__period__content{
          font-weight: bold;
          ul{
            padding-left: 0;
            list-style: none;
          }
          .period__items{
            display: flex;
            flex-wrap:wrap;
            text-align: center;
            .all__season{
              width:100%;
              margin-bottom: .5rem;
            }
            .period__item{
              padding-bottom: .5rem;
              @include media-breakpoint-up(lg) {
                width:calc( 100% / 4 );
                &:not(:nth-of-type(4n+5)){
                  padding-right: .5rem;
                }
              }
              @include media-breakpoint-down(md) {
                width:50%;
                &:not(:nth-of-type(2n+3)){
                  padding-right: .5rem;
                }
              }
              .period__item__block{
                display: flex;
                flex-wrap:wrap;
                li{
                  width:calc(100% / 3);
                  &:first-of-type{
                    width:100%;
                    padding-bottom: 2px;
                  }
                  &:not(:first-of-type,:last-of-type){
                    padding-right: 2px;
                  }
                }
              }
            }
            .period__check-item{
              background-color: $secondary;
              display: block;
              margin-bottom: 0;
              cursor: pointer;
              position: relative;
              transition:.2s;
              height: 100%;
              &.is_checked{
                background-color: $visit_color;
                color:$white;
              }
              input{
                position: absolute;
                z-index: -1;
                opacity: 0;
                &:focus+.checkbox__txt{
                  outline: 2px solid #adb5bd;
                }
              }
            }
            .checkbox__txt{
              margin-bottom: 0;
              padding:.5rem;
              height: 100%;
              @include media-breakpoint-down(sm) {
                padding: .5rem .2rem;
              }
            }
          }
        }
      }
      // カテゴリーを選ぶ
      .filter__category{
        margin-bottom: 3rem;
        .filter__category__content{
          @include media-breakpoint-up(md) {
            display: flex;
            flex-wrap:wrap;
          }
          font-weight: bold;
          ul{
            padding-left: 0;
            list-style: none;
          }
          .category__parent{
            width:calc(94% / 3);
            &:not(:last-of-type){
              margin-right:3%;
            }
            @include media-breakpoint-down(sm) {
              margin-right: 0;
              width:100%;
            }
            @include media-breakpoint-up(md) {
            }
            @include media-breakpoint-up(lg) {
            }
            .category__head{
              font-size: rem-calc(35);
              @include mintyo();
              font-weight: normal;
            }
            .category__items{
              .category__item{
                margin-bottom: 1px;
                .category__check-item{
                  display: block;
                  margin-bottom: 0;
                  cursor: pointer;
                  position: relative;
                  transition:.2s;
                  &.is_checked{
                    color:$white;
                  }
                  input{
                    position: absolute;
                    z-index: -1;
                    opacity: 0;
                    &:focus+.checkbox__txt{
                      outline: 2px solid #adb5bd;
                    }
                  }
                }
                .checkbox__txt{
                  margin-bottom: 0;
                  padding:.5rem;
                  height: 100%;
                  @include media-breakpoint-down(sm) {
                    padding: .5rem;
                  }
                }
              }
            }
            &.category__know{
              .category__head{
                color:$know_color;
                @include media-breakpoint-down(sm) {
                  margin-bottom: 0;
                }
              }
              .category__items{
                .category__item{
                  .category__check-item{
                    background-color: rgba($know_color,.2);
                    &.is_checked{
                      background-color: $know_color;
                    }
                  }
                }
              }
            }
            &.category__visit{
              .category__head{
                color:$visit_color;
                @include media-breakpoint-down(sm) {
                  margin-bottom: 0;
                }
              }
              .category__items{
                .category__item{
                  .category__check-item{
                    background-color: rgba($visit_color,.2);
                    &.is_checked{
                      background-color: $visit_color;
                    }
                  }
                }
              }
            }
            &.category__fun{
              .category__head{
                color:$fun_color;
                @include media-breakpoint-down(sm) {
                  margin-bottom: 0;
                }
              }
              .category__items{
                .category__item{
                  .category__check-item{
                    background-color: rgba($fun_color,.2);
                    &.is_checked{
                      background-color: $fun_color;
                    }
                  }
                }
              }
            }
          }
        }
      }
      // 地域を選ぶ、道の駅を選ぶ
      .filter__region{
        margin-bottom: 3rem;
        .filter__region__content{
          @include media-breakpoint-up(md) {
            display: flex;
            flex-wrap:wrap;
          }
          font-weight: bold;
          ul{
            padding-left: 0;
            list-style: none;
          }
          .region__parent{
            @include media-breakpoint-up(lg) {
              width:calc(94% / 3);
              &:not(:last-of-type){
                margin-right:3%;
              }
            }
            @include media-breakpoint-only(md) {
              width:calc(95% / 2);
              &:first-of-type{
                margin-right:5%;
              }
            }

            @include media-breakpoint-down(sm) {
              margin-right: 0;
              width:100%;
            }
            @include media-breakpoint-up(lg) {
            }
            .region__head{
              display: flex;
              .region__head__txt{
                padding:.2rem 0;
                color:$primary;
                font-size: rem-calc(18);
              }
              .check__select{
                background-color: $visit_color;
                font-weight: bold;
                margin-left: 1rem;
                margin-bottom: 1rem;
                padding: .5rem;
                cursor: pointer;
                color:$white;
                font-size: rem-calc(14);
                display: flex;
                align-items: center;
              }
              .check__release{
                letter-spacing: normal;
                background-color: $gray;
                color:$white;
                font-weight: bold;
                margin-left: .5rem;
                margin-bottom: 1rem;
                padding: .5rem;
                position: relative;
                cursor: pointer;
                display: flex;
                align-items: center;
                font-size: rem-calc(14);
              }
            }
            .region__items{
              display: flex;
              flex-wrap:wrap;
              .region__item{
                width:calc( (100% - 4px) / 3);
                &:not(:nth-of-type(3n)){
                  margin-right: 2px;
                }
                margin-bottom: 2px;
                .region__check-item{
                  display: block;
                  margin-bottom: 0;
                  cursor: pointer;
                  position: relative;
                  transition:.2s;
                  &.is_checked{
                    color:$white;
                  }
                  input{
                    position: absolute;
                    z-index: -1;
                    opacity: 0;
                    &:focus+.checkbox__txt{
                      outline: 2px solid #adb5bd;
                    }
                  }
                }
                .checkbox__txt{
                  margin-bottom: 0;
                  padding:.5rem;
                  text-align: center;
                  height: 100%;
                  @include media-breakpoint-down(sm) {
                    padding: .5rem;
                  }
                }
              }
            }
            .region__items{
              .region__item{
                .region__check-item{
                  background-color: $secondary;
                  height: 100%;
                  &.is_checked{
                    background-color: $primary;
                    color:$white;
                  }
                }
              }
            }
          }
        }
      }
      // 道の駅を選ぶ、ジャンルを選ぶ
      .filter__road-genre__wrap{
        display: flex;
        flex-wrap:wrap;
        .filter__road-station{
          width:calc(97% / 3);
          @include media-breakpoint-only(md) {
            width:calc(97% / 2);
          }
          @include media-breakpoint-down(sm) {
            width:100%;
          }
        }
        .filter__genre{}
        .filter__road-station{
          margin-bottom: 3rem;
          @include media-breakpoint-up(md) {
            margin-right: 3%;
          }
          .filter__content__title{
            .title{
              width:100%;
            }
            .check__select{
              margin-left: 0;
            }
          }
          .filter__road-station__content{
            @include media-breakpoint-up(md) {
              display: flex;
              flex-wrap:wrap;
            }
            font-weight: bold;
            ul{
              padding-left: 0;
              list-style: none;
            }
            .road-station__parent{
              .check__select{
                background-color: $visit_color;
                font-weight: bold;
                margin-left: 1rem;
                padding: .5rem;
                cursor: pointer;
                color:$white;
                font-size: rem-calc(14);
                display: flex;
                align-items: center;
              }
              .check__release{
                letter-spacing: normal;
                background-color: $gray;
                color:$white;
                font-weight: bold;
                margin-left: .5rem;
                padding: .5rem;
                position: relative;
                cursor: pointer;
                display: flex;
                align-items: center;
                font-size: rem-calc(14);
              }
              .road-station__items{
                display: flex;
                flex-wrap:wrap;
                .road-station__item{
                  width:calc( (100% - 4px) / 3);
                  &:not(:nth-of-type(3n)){
                    margin-right: 2px;
                  }
                  margin-bottom: 2px;
                  .road-station__check-item{
                    display: block;
                    margin-bottom: 0;
                    cursor: pointer;
                    position: relative;
                    transition:.2s;
                    &.is_checked{
                      color:$white;
                    }
                    input{
                      position: absolute;
                      z-index: -1;
                      opacity: 0;
                      &:focus+.checkbox__txt{
                        outline: 2px solid #adb5bd;
                      }
                    }
                  }
                  .checkbox__txt{
                    margin-bottom: 0;
                    padding:.5rem;
                    text-align: center;
                    height: 100%;
                    @include media-breakpoint-down(sm) {
                      padding: .5rem;
                    }
                  }
                }
              }
              .road-station__items{
                .road-station__item{
                  .road-station__check-item{
                    background-color: $secondary;
                    height: 100%;
                    &.is_checked{
                      background-color: $primary;
                      color:$white;
                    }
                  }
                }
              }
            }
          }
        }
        .filter__genre{
          margin-bottom: 3rem;
          @include media-breakpoint-down(sm) {
            margin-bottom: 1rem;
          }
          .filter__content__title{
            .title{
              width:100%;
            }
            .check__select{
              margin-left: 0;
            }
          }
          .filter__genre__content{
            @include media-breakpoint-up(md) {
              display: flex;
              flex-wrap:wrap;
            }
            font-weight: bold;
            ul{
              padding-left: 0;
              list-style: none;
            }
            .filter__genre__parent{
              .check__select{
                background-color: $visit_color;
                font-weight: bold;
                margin-left: 1rem;
                padding: .5rem;
                cursor: pointer;
                color:$white;
                font-size: rem-calc(14);
                display: flex;
                align-items: center;
              }
              .check__release{
                letter-spacing: normal;
                background-color: $gray;
                color:$white;
                font-weight: bold;
                margin-left: .5rem;
                padding: .5rem;
                position: relative;
                cursor: pointer;
                display: flex;
                align-items: center;
                font-size: rem-calc(14);
              }
              .genre__items{
                display: flex;
                flex-wrap:wrap;
                .genre__item{
                  margin-bottom: 2px;
                  @include media-breakpoint-up(md) {
                    width:calc( (100% - 10px) / 6);
                    &:not(:nth-of-type(6n+6)){
                      margin-right: 2px;
                    }
                  }
                  @include media-breakpoint-down(sm) {
                    width:calc( (100% - 4px) / 3);
                    &:not(:nth-of-type(3n+3)){
                      margin-right: 2px;
                    }
                  }
                  .genre__check-item{
                    display: block;
                    margin-bottom: 0;
                    cursor: pointer;
                    position: relative;
                    transition:.2s;
                    &.is_checked{
                      color:$white;
                    }
                    input{
                      position: absolute;
                      z-index: -1;
                      opacity: 0;
                      &:focus+.checkbox__txt{
                        outline: 2px solid #adb5bd;
                      }
                    }
                  }
                  .checkbox__txt{
                    margin-bottom: 0;
                    padding:.5rem;
                    text-align: center;
                    height: 100%;
                    @include media-breakpoint-down(sm) {
                      padding: .5rem;
                    }
                  }
                }
              }
              .genre__items{
                .genre__item{
                  .genre__check-item{
                    background-color: $secondary;
                    height: 100%;
                    &.is_checked{
                      background-color: $primary;
                      color:$white;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .form__btns{
        ul{
          list-style: none;
          padding-left: 0;
          display: flex;
          justify-content: center;
          margin-bottom: 0;
          flex-wrap:wrap;
          li{
            @include media-breakpoint-down(sm) {
              width:100%;
            }
            &:first-of-type{
              @include media-breakpoint-up(md) {
                margin-right: 0.5rem;
              }
              @include media-breakpoint-down(sm) {
                margin-bottom: 0.5rem;
              }
            }
            button{
              cursor: pointer;
              color:$white;
              font-weight: bold;
              border: none;
              padding: 1rem 2rem;
              transition:.2s;
              @include media-breakpoint-down(sm) {
                width:100%;
              }
              &.all__release{
                background-color: $gray-800;
              }
              &.filter__submit{
                background-color: #BB0900;
              }
              &:hover{
                &.all__release{
                  background-color: lighten($gray-800,7%);
                }
                &.filter__submit{
                  background-color: lighten(#BB0900,7%);
                }
              }
            }
          }
        }
      }
    }
  }
  // 共通記事一覧
  .p-common__article{
    $page_color:$primary;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    li{
      margin-bottom: 1rem;
      a{
        text-decoration: none;
        color:$black;
        display: block;
        figure{
          position: relative;
          margin-bottom: 0;
          @include media-breakpoint-down(sm) {
            letter-spacing: -.4em;
            display: flex;
          }
          .article__img__wrap{
            position: relative;
            padding-top: 70%;
            overflow: hidden;
            transition:.2s;
            @include media-breakpoint-down(sm) {
              padding-top: 40%;
              width:50%;
              display: inline-block;
              letter-spacing: normal;
            }
            &:after{
              content:"";
              display: block;
              position: absolute;
              bottom:0;
              left:0;
              width:100%;
              height:4px;
              transition:.2s;
              background-color: $page_color;
            }
            &:before{
              content:"";
              display: block;
              position: absolute;
              z-index: 1;
              bottom:0;
              left:0;
              right:0;
              margin:auto;
              border-bottom:15px solid $page_color;
              border-right:10px solid transparent;
              border-left:10px solid transparent;
              transform:translateY(15px);
              width:0;
              height:0;
              transition:.2s;
            }
            .article__img{
              position: absolute;
              top:0;
              left:0;
              width:100%;
              height:100%;
            }
          }
          figcaption{
            // text-align: center;
            padding:1rem;
            @include media-breakpoint-down(sm) {
              width:50%;
              display: inline-block;
              letter-spacing: normal;
              vertical-align: top;
              text-align: left;
              padding:.5rem 0 0 1rem;
            }
            .category__block{
              margin-bottom: 0.5rem;
              font-size: rem-calc(14);
              @include mintyo();
              @include media-breakpoint-down(sm) {
                font-family: $font-family-android;
              }
              span{
                &.parent__label{
                  color:$page_color;
                  &:after{
                    content:"";
                    display: inline-block;
                    width:15px;
                    height:2px;
                    background-color:$page_color;
                    vertical-align: middle;
                    margin: 0 5px 2px;
                  }
                }
              }
            }
            .article__titile{
              font-size: rem-calc(18);
              margin-bottom: 0.5rem;
              font-weight: bold;
            }
            .article__day{
              margin-bottom: 0;
            }
            .article__label__lists{
              letter-spacing: normal;
              list-style: none;
              padding-left: 0;
              display: flex;
              flex-wrap:wrap;
              margin-bottom: 0.5rem;
              li{
                margin:.2rem .5rem .2rem 0;
                background-color: $secondary;
                border-radius: 20px;
                padding: 0.2rem 0.7rem;
                font-size: rem-calc(13);
              }
            }
          }
        }
        &:hover{
          .article__img__wrap{
            opacity: .7;
            &:after{
              height:6px;
            }
            &:before{
              transform:translateY(-2px);
            }
          }
        }
      }
      &.fun__article{
        $page_color:$fun_color;
        a{
          // background-color: rgba($page_color,.03);
        }
        .article__img__wrap{
          &:after{
            background-color: $page_color;
          }
          &:before{
            border-bottom:15px solid $page_color;
          }
        }
        figcaption{
          .category__block{
            span{
              &.parent__label{
                color:$page_color;
                &:after{
                  background-color:$page_color;
                }
              }
            }
          }
        }
      }
      &.visit__article{
        $page_color:$visit_color;
        a{
          // background-color: rgba($page_color,.03);
        }
        .article__img__wrap{
          &:after{
            background-color: $page_color;
          }
          &:before{
            border-bottom:15px solid $page_color;
          }
        }
        figcaption{
          .category__block{
            span{
              &.parent__label{
                color:$page_color;
                &:after{
                  background-color:$page_color;
                }
              }
            }
          }
        }
      }
      &.know__article{
        $page_color:$know_color;
        a{
          // background-color: rgba($page_color,.03);
        }
        .article__img__wrap{
          &:after{
            background-color: $page_color;
          }
          &:before{
            border-bottom:15px solid $page_color;
          }
        }
        figcaption{
          .category__block{
            span{
              &.parent__label{
                color:$page_color;
                &:after{
                  background-color:$page_color;
                }
              }
            }
          }
        }
      }
    }
  }
  // 最新記事一覧
  .p-common__new-article{
    $page_color:$primary;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    li{
      a{
        // background-color: rgba($page_color,.03);
        text-decoration: none;
        color:$black;
        display: block;
        figure{
          position: relative;
          .article__img__wrap{
            position: relative;
            padding-top: 70%;
            overflow: hidden;
            transition:.2s;
            &:after{
              content:"";
              display: block;
              position: absolute;
              bottom:0;
              left:0;
              width:100%;
              height:4px;
              transition:.2s;
              background-color: $page_color;
            }
            &:before{
              content:"";
              display: block;
              position: absolute;
              z-index: 1;
              bottom:0;
              left:0;
              right:0;
              margin:auto;
              border-bottom:15px solid $page_color;
              border-right:10px solid transparent;
              border-left:10px solid transparent;
              transform:translateY(15px);
              width:0;
              height:0;
              transition:.2s;
            }
            .article__img{
              position: absolute;
              top:0;
              left:0;
              width:100%;
              height:100%;
            }
          }
          figcaption{
            // text-align: center;
            padding:1rem;
            .category__block{
              margin-bottom: 0.5rem;
              @include mintyo();
              @include media-breakpoint-down(sm) {
                font-family:$font-family-android;
              }
              span{
                &.parent__label{
                  color:$page_color;
                  &:after{
                    content:"";
                    display: inline-block;
                    width:15px;
                    height:2px;
                    background-color:$page_color;
                    vertical-align: middle;
                    margin: 0 5px 2px;
                  }
                }
              }
            }
          }
          .article__titile{
            font-size: rem-calc(18);
            font-weight: bold;
            margin-bottom: 0.5rem;
            text-align: center;
          }
          .article__day{
            margin-bottom: 0;
          }
          .article__label__lists{
            letter-spacing: normal;
            list-style: none;
            padding-left: 0;
            display: flex;
            flex-wrap:wrap;
            margin-bottom: 0.5rem;
            li{
              margin:.2rem .5rem .2rem 0;
              background-color: $secondary;
              border-radius: 20px;
              padding: 0.2rem 0.7rem;
              font-size: rem-calc(13);
            }
          }
        }
        &:hover,
        &:focus{
          .article__img__wrap{
            opacity: .7;
            &:after{
              height:6px;
            }
            &:before{
              transform:translateY(-2px);
            }
          }
        }
      }
      &.fun__article{
        $page_color:$fun_color;
        a{
          // background-color: rgba($page_color,.03);
        }
        .article__img__wrap{
          &:after{
            background-color: $page_color;
          }
          &:before{
            border-bottom:15px solid $page_color;
          }
        }
        figcaption{
          .category__block{
            span{
              &.parent__label{
                color:$page_color;
                &:after{
                  background-color:$page_color;
                }
              }
            }
          }
        }
      }
      &.visit__article{
        $page_color:$visit_color;
        a{
          // background-color: rgba($page_color,.03);
        }
        .article__img__wrap{
          &:after{
            background-color: $page_color;
          }
          &:before{
            border-bottom:15px solid $page_color;
          }
        }
        figcaption{
          .category__block{
            span{
              &.parent__label{
                color:$page_color;
                &:after{
                  background-color:$page_color;
                }
              }
            }
          }
        }
      }
      &.know__article{
        $page_color:$know_color;
        a{
          // background-color: rgba($page_color,.03);
        }
        .article__img__wrap{
          &:after{
            background-color: $page_color;
          }
          &:before{
            border-bottom:15px solid $page_color;
          }
        }
        figcaption{
          // background-color: rgba($page_color,.03);
          .category__block{
            span{
              &.parent__label{
                color:$page_color;
                &:after{
                  background-color:$page_color;
                }
              }
            }
          }
        }
      }
    }
  }
  // 共通リンクボタンリンクスタイル
  .general__link{
    list-style: none;
    padding-left: 0;
    li{
      margin-bottom: 0.5em;
      a{
        text-decoration: none;
        color:$black;
        display: block;
        background-color: $secondary;
        padding:1rem 1.5rem 1rem 1rem;
        height: 100%;
        display: flex;
        align-items: center;
        transition:.2s;
        position: relative;
        color:$primary;
        font-weight: bold;
        &:after{
          content:"";
          display: inline-block;
          position: absolute;
          right:20px;
          top:0;
          bottom:0;
          margin:auto;
          width:10px;
          height:10px;
          border-bottom: 1px solid #707070;
          border-right: 1px solid #707070;
          transform:rotate(-45deg);
        }
        &:hover{
          background-color: darken($secondary,5%);
        }
        span{
          display: flex;
          align-items: center;
        }
      }
    }
  }
  // ver2
  .general__link__ver2{
    list-style: none;
    padding-left: 0;
    justify-content: center;
    li{
      margin-bottom: 0.5em;
      a{
        text-decoration: none;
        color:$black;
        display: block;
        background-color: $secondary;
        padding:1rem 1.5rem 1rem 1rem;
        height: 100%;
        display: flex;
        align-items: center;
        transition:.2s;
        position: relative;
        &:after{
          content:"";
          display: inline-block;
          position: absolute;
          right:20px;
          top:0;
          bottom:0;
          margin:auto;
          width:10px;
          height:10px;
          border-bottom: 1px solid #707070;
          border-right: 1px solid #707070;
          transform:rotate(-45deg);
        }
        &:hover{
          background-color: darken($secondary,5%);
        }
        span{
          display: flex;
          align-items: center;
        }
      }
    }
  }
  .others{
    list-style: none;
    padding-left: 0;
    padding-bottom: 2rem;
    li{
      margin-bottom: 0.5em;
      a{
        text-decoration: none;
        color:$black;
        display: block;
        background-color: $secondary;
        padding:1rem 1.5rem 1rem 1rem;
        height: 100%;
        display: flex;
        align-items: center;
        transition:.2s;
        position: relative;
        &:after{
          content:"";
          display: inline-block;
          position: absolute;
          right:20px;
          top:0;
          bottom:0;
          margin:auto;
          width:10px;
          height:10px;
          border-bottom: 1px solid #707070;
          border-right: 1px solid #707070;
          transform:rotate(-45deg);
        }
        &:hover{
          background-color: darken($secondary,5%);
        }
        span{
          display: flex;
          align-items: center;
          &:before{
            content:"";
            margin-right: 1rem;
            margin-left: 0.5rem;
          }
        }
      }
      &:nth-of-type(1){
        a{
          span{
            &:before{
              content:"";
              display: inline-block;
              background: url(#{$dir}/common/icon_article-list02.svg)no-repeat;
              background-size: 31px 24px;
              width:31px;
              height:24px;
              padding-left: 31px;
            }
          }
        }
      }
      &:nth-of-type(2){
        a{
          span{
            &:before{
              content:"";
              display: inline-block;
              background: url(#{$dir}/common/icon_apps.svg)no-repeat;
              background-size: 18px 31px;
              width:18px;
              height:31px;
              padding-left: 18px;
            }
          }
        }
      }
      &:nth-of-type(3){
        a{
          span{
            &:before{
              content:"";
              display: inline-block;
              background: url(#{$dir}/common/icon_rss.svg)no-repeat;
              background-size: 31px 31px;
              width:31px;
              height:31px;
              padding-left: 31px;
            }
          }
        }
      }
    }
  }
  // リンクボタン
  .common__link-btn{
    list-style: none;
    padding-left: 0;
    padding-bottom: 2rem;
    li{
      margin-bottom: 0.5em;
      a{
        text-decoration: none;
        color:$black;
        display: block;
        background-color: $secondary;
        padding:1rem 1.5rem 1rem 1rem;
        height: 100%;
        display: flex;
        align-items: center;
        transition:.2s;
        position: relative;
        &:after{
          content:"";
          display: inline-block;
          position: absolute;
          right:20px;
          top:0;
          bottom:0;
          margin:auto;
          width:10px;
          height:10px;
          border-bottom: 1px solid #707070;
          border-right: 1px solid #707070;
          transform:rotate(-45deg);
        }
        &:hover{
          background-color: darken($secondary,5%);
        }
      }
    }
  }

  // article ellipse
  .one__ellipse-large{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .one__ellipse-small{
    @include media-breakpoint-down(sm) {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
  .two__ellipse__responsive{
    @include media-breakpoint-down(sm) {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .left_img__block{
    @include media-breakpoint-up(lg) {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
    }
    .img__block{
      text-align: center;
      margin-bottom: 1rem;
      width: 30%;
      @include media-breakpoint-down(md) {
        width:100%;
      }
    }
    .text__block{
      margin-bottom: 1rem;
      width:70%;
      @include media-breakpoint-up(lg) {
        padding-left: 1rem;
      }
      @include media-breakpoint-down(md) {
        width:100%;
      }
      .profile__block{
        display: flex;
        flex-wrap:wrap;
        dt{
          width:20%;
          text-align: center;
          padding: 0.6rem .2rem;
          font-weight: normal;
          @include media-breakpoint-down(sm) {
            width:30%;
          }
          &:nth-of-type(odd){
            background-color: rgba(#888,.2);
          }
        }
        dd{
          width:80%;
          margin-bottom: 0;
          padding: 0.6rem .2rem .6rem 1rem;
          @include media-breakpoint-down(sm) {
            width:70%;
          }
          // border-left: 1px dashed $green;
          &:nth-of-type(odd){
            background-color: rgba(#888,.2);
          }
        }
      }
    }
  }
  .right_img__block{
    @include media-breakpoint-up(lg) {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
    }
    .img__block{
      text-align: center;
      flex:0 0 auto;
      margin-bottom: 1rem;
      width: 30%;
      @include media-breakpoint-down(md) {
        width:100%;
      }
    }
    .text__block{
      margin-bottom: 1rem;
      width: 70%;
      @include media-breakpoint-down(md) {
        width:100%;
      }
      @include media-breakpoint-up(lg) {
        padding-right: 1rem;
      }
      .profile__block{
        display: flex;
        flex-wrap:wrap;
        dt{
          width:20%;
          text-align: center;
          padding: 0.6rem .2rem;
          font-weight: normal;
          @include media-breakpoint-down(sm) {
            width:30%;
          }
          &:nth-of-type(odd){
            background-color: rgba(#888,.2);
          }
        }
        dd{
          width:80%;
          margin-bottom: 0;
          padding: 0.6rem .2rem .6rem 1rem;
          @include media-breakpoint-down(sm) {
            width:70%;
          }
          // border-left: 1px dashed $green;
          &:nth-of-type(odd){
            background-color: rgba(#888,.2);
          }
        }
      }
    }
  }
}