//
// Typo
//

// text-decoration
// @mixin text-decoration( $decoration: underline, $decoration-hover: none ) {
// 	text-decoration: $decoration;

// 	&:focus,
// 	&:hover {
// 		text-decoration: $decoration-hover;
// 	}
// }

// ellipsis
@mixin ellipsis() {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

//
// Utility
//

// full width image
@mixin image-expanded() {
	width: 100%;
	height: auto;
}

// clearfix
@mixin clearfix() {
	&:after {
		content: '';
		clear: both;
		display: block;
	}
}

// image replacement (Scott Kellum Method)
@mixin image-replacement() {
	text-indent: 100%;
	white-space: nowrap;
	overflow: hidden;
	word-wrap: normal;
}

@mixin mintyo(){
	font-family: $font-family-mintyo;
	@media all and (-ms-high-contrast:none) {
		font-family: $font-family-ie-mintyo;
	}
}

@mixin gothic(){
	font-family: $font-family-gothic;
	@media all and (-ms-high-contrast:none) {
		font-family: $font-family-ie-gothic;
	}
}

@mixin opacity(){
	transition:.2s;
	&:hover{
		opacity: .7;
	}
}

// 共通記事一覧
@mixin common_article($test){
	@content;

}