$f-mincho : 游明朝,YuMincho,Yu Mincho,ヒラギノ明朝,serif;;
$f-gothic : 游ゴシック,Yu Gothic,YuGothic,Hiragino Meiryo,sans-serif;
body.lp{
    font-family:游明朝,YuMincho,Yu Mincho,ヒラギノ明朝 ProN W3,Hiragino Mincho ProN,HG明朝E,ＭＳ\ Ｐ明朝,serif;
    &.expo2022{
        .ex{
            &__title{
                font-weight:500;
                font-size:rem(30);
                line-height:calc(42 / 30);
                position:relative;
                @include media-breakpoint-up(md){
                    font-size:rem(36);
                }

                &::before{
                    content:'';
                    display:inline-block;
                    width:rem(40);
                    height:rem(24);
                    background:url($dir + '/lp/item-common-titleicon.svg') center/cover no-repeat;
                    position:absolute;
                    left:0;right:0;
                    margin-left:auto;margin-right:auto;
                    top:rem(-35);
                }
                
            }
            &__title2{
                border-bottom:6px double #C1E4FF;
                font-size:rem(20);
                font-weight:500;
                @include media-breakpoint-up(md){
                    font-size:rem(25);
                    margin-top:rem(50);
                    display:table;
                    margin-left:auto;margin-right:auto;
                    padding-left:rem(50);
                    padding-right:rem(50);
    
                }
            }
        }
        
        .ex01{
           
            &__inner{
                
    
            }
            &__logo{
                max-width:80%;

            }
            &__slider{
                width:100%;
                height:calc(100vh - 135px);
                position:relative;
            }
            &__title{
                width:93%;
                max-width:rem(629);
                position:absolute;
                bottom:57px;
                right:5px;
                z-index: 10;
                @include media-breakpoint-up(lg){
                    width:100%;
                    max-width:48%;
                    bottom:49px;
                    right:42px;
                }
            }
            &__slider-items{
                width:100%;
                height:inherit;
                
            }
            &__slider-item{
                width:100% !important;
                height:inherit;
                &:nth-of-type(1){
                    background:url($dir + '/lp/bg-ex01-slide01.jpg') center/cover no-repeat;
                }
                
            }
        }

        .slick-slider .slick-track, .slick-slider .slick-list{
            height:100%;
            width:100% !important
        }

        .ex02{
            &__title{
                
                margin-top:rem(73);
                @include media-breakpoint-up(md){
                    margin-top:rem(144);
                }
                
            }
            &__row{
                margin-bottom:rem(60);
                @include media-breakpoint-up(md){
                    margin-bottom:rem(103);
                }
            }
            &__img{
                max-width:rem(570);
                img{
                    width:100%;
                }
            }
            &__text{
                font-size:rem(14);
                font-weight:500;
                line-height: calc(28/14);
                @include media-breakpoint-up(lg){
                    font-size: rem(16);
                }

            }
            &__decoration{
                &--wave{
                    height:rem(30);
                    background:url($dir + '/lp/item-common-wave.png') center/contain repeat-x;
                }
                &--fish{
                   max-width:rem(221);
                   position:absolute;
                   bottom:0;
                   right:0 ;
                }
            }
        }
        .ex03{
            @include media-breakpoint-up(md){
                position:relative;
            }
            &__inner{
                padding-bottom:rem(40);
                @include media-breakpoint-up(md){
                    padding-bottom:rem(100);
                }
            }
            &__title{
                margin-top:rem(73);
                @include media-breakpoint-up(md){
                    margin-top:rem(124);
                   
                }
            }
            // &__title2{
            //     border-bottom:6px double #C1E4FF;
            //     font-size:rem(20);
            //     font-weight:500;
            //     @include media-breakpoint-up(md){
            //         font-size:rem(25);
            //         margin-top:rem(50);
            //         display:table;
            //         margin-left:auto;margin-right:auto;
            //         padding-left:rem(50);
            //         padding-right:rem(50);

            //     }
            // }
            &__text{
                max-width:83%;
                margin:0 auto;
                font-size: rem(14);
                font-weight:500;
                @include media-breakpoint-up(md){
                    max-width:100%;
                }
            }
            &__btn{
                
            }
            &__decorations{
                position:relative;
                height:rem(180);
                @include media-breakpoint-up(md){
                    position:static;
                    height:rem(60);
                }
            }
            &__item{
                position:absolute;
                &--01{
                    width:100%;
                    max-width:rem(74);
                    top:0;
                    left:7%;
                    @include media-breakpoint-up(xl){
                        max-width:rem(138);
                    }
                   
                }
                &--02{
                    max-width:rem(65);
                    top:rem(-20);
                    right:5%;
                    
                    @include media-breakpoint-up(xl){
                        max-width:rem(125);
                    }
                  
                }
                &--03{
                    max-width:rem(441);
                    width:auto;
                    bottom: rem(20);
                    left:rem(-130);
                    @include media-breakpoint-up(md){
                        max-width:rem(341);
                        bottom:0;
                    }
                    @include media-breakpoint-up(xl){
                        max-width:rem(579);
                        left:rem(-230);
                        bottom:rem(100);
                    }
                }
                &--04{
                    max-width:rem(180);
                    top:0;
                    right:0;
                    @include media-breakpoint-up(md){
                        max-width:rem(140);
                        top:auto;
                        bottom:0;
                    }
                    @include media-breakpoint-up(xl){
                        max-width:rem(269);
                        bottom:rem(80);
                        right:rem(38);
                    }

                }
            }
        }
        .ex04{
            margin-bottom:100px;
        }

    }
}