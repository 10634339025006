footer{
  background-color: #f5f5f5;
  position: relative;
  padding-top: 1.5rem;
  .footer__link-top{
    letter-spacing: -.4em;
    a{
      letter-spacing: normal;
      position: absolute;
      left:0;
      right:0;
      bottom:100%;
      margin:auto;
      display: inline-block;
      width:0;
      height:0;
      border-radius: 0;
      border-top: 0;
      border-bottom: 30px solid #f5f5f5;
      border-right: 30px solid transparent;
      border-left: 30px solid transparent;
      background: none;
      &:after{
        content:"";
        display: inline-block;
        width:15px;
        height:15px;
        border-top: 1px solid $gray;
        border-right:1px solid $gray;
        position: absolute;
        top: 10px;
        left: -2px;
        right:0;
        margin:auto;
        transform: rotate(-45deg)translate(-50%);
      }
    }
  }
  aside{
    ul.row{
      list-style: none;
      padding-left: 0;
      margin-bottom: 0;
      @include media-breakpoint-only(md) {
        justify-content: center;
      }
      li{
        text-align: center;
        margin-bottom: 1rem;
      }
    }
  }
  .footer__nav{
    display: flex;
    flex-wrap:wrap;
    align-items: center;
    @include media-breakpoint-down(md) {
      display: none;
    }
    dt{
      width:10%;
      margin-bottom: 1rem;
      @include mintyo();
      font-size: rem-calc(18);
      &.footer__nav__know,
      &.footer__nav__visit,
      &.footer__nav__fun{
        &:before{
          content:"";
          display: inline-block;
          vertical-align: middle;
          width:15px;
          height:5px;
          transform: translateY(-50%);
          margin-right: 0.5rem;
        }
      }
      &.footer__nav__know{
        &:before{
          background-color: $know_color;
        }
      }
      &.footer__nav__visit{
        &:before{
          background-color: $visit_color;
        }
      }
      &.footer__nav__fun{
        &:before{
          background-color: $fun_color;
        }
      }
    }
    dd{
      width:90%;
      font-size: rem-calc(14);
      margin-bottom: 1rem;
      ul{
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;
        display: flex;
        flex-wrap:wrap;
        li{
          &:not(:last-of-type){
            margin-right: 1rem;
          }
        }
      }
    }
  }
  .site__footer__nav{
    @include media-breakpoint-up(lg) {
      display: flex;
      justify-content: space-between;
    }
    padding:1rem 0;
    border-bottom: 1px solid $black;
    border-top: 1px solid $black;
    ul{
      list-style: none;
      padding-left: 0;
      margin-bottom: 0;
      &.nav__first{
        display: flex;
        flex-wrap:wrap;
        @include media-breakpoint-down(md) {
          margin-bottom: 1rem;
        }
        li{
          @include media-breakpoint-up(lg) {
            &:not(:last-of-type){
              margin-right: 2rem;
            }
          }
          @include media-breakpoint-down(md) {
            &:not(:last-of-type){
              margin-right: 1rem;
            }
          }
          a{
            &:before{
              content:"";
              display: inline-block;
              vertical-align: middle;
              width:7px;
              height:7px;
              border-bottom: 1px solid $black;
              border-right: 1px solid $black;
              transform:rotate(-45deg);
              margin-right: 1rem;
            }
          }
        }
      }
      &.nav__second{
        display: flex;
        flex-wrap:wrap;
        @include media-breakpoint-down(md) {
          justify-content: center;
        }
        li{
          &:not(:last-of-type){
            margin-right: 1rem;
          }
        }
      }
    }
  }
  address{
    @include media-breakpoint-up(lg) {
      display: flex;
      justify-content: space-between;
    }
    padding:1.5rem 0;
    margin-bottom: 0;
    @include media-breakpoint-down(md) {

    }
    ul{
      padding-left: 0;
      list-style: none;
      margin-bottom: 0;
    }
    .contact__address__first{
      @include media-breakpoint-down(sm) {
        margin-bottom: 1rem;
      }
      .contact__name{
        font-weight: bold;
        font-size: rem-calc(18);
        margin-bottom: 0;
        @include mintyo();
        @include media-breakpoint-down(md) {
          text-align: center;
        }
        img{
          &:not(.en){
            width: 170px;
            margin-left: .5rem;
            vertical-align: text-bottom;
          }
          &.en{
            width:120px;
            margin-left: .5rem;
            vertical-align: text-bottom;
          }
        }
      }
      ul{
        display: flex;
        @include media-breakpoint-down(md) {
          justify-content: center;
          flex-wrap:wrap;
        }
        li{
          &:last-of-type{
            margin-left: .5rem;
            a{
              &:before{
                content:"";
                display: inline-block;
                width:20px;
                height:20px;
                background: url(#{$dir}/common/icon_tel.svg)no-repeat;
                background-size: 16px 16px;
                width:16px;
                height:16px;
                margin-right: 0.2rem;
                // vertical-align: middle;
              }
            }
          }
        }
      }
    }
    .contact__address__second{
      @include media-breakpoint-up(md) {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      
      li{
        background-color: #E2E2E2;
        a{
          display: block;
          padding: .5rem 1rem;
          color:$black;
          @include media-breakpoint-down(sm) {
            text-align: center;
          }
        }
        &:first-of-type{
          @include media-breakpoint-up(md) {
            margin-right: 0.5rem;
          }
          @include media-breakpoint-down(sm) {
            margin-bottom: 0.5rem;
          }
          a{
            &:before{
              content:"";
              display: inline-block;
              background: url(#{$dir}/common/icon_inquiry.svg)no-repeat;
              background-size: 28px 18px;
              width:28px;
              height:18px;
              margin-right: 1rem;
              vertical-align: sub;
            }
          }
        }
        &:last-of-type{
          a{
            &:before{
              content:"";
              display: inline-block;
              background: url(#{$dir}/common/icon_mail.svg)no-repeat;
              background-size: 26px 21px;
              width:26px;
              height:21px;
              margin-right: 1rem;
              vertical-align: sub;
            }
          }
        }
      }
    }
  }
}